import { ReactElement } from 'react';

import { Link } from 'react-router-dom';
import MainStore, { ThemeStore } from 'store';
import { IComment } from 'types';
import { observer } from 'mobx-react';

import CommentCard from './CommentCard';

import './style.scss';

interface ICommentBox {
    comment: IComment;
    onlyRead?: boolean;
    i?: number;
    isLast?: any;
}

const CommentBox = observer(({ comment, onlyRead }: ICommentBox): ReactElement => {
  const { activeTheme } = ThemeStore;
  const { setActivePost } = MainStore;

  return (
    <div
      className={`comments-block comments-block--${activeTheme}`}
      key={comment.id}
    >
      <div className={`comments-card-title comments-card-title--${activeTheme}`}>
        <span>
          Комментарий к
          <Link to={`/post/${comment.articleAuthorLogin.String}/${comment.articleGUID}`} onClick={() => setActivePost(comment.articleGUID)}>{`"${comment.articleTitle}"`}</Link>
          <Link
            to={`/${comment.articleAuthorLogin.String}`}
            onClick={() => (onlyRead ? window.scrollTo({ top: 0, behavior: 'smooth' }) : null)}
          >
            {`(${comment.articleAuthorAlias.String || comment.articleAuthorLogin.String})`}
          </Link>
        </span>
      </div>
      <CommentCard comment={comment} level={0} key={`${comment.id}${Math.random()}`} onlyRead={onlyRead} />
    </div>
  );
});
export default CommentBox;
