import { useEffect } from 'react';

import MainStore, { ModalStore } from 'store';
import { observer } from 'mobx-react';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';
import Input from 'UI/InputText';
import ModalLine from 'UI/Modal/ModalLine';
import ModalNote from 'UI/ModalNote';
import Button from 'UI/Button';

import './style.scss';

const correctiveStyles = {
  display: 'flex',
  width: '70%',
};

const balanceStyle = {
  color: '#337DB2',
  fontWeight: 700,
  cursor: 'pointer',
};

const Contribute = observer(() => {
  const { modalValues, changeHandler } = ModalStore;
  const { userData, setUserBalances, patronBalance, fundConfigProxy } = MainStore;

  useEffect(() => {
    setUserBalances(userData.cyber_name);
  }, [userData.cyber_name, setUserBalances]);

  const minpot = (summ: string) => `${Number(summ.split(' ')[0])} ${summ.split(' ')[1]}`;

  useEffect(() => {
    checkFields(modalValues, reqFields.contrib);
  }, [modalValues]);

  return (
    <div className="w100">
      <div className="patronSumList">
        {userData.sponsor_values.map((el:number) => (
          <Button
            title={el.toString()}
            action={() => changeHandler(el, 'amount')}
            className={`${modalValues.amount === el ? 'patronSumList_buttonsSelected' : ''}`}
            secondary
            disabled={Number(el) > Number(patronBalance)}
          />
        ))}
      </div>
      <ModalLine noteLeft="Количество">
        <div style={correctiveStyles}>
          <Input
            type="number"
            onChange={(val: any) => (
              changeHandler(modalValues.amount && modalValues.amount < 0 ? val.slice(1) : val > Number(patronBalance) ? Number(patronBalance) : val, 'amount')
            )}
            pValue={modalValues.amount}
            noteRight="SAVVA"
            placeholder={`мин. сумма: ${minpot(fundConfigProxy.minamount)}`}
          />
        </div>
      </ModalLine>
      <ModalLine noteLeft="Баланс">
        <div style={correctiveStyles} onClick={() => changeHandler(patronBalance, 'amount')}>
          <div style={balanceStyle}>
            {`${modalValues.amount ? (Number(patronBalance) - Number(modalValues.amount)).toFixed(4) : (patronBalance.toFixed(4) || 0.0000)} ${modalValues.token || 'SAVVA'}`}
          </div>
        </div>
      </ModalLine>
      <ModalNote
        text={`Минимальная сумма: ${minpot(fundConfigProxy.minamount)}, 
        в том числе: ${Number(fundConfigProxy.ashare) * 100}% - донат автору и ${100 - Number(fundConfigProxy.ashare) * 100}% - в Фонд поста`}
        classname="pl5"
      />
      <div className="modal_body_line">
        <span className="label" />
        <InputPassword
          placeholder="Приватный ключ"
        />
      </div>
    </div>
  );
});
export default Contribute;
