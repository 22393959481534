import MainStore, { ModalStore } from 'store';
import apiRequest from 'services/Api';
import {
  getNextPosts, getPostById, getPostsCFG, getRubricsCFG,
} from 'settings/api';
import {
  checkIsBlog,
  firtsUppercase,
  getPic,
  getPostIdFromLink,
} from 'helpers';
import {
  FilterPosts, IPost, IPosts, IRubric,
} from 'types';

interface IStoreActivePost {
  isNeedToChange?: boolean;
}

interface IStoreActivePostReturn {
  content: string;
  post: IPost;
}

export function updatePostCover() {
  const { modalControl, modalValues } = ModalStore;
  const { setPostEditingBanner } = MainStore;

  setPostEditingBanner(modalValues.file);
  modalControl();
}

export async function getContentFromPost(hash: string) {
  const contentData = await fetch(getPic(hash));
  return contentData.text();
}

export async function storeActivePost(props?: IStoreActivePost): Promise<IStoreActivePostReturn | null> {
  const { isNeedToChange } = props || {};
  const { activePost } = MainStore;
  const { id } = getPostIdFromLink();

  if (!id || Number(id) === 0) {
    return null;
  }

  if (activePost && !isNeedToChange) {
    return {
      content: activePost ? await getContentFromPost(activePost.meta.content.hash) : '',
      post: activePost,
    };
  }

  const post = await apiRequest(getPostById(id));

  updatePosts({
    cursor: activePost && activePost?.id === post?.data?.result?.id ? activePost.cursor : '',
    list: [{ ...post?.data?.result || {}, active: true }],
  });

  return {
    content: await getContentFromPost(post.data.result?.meta.content.hash),
    post: post.data.result,
  };
}

export function updatePosts(newPosts: IPosts, isFilter?: boolean) {
  const { setPosts } = MainStore;

  setPosts({
    cursor: newPosts.cursor,
    list: newPosts.list,
  }, isFilter);

  return newPosts;
}

export async function storeNewPosts() {
  const { posts } = MainStore;

  return updatePosts((await apiRequest(getNextPosts(posts.cursor))).data?.list || []);
}

interface IUpdatePostsByFilter {
  id?: string;
  rubric?: string;
  time?: string;
  tag?: string;
  noNeedForCache?: boolean;
}

export const getPostsPage = (): FilterPosts => {
  const { location: { pathname } } = window;
  if (pathname === '/adminhub/posts') return FilterPosts.admin;
  return pathname === '/' ? FilterPosts.popular : checkIsBlog() ? FilterPosts.blog : pathname.split('/')[2] as FilterPosts;
};

export async function updatePostsByFilter(props?: IUpdatePostsByFilter) {
  const { id, rubric, time, tag, noNeedForCache } = props || {};

  const { setActiveRubric } = MainStore;

  setActiveRubric(props?.rubric || '');
  await updatePosts((await apiRequest(getPostsCFG({
    page: getPostsPage(), userId: id, rubricCode: rubric, tag, time, noNeedForCache,
  })))?.data.list || [], true);
}

export function changePostContent(ref: HTMLDivElement | null) {
  const allLinks = ref?.querySelectorAll('a');
  allLinks?.forEach(item => item.setAttribute('target', '_blank'));

  const allImages = ref?.querySelectorAll('img');
  allImages?.forEach(item => item.setAttribute('draggable', 'false'));
}

export async function storeRubrics(): Promise<IRubric[]> {
  const { setRubrics } = MainStore;

  const rubrics = await apiRequest(getRubricsCFG);
  setRubrics(rubrics?.data?.list || []);

  return rubrics?.data?.list || [];
}

export const fundCorretion = async (id: string) => apiRequest({ link: 'article/funds_recalc', method: 'get', params: { id } });

export const cleanedTags = (list: any) => {
  if (!list) return [];
  const clean: any = [];
  for (let i = 0; i < list.length; i++) {
    if (!clean.includes(list[i].tag.trim().toLowerCase())) firtsUppercase(clean.push(list[i].tag.trim().toLowerCase()));
  }
  return clean;
};

export const getBlockedArtsData = async () => {
  const { setBlockedPostsData } = MainStore;

  apiRequest({
    link: 'blocked_content/get_list',
    method: 'get',
  }).then(res => setBlockedPostsData(res?.data?.list || []))
    .catch();
};
