import { observer } from 'mobx-react';
import Amount from 'components/Amount';
import { Link } from 'react-router-dom';
import { timeAgo } from 'helpers';
import { ThemeStore } from 'store';

import { IAwardsLine } from '..';

const RewardLine = ({
  time,
  name,
  user_names,
  content_guid,
  content_title,
  event,
}: IAwardsLine) => {
  const { activeTheme } = ThemeStore;

  return (
    <div className={`walletAwards_line walletAwards_line--${activeTheme}`} key={`${time}-${name}`}>
      <div className="walletAwards_line_info">
        <div className="walletAwards_line_titles">
          <span>Награда из Фонда поста </span>
          <span className="walletAwards_line_link">
            <strong>
              <Link to={`/post/${user_names.cyber}/${content_guid.String}`} className="titleStyle">
                {`"${content_title.String}"`}
              </Link>
            </strong>
          </span>
        </div>
        <span>{timeAgo(time)}</span>
      </div>
      <Amount amount={event.award.split(' ')[0]} fontSize={20} classname="flex1" />
    </div>

  );
};

export default observer(RewardLine);
