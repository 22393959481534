import { getDataBody, getNFTS } from 'helpers';
import { sendTransaction } from 'services/Api';
import { contractAdress } from 'settings/main';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';
import { uint128 } from 'utils/KeyUtils';

const buyNFTPipeline: Pipeline = {
  title: 'buyNFTPipeline',
  tasks: [
    {
      title: 'Покупка NFT',
      task: async (meta: any) => {
        const { userData } = MainStore;
        const data = {
          amount: `${Number(meta.amount).toFixed(4)} SAVVA`,
          author: meta.author,
          storage: 'stihi.io',
          contentguid: uint128(meta.uuid).toString(),
          patron: userData.cyber_name,
        };
        const sendingData = getDataBody(userData.cyber_name, contractAdress, data, 'buynft');
        await sendTransaction(meta.key, sendingData);
      },
      errorShow: (meta: any, error: any) => {
        const cleanError = JSON.parse(JSON.stringify(error)).json.error.code;
        switch (cleanError) {
          case 3050003:
            return `Сумма должна быть больше или равна ${meta.min_amount}`;
          case 3090005:
            return 'Для покупки NFT необходимо использовать активный ключ или ключ владельца';
          default:
            return JSON.parse(JSON.stringify(error)).json.error.message;
        }
      },
    },
    {
      title: 'Запрос стоимости NFT поста',
      task: async (meta: any) => {
        const { uuid, author } = meta;
        const answer = await getNFTS(uuid, author);
        return answer;
      },
    },
    {
      title: 'Обновление стоимости NFT фонда поста',
      task: async (_: any, results) => {
        const { setNftStore } = MainStore;
        const data = results['Запрос стоимости NFT поста'].rows;
        setNftStore(data.length > 0 ? data[0] : {});
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default buyNFTPipeline;
