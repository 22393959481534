import { getDataBody, getNFTS, ShowToast } from 'helpers';
import { sendTransaction } from 'services/Api';
import { contractAdress } from 'settings/main';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';
import { uint128 } from 'utils/KeyUtils';

const saleNFTPipeline: Pipeline = {
  title: 'saleNFTPipeline',
  tasks: [
    {
      title: 'Подготовка данных и покупка NFT',
      task: async (meta: any) => {
        const { userData } = MainStore;
        const data = {
          amount: `${Number(meta.amount).toFixed(4)} SAVVA`,
          author: meta.author,
          storage: 'stihi.io',
          contentguid: uint128(meta.uuid).toString(),
          patron: userData.cyber_name,
        };
        const sendingData = getDataBody(userData.cyber_name, contractAdress, data, 'setnftsale');
        await sendTransaction(meta.key, sendingData);
      },
      errorShow: (meta: any, error: any) => {
        const cleanError = JSON.parse(JSON.stringify(error)).json.error.code;
        if (cleanError === 3050003) ShowToast(`Сумма должна быть больше или равна ${meta.min_amount}`, 'error');
        if (cleanError === 3090005) ShowToast('Для покупки NFT необходимо использовать активный ключ или ключ владельца', 'error');
      },
    },
    {
      title: 'Запрос стоимости NFT поста',
      task: async (meta: any) => {
        const { uuid, author } = meta;
        const answer = await getNFTS(uuid, author);
        return answer;
      },
    },
    {
      title: 'Обновление стоимости NFT фонда поста',
      task: async (_: any, results) => {
        const { setNftStore } = MainStore;
        const data = results['Запрос стоимости NFT поста'].rows;
        setNftStore(data.length > 0 ? data[0] : {});
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default saleNFTPipeline;
