import { useState, useEffect } from 'react';

import Preloader from 'components/Preloader';
import {
  checkCaptcha,
  getCaptcha,
  ShowToast,
} from 'helpers';
import { mainSettings } from 'settings';
import Button from 'UI/Button';
import Input from 'UI/InputText';
import { useMount } from 'hooks';

import './style.scss';

interface ICaptchaProps {
    check: Function;
}

const Captcha = ({ check }: ICaptchaProps) => {
  const [values, setValues] = useState({ captcha_id: '', captcha: '', capTrue: false });

  const setCaptcha = (id: string) => setValues({ ...values, captcha_id: id });

  const checkCaptchaHandler = async (id: string, value: string) => {
    const res = await checkCaptcha(id, value);

    if (res) {
      setValues({ ...values, capTrue: res });
    } else {
      ShowToast('Не верно введена капча', 'error');
      getCaptcha().then((cap: string) => setCaptcha(cap));
    }
  };

  useMount(() => getCaptcha().then((res: string) => setCaptcha(res)));

  useEffect(() => {
    // DISABLED BECAUSE GOT LOOP
    values.capTrue && check({ captcha_id: values.captcha_id, captcha: values.captcha });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div className="w100 captcha">
      {values.captcha_id.length > 0 ? (
        <img
          width="40%"
          src={`${mainSettings.mainSettings.apiLink}captcha/${values.captcha_id}.png`}
          alt="capt"
        />
      )
        : <Preloader />}
      <div className="captcha_input">
        <Input
          onChange={(val: string) => setValues({ ...values, captcha: val })}
          pValue={values.captcha}
          disabled={values.capTrue}
        />
      </div>
      <div style={{ padding: '10px 0px', width: '30%' }}>
        <Button
          title="Проверить код"
          action={() => checkCaptchaHandler(values.captcha_id, values.captcha)}
          disabled={!(values.captcha.length >= 6) || values.capTrue}
        />
      </div>

    </div>
  );
};

export default Captcha;
