import { useEffect } from 'react';

import Input from 'UI/InputText';
import CheckBox from 'UI/InputChechbox';
import * as settings from 'settings/';
import { ModalStore } from 'store';
import Captcha from 'components/Captcha';
import ModalLine from 'UI/Modal/ModalLine';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';

const SignUp = (): JSX.Element => {
  const {
    modalControl,
    changeHandler: storeHandler,
    getModalValues,
    setModalValues,
  } = ModalStore;

  const changeHandler = (key: string, value: string | boolean): void => {
    const p = /[^a-zA-Z0-9]+/g;
    const val = key === 'golos_login' && typeof value === 'string' ? value.toLowerCase().replace(p, '') : value;
    storeHandler(val, key);
  };

  const takeCaptchaData = (obj: object) => {
    setModalValues({ ...getModalValues, ...obj, enableChechbox: true });
  };

  const redirectToAuth = () => {
    modalControl(settings.modalSettings.signIn);
  };

  useEffect(() => {
    checkFields(getModalValues, reqFields.signUp);
  }, [getModalValues]);

  return (
    <div className="w100">
      <div className="modal_body_line">
        <div className="modal_body_regText">
          Приветствуем  Вас на Stihi.io !
          <br />
          Для полноценного пользования порталом необходимо иметь учетную запись в системе CyberWay или на Golos.io.
          Если такой аккаунт у Вас есть, то Вы  можете
          {' '}
          <span style={{ color: '#337DB2', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => redirectToAuth()}>войти</span>
          !
        </div>
      </div>
      <ModalLine noteLeft="Логин*   (допустимые символы: a-z, 0-9)" correctiveStyle="fc as">
        <Input onChange={(value: any) => changeHandler('golos_login', value)} pValue={getModalValues.golos_login} />
      </ModalLine>
      <ModalLine noteLeft="Отображаемое имя (псевдоним)*" correctiveStyle="fc as">
        <Input onChange={(value: any) => changeHandler('user_alias', value)} pValue={getModalValues.user_alias} />
      </ModalLine>
      <div className="modal_body_line">
        <Captcha check={(data: object) => takeCaptchaData(data)} />
      </div>

      {/*             <div className="modal_body_line">
                <span className="modal_body_line_warning">ОБЯЗАТЕЛЬНО СОХРАНИТЕ КЛЮЧИ В НАДЁЖНОМ МЕСТЕ</span>

            </div> */}

      <div className="modal_body_line">
        <span className="modal_body_line_note">
          Stihi.io не хранит Ваши ключи.
          <br />
          {' '}
          Восстановить утерянные ключи невозможно
        </span>

      </div>

      <ModalLine>
        <CheckBox
          checkBoxHandler={() => changeHandler('restore', !getModalValues.restore)}
          label="Я понимаю, что Stihi.io не может восстановить утерянный пароль"
          disabled={!getModalValues.enableChechbox}
        />
      </ModalLine>

      <ModalLine>
        <CheckBox checkBoxHandler={() => changeHandler('saveKeys', !getModalValues.saveKeys)} label="Я надежно сохраню сгенерированные ключи" disabled={!getModalValues.enableChechbox} />
      </ModalLine>

      <ModalLine>
        <CheckBox
          checkBoxHandler={() => changeHandler('terms', !getModalValues.terms)}
          label={'Я подтверждаю согласие с <a href="https://stihi.io/post/evdazo52xujg/f9f2803e-6d3d-48ec-9e04-5fafa61a7069" target="_blank"> Пользовательским Соглашением </a>'}
          disabled={!getModalValues.enableChechbox}
        />
      </ModalLine>
    </div>
  );
};
export default SignUp;
