import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  getPic,
  getUTCTimeToShow,
  updatePostsByFilter,
} from 'helpers';
import { IBlogPreviewProps, IMetaPost } from 'types';
import MainStore, { ModalStore, ThemeStore } from 'store';
import Amount from 'components/Amount';
import FundBox from 'components/FundBox';
import { ReactComponent as Comment } from 'assets/images/comment.svg';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import Preloader from 'UI/Preloader';
import { banArticle, unBanArticle, unBanUser } from 'settings/modal';
import { BADPIC } from 'settings/main';
import { ReactComponent as NFTIcon } from 'assets/images/NFT5.svg';

import BlockedHistory from './BlockedHistory';

export const getPreviwPic = (bnr: string, avatar: string) => getPic((bnr === BADPIC || bnr === '') ? avatar : bnr);

// TODO: all comments in this component should added when backend will be ready

// Note: need discuss 'small' property
const BlogPreview = ({
  id,
  meta = {} as IMetaPost,
  small = false,
  line = false,
  showNotes = true,
  authorAlias,
  authorLogin,
  donated,
  nft,
  donatedCurrent,
  commentsCount,
  nextRoundTime,
  isInternal,
  is_nsfw,
  is_blocked,
  is_blocked_author,
  authorAvatar,
  showBlock = false,
  blockedPage,
}: IBlogPreviewProps) => {
  const {
    isMobileContent,
    lastEventsList,
    userData,
    setShowedNsfw,
    deleteFromShowedNsfw,
    setActivePost,
    showedNsfwList,
    isAdmin,
    setPostFunds,
    getBlockedHistoryById,
  } = MainStore;
  const { activeTheme } = ThemeStore;
  const { modalControl } = ModalStore;
  const [balance, setBalance] = useState<number>(donatedCurrent || 0);
  const [blink, setBlink] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const isBlocked = is_blocked;
  const onClickRubric = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    const { location: { pathname } } = window;

    await updatePostsByFilter({
      ...(!pathname.includes('posts/') && { id: authorLogin }),
      rubric: meta.rubrics ? meta.rubrics[0].code : '',
    });
  };

  const setShow = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (showedNsfwList.includes(id)) {
      deleteFromShowedNsfw(id);
    } else {
      setShowedNsfw(id);
    }
  };

  const fixLengthPreview = (data: string) => {
    if (!data) return;
    // if ((data.slice(data.length - 3) === '...' || data.length < 85) && balance === 0) return data;
    // const length = data.length - 1;
    return `${data.slice(0, balance === 0 ? 85 : 65)}${data.length > 55 ? '...' : ''}`;
  };

  const checkNextRound = (roundData: any, isSidebar: boolean) => {
    if (!roundData) return;

    const nextRound = roundData !== '0001-01-01T00:00:00Z' ? roundData : '';

    return nextRound.length ? (
      <div className={`feedPost_fund--note feedPost_fund--note--small ${line ? 'feedPost_fund--note--line' : ''}`}>
        {
          new Date(roundData) < new Date(new Date().getTime() - 330 * 60 * 1000) ? ('Ожидайте тираж')
            : (
              <>
                {isSidebar ? 'Тираж ' : ''}
                <span style={{ fontWeight: 700, color: '#FFF' }}>{getUTCTimeToShow(nextRound)}</span>
              </>
            )
            }
      </div>
    ) : null;
  };

  useEffect(() => {
    const currBal = lastEventsList.find(el => el.name === 'cntrbtevent' && el.content_guid.String === id);
    if (!currBal) return;
    setBlink(true);
    setBalance(Number(currBal.event.balance.split(' ')[0]));
  }, [lastEventsList, id]);

  const checkNsfw = (text: string | undefined) => {
    if (!text) return;
    const lockedTxt = 'Осторожно, контент содержит нецензурную лексику';
    if (userData.nsfw === 's' || !is_nsfw || showedNsfwList.includes(id) || authorLogin === userData.cyber_name) return text;
    return lockedTxt;
  };

  useEffect(() => {
    blink && setTimeout(() => setBlink(false), 1990);
  }, [blink]);

  const setBlocked = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (!is_blocked_author) {
      const modalS = {
        ...(!is_blocked ? banArticle : unBanArticle),
      };
      modalControl(modalS, { guid: id, author_login: authorLogin, content_type: 'a' });
    } else if (is_blocked_author) {
      modalControl(unBanUser, { cyber_name: authorLogin });
    }
  };

  const openHist = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setOpen(!open);
  };

  const setPrev = () => {
    const arr = window.location.pathname.split('/');
    MainStore.listType = arr.includes('posts') ? arr[2] : 'blog';
  };

  setPrev();

  const hist = getBlockedHistoryById(id);

  return (
    <div style={{ width: '100%' }}>
      <Link
        to={`/post/${authorLogin}/${id}`}
        onClick={() => {
          setActivePost(id);
          setPostFunds({});
        }}
        className={`blogLine_box ${isMobileContent ? 'blogLineMobile_box' : ''} ${(small || line) ? 'blogLine_box--small' : ''}`}
      >
        {(!small && !line) && (
        <div className="blogLine_banner">
          {
            (is_blocked || is_blocked_author) && !blockedPage ? (
              <div className={`blogLine_img ${isMobileContent ? 'blogLineMobile_img' : ''}`}>
                <div className="blogLine_blocked"><Lock /></div>
                <img src={getPreviwPic(meta?.banner?.hash || '', authorAvatar || '')} alt="img_post" />
              </div>
            )
              : (is_nsfw && userData.nsfw === 'w' && !showedNsfwList.includes(id) && authorLogin !== userData.cyber_name)
                ? (
                  <div className="blogLine_nsfw">
                    <span>в н и м а н и е</span>
                    <span>ненормативная</span>
                    <span>л е к с и к а</span>
                  </div>
                ) : (
                  <div className={`blogLine_img ${isMobileContent ? 'blogLineMobile_img' : ''}`}>
                    <img src={getPreviwPic(meta?.banner?.hash || '', authorAvatar || '')} alt="img_post" />
                  </div>
                )
          }
        </div>
        )}
        <div className="blogLine_box_preview">
          <object style={{ display: 'flex' }}>
            <Link to={`/${authorLogin}`}>
              <span
                className={`blogLine_box_preview--author blogLine_box_preview--author--${activeTheme}`}
              >
                {authorAlias || authorLogin}
                {isInternal && <Preloader />}
              </span>
            </Link>
            {
            is_nsfw && authorLogin !== userData.cyber_name && <span className="showNsfw" onClick={setShow}>{showedNsfwList.includes(id) ? 'Скрыть' : 'Показать'}</span>
          }
          </object>
          <span className={`blogLine_box_preview--title blogLine_box_preview--title--${activeTheme}`}>{checkNsfw(meta.title)}</span>
          <span className={`blogLine_box_preview--prev blogLine_box_preview--prev--${activeTheme}`}>{checkNsfw(fixLengthPreview(meta.preview))}</span>
          <div className={`blogLine_box_preview_information blogLine_box_preview_information--${activeTheme} ${line ? 'blogLine_box_preview_information--line' : ''} `}>
            {showNotes && (
            <>
              <span className="blogLine_box_preview_rubric" onClick={onClickRubric}>{meta.rubrics ? meta.rubrics[0].name : ''}</span>
              <span className="dot">·</span>
              {MainStore.blogFilter?.type === 'tag'
                ? (
                  <>
                    <span className="blogLine_box_preview_rubric blogLine_tags">
                      {MainStore.blogFilter?.name}
                    </span>
                    <span className="dot">·</span>
                  </>
                )
                : null}
              <span>{getUTCTimeToShow(meta.time)}</span>
            </>
            )}

            <span className="comment-count">
              <Comment />
              {commentsCount}
            </span>
            {donated ? <Amount justifyContent="flex-start" amount={donated?.toFixed(0) || '0'} /> : ''}
            {nft ? <NFTIcon style={{ width: '18.5px', height: '18.5px', filter: 'invert(0%)', paddingLeft: '15px' }} /> : ''}
            {
          (isAdmin && showBlock) ? (
            <div className="prwBlock" onClick={setBlocked}>
              <Lock />
              <span>
                {is_blocked_author ? 'Разблокировать автора' : !isBlocked ? 'Заблокировать' : 'Разблокировать'}
              </span>
            </div>
          ) : null
          }
            {((blockedPage || isAdmin) && hist.length)
              ? (
                <div className="prwBlock" onClick={openHist}>
                  <span>Блокировки</span>
                </div>
              )
              : null}
          </div>
        </div>
        <div className={`blogLine_box_preview--fund ${line ? 'blogLine_box_preview--fund--line' : ''} ${small ? 'w100' : ''}`}>
          {balance > 0
            ? (
              <FundBox small line={line}>
                <div className={`blogLine_fund ${small ? 'fr' : ''} ${line ? 'blogLine_fund--line' : ''}`}>
                  <span className="flare" style={{ display: blink ? '' : 'none' }} />
                  {!line && (
                  <span className="feedPost_fund--title feedPost_fund--title--small">
                    ФОНД ПОСТА
                  </span>
                  )}
                  <div className={`feedPost_fund--amount feedPost_fund--amount--small ${blink ? 'scaleRide' : 'scaleRideOff'}`}>
                    <Amount amount={balance.toFixed(3)} fontSize={line ? 22 : 26} resize />
                  </div>
                  {checkNextRound(nextRoundTime, showNotes)}
                </div>
              </FundBox>
            ) : <></>}
        </div>
      </Link>
      {
        (isAdmin) ? <BlockedHistory data={hist} openSpoiler={open} theme={activeTheme} closeFunc={openHist} /> : null
      }
    </div>
  );
};
export default observer(BlogPreview);
