const reqFields = {
  sendAtoA: ['balance', 'amount', 'recipient', 'key'],
  stake: ['balance', 'sender', 'amount', 'key'],
  signIn: ['login', 'key'],
  innerSend: ['balance', 'sender', 'amount', 'recipient', 'key'],
  signTransaction: ['key', 'keyType'],
  keyFromPasswd: ['key'],
  signUp: ['golos_login', 'user_alias', 'captcha', 'restore', 'saveKeys', 'terms'],
  contrib: ['uuid', 'author', 'amount', 'key'],
  announce: ['code', 'guid', 'key'],
  ban: ['cyber_name', 'comment'],
  banArt: ['guid', 'comment'],
};
export default reqFields;
