import { ReactElement, useEffect, useState } from 'react';

import { ModalStore } from 'store';
import { observer } from 'mobx-react';
import checkFields from 'utils/checkFields';
import CheckBox from 'UI/InputChechbox';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';
import ModalLine from 'UI/Modal/ModalLine';
import SearchForm from 'UI/SearchForm';

const SignIn = observer((): ReactElement => {
  const { modalValues, changeHandler } = ModalStore;
  const [login, setLogin] = useState<string[]>([]);

  useEffect(() => {
    checkFields(modalValues, reqFields.signIn);
  }, [modalValues]);

  const sr = (val:any) => {
    if (!val) return;
    const names = val.trim().split('|');
    changeHandler(names[0], 'login');
    setLogin(names);
  };

  return (
    <div className="w100">
      <ModalLine noteLeft="Логин" correctiveStyle="fc as">
        <SearchForm searchResult={(val: string | boolean) => sr(val)} full />
      </ModalLine>
      <ModalLine noteLeft="Постинг или активный ключ" correctiveStyle="fc as">
        <InputPassword
          golos={login[1] || ''}
          checkLogin={login[0] || ''}
          placeholder="Введите приватный ключ"
        />
      </ModalLine>
      <div className="modal_body_line">
        <CheckBox label="Оставить меня залогиненым" />
      </div>
    </div>
  );
});
export default SignIn;
