import { firtsUppercase } from 'helpers';
import { ModalStore } from 'store';
import Amount from 'components/Amount';

import { IPageList } from '.';
import './style.scss';

const AnnounceListLIne = ({ code, name, price }: IPageList) => {
  const { modalValues, objectChangeHandler } = ModalStore;

  return (
    <div className={`announceLine ${modalValues.code === code ? 'announceLine_selected' : ''}`} onClick={() => objectChangeHandler({ code, price })}>
      <span className="announceLine_title">{firtsUppercase(name)}</span>
      <Amount amount={(price / 1000).toFixed(4)} />
    </div>
  );
};

export default AnnounceListLIne;
