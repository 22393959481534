import NoPosts from 'pages/components/NoPosts';
import MainStore, { ThemeStore } from 'store';
import { observer } from 'mobx-react';
import BlogPreviewLaround from 'pages/components/BlogPreviewLaround';

import './style.scss';

// TODO: change to dynamic data in this component
const LaroundList = () => {
  const { randomEvents, isMobileContent } = MainStore;
  const { activeTheme } = ThemeStore;

  return (
    <div className="announcementBox">
      {
          randomEvents.length ? randomEvents.map((el: any) => (
            <div className={`blogLine ${isMobileContent ? 'blogLineMobile' : ''} blogLine--${activeTheme}`}>
              <BlogPreviewLaround {...el} />
            </div>
          )) : <NoPosts text="Еще ничего не анонсировали" />
      }
    </div>
  );
};

export default observer(LaroundList);
