import {
  sendTransaction,
  nodeRequest,
  nrequest,
} from 'services/Api';
import {
  tableRowsPatron,
  tableRowsStaked,
  tableRowsFrozen,
  getContributors,
} from 'settings/api';
import {
  BASE_STORAGE,
  mainSettings,
  PATRON_BALANCE_COFFICIENT,
  SAVVA_CONTRACT,
  urls,
} from 'settings/main';
import { showPostingKeys } from 'settings/modal';
import MainStore, { ModalStore } from 'store';
import { createKey, uint128 } from 'utils/KeyUtils';

import { ShowToast, uint64 } from './helpers';

const ecc = require('eosjs-ecc');
const JSONbig = require('json-bigint');

export interface SendActionData {
  from?: string;
  quantity?: string;
  account?: string;
  to?: string;
  memo?: string;
  author?: string;
  website?: string;
  guid?: string;
  hash?: string;
}

interface IGetKeyType {
  key: string;
  type: string;
}

export interface IPermissions {
  id: number;
  account: string;
  code: string;
  message_type: string;
  required_permission: string;
}
export const getUserBlockchainAccount = async (account_name: string) => nodeRequest(urls.getAccount, { account_name });

export const getKeyType = async (key: string, account_name: string) => {
  const { setTrueKey, approveValues } = ModalStore;
  try {
    const account = await getUserBlockchainAccount(account_name);
    const keys:IGetKeyType[] = account.permissions.map((el: any) => ({ type: el.perm_name, key: el.required_auth.keys[0]?.key || '' }));
    try {
      const publicKey = ecc.privateToPublic(key, 'GLS');
      const keyInAction:any = keys.find((el: IGetKeyType) => el.key === publicKey);
      setTrueKey(true);
      return keyInAction.type || false;
    } catch (e) {
      approveValues(false);
      ShowToast('Вы ввели не валидный ключ', 'error');
      setTrueKey(false);
    }
  } catch (e) {
    approveValues(false);
    setTrueKey(false);
    ShowToast('Проверьте ключи связанные с Вашим аккаунтом', 'error');
  }
};

export const getDataBody = (actor: string = '', account: string = '', data: any, name: string = '') => {
  const { getModalSettings, modalValues } = ModalStore;
  return {
    actions: [{
      account,
      name,
      authorization: [{
        actor,
        permission: getModalSettings.permission || modalValues.keyType || 'active',
      }],
      data,
    }],
    data,
  };
};

// TODO delete
/* export const setOpenCyberContract = async (owner: string, token_code: string, ram_payer: string, key: string) => {
  const data = {
    owner,
    token_code,
    ram_payer,
  };
  const sendingData = await getDataBody(ram_payer, 'cyber.stake', data, 'open');
  const result = await sendTransaction(key, sendingData);
  return result;
}; */

export const getGolosName = async (owner: string) => {
  const body = {
    json: true,
    index: 'owner',
    code: 'cyber',
    table: 'username',
    limit: 1,
    lower_bound: {
      id: 0,
      owner,
      scope: 'gls',
      name: '',
    },
    upper_bound: {
      id: 99999999,
      owner,
      scope: 'gls',
      name: 'zzzzzzzzzzzz',
    },
  };
  const result = await nodeRequest(urls.getTableRows, body, mainSettings.releaseNodeLink);
  return result.rows[0]?.name || false;
};

export const resultMessage = (type: string, amount?: number, token_code?: string) => {
  const messages = [
    { type: 'default', message: `Перевод на сумму ${amount} ${token_code} успешно отправлен` },
    { type: 'announces', message: 'Вы успешно анонсировали пост' },
    { type: 'stake', message: `Ваш Stake успешно увеличен на сумму ${amount} ${token_code}` },
    { type: 'send-to-patron', message: `Сумма ${amount} ${token_code} успешно переведена в фонд Мецената` },
    { type: 'withdrawPatron', message: `Сумма ${amount} ${token_code} успешно добавлена к выводу из фонда Мецената` },
    { type: 'safe', message: `${amount} SAVVA успешно отправлены в сейф` },
    { type: 'unsafe', message: `${amount} SAVVA будут возвращены в фонд Мецената` },
    { type: 'cancelwd', message: 'Вывод из фонда Мецената успешно отменён' },
    { type: 'cancelus', message: 'Вывод из сейфа успешно отменён' },
    { type: 'accept', message: 'Операция успешно выполнена' },
    { type: 'withdraw', message: `Сумма ${amount} ${token_code} успешно выведена из Stake` },
    { type: 'delegateuse', message: `Сумма ${amount} ${token_code} делегированы пользователю` },
    { type: 'recalluse', message: `Ресурсы на сумму ${amount} ${token_code} больше не делегированы и доступны только Вам` },
  ];
  return messages.find(x => x.type === type)?.message || '';
};

export const getPermissions = async (account: string) => {
  const body = {
    json: true,
    index: 'action',
    code: 'cyber',
    scope: '',
    table: 'permlink',
    lower_bound: {
      account,
      code: '',
      message_type: '',
    },
    upper_bound: {
      account,
      code: 'zzzzzzzzzzzz',
      message_type: 'zzzzzzzzzzzz',
    },
  };
  const result = await nodeRequest(urls.getTableRows, body);
  return result.rows;
};

export const getTransactionObject = async (sendingData: any) => {
  const expireInSeconds = 60 * 60;
  const info: any = await nodeRequest(urls.getInfo, {});
  const chainDate = new Date(`${info.head_block_time}Z`);
  const expiration = new Date(chainDate.getTime() + expireInSeconds * 1000).toISOString().split('.')[0];
  const block = await nodeRequest(urls.getBlock, { block_num_or_id: info.last_irreversible_block_num });

  return {
    expiration,
    ref_block_num: info.last_irreversible_block_num & 0xFFFF,
    ref_block_prefix: block.ref_block_prefix,
    ...sendingData,
  };
};

// TODO SET RESEND IF ERROR
export const getUserAccountBalances = async (name: string, single?: string) => {
  const userBalances: string[] = [];

  // TODO: change to dynamic
  let currList = [
    { id: 1, name: 'CYBER', token: 'cyber.token' },
    { id: 2, name: 'SAVVA', token: SAVVA_CONTRACT },
  ];

  if (single) {
    currList = currList.filter(x => x.name === single);
  }

  for (let i = 0; i < currList.length; i++) {
    const cur = currList[i];

    if (!cur.token) continue;

    const balance = await nodeRequest(urls.getCurrencyBalance, {
      account: name,
      code: cur.token,
    });
    if (balance?.status === 500) continue;

    userBalances.push(...balance);
  }

  const res: any = {};

  userBalances.forEach(item => {
    const [balance, token] = item.split(' ');
    res[token] = balance;
  });

  return res;
};

export const getUserPermKeys = async (name: string) => {
  const response: any = await nodeRequest(urls.getAccount, {
    account_name: name,
  });

  const userKeys: any[] = [...response.permissions];
  const res: any = {};

  for (let i = 0; i < userKeys.length; i++) {
    if (!userKeys[i].required_auth.keys.length) continue;
    res[userKeys[i].perm_name] = userKeys[i].required_auth.keys[0].key;
  }

  return res;
};

export const getUserContract = async (cyberId: string) => {
  const result = await nodeRequest(urls.getTableRows, { ...tableRowsPatron, lower_bound: { accountname: cyberId }, upper_bound: { accountname: cyberId } });

  if (result.rows.length > 0) {
    return {
      pBalance: result.rows[0].balance,
      sBalance: result.rows[0].sbalance,
      wamount: result.rows[0].wamount,
      swamount: result.rows[0].swamount,
      patronWithdrawDate: result.rows[0].wtime,
      safeWithdrawDate: result.rows[0].swtime,
    };
  }
};

export const getStakedAccounts = async (cyberId: string) => {
  const result = await nodeRequest(urls.getTableRows, {
    ...tableRowsStaked,
    lower_bound: { grantor_name: cyberId, token_code: 'CYBER', recipient_name: '' },
    // upper_bound: { grantor_name: cyberId, token_code: 'CYBER', recipient_name: '' },
  });

  return result.rows.length > 0 ? result.rows.filter((x: any) => x.grantor_name === cyberId) : [];
};

export const getFrozen = async (cyberId: string) => {
  const result = await nodeRequest(urls.getTableRows, {
    ...tableRowsFrozen,
    lower_bound: { grantor_name: cyberId, token_code: 'CYBER', recipient_name: '' },
    // upper_bound: { grantor_name: cyberId, token_code: 'CYBER', recipient_name: '' },
  });

  return result.rows.length > 0 ? result.rows.filter((x: any) => x.grantor_name === cyberId) : [];
};

export const getResolveName = async (name: string, domain: string) => {
  const body: string[] = [`${name}@${domain}`];
  try {
    const result = await nodeRequest(urls.resolveNames, body, mainSettings.releaseNodeLink);
    return result[0].resolved_username;
  } catch (e) {
    return false;
  }
};

export const createPostingKey = async () => {
  const { userData, setBlockchainAccount } = MainStore;
  const { modalValues, modalControl, modalSettings } = ModalStore;

  const newPair = await createKey('POSTING');
  const authorization_object = {
    threshold: 1,
    accounts: [],
    keys: [{
      key: newPair.POSTING.public,
      weight: 1,
    }],
    waits: [],
  };

  const data = {
    account: userData.cyber_name,
    permission: 'posting',
    parent: 'active',
    auth: authorization_object,
  };

  const sendingData = await getDataBody(userData.cyber_name, 'cyber', data, 'updateauth');
  try {
    await sendTransaction(modalValues.key, sendingData);
    setBlockchainAccount(userData.cyber_name);
    modalControl({ ...showPostingKeys, tag: modalSettings.tag, buttonhidden: modalSettings.tag === 'updPosting' }, { keys: newPair.POSTING, active: modalValues.key });
  } catch (e) {
    ShowToast('Не достаточно ресурсов', 'error');
  }
};

export const firstPostingKey = async () => {
  const { modalValues } = ModalStore;

  const authorization_object = {
    threshold: 1,
    accounts: [],
    keys: [{
      key: modalValues.keys.key_posting.public,
      weight: 1,
    }],
    waits: [],
  };

  const data = {
    account: modalValues.cyberKey,
    permission: 'posting',
    parent: 'active',
    auth: authorization_object,
  };

  const sendingData = await getDataBody(modalValues.cyberKey, 'cyber', data, 'updateauth');
  try {
    const res = await sendTransaction(modalValues.keys.key_active.private, sendingData);
    return res;
  } catch (e) {
    ShowToast('Не достаточно ресурсов', 'error');
    return false;
  }
};

export const setPermissionLink = async (account: string, code: string, type: string, key: string, contract: string = 'cyber', action: string = 'linkauth') => {
  const data = {
    account,
    code,
    type,
    requirement: 'posting',
  };

  const sendingData = await getDataBody(account, contract, data, action);
  await sendTransaction(key, sendingData);
};

export const changeOwnPermissionLink = async () => {
  const { userData, setUserPermissionLinks } = MainStore;
  const { getModalValues, modalControl, setLoading } = ModalStore;
  const { code, key, action, type, title } = getModalValues;
  setLoading();
  try {
    await setPermissionLink(userData.cyber_name, code, type, key, 'cyber', action);
    getPermissions(userData.cyber_name).then((res:any) => setUserPermissionLinks(res));
    ShowToast(`Право: ${title}, Успешно обновлено`, 'success');
    modalControl();
  } catch (e: any) {
    let errText: string = '';
    const cleanError = JSON.parse(JSON.stringify(e)).json.error.code;
    switch (cleanError) {
      case 3090005:
        errText = 'Используемый ключ для изминения прав имеет слишком низкий уровень доступа';
        break;
      default:
        errText = `Ошибка с кодом: ${e.message.error.code}. Сообщите в тех. поддержку`;
        break;
    }
    ShowToast(errText, 'error');
    setLoading();
  }
};

export const getHashFromGuid = (guid: string, author: string, storage: string = 'stihi.io') => {
  const GUID:bigint = uint128(guid);
  const hi:bigint = GUID >> 64n;
  const lo:bigint = GUID & 0xFFFFFFFFFFFFFFFFn;
  return (uint64(author) ^ uint64(storage) ^ hi ^ lo);
};

export const getMyShare = async (guid: string, author: string, storage: string = 'stihi.io') => {
  const { userData } = MainStore;
  const hash:bigint = getHashFromGuid(guid, author, storage);
  const body = {
    ...getContributors,
    lower_bound: {
      account: '',
      hash,
    },
    upper_bound: {
      hash,
      account: 'zzzzzzzzzzzz',
    },
  };
  const result = await nodeRequest(urls.getTableRows, JSONbig.stringify(body));
  const my_share: number = result.rows.find((x: any) => x.account === userData.cyber_name)?.share / PATRON_BALANCE_COFFICIENT || 0;
  const total_share: number = result.rows.reduce(
    (acc: number, cv: any) => acc + cv.share / PATRON_BALANCE_COFFICIENT,
    0,
  );
  return { my_share: my_share.toFixed(0), total_share: total_share.toFixed(0) };
};

export const getFunds = async (guid: string, author: string, storage: string = 'stihi.io') => {
  const hash:bigint = getHashFromGuid(guid, author, storage);
  const body = {
    json: true,
    code: 'stihi.patron',
    scope: 'stihi.patron',
    table: 'funds',
    index: 'hash',
    limit: 10,
    lower_bound: {
      hash,
    },
    upper_bound: {
      hash,
    },
  };
  const result = await nrequest(urls.getTableRows, JSONbig.stringify(body));
  return result;
};
// delete
export const getNFTS = async (guid: string, author: string, storage: string = BASE_STORAGE) => {
  const hash:bigint = getHashFromGuid(guid, author, storage);
  const body = {
    json: true,
    code: 'stihi.patron',
    scope: 'stihi.patron',
    table: 'nfts',
    index: 'hash',
    limit: 10,
    lower_bound: {
      hash,
    },
    upper_bound: {
      hash,
    },
  };
  const res = await nrequest(urls.getTableRows, JSONbig.stringify(body));
  return res;
};

export const getNFTState = async (accountname: string) => {
  const body = {
    json: true,
    code: 'stihi.patron',
    scope: 'stihi.patron',
    table: 'nftprice',
    index: 'primary',
    limit: 10,
    lower_bound: {
      accountname,
    },
    upper_bound: {
      accountname,
    },
  };

  const result = await nrequest(urls.getTableRows, JSONbig.stringify(body));
  return result;
};

export const getPostConfig = async () => {
  const body = {
    json: true,
    code: 'stihi.patron',
    scope: 'stihi.patron',
    table: 'config',
    index: 'primary',
  };
  const result = await nodeRequest(urls.getTableRows, JSONbig.stringify(body));
  return result;
};
