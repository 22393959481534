import {
  Page, Text, View, Document, Font,
} from '@react-pdf/renderer';
import keyList from 'UI/Modal/Templates/SignUp/data';
import styles from 'UI/Modal/Templates/SignUp/styles';

interface IPdfDocumentProps {
    keys: any;
    golos: string;
    cyber: string;
    isPosting: boolean;
}

Font.register({
  family: 'Roboto',
  src:
    'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
});

const PdfDocument = ({ keys, golos, cyber, isPosting }: IPdfDocumentProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>
          ОБЯЗАТЕЛЬНО СОХРАНИТЕ КЛЮЧИ В НАДЕЖНОМ МЕСТЕ.
        </Text>
        <Text style={styles.note}>
          Stihi.io не хранит Ваши ключи. Если вы потеряете эти ключи, Вы не сможете получить доступ к Вашей учетной записи, и никто не сможет помочь Вам восстановить ключи.
        </Text>
        <Text style={styles.keyName}>
          {`GOLOS ЛОГИН: ${golos}`}
        </Text>
        <Text style={styles.keyName}>
          {`ID пользователя: ${cyber}`}
        </Text>
        {(isPosting ? keyList : keyList.filter(el => el.type !== 'key_posting')).map(el => (
          <View style={styles.case} key={`${el.title}${el.type}`}>
            <Text style={styles.title}>{el.title}</Text>
            <Text style={styles.keyName}>
              {`Приватный: ${keys[el.type].private}`}
            </Text>
            <Text style={styles.keyName}>
              {`Публичный: ${keys[el.type].public}`}
            </Text>
            <Text style={styles.note}>
              {el.note}
            </Text>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

export default PdfDocument;
