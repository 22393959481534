/* eslint-disable no-lone-blocks */
import ContentWrapper from 'components/Content/ContentWrapper';
import BlogList from 'pages/components/BlogList';
import MainStore, { ThemeStore } from 'store';
import { ReactComponent as Broke } from 'assets/images/pencil.svg';
import { Link, useHistory } from 'react-router-dom';
import { useMount } from 'hooks';

import UsersBlocked from './UsersBlocked';

import './style.scss';

const AdminHub = () => {
  const { isAdmin, isAuth, userData, setMetaDataStore } = MainStore;
  const history = useHistory();
  const { activeTheme } = ThemeStore;

  useMount(() => setMetaDataStore({
    title: 'Админ панель',
    description: 'Управление пользователями и контентов',
  }));
  const rigth = (
    <div className={`adminhub_menu adminhub_menu--${activeTheme}`}>
      <h1>Показать</h1>
      <Link to="/adminhub/posts">Заблокированые посты</Link>
      <Link to="/adminhub/users">Заблокированых пользователи</Link>
    </div>
  );

  if (!isAdmin) {
    return (
      <div className={`userNotFound userNotFound--${activeTheme}`}>
        <Broke />
        <span>
          Страница не доступна
        </span>
        <Link to={isAuth ? `/${userData.cyber_name}` : '/'}>
          {isAuth ? 'Перейти в свой блог' : 'Перейти на главную'}
        </Link>
      </div>
    );
  }
  return (
    <ContentWrapper
      leftBlock={history.location.pathname === '/adminhub/users' ? <UsersBlocked /> : <BlogList isBlockedList />}
      rightBlock={rigth}
    />
  );
};

export default AdminHub;
