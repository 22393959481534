import { useRef } from 'react';

import { Pipeline, PipeStore, PipelineStore } from 'store/PipelineStore';

const usePipeline = (pipeline: Pipeline) => {
  const { createPipeline } = PipeStore;
  const pipelineRef = useRef<PipelineStore>();

  if (!pipelineRef.current) {
    pipelineRef.current = createPipeline(pipeline);
  }

  return pipelineRef.current;
};

export default usePipeline;
