import { useEffect, useState } from 'react';

import { useMount } from 'hooks';
import apiRequest from 'services/Api';
import UserCard from 'UI/UserCard';
import Button from 'UI/Button';
import { ThemeStore } from 'store';
import moment from 'moment';
import { getPic } from 'helpers';

const UsersBlocked = () => {
  const { activeTheme } = ThemeStore;
  const [blockedlist, setBlickedList] = useState<any[]>([]);
  const [filtred, setFiltred] = useState<any[]>([]);
  const [isActual, setIsActual] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<number[]>([]);

  const createList = (list: any[]) => {
    const main: any[] = [];
    for (let i = 0; i < list.length; i++) {
      if (!main.find((el:any) => el.cyber_name === list[i].cyber_name)) {
        const blist = list.filter((el: any) => el.cyber_name === list[i].cyber_name);
        main.push({
          cyber_name: list[i].cyber_name,
          alias: list[i].authorAlias.String,
          golos: list[i].authorGolos.String,
          avatar: list[i].authorAvatar.String,
          unblocked: !!blist[blist.length - 1].UnblockedTime,
          blist,
          id: i,
        });
      }
    }
    return main;
  };

  useEffect(() => {
    const list = isActual ? blockedlist.filter(el => el.unblocked === false) : blockedlist;
    setFiltred(list);
  }, [isActual, blockedlist]);

  useMount(() => {
    apiRequest({
      link: 'profile/blocked_list',
      method: 'get',
    }).then(res => {
      setBlickedList(createList(res.data.list));
    })
      .catch();
  });

  const setOppend = (index: number) => {
    if (isOpen.includes(index)) {
      setIsOpen(isOpen.filter(el => el !== index));
    } else {
      setIsOpen([...isOpen, index]);
    }
  };

  return (
    <div className="subList">
      <div style={{ display: 'flex', gap: '20px', width: '100%', justifyContent: 'flex-end' }}>
        <Button
          action={() => setIsActual(!isActual)}
          className="btn-subscribed"
          title={isActual ? 'Показать всех нарушителей' : 'Показать заблокированыx сейчас'}
        />
      </div>

      {filtred.map((el:any, index: number) => (
        <div key={`${el.cyber}${el.id}`}>
          <div className={`subList_unit subList_unit--${activeTheme}`}>
            <UserCard
              title={`${el.alias || el.golos || el.cyber_name}. Заблокирован: ${el.blist.length} раз`}
              username={el.alias}
              description={`Дата последний блокировки: ${moment(el.blist[el.blist.length - 1]?.BlockedTime.Time).format('Do MMMM y, HH:mm:ss')}`}
              img={getPic(el.avatar)}
            />
            <div className="mr10">
              <Button
                action={() => setOppend(index)}
                className="btn-subscribed"
                title="Подробнее"
              />
            </div>
            <Button
              action={() => {}}
              className="btn-subscribed"
              title={el.blist[el.blist.length - 1]?.UnblockedTime ? 'Заблокировать' : 'Разблокировать'}
            />
          </div>
          <div className={`blockedHistory ${isOpen.includes(index) ? 'blockedHistory--open' : ''}`}>

            {
        el.blist.map((el2: any) => (
          <div className={`blockedHistory_unit shadow blockedHistory_unit--${activeTheme}`} key={el.id}>
            <p>
              <span>Заблокирован:</span>
              <span>{el2.BlockedAt.String}</span>
              <span>{moment(el2.BlockedTime.Time).format('Do MMMM y, h:mm:ss')}</span>
            </p>
            <p>
              <span>Комментарий:</span>
              <span>{el2.BlockComment.String}</span>
            </p>
            <p>
              <span>Разблокирован:</span>
              <span>{el2.UnblockedAt?.String}</span>
              <span>{el2.UnblockedTime ? moment(el2.UnblockedTime.Time).format('Do MMMM y, HH:mm:ss') : '---'}</span>
            </p>
            <p>
              <span>Комментарий:</span>
              <span>{el2.UnBlockedComment?.String || '---'}</span>
            </p>
          </div>
        ))
    }

          </div>
        </div>
      ))}
    </div>
  );
};

export default UsersBlocked;
