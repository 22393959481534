import { observer } from 'mobx-react';
import { ThemeStore } from 'store';

import './style.scss';

interface IBurgerMenuProps{
  onClick?: Function;
  open: boolean;
}

const BurgerMenu = ({ open, onClick }: IBurgerMenuProps) => {
  const { activeTheme } = ThemeStore;

  return (
    <div className={`burgerIcon ${open ? 'burgerIcon_open' : ''} burgerIcon_${activeTheme}`} onClick={() => onClick && onClick(!open)}>
      <span> </span>
      <span> </span>
      <span> </span>
    </div>
  );
};
export default observer(BurgerMenu);
