import { ReactElement } from 'react';

import { ThemeStore } from 'store';
import MarkDownPreview from '@uiw/react-markdown-preview';
import './style.scss';

interface IAboutSelfProps {
    about?: string;
    location?: string;
    site? :string;
}

const AboutSelf = ({ about, site, location }: IAboutSelfProps): ReactElement => {
  const { activeTheme } = ThemeStore;

  return (
    <div className="profileInfo">
      <div className="profileInfo_unit">
        <div className="profileInfo_unit_box">
          {about && <MarkDownPreview source={about} linkTarget="_blank" />}
          {location && (
            <>
              <span className={`profileInfo_unit_box_title profileInfo_unit_box_title--${activeTheme}`}>Живу</span>
              <span className="profileInfo_unit_box_text">{location}</span>
            </>
          )}
          {site && (
            <>
              <span className={`profileInfo_unit_box_title profileInfo_unit_box_title--${activeTheme}`}>ВЕБСАЙТ</span>
              <span className="profileInfo_unit_box_text">
                <a href={site} target="_blank" rel="noreferrer" className={`profileInfo_link profileInfo_link--${activeTheme}`}>
                  {site}
                </a>
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutSelf;
