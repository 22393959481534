import { getDataBody, SendActionData } from 'helpers';
import { postCache } from 'pages/PostEditor';
import apiRequest, { sendTransaction } from 'services/Api';
import LocalStorage from 'services/LocalStorage';
import { addPostCFG, prepareContentCFG, putPostCFG } from 'settings/api';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';

const { addNewPostToList, setPostEditMode } = MainStore;
const addPostPipeline: Pipeline = {
  title: 'addPostPipeline',
  tasks: [
    {
      title: 'Сохраняем пост на серверах stihi.io',
      task: async (meta: any) => {
        const { data: { result } } = await apiRequest(meta.isExisting ? putPostCFG : addPostCFG, meta.body, 'media');
        return result;
      },
    },
    {
      title: 'Сохраняем пост в блокчейне',
      task: async (meta: any, results: any) => {
        const uObject = LocalStorage.get('u');
        const cyber_name = uObject ? uObject.cyber_name : 'a';
        const { id: guid, metaFile: { hash } } = results['Сохраняем пост на серверах stihi.io'];
        const data: SendActionData = {
          author: cyber_name,
          website: 'stihi.io',
          guid,
          hash,
        };
        const sendingData = await getDataBody(cyber_name, 'stihi.patron', data, 'reg');
        const res = await sendTransaction(meta.key, sendingData);
        return res;
      },
    },
    {
      title: 'Публикация поста',
      task: async (meta: any, results: any) => {
        const postData = results['Сохраняем пост на серверах stihi.io'];
        const { id: guid } = postData;
        const uObject = LocalStorage.get('u');
        const cyber_name = uObject ? uObject.cyber_name : 'a';
        const res = await apiRequest(prepareContentCFG, { id: guid });
        addNewPostToList(postData);
        LocalStorage.remove(postCache); // test deploy
        meta.isExisting ? setPostEditMode(false) : window.location.href = `/${cyber_name}`;
        return res;
      },
      errorShow: (_: any, e: any) => {
        if (e.message.includes('action declares irrelevant authority')) {
          return 'Не достаточно прав для ключа, проверьте в настройках разрешений';
        }
        return 'У Вас недостаточно ресурсов';
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default addPostPipeline;
