import { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { getPostIdFromLink } from 'helpers';

const usePostId = () => {
  const history = useHistory();

  const [id, setId] = useState(getPostIdFromLink(history).id);

  history.listen(() => setId(getPostIdFromLink(history).id));

  return id;
};

export default usePostId;
