import { ReactElement } from 'react';

import { getPic } from 'helpers';
import { observer } from 'mobx-react';
// import Camera from 'assets/images/camera.svg';
import * as s from 'settings/modal';
import MainStore, { ModalStore } from 'store';
import { ReactComponent as Dustbin } from 'assets/images/dustbin.svg';
import { ReactComponent as Camera } from 'assets/images/camera.svg';
import Blockuser from 'assets/images/blockuser-line.png';

interface IProfileMainBoxDesktopProps {
  profileData: any;
  id: string;
  activeTheme: string;
}

const ProfileMainBoxDesktop = ({ id, profileData, activeTheme }: IProfileMainBoxDesktopProps): ReactElement => {
  const { checkIsUser } = MainStore;
  const { modalControl } = ModalStore;
  return (
    <>
      <div className={`profile-header_avatar profile-header_avatar--${activeTheme}`}>
        {profileData.blocked.blocked_actual
          && (
            <div className="profile-header_avatar--blocked">
              <img src={Blockuser} alt="blc" />
              <span>Пользователь заблокирован</span>
            </div>
          )}
        <img src={getPic(profileData.avatar, 'avatar')} alt="avatar" />
        {checkIsUser(id) && (
          <>
            <div className="profile-header_avatar--upload" onClick={() => modalControl(s.uploadAvatar)}>
              <Camera />
            </div>
            {profileData.avatar.length
              ? (
                <div className="profile-header_avatar--delete" onClick={() => modalControl(s.deleteAvatar)}>
                  <Dustbin />
                </div>
              ) : null}
          </>
        )}
      </div>
      <div
        className={`profile-header_banner profile-header_banner--${activeTheme}`}
        style={{
          backgroundImage: `url(${getPic(profileData.banner, 'banner')})`,
          backgroundSize: 'cover',
          width: '100%',
          height: '200px',
        }}
      >
        {checkIsUser(id) && (
          <>
            <div className="profile-header_banner--upload" onClick={() => modalControl(s.uploadBanner)}>
              <Camera />
              <span>Изменить обложку</span>
            </div>
            {profileData.banner
              ? (
                <div className="profile-header_banner--delete" onClick={() => modalControl(s.deleteBanner)}>
                  <Dustbin />
                </div>
              ) : null}
          </>
        )}
      </div>
    </>
  );
};
export default observer(ProfileMainBoxDesktop);
