import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    fontFamily: 'Roboto',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 12,
    color: '#333333',
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingBottom: 10,
  },
  note: {
    fontSize: 10,
    color: '#959595',
    paddingBottom: 10,
  },
  case: {
    flexDirection: 'column',
    padding: '5px 0px',
    borderBottom: 1,
    borderColor: '#bdbdbd',

  },
  keyName: {
    fontSize: 10,
    color: '#333333',
    lineHeight: '12px',
    paddingBottom: 5,
  },
  img: {
    width: 30,
  },
  keyBox: {
    flexDirection: 'column',
  },
});

export default styles;
