import {
  ReactElement, useCallback, useEffect, useMemo, useState, useRef,
} from 'react';

import {
  Link,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import apiRequest from 'services/Api';
import { observer } from 'mobx-react';
import Settings from 'pages/Profile/Settings';
import MainStore, * as Store from 'store';
import * as Types from 'types';
import * as c from 'settings/profile';
import { getUserById } from 'settings/api';
import {
  checkLogin,
  getGolosName,
  getResolveName,
  getUserBlockchainAccount,
  handleSubscribe,
} from 'helpers';
import { useUnMount } from 'hooks';
import { IProfileData, IRoute } from 'types';
import { MOBILE_POINT_MENU } from 'settings/main';
import { banUser, unBanUser } from 'settings/modal';
import PrivateRoute from 'components/PrivateRoute';
import MenuTabs from 'UI/MenuTabs';
import ProfileWrapper from 'pages/Profile/ProfileWrapper';
import SubscribersList from 'pages/Profile/Subscribers';
import CommentsUser from 'pages/Profile/Comments';
import BlogList from 'pages/components/BlogList';
import MenuProfile from 'UI/MenuProfile';
import Button from 'UI/Button';
import { ReactComponent as Broke } from 'assets/images/pencil.svg';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import Blockuser from 'assets/images/blockuser.png';
import Preloader from 'UI/Preloader';
import SliderButton from 'UI/SliderButton';
import NFTList from 'pages/components/NFTList';

import ProfileMainBoxDesktop from './ProfileMainBox/ProfileMainBoxDesktop';
import ProfileMainBoxMobile from './ProfileMainBox/ProfileMainBoxMobile';
import Wallet from './Wallet';
import ProfileSidebar from './ProfileSidebar';

import './style.scss';

interface KeyString {
  [key: string]: string;
}
const Profile = ({ match }: Types.RouteComponentProps): ReactElement => {
  const {
    isAuth, checkIsUser, screenWidth, updateUser, userData, setSubscribed, isAdmin, updateBlockedUser, setMetaDataStore,
  } = Store.default;
  const history = useHistory();
  const { modalControl } = Store.ModalStore;
  const { activeTheme } = Store.ThemeStore;
  const [loading, setLoading] = useState<boolean>(true);
  const [id, setId] = useState('');
  const [basePath, setBasePath] = useState<string>('');
  const [profileData, setProfileData] = useState<IProfileData | null>(null);
  const refContent = useRef<HTMLDivElement>(null);
  const refHeader = useRef<HTMLDivElement>(null);
  const [pseudoGolos, setPseudoGolos] = useState<string>('');

  const { uid } = match.params;
  // const id = uid?.includes('/') ? uid.split('/')[0].replace('@', '') : uid?.replace('@', '');
  const isMobile = screenWidth <= MOBILE_POINT_MENU;
  const menu = checkIsUser(id) ? c.ownerMenu : c.ownerMenu.filter(x => x.auth === false);

  useEffect(() => {
    if (uid.includes('@')) {
      const chid = uid?.includes('/') ? uid.split('/')[0].replace('@', '') : uid?.replace('@', '');
      setPseudoGolos(chid);
      getResolveName(chid, 'golos')
        .then(res => {
          if (!res) {
            setLoading(false);
            setProfileData(null);
            return;
          }
          setId(res);
        })
        // eslint-disable-next-line
        .catch(e => setProfileData(null));
    } else {
      getGolosName(uid).then(res => {
        if (!res) return;
        history.push(`/@${res}`);
        setPseudoGolos(res);
      });
      setId(uid);
    }
    setBasePath(`/${uid}`);
  }, [uid, history]);

  useEffect(() => {
    const pname = profileData?.alias || profileData?.golos_name || profileData?.cyber_name;
    const listTitles: KeyString = {
      subscriptions: 'Подписчики',
      subscribers: 'Подписки',
      'nft-collections': 'Коллекция NFT',
      wallet: 'Кошелек',
      balances: 'Балансы',
      patron: 'Блокнот Мецената',
      awards: 'Награды',
      donats: 'Полученные донаты',
      foundations: 'Из фондов поста',
      history: 'История транзакций',
      comments: 'Комментарии',
      sended: 'Оставленные',
      received: 'Полученные',
    };
    const listLink = history.location.pathname.split('/').filter(el => !!el && el !== id);
    let title: string = `${pname} ::`;
    for (let i = 0; i < listLink.length; i++) {
      title += ` ${listTitles[listLink[i]]} `;
      if (i < listLink.length - 1) title += '::';
    }
    setMetaDataStore({
      title,
      description: `Профиль автора  ${pname} на сайте stihi.io`,
    });
  }, [history.location.pathname, id, profileData, setMetaDataStore]);

  useEffect(() => {
    if (!id.length) return;
    apiRequest(getUserById(id)).then(res => {
      if (res.data?.profile !== null) {
        checkIsUser(id) && updateUser(res.data.profile);
        setProfileData(res.data.profile);
      } else {
        getUserBlockchainAccount(id)
          .then(resp => {
            setProfileData({
              cyber_name: resp.account_name,
              golos_name: '',
              names: [],
              keys: [],
              email: '',
              site: '',
              alias: resp.account_name,
              avatar: '',
              banner: '',
              location: '',
              gender: '',
              about: '',
              sponsor_values: [],
              subscribed: false,
              subscribers_count: 0,
              subscriptions_count: 0,
              tags: [],
              rubrics: [],
              blocked: {},
              unblocked: {},
              nsfw: 'h',
            });
          })
          // eslint-disable-next-line
          .catch();
      }
      setLoading(false);
      // eslint-disable-next-line
    }).catch();
  }, [id, checkIsUser, updateUser]);

  useEffect(() => {
    if (!profileData) return;
    setSubscribed({
      subscribers_count: profileData!.subscribers_count || 0,
      subscriptions_count: profileData!.subscriptions_count || 0,
    });
  }, [profileData, setSubscribed]);

  useEffect(() => {
    id === userData.cyber_name && setProfileData(userData as unknown as IProfileData);
  }, [userData, id]);

  useUnMount(() => setProfileData(null));

  const updateProfileData = useCallback(async () => {
    const res = await apiRequest(getUserById(id));
    setProfileData(res.data.profile);
  }, [id]);

  const updateProfileDataBl = useCallback(async () => {
    const res = await apiRequest(getUserById(id));
    updateBlockedUser(res.data.profile.blocked.blocked_actual);
    setProfileData(res.data.profile);
  }, [id, updateBlockedUser]);

  const onClickSubscribe = async () => {
    if (!profileData) return;

    await handleSubscribe({
      subscribed: profileData.subscribed,
      cyberName: profileData.cyber_name,
      alias: profileData.alias,
      cb: updateProfileData,
    });
  };

  const blogMenu: c.IBlogOwnerMenu[] = [{
    name: 'Блог',
    link: '/',
  }, {
    name: 'Подписки',
    link: '/subscriptions/',
    count: MainStore.subscribe.subscriptions_count,
  }, {
    name: 'Подписчики',
    link: '/subscribers/',
    count: MainStore.subscribe.subscribers_count,
  }, {
    name: 'Коллекция NFT',
    link: '/nft-collections/',
  },
  {
    name: 'Комментарии',
    link: '/comments/',
  }];

  const routes: IRoute[] = useMemo(() => ([
    { path: '/nft-collections/', auth: false, component: <NFTList id={id} /> },
    { path: '/comments/', auth: false, component: <CommentsUser id={id} bPass={basePath} /> },
    { path: '/subscribers/', auth: false, component: <SubscribersList id={id} updateProfile={updateProfileData} /> },
    { path: '/subscriptions/', auth: false, component: <SubscribersList id={id} updateProfile={updateProfileData} /> },
    { path: '/wallet/', auth: false, component: <Wallet id={id} bPass={basePath} /> },
    { path: '/settings/', auth: true, component: <Settings id={id} bPass={basePath} /> },
    { path: '/', auth: false, component: <BlogList id={id} /> },
  ]), [id, updateProfileData, basePath]);

  const setBlocked = async () => {
    const modalS = {
      ...(!profileData?.blocked.blocked_actual ? banUser : unBanUser),
      backFunc: updateProfileDataBl,
    };

    modalControl(modalS, { cyber_name: profileData?.cyber_name || '' });
  };

  return (
    <>
      {profileData && (!profileData?.blocked.blocked_actual || isAdmin || checkIsUser(id)) ? (
        <>
          <div className={`profile-header-wrapper profile-header-wrapper--${activeTheme}`} ref={refHeader}>
            <div className={`profile-header ${isMobile ? 'profile-header--mobile' : ''}`}>
              {!isMobile
                ? <ProfileMainBoxDesktop id={id} profileData={profileData} activeTheme={activeTheme} />
                : <ProfileMainBoxMobile id={id} profileData={profileData} activeTheme={activeTheme} />}
              <div className={`profile-header_detail ${isMobile ? 'profile-header_detail--mobile' : ''}`}>
                <span
                  className={`profile-header_detail_nickName ${isMobile ? 'profile-header_detail_nickName--mobile' : ''} profile-header_detail_nickName--${activeTheme}`}
                >
                  {pseudoGolos.length ? `@${pseudoGolos}` : checkLogin(profileData.names.length > 0 ? profileData.names[0].name : '', profileData.cyber_name)}
                </span>
                <div className={`profile-title-container ${isMobile ? 'profile-title-container--mobile' : ''}`}>
                  <span className={`profile-header_detail_realName ${isMobile ? 'profile-header_detail_realName--mobile' : ''} profile-header_detail_realName--${activeTheme}`}>
                    {profileData.alias || profileData.cyber_name}
                  </span>
                  <span style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {
                      isAdmin ? (
                        <SliderButton
                          title={!profileData?.blocked.blocked_actual ? 'Заблокировать' : 'Разблокировать'}
                          svg={<Lock />}
                          onClick={setBlocked}
                        />
                      ) : null
                    }
                  </span>
                  {(!checkIsUser(profileData.cyber_name) && isAuth)
                    ? (
                      <Button
                        title={profileData.subscribed ? 'Вы подписаны' : 'Подписаться'}
                        className="subscribe-btn"
                        action={onClickSubscribe}
                      />
                    ) : null}
                </div>
                {!isMobile
                  ? (
                    <div className={`profile-header_detail_navigation profile-header_detail_navigation--${activeTheme} pl0`}>
                      <MenuTabs list={blogMenu} prefix={basePath} paddingLeftNone />
                      <div className="profile-header_detail_navigation--second">
                        <MenuTabs list={menu} prefix={basePath} />
                      </div>
                    </div>
                  ) : <MenuProfile list={[...c.blogMenu, ...menu]} prefix={basePath.slice(0, -1)} />}
              </div>
            </div>
          </div>
          <ProfileWrapper>
            <ProfileSidebar profileData={profileData} id={id} refContent={refContent} refHeader={refHeader} />
            <div className={`profileContent profileContent_${activeTheme}`} ref={refContent}>
              <Switch>
                {routes.map((el: any): JSX.Element => (
                  el.auth
                    ? <PrivateRoute key={el.path} auth={isAuth} path={`${basePath}${el.path}`}>{el.component}</PrivateRoute>
                    : (
                      <Route key={el.path} path={`${basePath}${el.path}`}>
                        {el.component}
                      </Route>
                    )
                ))}
              </Switch>
            </div>
          </ProfileWrapper>
        </>
      ) : (
        loading ? <div className="userNotFound userNotFound--transparentBackground"><Preloader /></div>
          : (
            <div className={`userNotFound userNotFound--${activeTheme}`}>
              {profileData?.blocked.blocked_actual ? <img src={Blockuser} alt="bu" /> : <Broke />}
              <span>
                {`Пользователь ${profileData?.blocked.blocked_actual ? 'заблокирован за нарушение Пользовательского соглашения' : 'не найден'}...`}
              </span>
              <Link to={isAuth ? `/${userData.cyber_name}` : '/'}>
                {isAuth ? 'Перейти в свой блог' : 'Перейти на главную'}
              </Link>
            </div>
          )
      )}
    </>
  );
};

export default observer(Profile);
