import {
  useEffect, useState,
} from 'react';

import UserCard from 'UI/UserCard';
import PostBottomBar from 'pages/Post/PostBottomBar';
import AddSimpleComment from 'pages/Post/AddSimpleComment';
import {
  changePostContent,
  getPic,
} from 'helpers';
import MarkDownPreview from '@uiw/react-markdown-preview';
import { IComment } from 'types';
import Preloader from 'UI/Preloader';
import { useHistory } from 'react-router';
import MainStore, { CommentsStore, ModalStore } from 'store';
import { banArticle } from 'settings/modal';
import { observer } from 'mobx-react';
import { useMount, useUnMount } from 'hooks';

interface IPostCommentCardProps {
  comment: IComment;
  level: number;
  isPost?: boolean;
  onlyRead?: boolean;
  parRef?: any;
  ind?: number;
}

const CommentCard = (
  {
    comment: {
      id: comId, authorLogin, content, articleGUID, meta: { time, parent_guid }, authorAvatar, authorAlias, isInternal, comments, child_count,
    },
    level,
    isPost,
    onlyRead,
  }: IPostCommentCardProps,
) => {
  const { setActivePost, isAdmin } = MainStore;

  const [offsetByMain, setOffsetByMain] = useState<number>(0);

  const { modalControl } = ModalStore;
  const {
    getMoreComments, dropGetMore, dropMoreId, dropMoreIdHandle, updateMore, updateMoreHandle,
  } = CommentsStore;
  const [addCommentEnabled, setAddCommentEnabled] = useState<boolean>(false);
  const [editCommentEnabled, setEditCommentEnabled] = useState<boolean>(false);
  const [childrens, setChildrens] = useState<any>({ list: comments || [], cursor: '' });
  const history = useHistory();

  const calcHeight = (currentId: string, parrentId: string) => {
    if (level < 1) return 0;
    const currentData: any = document.getElementById(currentId)?.getBoundingClientRect();
    const parrentData: any = document.getElementById(parrentId)?.getBoundingClientRect();
    const position = window.pageYOffset;
    const mainYPos = parrentData?.y - position;
    const childYpos = currentData?.y - position;
    return childYpos - mainYPos;
  };

  const onClickEdit = () => {
    setEditCommentEnabled(true);
    setAddCommentEnabled(true);
  };

  const replaceGolos = (data: any) => {
    if (!data) return '';
    return data.replaceAll('golos.io', 'stihi.io');
  };

  const gotopost = () => {
    setActivePost(articleGUID);
    history.push(`/post/${authorLogin}/${articleGUID}`);
  };

  const blockDiv = async (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!isAdmin) return;
    e.preventDefault();
    modalControl(banArticle, { guid: comId, author_login: authorLogin, content_type: 'c' });
  };

  useEffect(() => {
    if (getMoreComments[comId]) {
      setChildrens({ list: getMoreComments[comId] || [], cursor: '' });
    } else if (dropMoreId === comId) {
      setChildrens({ list: [], cursor: '' });
      dropMoreIdHandle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMoreComments]);

  useEffect(() => {
    if (level < 1) return;
    setOffsetByMain(calcHeight(comId, parent_guid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrens, getMoreComments, dropMoreId, updateMore]);

  useEffect(() => {
    isPost && Object.keys(getMoreComments).length && dropGetMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPost]);

  useMount(() => {
    if (level < 1) return;
    setOffsetByMain(calcHeight(comId, parent_guid));
  });

  useUnMount(() => {
    setOffsetByMain(calcHeight(comId, parent_guid));
  });

  return (
    <div className="treeContainer" key={comId}>
      <div>
        <div
          className="comment-card"
          style={{ marginLeft: level > 4 ? 4 * 60 : level * 60, marginTop: 3, width: '100%', position: 'relative' }}
        >
          <div className="comment-card-heading" style={{ position: 'relative' }} id={comId}>
            <UserCard
              title={authorAlias || authorLogin || ''}
              username={authorLogin || ''}
              img={getPic(authorAvatar || '')}
              marginName={-15}
            />
            {isInternal && <Preloader />}
            {level > 0
              ? (
                <div
                  className="commentToParent"
                  style={{ height: `${offsetByMain - (level > 4 ? -10 : 28)}px`, top: `-${offsetByMain - (level > 4 ? 15 : 52)}px` }}
                />
              ) : null}
          </div>
          <div
            className="comment-content"
            style={{ marginTop: '-25px', marginLeft: '-3px' }}
            ref={ref => changePostContent(ref)}
          >
            <MarkDownPreview source={replaceGolos(content) || ''} linkTarget="_blank" />
          </div>
          <PostBottomBar
            authorAvatar={authorAvatar || ''}
            level={level}
            authorLogin={authorLogin}
            time={time}
            paddingBottom={2}
            parrent={comId}
            isMore={child_count ? child_count > 0 && level < 4 : false}
            addCommentText="Ответить"
            addCommentEnabled={addCommentEnabled}
            setCommentLess={gotopost}
            setAddCommentEnabled={(s: boolean) => { setAddCommentEnabled(s); updateMoreHandle(); }}
            onClickEdit={onClickEdit}
            isPost={isPost}
            blockComment={blockDiv}
          />

          <AddSimpleComment
            margin="10px 0px 0px 65px"
            level={level + 1}
            id={comId}
            parentId={editCommentEnabled ? parent_guid : comId}
            articleId={articleGUID}
            addCommentEnabled={addCommentEnabled}
            setAddCommentEnabled={(s: boolean) => { setAddCommentEnabled(s); updateMoreHandle(); }}
            setEditCommentEnabled={(s: boolean) => { setEditCommentEnabled(s); updateMoreHandle(); }}
            content={editCommentEnabled ? content as string : ''}
            isPost={isPost}
            editCommentEnabled={editCommentEnabled}
          />

        </div>
        {childrens.list.map((el: IComment, index: number) => (
          <CommentCard
            comment={el}
            level={level + 1}
            isPost={isPost}
            onlyRead={onlyRead}
            ind={index}
            key={el.id}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(CommentCard);
