import logo from 'assets/images/logo.svg';
import logoDark from 'assets/images/logo_dark.svg';
import sun from 'assets/images/sun.svg';
import moon from 'assets/images/moon.svg';
import logoMobileDark from 'assets/images/logo_mobile_dark.svg';
import logoMobileLight from 'assets/images/logo_mobile_light.svg';

export const isDEV = window.location.origin !== 'https://stihi.io';
export const BASE_LINK = isDEV ? 'https://test.stihi.io/' : `${window.location.origin}/`;
export const API_VERSION = 'api/v3/';
export const BASE_NODE_LINK = isDEV ? 'https://node_test.stihi.io' : 'https://node-api.stihi.io';
export const CHAIN_VERSION = '/v1/chain/';
export const listReserveNode = ['https://node-cyberway.golos.io'];
export const BASE_STORAGE = 'stihi.io';

export const mainSettings = {
  apiLink: `${BASE_LINK}${API_VERSION}`,
  nodeLink: `${BASE_NODE_LINK}`,
  releaseNodeLink: isDEV ? 'https://node.stihi.io' : 'https://node-api.stihi.io',
  logo,
  themesList: [
    {
      id: 1,
      name: 'light',
      logo,
      logoMobile: logoMobileLight,
      next: 'dark',
      next_name: 'Ночной режим',
      color: '#fff',
      icon: moon,
    },
    {
      id: 2,
      name: 'dark',
      next: 'light',
      logo: logoDark,
      logoMobile: logoMobileDark,
      next_name: 'Дневной режим',
      color: '#222',
      icon: sun,
    },
  ],
};

export enum urls {
  resolveNames = 'resolve_names',
  getTableRows = 'get_table_rows',
  getCurrencyBalance = 'get_currency_balance',
  getAccount = 'get_account',
  getInfo = 'get_info',
  getBlock = 'get_block',
}

export const BADPIC = 'QmbFMke1KXqnYyBBWxB74N4c5SBnJMVAiMNRcGu6x1AwQH';
export const SAVVA_CONTRACT = 'cw.token';
export const contractAdress = 'stihi.patron';
export const PATRON_BALANCE_COFFICIENT = 10000;
export const SAFE_BALANCE_COFFICIENT = 10000;
export const MOBILE_POINT_MENU = 768;
export const MOBILE_POINT_CONTENT = 414;
export const CONNECTION_TIMEOUT = 30000;

export const LINK_PATTERN = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
export const USER_NAME_PATTERN = /@[a-z]\w+[a-z]\b/gi;

export const INPUT_SYMBOLS_CRYPTO = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'.split('');
export const OUTPUT_SYMBOLS_CRYPTO = 'NOPQRSTUVWXYZABCDEFGHIJKLMnopqrstuvwxyzabcdefghijklm1029384756'.split('');

export const initialUserData = {
  cyber_name: '',
  golos_name: '',
  names: [],
  keys: {
    type: '',
    key: '',
  },
  site: '',
  alias: '',
  avatar: '',
  banner: '',
  location: '',
  about: '',
  sponsor_values: [],
  rubrics: [],
  tags: [],
  nsfw: 'h',
  blocked: {
    blocked_actual: false,
  },
};
