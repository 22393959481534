import {
  ChangeEvent, ReactElement, useEffect, useRef,
} from 'react';

import borderStyle from 'UI/InputTransparent/data';
import { BorderPosTypes } from 'types';
import './style.scss';
import { ThemeStore } from 'store';
import { observer } from 'mobx-react';

interface ITransparentInput {
  onChange?: Function;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  containerClassName?: string;
  inpClassName?: string;
  borderPos?: BorderPosTypes;
}

const InputTransparent = ({
  onChange, value, placeholder, disabled, containerClassName, inpClassName, borderPos,
}: ITransparentInput): ReactElement => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { activeTheme } = ThemeStore;

  useEffect(() => {
    if (!textareaRef.current) return;
    const { current: { style } } = textareaRef;

    style.setProperty('height', 'auto');

    const { current: { scrollHeight } } = textareaRef;

    style.setProperty('height', `${scrollHeight}px`);
  }, [value]);

  const onChangeTextArea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e.target.value);
  };

  return (
    <div className={`input-transparent-container ${containerClassName}`}>
      <textarea
        ref={textareaRef}
        className={`${inpClassName} inp inp--${activeTheme}`}
        disabled={disabled}
        value={value || ''}
        style={borderPos && borderStyle[borderPos]}
        placeholder={placeholder}
        rows={1}
        onChange={onChangeTextArea}
      />
    </div>
  );
};

export default observer(InputTransparent);
