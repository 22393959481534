import { observer } from 'mobx-react';
import { MOBILE_POINT_MENU } from 'settings/main';
// import * as Store from 'store';
import MainStore from 'store';

import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

import './menu.scss';

const Menu = observer((): JSX.Element => {
  const { screenWidth } = MainStore;

  return (
    <>
      {screenWidth <= MOBILE_POINT_MENU ? <MobileMenu /> : <DesktopMenu />}
    </>
  );
});
export default Menu;
