import { useState } from 'react';

import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMount, useUnMount } from 'hooks';
import apiRequest from 'services/Api';
import { getNextNFT, initialGetNFTsList } from 'settings/api';
import MainStore, { ThemeStore } from 'store';
import 'pages/Post/style.scss';
import Preloader from 'UI/Preloader';

import NFTLine from './NFTLine';
import NoNFT from './NoNFT';
import ActionMenu from '../../UI/ActionMenu';
import { storeRubrics } from '../../helpers';
import LocalStorage from '../../services/LocalStorage';

interface INFT {
    list: any[];
    cursor: string;
    isLoaded: boolean;
}
interface INFTProps {
    id?: string;
}

interface INFTFilterData {
  id: number;
  name: string;
  type: string;
}

const initState = { list: [], cursor: '', isLoaded: false };

const nftFilterData: INFTFilterData[] = [
  { id: 1, name: 'Только что купили', type: 't' },
  { id: 2, name: 'Цена по убыванию', type: 'a' },
  { id: 3, name: 'Цена по возрастанию', type: 'ad' },
  { id: 4, name: 'Давно купили', type: 'td' },
];

const NFTList = ({ id }: INFTProps) => {
  const { activeTheme } = ThemeStore;
  const { isMobileContent, rubrics, activeRubric, setActiveRubric } = MainStore;
  const [nftsList, setNFTsList] = useState<INFT>(initState);
  const [hasMore, setHasMore] = useState(true);
  const [activeNftFilter, setActiveNftFilter] = useState(nftFilterData[0]);

  useMount(async () => {
    const storedFilters = LocalStorage.get('nft');

    const list = (await apiRequest(initialGetNFTsList(id, storedFilters?.order, storedFilters?.rubricCode))).data;
    setNFTsList({ ...list, isLoaded: true });
    !rubrics?.length && await storeRubrics();
    setActiveRubric(storedFilters?.rubricCode || '');
    storedFilters?.order && setActiveNftFilter(nftFilterData.find(item => item.type === storedFilters.order) as INFTFilterData);
  });

  const onNextScroll = async () => {
    const result = await apiRequest(getNextNFT(nftsList.cursor));
    const newList = {
      ...nftsList,
      list: [...nftsList.list, ...result.data.list],
      cursor: result.data.cursor,
      loaded: true,
    };
    setNFTsList({ ...newList });
    setHasMore(!!result?.list?.length);
  };

  useUnMount(() => setNFTsList(initState));

  const handleFilterNFT = async ({ rubricCode, order }: { rubricCode?: string; order?: string }) => {
    try {
      const list = (await apiRequest(initialGetNFTsList(id, order, rubricCode))).data;
      setNFTsList({ ...list, isLoaded: true });
      setActiveRubric(rubricCode || '');
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err.message);
    }
  };

  return (
    <>
      <div className="rubrics-filter-container">
        <ActionMenu
          items={nftFilterData}
          onClickItem={async filter => {
            setActiveNftFilter(filter);
            await handleFilterNFT({ order: filter.type, rubricCode: activeRubric?.code });
          }}
          selectedItem={activeNftFilter}
          containerClassName="filter-container"
          showSelected
        />
        <div className={`rubrics-line rubrics-line--${activeTheme}`} />
        <ActionMenu
          defaultSelected="Все рубрики"
          items={rubrics}
          onReset={async () => handleFilterNFT({ order: activeNftFilter.type })}
          onClickItem={async rubric => handleFilterNFT({ rubricCode: rubric.code, order: activeNftFilter.type })}
          showSelected
          selectedItem={activeRubric}
        />
      </div>

      {nftsList.list?.length ? (
        <InfiniteScroll
          style={{ minHeight: 'calc(100vh - 270px)' }}
          dataLength={nftsList.list.length}
          hasMore={hasMore}
          next={onNextScroll}
          loader={<div className={`noPosts noPosts--${activeTheme}`}><Preloader /></div>}
          /* endMessage={(
            <p style={{ textAlign: 'center' }}>
              <b>Больше записей нет</b>
            </p>
          )} */
        >
          {nftsList?.list.map((el: any) => <div className={`blogLine ${isMobileContent ? 'blogLineMobile' : ''} blogLine--${activeTheme}`} key={el.id}><NFTLine {...el} /></div>)}
        </InfiniteScroll>
      ) : <NoNFT loaded={nftsList.isLoaded} />}
    </>
  );
};

export default observer(NFTList);
