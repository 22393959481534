import { useCallback, useState } from 'react';

import Axios from 'axios';
import { BASE_NODE_LINK, CHAIN_VERSION, CONNECTION_TIMEOUT } from 'settings/main';
import { ShowToast } from 'helpers';

interface IContext {
  method: string;
  body: any;
  node?: string;
}

export default function useNodeQuery() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<any>(null);

  const nodeRequest = async ({ method, body, node = BASE_NODE_LINK }: IContext) => {
    setIsError(false);
    const axiosNodeInstance = Axios.create({
      timeout: CONNECTION_TIMEOUT,
    });
    const link = `${node}${CHAIN_VERSION}${method}`;
    axiosNodeInstance.post(link, body)
      .then(res => {
        setData(res.data);
        setIsSuccess(true);
      })
      .catch(err => {
        setError(err);
        setIsError(true);
        ShowToast('Нода не доступна', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetch = useCallback(
    (
      context: IContext,
    ) => {
      setIsLoading(true);
      nodeRequest(context);
    }, [],
  );

  return {
    data, isLoading, isError, isSuccess, error, fetch,
  };
}
