import { ReactElement } from 'react';

import './style.scss';
import { ReactComponent as EditBtnIcon } from 'assets/images/edit-btn.svg';

interface IEditButtonProps {
    action: Function;
}
const EditButton = ({ action }: IEditButtonProps) : ReactElement => (
  <div className="editButton" onClick={() => action()}>
    <EditBtnIcon height={35} width={48} />
  </div>
);

export default EditButton;
