import { IPost } from 'types';

export enum NextPrev {
  next = 'next',
  prev = 'prev',
}

export interface INextPrevPostsState {
  [NextPrev.next]: IPost;
  [NextPrev.prev]: IPost;
}

interface INextPrevButtons {
  id: number;
  className: string;
  title: string;
  type: NextPrev;
  iconDeg: number;
}

export const nextPrevButtons: INextPrevButtons[] = [{
  id: 1,
  className: 'feedPost_nav--prev note',
  title: 'Предыдущий пост в',
  type: NextPrev.prev,
  iconDeg: 90,
}, {
  id: 2,
  className: 'feedPost_nav--next note',
  title: 'Следующий пост в',
  type: NextPrev.next,
  iconDeg: 270,
}];

export const nextPrevPageKeywords: { [key: string]: any } = {
  new: 'новом',
  actual: 'актуальном',
  feed: 'ленте',
  popular: 'лидерах',
  blog: 'блоге',
};
