import { ReactComponent as Broke } from 'assets/images/pencil.svg';
import { observer } from 'mobx-react';
import MainStore, { ThemeStore } from 'store';
import Preloader from 'UI/Preloader';

interface INopostProps{
  text?: string;
}

const NoPosts = ({ text = 'Пока нет ни одного поста.' } : INopostProps) => {
  const { activeTheme } = ThemeStore;
  const { postsProxy } = MainStore;

  return (
    <div className={`noPosts noPosts--${activeTheme}`}>
      {
        postsProxy.loaded ? (
          <>
            <Broke />
            <span>
              {text}
            </span>
          </>
        ) : <Preloader />
      }
    </div>
  );
};
export default observer(NoPosts);
