import { Link } from 'react-router-dom';
import * as settings from 'settings';
import Tabs from 'UI/MenuTabs';
import UserPanel from 'UI/UserPanel';
import Button from 'UI/Button';
import { observer } from 'mobx-react';
import * as Store from 'store';
import { updatePostsByFilter } from 'helpers';
import SearchUser from 'UI/SearchUser/SearchUser';

const DesktopMenu = () => {
  const { isAuth, userData, activeRubric } = Store.default;
  const { activeTheme, themeData, getThemeIcon, changeTheme } = Store.ThemeStore;
  const { modalControl } = Store.ModalStore;

  const menuList = !isAuth ? settings.mainMenu.default.filter((el: any) => !el.private) : settings.mainMenu.default;

  const onClickTab = async () => {
    if (!activeRubric) return;

    await updatePostsByFilter({ noNeedForCache: true });
  };

  return (
    <div className={`menu_wrap ${activeTheme}`}>
      <div className="menu_content uc">
        <Link to="/posts/popular" className="menu_logo">
          <img src={themeData?.logo || ''} alt="stihi.io" />
        </Link>
        <Tabs list={menuList} onClick={onClickTab} backlight={false} className="tabs--small" />
        <div className="menu_content_userPanel">
          {isAuth
            ? (
              <>
                <SearchUser />
                <UserPanel {...userData} />
              </>
            )
            : (
              <>
                <Button title="Регистрация" action={() => modalControl(settings.modalSettings.signUp)} />
                <Button title="Вход" secondary action={() => modalControl(settings.modalSettings.signIn, { login: '' })} />
                <div className="usePanel-box_img--theme" onClick={() => changeTheme(themeData?.next || 'light')}>
                  <img src={getThemeIcon() || ''} alt="avatar" />
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default observer(DesktopMenu);
