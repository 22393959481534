import {
  useRef, useEffect, ReactElement, MutableRefObject,
} from 'react';

import { observer } from 'mobx-react';
import apiRequest from 'services/Api';
import { useHistory } from 'react-router-dom';
import MainStore, { ModalStore, ThemeStore } from 'store';
import { MOBILE_POINT_MENU } from 'settings/main';
import { saveKeyInBrowser } from 'utils/KeyUtils';
import LocalStorage from 'services/LocalStorage';
import ModalTemplate from 'curtain/ModalTemplate';
import Button from 'UI/Button';
import UploadImg from 'UI/Modal/Templates/AddPic/UploadImg';
import Preloader from 'UI/Preloader';
import usePipeline from 'Pipelines/usePipeline';
import { Pipeline } from 'store/PipelineStore';

import SignUp from './Templates/SignUp';
import SignIn from './Templates/SignIn';
import FinishSignUp from './Templates/SignUp/FinishSignUp';
import SendToUSer from './Templates/SendToUser';
import InnerSend from './Templates/InnerSend';
import SignTransaction from './Templates/SignTransacion';
import './style.scss';
import Stake from './Templates/Stake';
import JsonVIew from './Templates/JsonView/JsonVIew';
import InfoModal from './Templates/InfoModal';
import CreatePermissions from './Templates/CreatePermissions';
import Contribute from './Templates/Contribute';
import Announce from './Templates/Announce';
import BlockUser from './Templates/BlockUser';
import BlockArticle from './Templates/BlockArticle';
import Stage2 from './Templates/SignIn/Stage2';
import KeysFromPassword from './Templates/CreatePermissions/KeysFromPassword';
import BuyNFT from './Templates/NFT/BuyNFT';
import SetNFTPrice from './Templates/NFT/SetNFTPrice';

const Modal = observer((): JSX.Element => {
  const {
    modalControl,
    unsetModalError,
    modalValues,
    modalSettings,
    modalApproved,
    loading,
    loadingCurt,
    getModalSettings,
    loadingTitle,
  } = ModalStore;
  const { screenWidth } = MainStore;
  const { activeTheme } = ThemeStore;
  const isMobile = screenWidth <= MOBILE_POINT_MENU;
  const refInside: MutableRefObject<any> = useRef(null);
  const refOutSide:MutableRefObject<any> = useRef(null);
  const history = useHistory();
  const pipelineStore = usePipeline(modalSettings.pipeline || {} as Pipeline);
  const {
    runPipeline, isProgress,
  } = pipelineStore;
  const closeModal = () => {
    refInside.current.addEventListener('transitionend', (): void => {
      unsetModalError();
      modalControl();
    }, { once: true });
    refOutSide.current.classList.add('modal--slide-down');
    refInside.current.classList.add('modal_body--slide-down');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      refOutSide.current.classList.remove('modal--slide-down');
      refInside.current.classList.remove('modal_body--slide-down');
    }, 1);
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async () => {
    unsetModalError();
    let type = 'data';
    if (modalSettings.pipeline) {
      runPipeline(modalValues);
      if (modalValues.keyType === 'posting') {
        modalSettings.saveKey || modalSettings.pipeline.title === 'signInPipeline'
          ? saveKeyInBrowser(modalValues.key)
          : LocalStorage.remove('pk');
      }
    } else if (modalSettings.func) {
      if (modalSettings.tag === 'posting') {
        if (modalValues.keyType) {
          if (modalValues.keyType === 'posting') {
            modalSettings.saveKey
              ? saveKeyInBrowser(modalValues.key)
              : LocalStorage.remove('pk');
          }
          modalSettings.permission = modalValues.keyType;
          await modalSettings.func({ ...modalValues }, { ...modalSettings.сonfig });
        }
      } else {
        await modalSettings.func({ ...modalValues }, { ...modalSettings.сonfig });
      }
    } else {
      const values = { ...modalValues };
      const settings: any = getModalSettings.config;
      if (settings.media) type = 'media';

      await apiRequest(settings, values, type).then(() => modalControl());
    }
    if (modalSettings.redirect) history.replace(modalSettings.redirect);
    modalSettings.backFunc && await modalSettings.backFunc();
  };

  const createModalBody = (settings: any) => {
    let result: ReactElement | string = 'Шаблон отсутствует';
    switch (settings.type) {
      case 'sign-in':
        result = <SignIn />;
        break;
      case 'sign-up':
        result = <SignUp />;
        break;
      case 'sign-in2':
        result = <Stage2 />;
        break;
      case 'kfp':
        result = <KeysFromPassword />;
        break;
      case 'FinishSignUp':
        result = <FinishSignUp />;
        break;
      case 'sent-to-user':
        result = <SendToUSer />;
        break;
      case 'stake':
        result = <Stake />;
        break;
      case 'sent-to-patron':
        result = <InnerSend />;
        break;
      case 'receive-from-patron':
        result = <InnerSend receive />;
        break;
      case 'send-to-store':
        result = <InnerSend store />;
        break;
      case 'receive-from-store':
        result = <InnerSend store receive />;
        break;
      case 'sign-transaction':
        result = <SignTransaction />;
        break;
      case 'curtain':
        result = <ModalTemplate />;
        break;
      case 'uploadImg':
        result = <UploadImg aspect={settings.aspect || [1, 1]} />;
        break;
      case 'json-view':
        result = <JsonVIew />;
        break;
      case 'info':
        result = <InfoModal />;
        break;
      case 'createPermissions':
        result = <CreatePermissions />;
        break;
      case 'contribute':
        result = <Contribute />;
        break;
      case 'buyNFT':
        result = <BuyNFT />;
        break;
      case 'setNFTPtice':
        result = <SetNFTPrice />;
        break;
      case 'announce':
        result = <Announce />;
        break;
      case 'blockuser':
        result = <BlockUser />;
        break;
      case 'blockart':
        result = <BlockArticle />;
        break;
      default:
        break;
    }
    return result;
  };

  useEffect(() => {
    if (modalSettings.tag !== 'posting' || !LocalStorage.get('pk') || !modalValues.keyType || !modalValues.key || !modalSettings.autoSign) return;
    handleSubmit();
    // eslint-disable-next-line
  }, [modalSettings.tag, modalValues.keyType, modalValues.key])

  return (
    <div className="modal modal--slide-down" ref={refOutSide}>
      <div className={`modal_body modal_body_${activeTheme} modal_body--slide-down ${isMobile ? 'modal_body--mobile' : ''}`} ref={refInside}>
        <div className="modal_body_header">
          <div>{modalSettings.title}</div>
          <div className="modal_body_header--close" onClick={() => closeModal()}>&#215;</div>
        </div>
        <div className={`modal_preloader modal_preloader--${activeTheme} ${loadingCurt && !loading && !isProgress ? 'modal_preloader--hide' : ''}`}>
          <span>{loadingTitle || 'Отправляем данные'}</span>
          <Preloader />
        </div>
        <div className="modal_body_content">
          {createModalBody(modalSettings)}
        </div>
        {!modalSettings.buttonhidden && (
          <div className="modal_body_buttons">
            <Button
              disabled={!modalApproved || loading}
              title={loading ? loadingTitle || 'Отправка' : modalSettings.button || 'Подтвердить'}
              action={handleSubmit}
            />
            <Button
              title="Отмена"
              secondary
              action={closeModal}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default Modal;
