import { useEffect } from 'react';

import MainStore, { ModalStore } from 'store';
import { observer } from 'mobx-react';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';
import Input from 'UI/InputText';
import ModalLine from 'UI/Modal/ModalLine';
import ModalNote from 'UI/ModalNote';

import './style.scss';

const correctiveStyles = {
  display: 'flex',
  width: '70%',
};

const SetNFTPrice = observer(() => {
  const { modalValues, changeHandler } = ModalStore;
  const { userData, setUserBalances } = MainStore;

  useEffect(() => {
    setUserBalances(userData.cyber_name);
  }, [userData.cyber_name, setUserBalances]);

  const minpot = (summ: string) => `${Number(summ.split(' ')[0])} ${summ.split(' ')[1]}`;

  useEffect(() => {
    checkFields(modalValues, reqFields.contrib);
  }, [modalValues]);

  return (
    <div className="w100">
      <ModalNote
        text="Вы можете выставить NFT токен на продажу изменив цену. Новая цена может быть либо меньше, либо не выше 30% от суммы последней сделки"
        classname="pl5"
      />
      <ModalLine noteLeft="Цена">
        <div style={correctiveStyles}>
          <Input
            type="number"
            onChange={(val: any) => (
              changeHandler(
                (modalValues.amount && modalValues.amount < 0)
                  ? val.slice(1)
                  : val > Number(modalValues.max_amount.split(' ')[0]) ? Number(modalValues.max_amount.split(' ')[0]) : val, 'amount',
              )
            )}
            pValue={modalValues.amount}
            noteRight="SAVVA"
            placeholder={`мин. цена: ${minpot(modalValues.min_amount)}`}
          />
        </div>
      </ModalLine>
      <ModalNote
        text={`Минимальная цена: ${minpot(modalValues.min_amount)}, максимальная: ${modalValues.max_amount}`}
        classname="pl5"
      />
      <div className="modal_body_line">
        <span className="label" />
        <InputPassword
          placeholder="Приватный ключ"
        />
      </div>
    </div>
  );
});
export default SetNFTPrice;
