import { BorderPosTypes } from 'types';

interface ILeftBorder {
  borderLeft: string;
  marginLeft: number;
}

interface IRightBorder {
  borderRight: string;
  marginRight: number;
}

interface ITopBorder {
  borderTop: string;
  marginTop: number;
}

interface IBottomBorder {
  borderBottom: string;
  marginBottom: number;
}

interface IBorderStyle {
  [BorderPosTypes.left]: ILeftBorder;
  [BorderPosTypes.right]: IRightBorder;
  [BorderPosTypes.top]: ITopBorder;
  [BorderPosTypes.bottom]: IBottomBorder;
}

const borderStyle: IBorderStyle = {
  [BorderPosTypes.left]: {
    borderLeft: '1px solid gray',
    marginLeft: 10,
  },
  [BorderPosTypes.right]: {
    borderRight: '1px solid gray',
    marginRight: 10,
  },
  [BorderPosTypes.top]: {
    borderTop: '1px solid gray',
    marginTop: 10,
  },
  [BorderPosTypes.bottom]: {
    borderBottom: '1px solid gray',
    marginBottom: 10,
  },
};

export default borderStyle;
