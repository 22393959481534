import { getDataBody } from 'helpers';
import { sendTransaction } from 'services/Api';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';

const nftMinAmountPipeline: Pipeline = {
  title: 'nftMinAmount',
  tasks: [
    {
      title: 'Изминение минимальной стоимости NFT поста',
      task: async (meta: any) => {
        const { userData } = MainStore;
        const { acc, amount, key } = meta;
        const data = {
          acc,
          amount,
        };

        const sendingData = await getDataBody(userData.cyber_name, 'stihi.patron', data, 'setnftprice');

        const result = await sendTransaction(key, sendingData);
        return result;
      },
      errorShow: (_: any, error: any) => {
        if (error.message.includes('action declares irrelevant authority')) {
          return 'Не достаточно прав для ключа, проверьте в настройках разрешений';
        }
        return 'Не удалось внести изминения';
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default nftMinAmountPipeline;
