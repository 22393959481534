import { ReactElement, useEffect, useState } from 'react';

import MainStore, { ModalStore, ThemeStore } from 'store';
import Button from 'UI/Button';
import { observer } from 'mobx-react';
import { MOBILE_POINT_MENU, PATRON_BALANCE_COFFICIENT } from 'settings/main';
import Input from 'UI/InputText';
import useNodeQuery from 'hooks/useNodeRequest';
import { getNFTStateContext } from 'helpers/requestContext';
import { signTransactionModal } from 'settings/modal';
import nftMinAmountPipeline from 'Pipelines/setNFTminAmount';

interface IPermissionsProps {
    id: string;
}

const NFTSettings = ({ id }: IPermissionsProps): ReactElement => {
  const { activeTheme } = ThemeStore;
  const { screenWidth, userData } = MainStore;
  const { modalControl } = ModalStore;
  const [changed, setChanged] = useState(false);
  const [minPrice, setMinPrice] = useState<number>(0);
  const {
    isSuccess, data, fetch,
  } = useNodeQuery();

  const changeHandler = (value: number) => {
    setChanged(true);
    setMinPrice(value);
  };
  useEffect(() => {
    fetch(getNFTStateContext(id));
  }, [id, fetch]);
  const handleSubmit = async () => {
    modalControl({ ...signTransactionModal, pipeline: nftMinAmountPipeline }, {
      acc: userData.cyber_name,
      amount: `${Number(minPrice).toFixed(4)} SAVVA`,
    });
  };

  const oldBalance = (isSuccess && data.rows.length) ? Number(data.rows?.[0].price) / PATRON_BALANCE_COFFICIENT : 0;

  useEffect(() => {
    (isSuccess && data) && setMinPrice(data.rows.length ? Number(data.rows?.[0].price) / PATRON_BALANCE_COFFICIENT : 0);
  }, [isSuccess, data]);

  return (
    <div className="settingBox_box">
      <div className="settingBox_box_userId">
        <span className={`note note_${activeTheme}`}>
          Вы можете установить минимальную стоимость для покупки NFT токенов Ваших произведений
        </span>
      </div>
      <div className="settingBox_box_field w100">
        <span className={`label label--${activeTheme}`}>Минимальная стоимость</span>
        <Input onChange={(value: any) => changeHandler(value)} pValue={minPrice} placeholder="Введите сумму" type="number" />
      </div>
      <div className="settingBox_box_field" style={{ width: screenWidth > MOBILE_POINT_MENU ? '118px' : '100%' }}>
        <Button
          title="Обновить"
          disabled={oldBalance === minPrice}
          secondary={oldBalance === minPrice}
          action={() => changed && handleSubmit()}
        />
      </div>

    </div>
  );
};

export default observer(NFTSettings);
