import React from 'react';

import { observer } from 'mobx-react';
import { CommentsStore } from 'store';
import { useMount, useUnMount } from 'hooks';
import {
  getCommentsList,
  storePostCommentsById,
} from 'helpers';

const PostCommentsList = ({ list }: any) => {
  const { commentList, dropCommentsList } = CommentsStore;

  useMount(() => !list && storePostCommentsById());

  useUnMount(() => !list && dropCommentsList());

  return commentList.list.length ? (
    <>
      <div className="comments-title">Комментарии</div>
      <div className="comments ">
        <div className="post-comments-list">
          {getCommentsList(list || commentList, false)}
        </div>
      </div>
    </>
  ) : null;
};

export default observer(PostCommentsList);
