import { ReactChild } from 'react';

import { ReactComponent as FundBoxBG } from 'assets/images/fund-box.svg';
import { ReactComponent as NFTIcon } from 'assets/images/NFT5.svg';
import { ReactComponent as NFTIconWhite } from 'assets/images/nftlines.svg';
import './style.scss';
import { ThemeStore } from 'store';
import { observer } from 'mobx-react';

interface IFundBoxProps {
    small?: boolean;
    large?: boolean;
    secondary?: boolean;
    line?: boolean;
    children: ReactChild;
    nft?: boolean;
}

const FundBox = ({
  small, large, secondary, children, line, nft,
}: IFundBoxProps) => {
  const { activeTheme } = ThemeStore;
  const style = (isSmall: boolean, isLarge: boolean, isLine: boolean, prefix: string) => {
    let body: string = `${prefix} `;
    if (isSmall) body += ` ${prefix}--small`;
    if (isLarge) body += ` ${prefix}--large`;
    if (isLine) body += ` ${prefix}--line`;
    return body;
  };

  return (
    <div className={`${style(!!small, !!large, !!line, 'fundBox')} ${secondary ? ' fundBox--white' : ''} fundBox--${activeTheme}`}>
      <div className="fundBox_children">
        {children}
      </div>
      <div className="fundBox_backgroundimg">
        {
          nft
            ? activeTheme === 'dark' ? <NFTIconWhite style={{ opacity: '0.08' }} /> : <NFTIcon style={{ opacity: '0.2' }} />
            : <FundBoxBG style={{ fill: activeTheme === 'dark' ? 'rgba(255, 255, 255, 0.7)' : secondary ? 'rgba(0, 0, 0, 0.6)' : '#fff' }} />
        }
      </div>
    </div>
  );
};

export default observer(FundBox);
