import {
  observable, decorate, action, computed,
} from 'mobx';
import MainStore from 'store';

import { Pipeline } from './PipelineStore';

interface modalS {
  title?: string;
  button?: string;
  сonfig?: ReqConfig;
  type?: string;
  func?: Function;
  afterFunc?: Function;
  buttonhidden?: boolean;
  tag?: string;
  isCrop?: boolean;
  disableCheck?: boolean;
  permission?: string;
  saveKey?: boolean;
  note?: string;
  payload?: any;
  passwd?: boolean;
  backFunc?: () => void | Promise<void>;
  autoSign?: boolean;
  pipeline?: Pipeline;
  redirect?: string;
}

interface ReqConfig {
  link: string;
  method: string;
  tag?: string;
}

class Modal {
  open: boolean = false

  modalSettings: modalS = {}

  modalValues: any = []

  modalErrors: any = []

  modalApproved = false

  trueKey: boolean = false

  firstAuth: string = ''

  loading: boolean = false

  loadingCurt: boolean = true;

  loadingTitle: string | false = false

  get getModalValues() {
    return MainStore.observableArray(this.modalValues);
  }

  get getModalSettings() {
    return MainStore.observableArray(this.modalSettings);
  }

  setTrueKey = (data: boolean) => this.trueKey = data

  setLoadingTitle = (title: string) => this.loadingTitle = title

  setLoading = (title?: string, curt: boolean = true) => {
    this.loadingTitle = title || false;
    this.loadingCurt = curt;
    this.loading = title ? true : !this.loading;
  }

  disableLoading = () => this.loading = false

  setFirstAuth = (key: string) => this.firstAuth = key

  approveValues = (state: boolean) => this.modalApproved = state

  setModalError = (field: string, value: string) => this.modalErrors[field] = value

  unsetModalError = () => this.modalErrors = []

  setModalValues = (obj: object) => this.modalValues = { ...obj }

  changeHandler = (val: any, key: string) => this.setModalValues({ ...this.modalValues, [key]: val });

  objectChangeHandler = (obj: {}) => this.setModalValues({ ...this.modalValues, ...obj });

  modalControl = (setting?: modalS, initData?: object): void => {
    if (setting) {
      this.loading = false;
      this.modalSettings = setting;
      if (initData) this.modalValues = { ...initData };
      this.open = true;
    } else {
      this.modalSettings = {};
      this.modalValues = {};
      this.modalApproved = false;
      this.open = false;
    }
  }

  modalControlForce = (setting?: modalS, initData?: object): void => {
    this.modalSettings = {};
    this.modalValues = {};
    this.modalApproved = false;
    this.open = false;
    setTimeout(() => this.modalControl(setting, initData), 200);
  }
}

decorate(Modal, {
  open: observable,
  modalValues: observable,
  loadingTitle: observable,
  modalApproved: observable,
  modalSettings: observable,
  setModalValues: action,
  modalControl: action,
  modalErrors: observable,
  setModalError: observable,
  unsetModalError: action,
  setFirstAuth: action,
  firstAuth: observable,
  loading: observable,
  setLoading: action,
  changeHandler: action,
  getModalValues: computed,
  getModalSettings: computed,
  trueKey: observable,
  setTrueKey: action,
  objectChangeHandler: action,
  loadingCurt: observable,
});

const ModalStore = new Modal();

export default ModalStore;
