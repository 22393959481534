import { useMemo } from 'react';

import {
  Route, Switch, Redirect, useLocation,
} from 'react-router-dom';
import Tabs from 'UI/MenuTabs';
import Transactions from 'pages/Profile/Wallet/components/Transactions';
import Awards from 'pages/Profile/Wallet/components/Awards';
import Patron from 'pages/Profile/Wallet/components/Patron';
import Balances from 'pages/Profile/Wallet/components/Balances';
import walletsMenu from 'pages/Profile/Wallet/data';
import CyberBalances from 'pages/Profile/Wallet/components/CyberBalances';
import { IRoute } from 'types';
import MainStore, { ThemeStore } from 'store';
import MenuProfile from 'UI/MenuProfile';
import { MOBILE_POINT_MENU } from 'settings/main';

interface IWalletProps {
    id: string;
    bPass: string;
}

const Wallet = ({ id, bPass }: IWalletProps): JSX.Element => {
  const basePath = `${bPass}/wallet/`;
  const { screenWidth } = MainStore;
  const { activeTheme } = ThemeStore;

  const routes: IRoute[] = useMemo(() => ([
    { path: 'balances', auth: false, component: <Balances id={id} /> },
    { path: 'cyber-balance', auth: true, component: <CyberBalances id={id} /> },
    { path: 'history', auth: true, component: <Transactions id={id} /> },
    { path: 'awards', auth: true, component: <Awards id={id} bPass={bPass} /> },
    { path: 'patron', auth: true, component: <Patron id={id} /> },
  ]), [id, bPass]);

  const redirect = () => <Redirect push to="balances" />;

  return (
    <div className={`settingBox settingBox_${activeTheme}`}>
      {basePath === useLocation().pathname && redirect()}
      <>
        <div className={`settingBox_menu settingBox_menu_${activeTheme}`}>
          {screenWidth >= MOBILE_POINT_MENU
            ? <Tabs list={walletsMenu} prefix={basePath} />
            : <MenuProfile list={walletsMenu} prefix={basePath} />}
        </div>
        <div className="settingBox_content">
          <Switch>
            {routes.map((el: any): JSX.Element => (
              <Route key={`${el.path}`} path={`${basePath}${el.path}`}>{el.component}</Route>
            ))}
          </Switch>
        </div>
      </>
    </div>
  );
};
export default Wallet;
