import apiRequest from 'services/Api';
import { loginIn } from 'settings/api';
import { Pipeline } from 'store/PipelineStore';
import { createSign, privateToPublic } from 'utils/KeyUtils';
import MainStore, { ModalStore } from 'store';
import { signIn } from 'settings/modal';
import LocalStorage from 'services/LocalStorage';
import { userToStorage } from 'helpers';

const signInPipeline: Pipeline = {
  title: 'signInPipeline',
  tasks: [
    {
      title: 'Получение контрольной суммы',
      task: async () => {
        const res: any = await apiRequest(signIn.config);
        return res.data;
      },
      errorShow: (_: any, error: any) => error,
    },
    {
      title: 'Проверка авторизационных данных',
      task: async (meta: any, results: any) => {
        const code = results['Получение контрольной суммы'];
        const dateToSend: any = {};
        try {
          dateToSend.sign = createSign(meta.key, code);
          dateToSend.key = privateToPublic(meta.key);
          dateToSend.code = code;
          dateToSend.login = meta.login;
          return dateToSend;
        } catch (err) {
          throw new Error('Вы указали не корректный или не существующий ключ для авторизации');
        }
      },
      errorShow: (_: any, error: any) => error,
    },
    {
      title: 'Вход',
      task: async (meta: any, results: any) => {
        const sendingData = results['Проверка авторизационных данных'];
        const res: any = await apiRequest(loginIn, sendingData);
        return res.data;
      },
      errorShow: (_: any, error: any) => error,
    },
    {
      title: 'Вход',
      task: async (meta: any, results: any) => {
        const sendingData = results['Вход'];
        const { modalSettings, modalControl } = ModalStore;
        const { setUser } = MainStore;
        if (modalSettings.passwd) {
          modalControl({ ...modalSettings, type: 'sign-in2', title: 'Сохраните приватные ключи', button: 'Продолжить', buttonhidden: true });
        } else {
          modalControl();
        }
        let authData: any = {};
        const userData: any = [];
        const uKey:number = Number(Object.keys(sendingData.jwts)[0]);
        LocalStorage.set('at', sendingData.jwts[uKey]);
        LocalStorage.set('rt', sendingData.refreshes[uKey]);
        LocalStorage.set('rtExp', sendingData.refreshesExpAt[uKey]);
        // eslint-disable-next-line guard-for-in
        for (const key in sendingData.profiles) {
          userData.push({ uid: key, udata: sendingData.profiles[key] });
        }
        if (userData.length < 2) {
          authData = userData[0].udata;
        }
        userToStorage(authData);
        setUser(authData);
      },
      errorShow: (_: any, error: any) => error,
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default signInPipeline;
