import {
  Page, Text, View, Document,
} from '@react-pdf/renderer';
import styles from 'UI/Modal/Templates/SignUp/styles';

interface IKey{
  private: string;
  public: string;
}

interface IPdfDocumentProps {
    keys: IKey;
    cyber: string;
    golos: string;
}

const PdfDocumentPosting = ({ keys, cyber, golos }: IPdfDocumentProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>
          ОБЯЗАТЕЛЬНО СОХРАНИТЕ КЛЮЧИ В НАДЕЖНОМ МЕСТЕ.
        </Text>
        <Text style={styles.note}>
          Stihi.io не хранит Ваши ключи. Если вы потеряете эти ключи, Вы не сможете получить доступ к Вашей учетной записи, и никто не сможет помочь Вам восстановить ключи.
        </Text>
        <Text style={styles.keyName}>
          {`GOLOS ЛОГИН: ${golos}`}
        </Text>
        <Text style={styles.keyName}>
          {`ID пользователя: ${cyber}`}
        </Text>
        <View style={styles.case} key={new Date().getTime()}>
          <Text style={styles.title}>POSTING KEY</Text>
          <Text style={styles.keyName}>
            {`Приватный: ${keys.private}`}
          </Text>
          <Text style={styles.keyName}>
            {`Публичный: ${keys.public}`}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default PdfDocumentPosting;
