import { ReactElement } from 'react';

import { observer } from 'mobx-react';
import { ThemeStore } from 'store';

import './style.scss';

interface IInputText {
    onChange?: Function;
    pValue?: string | number;
    placeholder?: string;
    label?: string;
    disabled?: boolean;
    error?: boolean;
    noteLeft?: any;
    noteRight?: any;
    className?: string;
    type?: string;
    autoComplete?: boolean;
    style?: any;
}

const Input = ({
  onChange, pValue, placeholder, disabled, error, noteLeft, noteRight, className, type, style,
}: IInputText): ReactElement => {
  const { activeTheme } = ThemeStore;

  return (
    <div className={`customInput ${error ? 'customInput_error' : ''} customInput_${activeTheme}`} style={style}>
      {noteLeft && <div className={`customInput_note customInput_note_${activeTheme}`}>{noteLeft}</div>}
      <input
        autoComplete="new-password" // {(!autoComplete || type !== 'password') ? 'off' : 'new-password'}
        disabled={disabled}
        className={className}
        type={type || 'text'}
        value={pValue || ''}
        placeholder={placeholder}
        onChange={(e): void => (onChange && !disabled) && onChange(e.target.value)}
      />
      {noteRight && <span className={`customInput_note customInput_note_${activeTheme}`}>{noteRight}</span>}
    </div>
  );
};

export default observer(Input);
