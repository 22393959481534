import { ReactElement, useState, useEffect } from 'react';

import { ModalStore } from 'store';
import Input from 'UI/InputText';

interface IValues {
    key: string;
}

const ModalTemplate = (): ReactElement => {
  const { setModalValues, approveValues } = ModalStore;

  const [values, setValues] = useState<IValues>({ key: '' });

  const changeHandler = (key: string, value: string) => setValues({ ...values, [key]: value });

  useEffect(() => {
    setModalValues(values);
    approveValues(values.key.length > 5);
  }, [values, approveValues, setModalValues]);
  return (
    <div className="w100">
      <div className="modal_body_line">
        <Input onChange={(val: string) => { changeHandler('key', val); }} pValue={values.key} placeholder="Введите пароль" />
      </div>
    </div>
  );
};

export default ModalTemplate;
