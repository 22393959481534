import { ReactElement } from 'react';
import './style.scss';

interface IProfileWrapperProps {
    children: React.ReactNode;
}

const ProfileWrapper = ({ children }: IProfileWrapperProps): ReactElement => (
  <div className="profileWrapper">
    {children}
  </div>
);

export default ProfileWrapper;
