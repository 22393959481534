export interface IBlogOwnerMenu {
  name: string;
  link: string;
  auth?: boolean;
  count?: number;
}

export const blogMenu: IBlogOwnerMenu[] = [{
  name: 'Блог',
  link: '/blog/',
}, {
  name: 'Подписки',
  link: '/subscriptions/',
}, {
  name: 'Подписчики',
  link: '/subscribers/',
}, {
  name: 'Коллекция NFT',
  link: '/nft-collections/',
}];

export const ownerMenu: IBlogOwnerMenu[] = [{
  name: 'Кошелёк',
  link: '/wallet/',
  auth: false,
}, {
  name: 'Настройки',
  link: '/settings/',
  auth: true,
}];
