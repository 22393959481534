import { useEffect } from 'react';

import updateFundPost from 'Pipelines/updateFundPost';
import usePipeline from 'Pipelines/usePipeline';
import MainStore from 'store';
import Preloader from 'UI/Preloader';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useUnMount } from 'hooks';

import FundBox from '.';
import EmptyFund from './EmptyFund';
import PositiveFund from './PositiveFund';

const FundIPost = () => {
  const { activePost, setPostFunds, postFundsObject } = MainStore;

  const pipelineStore = usePipeline(updateFundPost);

  const {
    runPipeline, status, results, dropPipline,
  } = pipelineStore;

  useEffect(() => {
    if (!activePost) return;
    runPipeline({ guid: activePost.id, author: activePost.authorLogin });
  }, [activePost, runPipeline, dropPipline, setPostFunds]);

  useEffect(() => {
    if (status !== 'success') return;
    const data = toJS(results)['Запрос фонд поста'];

    setPostFunds(data.rows.length ? data.rows[0] : {});
  }, [results, status, setPostFunds]);

  useUnMount(() => setPostFunds({}));

  return (
    <>
      {
        status === 'progress'
          ? (
            <div className="vertical mt10">
              <FundBox>
                <div className="fundBoxPreloader">
                  <Preloader />
                </div>
              </FundBox>
            </div>
          )
          : +postFundsObject.balance?.split(' ')[0] > 0
            ? <PositiveFund /> : <EmptyFund />
    }

    </>
  );
};

export default observer(FundIPost);
