import { useEffect, useState } from 'react';

import { ReactComponent as NFTIcon } from 'assets/images/NFT5.svg';
import MainStore, {
  INftState, INftStore, ThemeStore,
} from 'store';
import { observer } from 'mobx-react';
import { useUnMount } from 'hooks';
import Preloader from 'UI/Preloader';
import { getNFTSContext } from 'helpers/requestContext';
import useNodeTimerRequest from 'hooks/useNodeTimerRequest';
import usePipeline from 'Pipelines/usePipeline';
import getNFTData from 'Pipelines/getNFTData';

import PositiveNFT from './PositiveNFT';
import EmptyNFT from './EmptyNFT';

import './style.scss';

const NftBox = () => {
  const {
    activePost, nftStore, setNftStore, setNftState,
  } = MainStore;
  const { activeTheme } = ThemeStore;
  const [blink, setBlink] = useState<boolean>(false);
  const { returnedData, initTimer } = useNodeTimerRequest();
  const pipelineStore = usePipeline(getNFTData);
  const {
    runPipeline, isProgress,
  } = pipelineStore;

  useEffect(() => {
    if (!activePost) return;
    runPipeline({ guid: activePost.id, author: activePost.authorLogin });
  }, [activePost, runPipeline]);

  useEffect(() => {
    if (returnedData?.rows?.length && nftStore?.lastprice !== returnedData?.rows[0].lastprice) {
      setBlink(true);
      setNftStore(returnedData?.rows[0]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnedData, setNftStore]);

  useEffect(() => {
    blink && setTimeout(() => setBlink(false), 1990);
  }, [blink]);

  useEffect(() => {
    if (!activePost) return;
    initTimer(getNFTSContext(activePost.id, activePost.authorLogin));
  }, [activePost, initTimer]);

  useUnMount(() => {
    setNftStore({} as INftStore);
    setNftState({} as INftState);
  });

  return (
    <div className={`nftBox nftBox--${activeTheme}`}>
      <span className="flare" style={{ display: blink ? '' : 'none' }} />
      {isProgress ? <Preloader />
        : (
          <>
            <div className={`nftBox_icon nftBox_icon--${activeTheme}`}>
              <NFTIcon />
            </div>
            {nftStore?.lastprice?.length ? <PositiveNFT /> : <EmptyNFT />}
          </>
        )}
    </div>
  );
};

export default observer(NftBox);
