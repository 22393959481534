import { useEffect, useState } from 'react';

import Button from 'UI/Button';
import defaultAvatar from 'assets/images/avatar-default.svg';
import { ReactComponent as Savva } from 'assets/images/currIcon.svg';
import ModalNote from 'UI/ModalNote';
import Amount from 'components/Amount';
import MainStore, { ModalStore, ThemeStore } from 'store';
import * as settings from 'settings';
import { buyNFT, setNFTPtice } from 'settings/modal';
import { observer } from 'mobx-react';
import apiRequest from 'services/Api';
import { getUserById } from 'settings/api';
import { getPic, getProfileName } from 'helpers';
import { IUserData } from 'types';
import { Link } from 'react-router-dom';
import Preloader from 'UI/Preloader';

const PositiveNFT = () => {
  const { nftStore, activePost, userData, isAuth, fundConfig } = MainStore;
  const { activeTheme } = ThemeStore;
  const { modalControl } = ModalStore;
  const [user, setUser] = useState<IUserData | null>(null);
  const [loadging, setLoading] = useState<boolean>(true);

  useEffect(() => {
    !loadging && setLoading(true);
    apiRequest(getUserById(nftStore.owner))
      .then(res => {
        setUser(res.data.profile);
        setLoading(false);
      })
      .catch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftStore]);

  const sprice = Number(nftStore?.saleprice?.split(' ')[0] || '0.0000');
  const lprice = Number(nftStore?.lastprice?.split(' ')[0] || '0.0000');

  return (
    <>
      <ModalNote
        text="Из частной коллекции"
        style={{ width: '150px', marginTop: '-10px' }}
      />
      <div className={`delimetr delimetr--${activeTheme}`} style={{ marginTop: '20px' }} />
      <Link to={`/${user?.cyber_name}`} className="nftBox_link">
        {
          loadging ? (
            <div style={{ marginTop: '20px' }}>
              <Preloader />
              {' '}
            </div>
          ) : (
            <>
              <div className={`nftBox_imgBox ${user && user.avatar.includes('ipfs') ? '' : 'avatarBorder'} `}>
                <img src={user && user.avatar.length ? getPic(user.avatar) : defaultAvatar} alt="avatar" />
              </div>
              <ModalNote text="Меценат" style={{ width: '60px' }} />
              <span className={`nftBox_userName nftBox_userName-${activeTheme}`}>{user ? getProfileName(user) : nftStore.owner}</span>
            </>
          )
        }

      </Link>
      <div className={`delimetr delimetr--${activeTheme}`} style={{ marginTop: '18px' }} />
      <Amount amount={nftStore.lastprice.split(' ')[0]} classname="nftBox_lastPrice" />
      <div className="nftBox_button">
        <Button
          className="w100"
          action={() => {
            modalControl(
              isAuth ? buyNFT : settings.modalSettings.signIn,
              isAuth ? {
                uuid: activePost?.id,
                author: activePost?.authorLogin,
                amount: sprice,
                min_amount: nftStore.saleprice,
              } : { login: '' },
            );
          }}
        >
          <div className="nftBox_buyButton">
            <span>Перекупить NFT</span>
            <div className="nftBox_amount">
              <Savva className="nftBox_currency" style={{ filter: 'invert(0%)' }} />
              <div>{sprice.toFixed(4)}</div>
            </div>
          </div>
        </Button>
      </div>
      {
        (nftStore.owner === userData.cyber_name && isAuth)
          ? (
            <div className="nftBox_button">
              <Button
                className="w100"
                title="Изменить цену"
                action={() => {
                  modalControl(
                    setNFTPtice,
                    {
                      uuid: activePost?.id,
                      author: activePost?.authorLogin,
                      min_amount: fundConfig.minamount,
                      max_amount: `${(lprice + (lprice / 100 * 30)).toFixed(4)} SAVVA`,
                    },
                  );
                }}
              />
            </div>
          )
          : null
      }

    </>
  );
};
export default observer(PositiveNFT);
