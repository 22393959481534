import { ReactChild } from 'react';

import { observer } from 'mobx-react';
import { ThemeStore } from 'store';
import './style.scss';

interface IInfoBlockProps {
    title: string;
    children?: ReactChild;
    extra?: any;
}

const InfoBlock = ({ children, title, extra }: IInfoBlockProps) => {
  const { activeTheme } = ThemeStore;
  return (
    <div className="infoBlock ">
      <div className={`infoBlock_title infoBlock_title--${activeTheme}`}>
        <div>{title}</div>
        {extra && <div className={`infoBlock_coloredBox infoBlock_coloredBox--${activeTheme}`}>{extra}</div>}
      </div>
      {children}
    </div>
  );
};
export default observer(InfoBlock);
