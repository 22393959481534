import {
  useEffect,
} from 'react';

import { ModalStore } from 'store';

const InfoModal = () => {
  useEffect(() => {
    ModalStore.approveValues(true);
  }, []);
  return (
    <div className="infoModal">
      {ModalStore.getModalValues.note || 'Подтвердите действие'}
    </div>
  );
};
export default InfoModal;
