import { useRef, useEffect } from 'react';

import { observer } from 'mobx-react';
import MainStore, { ThemeStore } from 'store';
import BlogPreview from 'pages/components/BlogPreview';
import { IBlogPreviewProps } from 'types';

import './style.scss';

const BlogLine = (props: IBlogPreviewProps) => {
  const { isMobileContent } = MainStore;
  const { activeTheme } = ThemeStore;
  const ref = useRef<any>(null);
  const {
    showBlock = false,
    focusedG,
    id,
    blockedPage,
  } = props;

  useEffect(() => {
    if (focusedG !== '' && focusedG === id && !!id) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [focusedG, id]);

  return (
    <div className={`blogLine ${isMobileContent ? 'blogLineMobile' : ''} blogLine--${activeTheme}`} ref={ref}>
      <BlogPreview {...props} showBlock={showBlock} blockedPage={blockedPage} />
    </div>
  );
};

export default observer(BlogLine);
