import {
  getDataBody, SendActionData,
} from 'helpers';
import apiRequest, { sendTransaction } from 'services/Api';
import { announceCFG } from 'settings/api';
import { SAVVA_CONTRACT } from 'settings/main';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';

const announcePipeline: Pipeline = {
  title: 'announcePipeline',
  tasks: [
    {
      title: 'Проверка ключа',
      task: async (meta: any) => {
        if (['active', 'owner'].includes(meta.keyType)) {
          return meta;
        }
        throw new Error('Анонсировать можно используя только активный ключ или ключ владельца');
      },
      errorShow: (_: any, error: any) => error.message,
    },
    {
      title: 'Проверка баланса',
      task: async (meta: any) => {
        if (Number(meta.balance) > meta.price / 1000) {
          return meta;
        }
        throw new Error('Не достаточно токенов');
      },
      errorShow: (_: any, error: any) => error,
    },
    {
      title: 'Проверка доступности анонсирования',
      task: async (meta: any) => {
        const result = await apiRequest({
          link: 'get_announces',
          method: 'get',
          params: {
            count: 5,
            code: meta.code,
          },
        });
        const isAnnounced = result.data.list.find((el: any) => el.guid === meta.guid);
        if (isAnnounced) {
          throw new Error('Пост уже анонсирован в этой ленте');
        }
        return isAnnounced;
      },
      errorShow: (_: any, error: any) => error.message,
    },
    {
      title: 'Оплата анонсирования',
      task: async (meta: any) => {
        const { userData } = MainStore;
        const data: SendActionData = {
          quantity: `${(meta.price / 1000).toFixed(4)} SAVVA`,
          to: 'stihi.fund',
          memo: JSON.stringify({
            guid: meta.guid,
            code: meta.code,
            title: meta.title,
          }),
          from: userData.cyber_name,
        };
        const sendingData = await getDataBody(userData.cyber_name, SAVVA_CONTRACT, data, 'transfer');
        const result = await sendTransaction(meta.key, sendingData);
        return result;
      },
    },
    {
      title: 'Анонсирование поста',
      task: async (meta: any, results: any) => {
        const { transaction_id } = results['Оплата анонсирования'];
        const result = await apiRequest(
          announceCFG,
          {
            guid: meta.guid,
            code: meta.code,
            tx: transaction_id,
          },
        );
        return result;
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default announcePipeline;
