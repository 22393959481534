import { useEffect } from 'react';

import { ModalStore } from 'store';
import { observer } from 'mobx-react';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';

const KeysFromPassword = observer(() => {
  const { modalValues, modalSettings } = ModalStore;

  useEffect(() => {
    checkFields(modalValues, reqFields.keyFromPasswd);
  }, [modalValues, modalSettings]);

  return (
    <div className="w100">
      <div className="modal_body_line">
        <span className="label" />
        <InputPassword
          placeholder="Пароль"
          type="p"
          forceKey
        />
      </div>
    </div>
  );
});
export default KeysFromPassword;
