import {
  useState,
} from 'react';

import { observer } from 'mobx-react';
import { useMount } from 'hooks';
import MainStore, { ModalStore } from 'store';
import { ReactComponent as Done } from 'assets/images/done.svg';
import { setPermissionLink, ShowToast } from 'helpers';
import { permList } from 'pages/Profile/Settings/data';
import { PDFDownloadLink } from '@react-pdf/renderer';

import PdfDocumentPosting from '../SignUp/KeyTemplatePosting';

import './style.scss';

const CreatePermissions = () => {
  const { userData } = MainStore;
  const { getModalValues, modalSettings } = ModalStore;
  const [savedPermissions, setSavedPermissions] = useState<number[]>([]);
  const isUpdate = modalSettings.tag === 'updPosting';
  const setPermissions = async (
    account: string,
    key: string,
    permissionsList: any[],
  ) => {
    if (!permissionsList.length || isUpdate) return;
    const saved = [...savedPermissions];
    for (let i = 0; i < permissionsList.length; i++) {
      try {
        await setPermissionLink(account, permissionsList[i].code, permissionsList[i].type, key);
        ShowToast(`Успешно добавлено: ${permissionsList[i].title}`, 'success');
        saved.push(permissionsList[i].id);
      } catch {
        ShowToast(`Не удалось добавить: ${permissionsList[i].title}`, 'error');
        continue;
      }
    }
    setSavedPermissions(saved);
  };
  useMount(() => {
    ModalStore.approveValues(true);
    setPermissions(userData.cyber_name, getModalValues.active, permList);
  });

  return (
    <div className="createPermissionsBox">
      <div className="createPermissionsBox_keys">
        <span className="createPermissionsBox_keys--title">
          Ваши ПОСТИНГ ключи
        </span>
        <span className="createPermissionsBox_keys--key">
          <strong>
            Приватный:
          </strong>
          {getModalValues.keys.private}
        </span>
        <span className="createPermissionsBox_keys--key">
          <strong>
            Публичный:
          </strong>
          {getModalValues.keys.public}
        </span>
      </div>
      <PDFDownloadLink
        document={<PdfDocumentPosting keys={getModalValues.keys} cyber={userData.cyber_name} golos={userData.golos_name || ''} />}
        fileName="stihi_io_posting_keys.pdf"
        className="button"
      >
        {({ loading }) => (loading ? 'Генерация ключей' : 'Сохранить ключи в PDF')}
      </PDFDownloadLink>
      {!isUpdate ? (
        <div className="createPermissionsBox_permList">
          {permList.map(el => (
            <div key={el.id} className={`createPermissionsBox_permList_line ${savedPermissions.includes(el.id) ? 'createPermissionsBox_permList_line--done' : ''}`}>
              <span>
                {el.title}
              </span>
              <Done />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
export default observer(CreatePermissions);
