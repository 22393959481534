import { BASE_STORAGE, urls } from 'settings/main';

import { getHashFromGuid } from './node';

const JSONbig = require('json-bigint');

export const getNFTStateContext = (accountname: string) => {
  const body = {
    json: true,
    code: 'stihi.patron',
    scope: 'stihi.patron',
    table: 'nftprice',
    index: 'primary',
    limit: 10,
    lower_bound: {
      accountname,
    },
    upper_bound: {
      accountname,
    },
  };

  return {
    method: urls.getTableRows,
    body: JSONbig.stringify(body),
  };
};

// delete
export const getNFTSContext = (guid: string, author: string, storage: string = BASE_STORAGE) => {
  const hash:bigint = getHashFromGuid(guid, author, storage);
  const body = {
    json: true,
    code: 'stihi.patron',
    scope: 'stihi.patron',
    table: 'nfts',
    index: 'hash',
    limit: 10,
    lower_bound: {
      hash,
    },
    upper_bound: {
      hash,
    },
  };
  return {
    method: urls.getTableRows,
    body: JSONbig.stringify(body),
  };
};
