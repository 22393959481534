import Axios from 'axios';
import { Api, JsonRpc } from 'cyberwayjs';
import JsSignatureProvider from 'cyberwayjs/dist/eosjs-jssig';
import {
  getTransactionObject,
  parseJwt,
  ShowToast,
  updToken,
} from 'helpers';
import * as settings from 'settings';
import { updateJwtCFG } from 'settings/api';
import { BASE_NODE_LINK, CHAIN_VERSION, listReserveNode } from 'settings/main';
import MainStore from 'store';

import LocalStorage from './LocalStorage';

const timeout = 30000;

export interface RequestTypes {
    config: RequestConfig;
    token?: string;
    body?: any;
    type?: string;
    tokenLocal?: string;
}

export interface RequestConfig {
    link?: string;
    method?: string;
    params?: object;
    media?: boolean;
}

const createParams = (obj: any): string => {
  if (obj.params) {
    let compiledParams = '?';

    for (const key in obj.params) {
      if (obj.params.hasOwnProperty(key)) {
        compiledParams += `${key}=${obj.params[key]}&`;
      }
    }
    compiledParams = compiledParams.slice(0, -1);
    return compiledParams;
  }
  return '';
};

const createRequest = async ({ config, body, token, type }: RequestTypes): Promise<any> => {
  const { isAuth } = MainStore;
  let contentType = 'application/json';
  if (type === 'media') {
    contentType = 'multipart/form-data';
    const fd = new FormData();
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        fd.append(key, body[key]);
      }
    }
    // eslint-disable-next-line no-param-reassign
    body = fd;
  }

  let result: object = {};
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      ...(isAuth && { Authorization: `JWT ${token}` }),
      'Content-Type': contentType,
      accept: 'application/json',
    },
  });
  const link = `${settings.mainSettings.mainSettings.apiLink}${config.link}${createParams(config)}`;

  switch (config.method) {
    case 'get':
      result = axiosInstance.get(link);
      break;
    case 'post':
      result = axiosInstance.post(link, body);
      break;
    case 'put':
      result = axiosInstance.put(link, body);
      break;
    case 'delete':
      result = axiosInstance.delete(link, { data: body });
      break;
    default:
      break;
  }
  return result;
};

const interceprot401 = async ({ config, body, type, tokenLocal }: RequestTypes): Promise<any> => {
  const token = tokenLocal || LocalStorage.get('at');
  const { setIsCrashed, isCrached } = MainStore;
  const atExpt: any = parseJwt(token)?.exp || 0;
  const now: number = Math.round(new Date().getTime() / 1000);

  if (!tokenLocal && config.link !== updateJwtCFG.link && atExpt !== 0 && atExpt < now && MainStore.isAuth) {
    return updToken({ config, body, type });
  }

  try {
    return await createRequest({ config, body, token, type });
  } catch (error:any) {
    const cleanErr = JSON.parse(JSON.stringify(error));
    if (cleanErr.code === 'ECONNABORTED' && !isCrached) {
      setIsCrashed(cleanErr);
    }
    if (!error.response) return;
    if (error.response.status === 401) {
      return updToken({ config, body, type });
      // NEED TO CREATE ERROR OBSERVER
    }
    return error.response;
  }
};

const apiRequest = async (config: RequestConfig, body?: any, type?: any, tokenLocal?: string) => interceprot401({ config, body, type, tokenLocal });

const createNodeRequest = async (method: string, body: any, node: string) => {
  const axiosNodeInstance = Axios.create({
    timeout,
  });
  const link = `${node}${CHAIN_VERSION}${method}`;
  return (await axiosNodeInstance.post(link, body)).data;
};

const lostNodeConnection = async (method: string, body: any, node: string, reconnect: number = 3): Promise<any> => {
  const tryCount = reconnect > 0 ? reconnect - 1 : 0;
  if (tryCount === 0) {
    MainStore.setIsCrashed({ node, status: 'crashed' });
    return { rows: [] };
  }
  try {
    const res = await createNodeRequest(method, body, node);
    MainStore.activeNode = node;
    return res;
  } catch (error:any) {
    if (error.response?.status === 500) {
      return error.response;
    }
    return lostNodeConnection(method, body, listReserveNode[0], tryCount);
  }
};

export const nodeRequest = async (method: string, body: any, node: string = settings.mainSettings.mainSettings.nodeLink) => lostNodeConnection(method, body, node);

export const sendTransaction = async (privateKey: string, sendingData: any) => {
  const rpc = new JsonRpc(MainStore?.activeNode || BASE_NODE_LINK);
  try {
    const signatureProvider = new JsSignatureProvider([privateKey]);
    const api = new Api({
      rpc,
      signatureProvider,
      textDecoder: new TextDecoder(),
      textEncoder: new TextEncoder(),
    });
    const transactionObject = await getTransactionObject(sendingData);
    return api.transact(transactionObject);
  } catch (err) {
    ShowToast('Не удалось подписать транзакцию, не корректный ключ', 'error');
  }
};

export const nrequest = async (method: string, body: any, node: string = settings.mainSettings.mainSettings.nodeLink) => {
  const axiosNodeInstance = Axios.create({
    timeout,
  });
  const link = `${node}${CHAIN_VERSION}${method}`;
  return (await axiosNodeInstance.post(link, body)).data;
};

export default apiRequest;
