import { useEffect, useRef, useState } from 'react';

import Editor from 'components/Editor';
import Button from 'UI/Button';
import { IAddSetCommentEnabled, IComment, IEditorRef } from 'types';
import { storePostCommentsById } from 'helpers';
import { signTransactionModal } from 'settings/modal';
import { CommentsStore, ModalStore, ThemeStore } from 'store';
import { observer } from 'mobx-react';
import addCommentPipeline from 'Pipelines/addCommentPipeline';

interface IAddSimpleCommentProps extends IAddSetCommentEnabled {
  parentId: string;
  articleId: string;
  id: string;
  level: number;
  setEditCommentEnabled?: React.SetStateAction<any>;
  margin?: string;
  className?: string;
  content?: string;
  isPost?: boolean;
  editCommentEnabled?: boolean;
}

const AddSimpleComment = ({
  addCommentEnabled, setAddCommentEnabled, className, margin, parentId, articleId, level, content, setEditCommentEnabled, id: comId, isPost, editCommentEnabled,
}: IAddSimpleCommentProps) => {
  const { modalControl } = ModalStore;
  const { addNewCommentToList } = CommentsStore;
  const { activeTheme } = ThemeStore;
  const [isHaveValueEditor, setIsHaveValueEditor] = useState(false);

  const editorRef = useRef<IEditorRef | null>(null);

  const updateCommentsList = async (res: IComment) => {
    setAddCommentEnabled(false);
    await isPost ? storePostCommentsById() : addNewCommentToList(res, level);
  };

  const onClickAddComment = async () => {
    const contentObj = await editorRef?.current?.getContentObj();

    const metaObj = {
      ...contentObj?.meta,
      parent_guid: parentId,
      article_guid: articleId,
    };

    const commentMeta = new Blob([JSON.stringify(metaObj)], { type: 'text/plain;charset=utf-8' });

    const body = {
      level: new Blob([`${level}`]),
      content: contentObj?.content,
      meta: commentMeta,
      ...(content && { id: comId }),
    };

    const signModal = { ...signTransactionModal, tag: 'posting', autoSign: true, pipeline: addCommentPipeline };
    modalControl(signModal, {
      content: !!content,
      updateCommentsList,
      body,
    });
  };

  useEffect(() => {
    if (!content || !addCommentEnabled) return;

    editorRef.current?.setContent(content);
  }, [content, addCommentEnabled]);

  const onClickCancelAdd = () => {
    setAddCommentEnabled(false);
    setEditCommentEnabled && setEditCommentEnabled(false);
  };

  const calcWidth = (l: number) => {
    let w = 0;

    switch (l) {
      case 1:
        w = 755;
        break;
      case 2:
        w = 695;
        break;
      case 3:
        w = 635;
        break;
      case 4:
        w = 575;
        break;
      case 5:
        w = 510;
        break;
      default:
        w = 510;
        break;
    }

    return `${w}px`;
  };

  return addCommentEnabled ? (
    <div className={`${className || ''} add-comment-container edit-texting-${activeTheme}`} style={{ margin, width: calcWidth(level) }}>
      <Editor
        ref={editorRef}
        minHeight={80}
        showSwitcherButtons
        isEmptyPlaceholder
        showPreviewBtn
        setIsHaveValue={setIsHaveValueEditor}
        maxImageSize={200}
      />
      <div className="add-comment-buttons">
        <Button
          title={editCommentEnabled ? 'Обновить' : 'Добавить'}
          className="add-comment-btn"
          action={onClickAddComment}
          disabled={!isHaveValueEditor}
        />
        <Button
          title="Отменить"
          className="cancel-add-btn"
          action={onClickCancelAdd}
        />
      </div>
    </div>
  ) : null;
};

export default observer(AddSimpleComment);
