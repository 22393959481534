import {
  getDataBody, getFunds,
} from 'helpers';
import { sendTransaction } from 'services/Api';
import { contractAdress } from 'settings/main';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';
import { uint128 } from 'utils/KeyUtils';

const contributePipeline: Pipeline = {
  title: 'contributePipeline',
  tasks: [
    {
      title: 'Подготовка данных и спонисрование поста',
      task: async (meta: any) => {
        const { userData } = MainStore;
        const data = {
          amount: `${Number(meta.amount).toFixed(4)} SAVVA`,
          author: meta.author,
          storage: 'stihi.io',
          contentguid: uint128(meta.uuid).toString(),
          patron: userData.cyber_name,
        };
        const sendingData = getDataBody(userData.cyber_name, contractAdress, data, 'contribute');
        await sendTransaction(meta.key, sendingData);
      },
      errorShow: (_: any, error: any) => {
        const { fundConfigProxy } = MainStore;
        const cleanError = JSON.parse(JSON.stringify(error)).json.error.code;
        switch (cleanError) {
          case 3050003:
            return `Сумма должна быть больше или равна ${fundConfigProxy.minamount}`;
          case 3090005:
            return 'Не хватает прав для ключа, проверьте настройки разрешений';
          default:
            return JSON.parse(JSON.stringify(error)).json.error.message;
        }
      },
    },
    {
      title: 'Запрос фонд поста',
      task: async (meta: any) => {
        const { uuid, author } = meta;
        const answer = await getFunds(uuid, author);
        return answer;
      },
    },
    {
      title: 'Обновление фонда поста',
      task: async (_: any, results) => {
        const { setPostFunds } = MainStore;
        const data = results['Запрос фонд поста'].rows;
        setPostFunds(data.length > 0 ? data[0] : {});
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default contributePipeline;
