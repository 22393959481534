import { ReactElement, useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import './style.scss';
import * as Store from 'store';
import Modal from 'UI/Modal';

const CurtainWrapper = (): ReactElement => {
  const [counter, setCounter] = useState<number>(Math.round(1638997200 - new Date().getTime() / 1000));
  const { modalControl, open } = Store.ModalStore;
  const { comeOn } = Store.CurtainStore;
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const openModal = () => {
    const isDeveloper = {
      title: 'У Вас есть доступ?',
      button: 'Войти',
      сonfig: {
        link: '',
        method: '',
      },
      type: 'curtain',
      func: comeOn,
    };
    modalControl(isDeveloper);
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const timeLeft = () => {
    const dateNow = Math.round(new Date().getTime());
    let delta = Math.abs(1638997200000 - dateNow) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const seconds = Math.round(delta % 60);
    setTimer({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  useEffect(() => {
    timeLeft();
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <div className="curtainWrapper">
      {open && <Modal />}
      <Logo />
      <p>Друзья! Мы упорно готовимся к запуску новой версии сайта</p>
      <p>В данный момент мы производим тестирование сервиса, совсем скоро доступ будет открыт</p>
      <br />
      <div className="timer">
        <div className="timer_unit">
          <span>{timer.days}</span>
          <span>дней</span>
        </div>
        <div className="timer_unit">
          <span>{timer.hours}</span>
          <span>часов</span>
        </div>
        <div className="timer_unit">
          <span>{timer.minutes}</span>
          <span>минут</span>
        </div>
        <div className="timer_unit">
          <span>{timer.seconds}</span>
          <span>секунд</span>
        </div>
      </div>
      <p className="curtainWrapper_beta">
        Если вы бета-тестер нажмите
        <span onClick={() => openModal()}>сюда</span>
      </p>
    </div>
  );
};

export default observer(CurtainWrapper);
