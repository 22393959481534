import { Link } from 'react-router-dom';
import { ThemeStore } from 'store';
import defaultAvatar from 'assets/images/avatar-default.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import './style.scss';

interface IUserCard {
    title: string;
    username: string;
    description?: string | boolean | any;
    img?: string;
    link?: string;
    showArrow?: boolean;
    small?: boolean;
    marginName?: number;
    onClick?: any;
}

const UserCard = ({
  title, description, link, username, img, showArrow, small, marginName = 0, onClick,
}: IUserCard) => {
  const { activeTheme } = ThemeStore;
  return (
    <div className={`userCard ${small ? 'userCard-small' : ''}`}>
      <Link to={link || `/${username}`} onClick={onClick}>
        <div className={`userCard_imgBox ${!img?.includes('ipfs') ? 'avatarBorder' : ''} `}>
          <img src={img || defaultAvatar} alt="avatar" />
        </div>
        <div className="userCard_content" style={{ marginTop: `${marginName}px` }}>
          <span className={`userCard_content_name userCard_content_name_${activeTheme} ${!description ? 'small-name' : ''}`}>{title}</span>
          <span className={`note note_${activeTheme}`}>{description || ''}</span>
        </div>
        <div className="userCard_arrowIcon">
          {showArrow ? <ArrowIcon /> : null}
        </div>
      </Link>
    </div>
  );
};

export default UserCard;
