import { useState } from 'react';

import { observer } from 'mobx-react';
import { ModalStore } from 'store';
import { useMount } from 'hooks';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { keyFromPassw } from 'utils/KeyUtils';
import Button from 'UI/Button';

// import { modalSettings } from 'settings';
import PdfDocument from './SaveKeyTemplate';

import './style.scss';

const Stage2 = observer(() => {
  const { modalValues, getModalSettings } = ModalStore;
  const { payload: { g, p } } = getModalSettings;
  const [keys, setKeys] = useState<false | object>(false);
  const generateKeyList = async () => {
    const key_owner = await keyFromPassw(g, p, 'owner');
    const key_active = await keyFromPassw(g, p, 'active');
    const key_posting = await keyFromPassw(g, p, 'posting');
    const b = {
      key_owner,
      key_active,
      key_posting,
    };
    setKeys({ ...b });
  };

  useMount(() => generateKeyList());

  return (
    <div className="signinSaveKeys">
      <div className="SignUpInfo_helloText">
        {getModalSettings.tag !== 'savedKeys' ? <p>Вы авторизовались по паролю</p> : null}
        <p>Обращаем Ваше внимание, что использовать пароль - небезопасно!</p>
        <p>В пароле хранятся ключи которые можно использовать для перевода токенов</p>
        <p>Сохраните ключи находящиеся в пароле и используйте постинг ключ для авторизации и постинга статей</p>
      </div>
      <div
        className="signinSaveKeys_button"
      >
        {keys ? (
          <PDFDownloadLink
            document={<PdfDocument keys={keys} golos={g || 0} cyber={modalValues.login} />}
            fileName="stihi_io_keys.pdf"
            className="button m0"
          >
            {({ loading }) => (loading ? 'Генерация ключей' : 'Сохранить ключи в PDF')}
          </PDFDownloadLink>
        // eslint-disable-next-line
        ) : <Button action={() => {}} title={'Генерация ключей'} />}
      </div>
    </div>
  );
});

export default Stage2;
