import {
  updateProfilePic,
  updatePostCover,
  deleteUserPic,
  createPostingKey,
  changeOwnPermissionLink,
  blockUser,
  blockArt,
  getUserKeyList,
} from 'helpers';
import announcePipeline from 'Pipelines/announcePipeline';
import buyNFTPipeline from 'Pipelines/buyNFT';
import contractActionPipeline from 'Pipelines/contractActionPipeline';
import contributePipeline from 'Pipelines/contribute';
import saleNFTPipeline from 'Pipelines/saleNFT';
import signInPipeline from 'Pipelines/signInPipeline';
import signupPipeline from 'Pipelines/signupPipeline';
import stakeActionPipeline from 'Pipelines/stakeActionPipeline';
import tokenTransferPipline from 'Pipelines/tokenTransferPipline';
import { Pipeline } from 'store/PipelineStore';

import {
  announceCFG,
  BanArtCFG,
  banUserCFG,
  defaultCFG,
  deleteAvatarCFG,
  deleteBannerCGF,
  IRequestConfig,
  loginCFG,
  signUpCFG, unBanArtCFG, unBanUserCFG, unsubscribeCFG,
  updateAvatarCFG,
  updateBannerCGF,
} from './api';

interface IModals {
  title: string;
  button: string;
  config: IRequestConfig;
  type: string;
  func?: any;
  aspect?: number[];
  tag?: string;
  isCrop?: boolean;
  buttonhidden?: boolean;
  disableCheck?: boolean;
  note?: string;
  pipeline?: Pipeline;
  backFunc?: () => void | Promise<void>;
}

export const uploadAvatar: IModals = {
  title: 'Редактировать аватар',
  button: 'Сохранить',
  config: updateAvatarCFG,
  tag: 'avatar',
  func: () => updateProfilePic(),
  type: 'uploadImg',
};

export const uploadBanner: IModals = {
  title: 'Редактировать баннер',
  button: 'Сохранить',
  config: updateBannerCGF,
  tag: 'banner',
  func: () => updateProfilePic(),
  type: 'uploadImg',
  aspect: [6, 1],
};

export const deleteAvatar: IModals = {
  title: 'Удалить аватар',
  button: 'Удалить',
  config: deleteAvatarCFG,
  tag: 'avatar',
  func: () => deleteUserPic(),
  type: 'info',
  disableCheck: true,
};

export const deleteBanner: IModals = {
  title: 'Удалить баннер',
  button: 'Удалить',
  config: deleteBannerCGF,
  tag: 'banner',
  func: () => deleteUserPic(),
  type: 'info',
  disableCheck: true,
};

export const uploadPostBanner: IModals = {
  title: 'Добавить обложку поста',
  button: 'Сохранить',
  config: defaultCFG,
  func: () => updatePostCover(),
  aspect: [3, 2],
  tag: 'postBanner',
  type: 'uploadImg',
};

export const signIn: IModals = {
  title: 'ВОЙТИ',
  button: 'Войти',
  config: loginCFG,
  type: 'sign-in',
  pipeline: signInPipeline,
};

export const signUp: IModals = {
  title: 'Регистрация',
  button: 'Регистрация',
  config: signUpCFG,
  type: 'sign-up',
  pipeline: signupPipeline,
};

export const sendTokens: IModals = {
  title: 'Передать пользователю',
  button: 'Передать',
  config: defaultCFG,
  type: 'sent-to-user',
  pipeline: tokenTransferPipline,
};

export const stake: IModals = {
  title: 'Увеличить ресурсы (Stake)',
  button: 'Stake',
  config: defaultCFG,
  type: 'stake',
  tag: 'stake',
  pipeline: tokenTransferPipline,
};

export const unStake: IModals = {
  title: 'Уменьшить ресурсы (Stake)',
  button: 'Unstake',
  config: defaultCFG,
  type: 'stake',
  tag: 'withdraw',
  pipeline: stakeActionPipeline,
};

export const stakeToOtherAccount: IModals = {
  title: 'Делегировать ресурсы',
  button: 'Делегировать',
  config: defaultCFG,
  type: 'stake',
  tag: 'delegateuse',
  pipeline: stakeActionPipeline,
};

export const unStakeToOtherAccount: IModals = {
  title: 'Отменить делегирование ресурсов',
  button: 'Подтвердить',
  config: defaultCFG,
  type: 'stake',
  tag: 'recalluse',
  pipeline: stakeActionPipeline,
};

export const claim: IModals = {
  title: 'Подтвердить вывод делегированных ресурсов',
  button: 'Подтвердить',
  config: defaultCFG,
  type: 'stake',
  tag: 'claim',
  pipeline: tokenTransferPipline,
};

export const sentToPatron: IModals = {
  title: 'Перевести в Фонд Мецената',
  button: 'Перевести',
  config: defaultCFG,
  type: 'sent-to-patron',
  tag: 'send-to-patron',
  pipeline: tokenTransferPipline,
};

export const receiveFromPatron: IModals = {
  title: 'Вывести из Фонда Мецената',
  button: 'Вывести',
  config: defaultCFG,
  type: 'receive-from-patron',
  tag: 'withdrawPatron',
  pipeline: contractActionPipeline,
};

export const sendToStore: IModals = {
  title: 'Перевести в сейф',
  button: 'Перевести',
  config: defaultCFG,
  type: 'send-to-store',
  tag: 'safe',
  pipeline: contractActionPipeline,
};

export const receiveFromStore: IModals = {
  title: 'Забрать из сейфа',
  button: 'Вывести',
  config: defaultCFG,
  type: 'receive-from-store',
  tag: 'unsafe',
  pipeline: contractActionPipeline,
};

export const jsonShow: IModals = {
  title: 'JSON транзакции',
  button: 'Вывести',
  config: defaultCFG,
  type: 'json-view',
  buttonhidden: true,
};

export const errorView: IModals = {
  title: 'Подробности ошибки',
  button: 'Вывести',
  config: defaultCFG,
  type: 'json-view',
  buttonhidden: true,
};

export const signTransactionModal: IModals = {
  title: 'Введите ключ, чтобы завершить действие',
  button: 'Подтвердить',
  config: defaultCFG,
  type: 'sign-transaction',
  tag: 'accept',
};

export const setPostingKey: IModals = {
  title: 'Введите ключ, чтобы завершить действие',
  button: 'Подтвердить',
  note: 'Эта операция требует ввода Вашего приватного активного ключа или ключа владельца',
  config: defaultCFG,
  type: 'sign-transaction',
  func: () => createPostingKey(),
  tag: 'setPosting',
};

export const getKFP: IModals = {
  title: 'Получение ключей из пароля',
  button: 'Получить',
  note: 'Введите Ваш пароль для получения ключей',
  config: defaultCFG,
  type: 'kfp',
  func: () => getUserKeyList(),
};

export const updPostingKey: IModals = {
  title: 'Введите ключ, чтобы завершить действие',
  button: 'Подтвердить',
  note: 'Эта операция требует ввода Вашего приватного активного ключа или ключа владельца',
  config: defaultCFG,
  type: 'sign-transaction',
  func: () => createPostingKey(),
  tag: 'updPosting',
};

export const showPostingKeys: IModals = {
  title: 'Создание разрешений для постинг ключа',
  button: 'Сохранить',
  config: defaultCFG,
  // func: () => deleteUserPic(),
  type: 'createPermissions',
  disableCheck: true,
};

export const setOwnPermissionLInk: IModals = {
  title: 'Изменить статус разрешения для ключа',
  button: 'Сохранить',
  note: 'Эта операция требует ввода Вашего приватного активного ключа или ключа владельца',
  config: defaultCFG,
  func: () => changeOwnPermissionLink(),
  type: 'sign-transaction',
  disableCheck: true,
};

export const unSubscribe: IModals = {
  title: 'Редактировать подписки',
  button: 'Отказаться от подписки',
  config: unsubscribeCFG,
  type: 'info',
};

export const contributeModal: IModals = {
  title: 'Спонсировать',
  button: 'Спонсировать',
  config: defaultCFG,
  tag: 'posting',
  pipeline: contributePipeline,
  type: 'contribute',
};

export const buyNFT: IModals = {
  title: 'Купить NFT в коллекцию',
  button: 'Купить',
  config: defaultCFG,
  type: 'buyNFT',
  tag: 'posting',
  pipeline: buyNFTPipeline,
};

export const setNFTPtice: IModals = {
  title: 'Изменить цену',
  button: 'Подтвердить',
  config: defaultCFG,
  type: 'setNFTPtice',
  pipeline: saleNFTPipeline,
};

export const announceModal: IModals = {
  title: 'Размещение анонса произведения',
  button: 'Анонсировать',
  config: announceCFG,
  type: 'announce',
  tag: 'announces',
  pipeline: announcePipeline,
};

export const banUser: IModals = {
  title: 'Блокировка пользователя',
  button: 'Заблокировать',
  config: banUserCFG,
  type: 'blockuser',
  func: () => blockUser(),
};

export const unBanUser: IModals = {
  title: 'Разблокировка пользователя',
  button: 'Разблокировать',
  config: unBanUserCFG,
  type: 'blockuser',
  tag: 'unban',
  func: () => blockUser(),
};

export const banArticle: IModals = {
  title: 'Блокировка статьи',
  button: 'Заблокировать',
  config: BanArtCFG,
  type: 'blockart',
  func: () => blockArt(),
};

export const unBanArticle: IModals = {
  title: 'Разблокировка статьи',
  button: 'Разблокировать',
  config: unBanArtCFG,
  type: 'blockart',
  tag: 'unban',
  func: () => blockArt(),
};
