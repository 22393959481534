import {
  Dispatch,
  forwardRef, SetStateAction, useImperativeHandle, useRef, useState,
} from 'react';

import MarkdownEditor from '@uiw/react-markdown-editor';
import { observer } from 'mobx-react';
import { ThemeStore } from 'store';
import SunEditor from 'suneditor-react';
import {
  getUserName, getUTCTime, resizeImages, urlify,
} from 'helpers';
import InputTransparent from 'UI/InputTransparent';
import { IContentObj } from 'types';
import typewriterMp3 from 'assets/typewriter.mp3';
import { ReactComponent as TypewriterIcon } from 'assets/images/typewriter-icon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/eye.svg';
import { useMount } from 'hooks';
import { LINK_PATTERN } from 'settings/main';
import './style.scss';
import SunEditorCore from 'suneditor/src/lib/core';

import { editorConfig, EditorTypes, editorTypesData } from './data';

const previewCharactersCount = 83;
const typewriterAudio = new Audio(typewriterMp3);
const emptyLine = '<span><br></span>';
const whiteSpace = '&ZeroWidthSpace;';

interface IEditorProps {
  showTypewriterBtn?: boolean;
  showPreviewBtn?: boolean;
  showHeadingInp?: boolean;
  showSwitcherButtons?: boolean;
  defaultEditorType?: EditorTypes;
  onInput?: () => void;
  onChange?: () => void;
  classNames?: {
    topContainer?: string;
    iconsContainer?: string;
    iconContainer?: string;
    switcherButtonsContainer?: string;
    editorContainer?: string;
    headingContainer?: string;
  };
  minHeight?: number;
  isEmptyPlaceholder?: boolean;
  setIsHaveValue?: Dispatch<SetStateAction<boolean>>;
  maxImageSize?: number;
}

const Editor = forwardRef(({
  showTypewriterBtn,
  showPreviewBtn,
  showHeadingInp,
  showSwitcherButtons,
  defaultEditorType,
  onInput,
  onChange,
  classNames,
  minHeight,
  isEmptyPlaceholder,
  setIsHaveValue,
  maxImageSize = 700,
}: IEditorProps, ref) => {
  const { activeTheme } = ThemeStore;

  const [heading, setHeading] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [markdownText, setMarkdownText] = useState<string>('');
  const [previewClassName, setPreviewClassName] = useState<string>('');
  const [isTypewriterEnabled, setIsTypewriterEnabled] = useState<boolean>(false);
  const [editorType, setEditorType] = useState<EditorTypes>(defaultEditorType || EditorTypes.visual);

  const sunEditorRef = useRef<SunEditorCore | null>(null);
  const typewriterRef = useRef<boolean>(false);

  const isMarkdown = editorType === EditorTypes.markdown;

  const setTypeWriterMode = (mode: boolean) => {
    typewriterRef.current = mode;
    setIsTypewriterEnabled(mode);
  };

  const playAudio = () => {
    onInput && onInput();

    if (!typewriterRef.current) return;

    typewriterAudio.pause();
    typewriterAudio.currentTime = 0;
    typewriterAudio.play();
  };

  const removeBlankSpacesFromEndOfHTML = (html: string): string => {
    const contentArr = Array.from(new DOMParser().parseFromString(html, 'text/html').body.childNodes);

    for (let i = contentArr.length - 1; i >= 0; i--) {
      const nodeChildArr = Array.from(contentArr[i].childNodes);

      const isFirstElemWhiteSpace = !nodeChildArr[0].textContent?.replace('\u200B', '').length;

      if (!((nodeChildArr.length === 1 && nodeChildArr[0] instanceof HTMLBRElement)
        || (nodeChildArr.length === 2 && isFirstElemWhiteSpace && nodeChildArr[1] instanceof HTMLBRElement))) break;

      contentArr.splice(i, 1);
    }

    return contentArr.map(item => {
      if (item instanceof Text) {
        const p = document.createElement('p');
        p.appendChild(item);

        return p.outerHTML;
      }
      return (item as HTMLElement).outerHTML;
    }).join('');
  };

  const getContentObj = async (): Promise<IContentObj> => {
    const { getContents } = (sunEditorRef.current as any) || {};

    const sunEditorTextPreview = sunEditorRef.current?.getContext && (sunEditorRef.current?.getContext().element.wysiwyg as HTMLElement).innerText.split('\n').join(' ');

    // Note: '@uiw/react-markdown-editor' doesn't give api yet
    const { innerHTML: markdownHTML, textContent: markDownTxt } = document.querySelector('.md-editor-preview') || {};

    const allContent = removeBlankSpacesFromEndOfHTML(isMarkdown ? markdownHTML : getContents ? getContents() : '');

    const text = (isMarkdown ? markDownTxt : sunEditorTextPreview) || '';

    const lastCharacter = text[previewCharactersCount + 1];
    const droppedLink = text.split(' ').map(el => el.replace(LINK_PATTERN, '')).join(' ');
    const preview = `${droppedLink.trim().slice(0, previewCharactersCount)}${lastCharacter ? '...' : ''}`;

    const intermediateDiv = document.createElement('div');
    intermediateDiv.innerHTML = allContent;
    (intermediateDiv.firstChild as HTMLElement)?.classList.add(editorType);

    const allImages = intermediateDiv.querySelectorAll('img');

    await resizeImages(allImages, maxImageSize);

    const cnt = new Blob([intermediateDiv.innerHTML], { type: 'text/plain;charset=utf-8' });

    intermediateDiv.remove();

    const meta = {
      title: heading,
      author: getUserName(),
      time: getUTCTime(),
      preview,
    };

    return { meta, content: cnt, contentText: intermediateDiv.innerHTML };
  };

  const onChangeText = (value: string, isPlayAudio?: boolean) => {
    setContent(value);
    setIsHaveValue && setIsHaveValue(!!removeBlankSpacesFromEndOfHTML(value));
    isPlayAudio && playAudio();

    onChange && onChange();
  };

  const onChangeHeading = (value: string) => {
    setHeading(value);
    playAudio();
  };

  useImperativeHandle(ref, () => ({
    setContent, setTypeWriterMode, getContentObj, setHeading, heading, content,
  }));

  useMount(() => {
    const defaultSpans = document.querySelectorAll('.txt');

    defaultSpans.forEach((item, index) => {
      const { parentElement } = item;
      item.remove();
      if (parentElement) {
        const span = document.createElement('span');
        span.innerHTML = index === 0 ? 'Т' : 'Р';

        span.style.setProperty('font-weight', 'bold');
        span.style.setProperty('font-size', '20px');
        span.style.setProperty('text-align', 'center');

        span.classList.add('txt');

        parentElement.firstChild?.remove();
        parentElement.appendChild(span);
        parentElement.style.setProperty('width', '36px');
      }
    });
  });

  const handleSwitchEditor = (type: EditorTypes) => {
    const { innerHTML: markdownHTML } = document.querySelector('.md-editor-preview') || {};

    if (isMarkdown) {
      setContent(markdownHTML || '');
      setMarkdownText(content);
    } else {
      markdownText && setContent(markdownText);
    }

    editorType !== type && setEditorType(type);
  };

  return (
    <>
      <div className={`${classNames?.topContainer || ''} edit-top-container`}>
        <div className={`${classNames?.iconsContainer || ''} editor-icons`}>
          {showTypewriterBtn && (
            <div
              className={`${classNames?.iconContainer || ''} editor-icon-container editor-icon-container--${activeTheme} ${isTypewriterEnabled ? 'selected-icon' : ''} `}
              onClick={() => {
                typewriterRef.current = !typewriterRef.current;
                setIsTypewriterEnabled(!isTypewriterEnabled);
              }}
            >
              <TypewriterIcon />
            </div>
          )}
          {showPreviewBtn && isMarkdown && (
            <div
              className={`${classNames?.iconContainer || ''} editor-icon-container eye-icon editor-icon-container--${activeTheme} ${previewClassName ? 'selected-icon' : ''}`}
              onClick={() => setPreviewClassName(previewClassName ? '' : `editing-preview editing-preview--${activeTheme}`)}
            >
              <EyeIcon />
            </div>
          )}
        </div>
        {showSwitcherButtons && (
          <div className={`${classNames?.switcherButtonsContainer || ''} switcher-btns`}>
            {editorTypesData.map(item => (
              <div
                key={item.id}
                className={`type-preview-btn type-preview-btn--${activeTheme} ${item.type === editorType ? `selected-editor selected-editor--${activeTheme}` : ''}`}
                onClick={() => handleSwitchEditor(item.type)}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={`${classNames?.editorContainer || ''} edit-texting ${previewClassName} edit-texting--${activeTheme}`}>
        {showHeadingInp && (
          <InputTransparent
            inpClassName={`${classNames?.headingContainer || ''} heading-inp`}
            placeholder="Заголовок"
            onChange={onChangeHeading}
            value={heading}
          />
        )}
        {isMarkdown ? (
          <MarkdownEditor
            toolbars={[]}
            toolbarsMode={[]}
            className={`${activeTheme === 'dark' ? 'whiteText' : ''}`}
            options={{ lineNumbers: false, lineWrapping: true, defaultStyle: 'font-size: 16px; line-height: 1.56; font-family: Roboto, sans-serif' }}
            value={content}
            // Note: not defined types of this arguments in lib
            onChange={(editor: any, data: any, value: string) => onChangeText(urlify(value), true)}
          />
        ) : (
          <SunEditor
            getSunEditorInstance={(sunEditor => sunEditorRef.current = sunEditor)}
            lang="ru"
            setOptions={{
              ...editorConfig,
              ...(!!minHeight && { minHeight: `${minHeight}px` }),
              ...(!!isEmptyPlaceholder && { placeholder: '' }),
            }}
            setContents={content}
            onInput={e => {
              setIsHaveValue && setIsHaveValue(!!(e.target as HTMLElement)?.textContent);
              playAudio();
            }}
            onChange={onChangeText}
            onKeyUp={e => {
              if (e.key !== 'Enter') return;
              // Note: adding space for open toolbar with double click
              sunEditorRef.current?.insertHTML(whiteSpace);
            }}
            onPaste={e => {
              const cnt = e.clipboardData?.getData('text/plain');
              const div = document.createElement('div');

              const splitCnt = cnt?.split('\n');

              splitCnt?.forEach(item => {
                const p = !item.trim() ? emptyLine : item.includes('http') ? item : `<p>${item}</p>`;
                div.innerHTML = `${div.innerHTML}${p}`;
              });

              div.innerHTML = urlify(div.innerHTML);

              sunEditorRef.current?.insertHTML(splitCnt?.length === 1 && !splitCnt[0].includes('http') ? splitCnt[0] : div.innerHTML, true, true);

              div.remove();
            }}
          />
        )}
      </div>
    </>
  );
});

export default observer(Editor);
