import { useEffect, useMemo, useState } from 'react';

import MainStore, { ModalStore, ThemeStore } from 'store';
import { observer } from 'mobx-react';
import * as s from 'settings/modal';
// import Preloader from 'components/Preloader';
import resourses from 'pages/Profile/Wallet/components/CyberBalances/data';
import {
  getFrozen,
} from 'helpers';
import BalanceLine from 'pages/Profile/Wallet/components/BalanceLine';
import { ReactComponent as CyberIcon } from 'assets/images/cyber_icon.svg';
import { useUnMount } from 'hooks';
import Preloader from 'UI/Preloader';

interface ICyberBalancesProps {
  id: string;
}

const CyberBalances = observer(({ id }: ICyberBalancesProps): JSX.Element => {
  const [claimed, setClaimed] = useState([]);
  // const [cyberBalanceAmount, ] = useState<number>(0.0000);
  // const [getBlokchainAccountData, setStakeInformation] = useState<any>({});
  const {
    userData,
    userBalance,
    setUserBalances,
    dropUserBalances,
    userBalanceLoading,
    setBlockchainAccount,
    getBlokchainAccountData,
    blockchainAccountLoading,
  } = MainStore;

  const {
    modalControl,
  } = ModalStore;

  const { activeTheme } = ThemeStore;

  const cyberBalance = useMemo(() => ([
    { name: 'Передать', action: '', function: () => modalControl(s.sendTokens, { sender: userData.cyber_name, token: 'CYBER' }) },
    { name: 'Увеличить ресурсы (stake)', action: '', function: () => modalControl(s.stake, { sender: userData.cyber_name, token: 'CYBER', recipient: 'cyber.stake' }) },
    { name: 'Обменять', action: '', link: `https://cw.finance/?account=${userData.cyber_name}&pair=cw.pcybsav` },

  ]), [userData.cyber_name, modalControl]);

  const stakedBalance = useMemo(() => ([
    { name: 'Уменьшить ресурсы (unstake)', action: '', function: () => modalControl(s.unStake, { sender: userData.cyber_name, token: 'CYBER' }) },
    { name: 'Делегировать ресурсы', action: '', function: () => modalControl(s.stakeToOtherAccount, { sender: userData.cyber_name, token: 'CYBER' }) },
  ]), [userData.cyber_name, modalControl]);

  const frozen = useMemo(() => ([
    { name: 'Вывести доступные (Claim)', action: '', function: () => modalControl(s.claim, { sender: userData.cyber_name, token: 'CYBER' }) },
  ]), [userData.cyber_name, modalControl]);

  const delegetedBalance = useMemo(() => ([
    { name: 'Отменить делегирование', action: '', function: () => modalControl(s.unStakeToOtherAccount, { sender: userData.cyber_name, token: 'CYBER' }) },
  ]), [userData.cyber_name, modalControl]);

  const claimedSumm = (data: any) => {
    let summ = 0;
    data.length && data.map((item:any) => summ += item.amount);
    return summ ? (summ / 10000).toFixed(4) : 0;
  };

  const checPercent = (key: string) => {
    const result:number = getBlokchainAccountData[key]?.used || 1 / (getBlokchainAccountData[key]?.available || 1 / 100);
    return !Number.isNaN(result) ? result.toFixed(2) : 0;
  };

  useEffect(() => {
    getFrozen(id).then(res => setClaimed(res));
    setUserBalances(id);
    setBlockchainAccount(id);
  }, [id, setUserBalances, setBlockchainAccount]);

  useUnMount(() => dropUserBalances());

  const balancesList = [
    {
      name: 'CYBER',
      note: 'Перемещаемые токены системы CyberWay, которые могут быть переданы на любой аккаунт в любой момент',
      icon: <CyberIcon />,
      amount: userBalance.CYBER || '0.0000',
      menuItems: cyberBalance,
      menuHide: !(+userBalance.CYBER > 0),
      isLoading: userBalanceLoading,
    },
    {
      name: 'Ресурсы',
      note: 'Заблокированное (staked)  количество токенов CYBER -  необходимое условие для работы с блокчейном CyberWay. Чем больше у Вас ресурсов , тем больше транзакций вы сможете выполнить.',
      styles: { borderBottom: 'none' },
    },
    {
      name: 'Доступные',
      note: 'Ресурсы доступные для использования и делегирования другим пользователям.',
      icon: <CyberIcon />,
      amount: (+getBlokchainAccountData.stake_info?.staked.split(' ')[0] - +getBlokchainAccountData.stake_info?.provided.split(' ')[0]).toFixed(4),
      menuItems: stakedBalance,
      menuHide: getBlokchainAccountData.stake_info?.staked === '0.0000 CYBER',
      isLoading: blockchainAccountLoading,
      styles: { borderBottom: 'none', paddingLeft: '60px' },
    },
    {
      name: 'Делегировано',
      note: 'Ресурсы делегированные для использования другим пользователям.',
      icon: <CyberIcon />,
      amount: (+getBlokchainAccountData.stake_info?.provided.split(' ')[0] - +claimedSumm(claimed)).toFixed(4),
      menuItems: delegetedBalance,
      menuHide: getBlokchainAccountData.stake_info?.staked === '0.0000 CYBER',
      isLoading: blockchainAccountLoading,
      styles: { borderBottom: 'none', paddingLeft: '60px' },

    },
    {
      name: 'Получено',
      note: 'Ресурсы делегированные Вам другими пользователями.',
      icon: <CyberIcon />,
      amount: getBlokchainAccountData.stake_info?.received.split(' ')[0] || '0.0000',
      isLoading: blockchainAccountLoading,
      styles: { borderBottom: 'none', paddingLeft: '60px' },

    },
    {
      name: 'Заморожено',
      note: 'Ресурсы находящиеся в статусе вывода из делегирования другим пользователям.',
      icon: <CyberIcon />,
      menuItems: frozen,
      amount: claimedSumm(claimed) || '0.0000',
      isLoading: blockchainAccountLoading,
      styles: { paddingLeft: '60px' },

    },
  ];

  return (
    <>
      <div className={`walletBalances walletBalances_${activeTheme}`}>
        {balancesList.map(el => (
          <BalanceLine {...el} id={id} activeTheme={activeTheme} key={el.name} isLoading={el.isLoading || false} styles={el.styles || false} invert />
        ))}
        <div className={`walletBalances_line walletBalances_line_${activeTheme}`}>
          <div className={`walletBalances_line_title walletBalances_line_title_${activeTheme}`}>
            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%', alignItems: blockchainAccountLoading ? 'center' : '' }}>
              {!blockchainAccountLoading
                ? (
                  <div className="walletBalances_cyberInfoBox">
                    <div className={`walletBalances_cyberInfoBox_line strongSpan strongSpan--${activeTheme}`}>
                      <span />
                      <span>
                        available
                      </span>
                      <span>
                        used
                      </span>
                      <span>
                        %
                      </span>
                    </div>
                    {Object.keys(getBlokchainAccountData).length
                      && (
                        resourses.map(el => (
                          <div className="walletBalances_cyberInfoBox_line" key={`${el.name}`}>
                            <span>
                              {el.name}
                            </span>
                            <span>
                              {getBlokchainAccountData[el.key]?.available}
                            </span>
                            <span>
                              {getBlokchainAccountData[el.key]?.used}
                            </span>
                            <span>
                              {checPercent(el.key)}
                            </span>
                          </div>
                        ))
                      )}
                  </div>
                ) : <Preloader />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default CyberBalances;
