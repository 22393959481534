import React from 'react';

import MainStore, { ThemeStore } from 'store';
import MarkDownPreview from '@uiw/react-markdown-preview';
import { ReactComponent as HideEye } from 'assets/images/eye-hide.svg';
import { observer } from 'mobx-react';
import { setDocumentCopiedText } from 'helpers';

interface IPostContentProps {
  content: string;
  contentRef: React.Ref<HTMLDivElement>;
}

const PostContent = ({ content, contentRef }: IPostContentProps) => {
  const {
    activePost,
    setShowedNsfw,
    deleteFromShowedNsfw,
    showedNsfwList,
    userData,
    isAdmin,
  } = MainStore;
  const { activeTheme } = ThemeStore;

  const {
    meta, is_nsfw, id, authorLogin, is_blocked_author, is_blocked,
  } = activePost || {};
  const { title } = meta || {};

  const setShow = () => {
    if (showedNsfwList.includes(id)) {
      deleteFromShowedNsfw(id);
    } else {
      setShowedNsfw(id);
    }
  };

  const isHideContent = !isAdmin && (is_blocked_author || is_blocked) && authorLogin !== userData.cyber_name;

  return (
    <div className="feedPost_content_pText">
      <span className={`feedPost_content_pText_title feedPost_content_pText_title_${activeTheme}`}>{title}</span>
      {(is_nsfw && showedNsfwList.includes(id)) && (
        <span className="showNsfw" style={{ marginLeft: '5px', marginBottom: '5px' }} onClick={setShow}>Скрыть</span>
      )}
      {
        isHideContent ? (
          <div className="feedPost_content_nsfw">
            <HideEye />
            <span>
              <p>{is_blocked_author ? 'Автор заблокирован!' : 'Пост заблокирован!'}</p>
              <p>
                Контент данного
                {' '}
                {is_blocked_author ? 'автора' : 'поста'}
                {' '}
                нарушает правила сервиса
              </p>
            </span>
          </div>
        ) : (is_nsfw && !showedNsfwList.includes(id) && authorLogin !== userData.cyber_name) && (
        <div className="feedPost_content_nsfw">
          <HideEye />
          <span>
            <p>Осторожно!</p>
            <p>Контент содержит нецензурную лексику</p>
            <p onClick={setShow}>Нажмите что бы посмотреть</p>
          </span>
        </div>
        )
      }
      <div
        ref={contentRef}
        className="feedPost_content_pText--text"
        onCopy={() => {
          const selectedText = window.getSelection()?.toString();

          if (!selectedText) return true;

          let newText = '';
          const splittedText = selectedText.split('\n');

          splittedText.forEach(item => {
            newText += item || '\n';
          });

          setDocumentCopiedText(newText);
          return true;
        }}
      >
        <MarkDownPreview className={activeTheme} source={content} />
      </div>
    </div>
  );
};

export default observer(PostContent);
