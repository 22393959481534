import './style.scss';

type PreloaderTypes = {
  small?: boolean;
}

const Preloader = ({ small }: PreloaderTypes) => (
  <div className={`preloaderContainer ${small ? 'preloaderContainer_small' : ''}`}>
    <div className={`preloaderContainer_item ${small ? 'preloaderContainer_item_small' : ''}`} />
    <div className={`preloaderContainer_item ${small ? 'preloaderContainer_item_small' : ''}`} />
    <div className={`preloaderContainer_item ${small ? 'preloaderContainer_item_small' : ''}`} />
    <div className={`preloaderContainer_item ${small ? 'preloaderContainer_item_small' : ''}`} />
    <div className={`preloaderContainer_item ${small ? 'preloaderContainer_item_small' : ''}`} />
  </div>
);
export default Preloader;
