import { useState } from 'react';

import Select, { OptionsType, OptionTypeBase } from 'react-select';
import apiRequest from 'services/Api';
import { IProvidedOptions } from 'types';
import { useMount } from 'hooks';
import { ThemeStore } from 'store';
import { observer } from 'mobx-react';
import './style.scss';
import { StylesConfig } from 'react-select/src/styles';

interface ICustomSelectProps {
    onChange: Function;
    optionsList?: IProvidedOptions[];
    placeholder?: string;
    valueKey?: string | number;
    labelKey?: string | number;
    isDisabled?: boolean;
    selected?: any;
    apiLink?: string;
    responseKey?: string;
    params?: any;
    className?: string;
    styles?: StylesConfig<OptionsType<OptionTypeBase>, boolean>;
    isSearchable?: boolean;
    listFunc?: any;
}

const CustomSelect = ({
  apiLink,
  onChange,
  selected,
  responseKey,
  optionsList,
  valueKey,
  labelKey,
  placeholder,
  isDisabled,
  params,
  className,
  styles,
  isSearchable,
  listFunc,
}: ICustomSelectProps) => {
  const [options, setOptions] = useState<any>([]);
  const { activeTheme } = ThemeStore;
  useMount(() => {
    if (!apiLink) return;

    const conf = {
      link: apiLink,
      method: 'get',
      params: { ...params },
    };
    // SORT BY ID
    const createOptions = (data:[]) => (data ? data.map(opt => ({
      opt,
      value: opt[valueKey || 'id'],
      label: opt[labelKey || 'name'],
    })) : []);

    apiRequest(conf).then(res => {
      if (!res) return;
      if (listFunc) {
        setOptions(createOptions(listFunc(responseKey ? res.data[responseKey] : res.data)));
      } else {
        setOptions(createOptions(responseKey ? res.data[responseKey] : res.data));
      }
    });
  });

  return (
    <Select
      value={selected}
      isDisabled={isDisabled || false}
      options={options.length ? options : optionsList}
      onChange={o => onChange(o)}
      placeholder={placeholder}
      className={`${className || ''} customSelect--${activeTheme}`}
      classNamePrefix="customSelect"
      styles={styles}
      isSearchable={isSearchable}
    />
  );
};

export default observer(CustomSelect);
