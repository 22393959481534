import MainStore from 'store';
import { FilterPosts } from 'types';

import LocalStorage from '../services/LocalStorage';

interface IRequestParams {
  count?: number;
  cursor?: string;
  dict?: string;
  filter?: string;
  order?: string;
  id?: string;
  index?: number;
  a?: string;
  cyber?: string;
  login?: string;
  user?: string;
  age?: number;
}

enum RequestTypes {
  get = 'get',
  post = 'post',
  put = 'put',
  delete = 'delete',
}

enum ApiLinks {
  getCaptcha = 'get_captcha',
  checkCaptcha = 'check_captcha',
  profileAvatar = 'profile/avatar',
  profileBanner = 'profile/banner',
  login = 'login',
  signup = 'signup',
  profile = 'profile',
  profileFind = 'profile/find',
  article = 'article',
  articleList = 'article/list',
  articleNav = 'article/nav',
  comment = 'comment',
  commentList = 'comment/list',
  nftsList = 'nft/list',
  commentListLast = 'comment/list_last',
  commentGetFromParent = 'comment/list_level',
  rubrics = 'dict',
  subscription = '/subscription',
  refresh = 'login/refresh',
  events = 'events/history',
  actions = 'actions/history',
  laround = 'rounds/history',
  announce = 'create_announce',
  prepareConfigs = 'content/prepare',
  blockUser = 'profile/block_user',
  unblockUser = 'profile/unblock_user',
  blockArt = 'blocked_content/set_block',
  unblockArt = 'blocked_content/set_unblock',
  patronNotepad = 'funds/patron_notepad',
}

export interface IRequestConfig {
  link: '' | ApiLinks;
  method: '' | RequestTypes;
  tag?: string;
  params?: IRequestParams;
}

export const POSTS_PAGE_COUNT = 10;
export const COMMENTS_COUNT = 20;
export const COMMENTS_COUNT_POST = 8888;
export const UPDATE_TIME = 15;

export const defaultCFG: IRequestConfig = {
  link: '',
  method: '',
};
export const updateJwtCFG: IRequestConfig = {
  link: ApiLinks.refresh,
  method: RequestTypes.get,
};
export const getCaptchaCFG: IRequestConfig = {
  link: ApiLinks.getCaptcha,
  method: RequestTypes.get,
};

export const checkCaptchaCFG: IRequestConfig = {
  link: ApiLinks.checkCaptcha,
  method: RequestTypes.post,
};

export const updateAvatarCFG: IRequestConfig = {
  link: ApiLinks.profileAvatar,
  method: RequestTypes.post,
};

export const deleteAvatarCFG: IRequestConfig = {
  link: ApiLinks.profileAvatar,
  method: RequestTypes.delete,
};

export const updateBannerCGF: IRequestConfig = {
  link: ApiLinks.profileBanner,
  method: RequestTypes.put,
};

export const deleteBannerCGF: IRequestConfig = {
  link: ApiLinks.profileBanner,
  method: RequestTypes.delete,
};

export const loginCFG: IRequestConfig = {
  link: ApiLinks.login,
  method: RequestTypes.get,
};

export const loginIn: IRequestConfig = {
  link: ApiLinks.login,
  method: RequestTypes.post,
};

export const signUpCFG: IRequestConfig = {
  link: ApiLinks.signup,
  method: RequestTypes.post,
};

export const editProfileCFG: IRequestConfig = {
  link: ApiLinks.profile,
  method: RequestTypes.put,
};
export const findUserCFG: IRequestConfig = {
  link: ApiLinks.profileFind,
  method: RequestTypes.get,
};

export const getUserById = (id: string): IRequestConfig => ({
  link: ApiLinks.profile,
  method: RequestTypes.get,
  params: {
    cyber: id,
  },
});

export const addPostCFG: IRequestConfig = {
  link: ApiLinks.article,
  method: RequestTypes.post,
};

export const putPostCFG: IRequestConfig = {
  link: ApiLinks.article,
  method: RequestTypes.put,
};

export const addCommentCFG: IRequestConfig = {
  link: ApiLinks.comment,
  method: RequestTypes.post,
};

export const prepareContentCFG: IRequestConfig = {
  link: ApiLinks.prepareConfigs,
  method: RequestTypes.post,
};

export const putCommentCFG: IRequestConfig = {
  link: ApiLinks.comment,
  method: RequestTypes.put,
};

export const getCommentsByPost = (id: string): IRequestConfig => ({
  link: ApiLinks.commentList,
  method: RequestTypes.get,
  params: {
    filter: `b:1|a:${id}`,
    count: COMMENTS_COUNT_POST,
    order: 't',
  },
});

export const initialGetCommentsList: IRequestConfig = {
  link: ApiLinks.commentList,
  method: RequestTypes.get,
  params: {
    filter: 'b:1|l:1',
    count: COMMENTS_COUNT,
    order: 't',
  },
};

export const initialGetNFTsList = (id?: string, order: string = 't', rubricCode?: string): IRequestConfig => {
  const filter = `${id ? `p:${id}` : ''}${rubricCode ? `|r:${rubricCode}` : ''}`;
  LocalStorage.set('nft', { rubricCode, order });

  return {
    link: ApiLinks.nftsList,
    method: RequestTypes.get,
    params: {
      order,
      ...(filter && { filter }),
      count: POSTS_PAGE_COUNT,
    },
  };
};

export const getNextNFT = (cursor: string): IRequestConfig => ({
  link: ApiLinks.nftsList,
  method: RequestTypes.get,
  params: {
    count: POSTS_PAGE_COUNT,
    cursor,
  },
});

export const CommentListCFG: IRequestConfig = {
  link: ApiLinks.commentListLast,
  method: RequestTypes.get,
  params: {
    filter: 'b:1',
    count: COMMENTS_COUNT,
    order: 't',
  },
};

export const getCommentsByParrentList = (id: string): IRequestConfig => ({
  link: ApiLinks.commentGetFromParent,
  method: RequestTypes.get,
  params: {
    filter: `b:1|p:${id}`,
    count: COMMENTS_COUNT,
    order: 't',
  },
});

export const getCommentsByParrentListMore = (cursor: string): IRequestConfig => ({
  link: ApiLinks.commentListLast,
  method: RequestTypes.get,
  params: {
    count: COMMENTS_COUNT,
    cursor,
  },
});

export const subscribeCFG: IRequestConfig = {
  link: ApiLinks.subscription,
  method: RequestTypes.post,
};

export const subscriptionListCFG = (link: string, login: string): IRequestConfig => ({
  link: link as ApiLinks,
  method: RequestTypes.get,
  params: {
    login,
  },
});

export const unsubscribeCFG: IRequestConfig = {
  link: ApiLinks.subscription,
  method: RequestTypes.delete,
};

export const announceCFG: IRequestConfig = {
  link: ApiLinks.announce,
  method: RequestTypes.post,
};

export const banUserCFG: IRequestConfig = {
  link: ApiLinks.blockUser,
  method: RequestTypes.post,
};

export const unBanUserCFG: IRequestConfig = {
  link: ApiLinks.unblockUser,
  method: RequestTypes.post,
};

export const BanArtCFG: IRequestConfig = {
  link: ApiLinks.blockArt,
  method: RequestTypes.post,
};
export const unBanArtCFG: IRequestConfig = {
  link: ApiLinks.unblockArt,
  method: RequestTypes.post,
};

interface IPatronFund {
  user?: string;
  guid?: string;
}

export const getPatronCFG = ({ user, guid }: IPatronFund) => ({
  link: ApiLinks.patronNotepad,
  method: RequestTypes.get,
  params: {
    ...(user && { user }),
    ...(guid && { guid }),
  },
});

interface IGetPostsCFG {
  page: FilterPosts;
  userId?: string;
  rubricCode?: string;
  tag?: string;
  time?: string;
  noNeedForCache?: boolean;
  filters?: string;
}

export const getPostsCFG = ({
  page, userId, rubricCode, tag, time, noNeedForCache,
}: IGetPostsCFG): IRequestConfig => {
  const { userData } = MainStore;
  const isShowNsfw = (userData.nsfw === 'h' && page !== 'blog' && userId !== userData.cyber_name) ? '|nsfw:false' : '';

  let params = {
    count: POSTS_PAGE_COUNT,
    filter: `b:1${isShowNsfw}${rubricCode ? `|r:${rubricCode}` : ''}`,
    order: 't',
  };

  const {
    actual, popular, blog, feed, admin,
  } = FilterPosts;

  switch (page) {
    case actual:
      params = { ...params, filter: `${params.filter}|fg:0`, order: 'dcur' };
      break;
    case blog:
      params = { ...params, filter: `${params.filter}|l:${userId || userData.cyber_name}${tag ? `|tg:${tag}` : ''}` };
      break;
    case popular:
      params = { ...params, filter: `${params.filter}${time ? `|t:${time}` : ''}`, order: 'd' };
      break;
    case feed:
      params = { ...params, filter: `${params.filter}|s:${userData.cyber_name}` };
      break;
    case admin:
      params = { ...params, filter: 'blc:t' };
      break;
    default:
      break;
  }

  !noNeedForCache && LocalStorage.set(page, { filter: params.filter, rubric: rubricCode, time, tag });

  return {
    link: ApiLinks.articleList,
    method: RequestTypes.get,
    params,
  };
};

export const getNextPosts = (cursor: string): IRequestConfig => ({
  link: ApiLinks.articleList,
  method: RequestTypes.get,
  params: {
    count: POSTS_PAGE_COUNT,
    cursor,
  },
});

export const getNextComment = (cursor: string): IRequestConfig => ({
  link: ApiLinks.commentListLast,
  method: RequestTypes.get,
  params: {
    count: POSTS_PAGE_COUNT,
    cursor,
  },
});

export const getPostById = (id: string): IRequestConfig => ({
  link: ApiLinks.article,
  method: RequestTypes.get,
  params: {
    id,
  },
});

export const getNextPrevPosts = (cursor: string, index: number): IRequestConfig => ({
  link: ApiLinks.articleNav,
  method: RequestTypes.get,
  params: {
    cursor,
    index,
  },
});

export const getRubricsCFG: IRequestConfig = {
  link: ApiLinks.rubrics,
  method: RequestTypes.get,
  params: {
    dict: 'rubrics',
  },
};

export const getEventsCFG: IRequestConfig = {
  link: ApiLinks.events,
  method: RequestTypes.get,
};

export const getActionsCFG: IRequestConfig = {
  link: ApiLinks.actions,
  method: RequestTypes.get,
};

export const getLaroundsCFG: IRequestConfig = {
  link: ApiLinks.laround,
  method: RequestTypes.get,
};
// node configs

export const tableRowsPatron = {
  json: true,
  index: 'primary',
  code: 'stihi.patron',
  scope: 'stihi.patron',
  table: 'accounts',
  limit: 1,
};

export const tableRowsStaked = {
  json: true,
  index: 'bykey',
  code: 'cyber.stake',
  scope: 'cyber.stake',
  table: 'provision',
};

export const tableRowsFrozen = {
  json: true,
  index: 'bykey',
  code: 'cyber.stake',
  scope: 'cyber.stake',
  table: 'provpayout',
};

export const getContributors = {
  json: true,
  code: 'stihi.patron',
  scope: 'stihi.patron',
  table: 'contributors',
  index: 'hashaccount',
  limit: 300,
};
