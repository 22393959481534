interface IStakeList {
    name: string;
    key: string;
  }

const resourses: IStakeList[] = [
  { name: 'Storage', key: 'storage_limit' },
  { name: 'Bandwidth', key: 'net_limit' },
  { name: 'CPU', key: 'cpu_limit' },
];

export default resourses;
