import { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import MainStore, { ModalStore, ThemeStore } from 'store';
import Button from 'UI/Button';
import Checkbox from 'UI/InputChechbox';
import Input from 'UI/InputText';
import CustomSelect from 'UI/CustomSelect';
import Editor from 'components/Editor';
import { signTransactionModal, uploadPostBanner } from 'settings/modal';
import { usePostId, useUnMount } from 'hooks';
import {
  dataURLtoFile, fileToBase64, getPic, storeActivePost, uniqueArrayFromString, urlToFile,
} from 'helpers';
import { IEditorRef, IRubric } from 'types';
import './style.scss';
import 'suneditor/dist/css/suneditor.min.css';
import LocalStorage from 'services/LocalStorage';
import { EditorTypes } from 'components/Editor/data';
import addPostPipeline from 'Pipelines/addPostPipeline';
import { useLocation } from 'react-router-dom';

export const postCache = 'post';

const PostEditor = ({ containerClassName, defaultEditorType }: { containerClassName?: string; defaultEditorType?: EditorTypes }) => {
  const postId = usePostId();

  const {
    postEditingBanner,
    checkIsUser,
    setPostEditingBanner,
    postEditingBannerUrl,
    setActivePost,
    userData,
    isBlocked,
    postEditMode,
    setPostEditMode,
  } = MainStore;
  const { modalControl } = ModalStore;
  const { activeTheme, themeData, getThemeIcon, changeTheme } = ThemeStore;
  const [selectedRubric, setSelectedRubric] = useState<IRubric | null>(null);
  const [tags, setTags] = useState<string>('');
  const [titleEmpty, setTitleEmpty] = useState<boolean>(true);
  const [isExisting, setIsExisting] = useState<boolean>(false);
  const [isHavePreview, setIsHavePreview] = useState<boolean>(false);
  const [agreementChecked, setAgreementChecked] = useState<boolean>(!!userData?.rubrics?.length);
  const editorRef = useRef<null | IEditorRef>(null);
  const location = useLocation();

  /* useMount(() => {
    const { setContent, setHeading } = editorRef?.current || {};
    const isFromEditMode = postEditMode && location.pathname === '/edit/0';

    const cached = LocalStorage.get(postCache);

    if (!cached && !isFromEditMode) return;

    const { content: cnt, title, rubrics, tags: postTags, banner } = cached;

    cnt && setContent && setContent(cnt);
    title && setHeading && setHeading(title);
    rubrics && setSelectedRubric(rubrics[0]);
    postTags && setTags(`${postTags}`);
    banner && setPostEditingBanner(dataURLtoFile(banner));
  }); */

  const setEditPost = async () => {
    const { setContent, setHeading } = editorRef?.current || {};

    const { content, post } = await storeActivePost() || {};
    const { authorLogin, meta } = post || {};

    if (!checkIsUser(authorLogin || '')) return;

    const { title, rubrics, tags: postTags, banner } = meta || {};

    // Note: string taken from avatar-default.svg file
    content && setContent && !content.includes('mask id="mask0" mask-type="alpha"') && setContent(content);
    title && setHeading && setHeading(title);
    rubrics && setSelectedRubric(rubrics[0]);
    postTags && setTags(`${postTags}`);
    banner && setPostEditingBanner(await urlToFile(getPic(banner.hash)));

    setIsExisting(!!post);
  };

  useEffect(() => {
    const { setContent, setHeading } = editorRef?.current || {};
    const isCreate = location.pathname === '/edit/0';

    const isFromEditMode = postEditMode && isCreate;

    const cached = LocalStorage.get(postCache);

    if (!cached && isCreate) {
      editorRef.current?.setContent('');
      editorRef.current?.setHeading('');
      setTags('');
      setSelectedRubric(null);
      setPostEditingBanner(null);
      return;
    }

    if (cached && isCreate) {
      const { content: cnt, title, rubrics, tags: postTags, banner } = cached;

      cnt && setContent && setContent(cnt);
      title && setHeading && setHeading(title);
      rubrics && setSelectedRubric(rubrics[0]);
      postTags && setTags(`${postTags}`);
      banner && setPostEditingBanner(dataURLtoFile(banner));
    }

    if (postEditMode && !isCreate) {
      setEditPost();
    }

    if (isFromEditMode) {
      if (postEditMode) {
        return setPostEditMode(false);
      }

      editorRef.current?.setContent('');
      editorRef.current?.setHeading('');
      setTags('');
      setSelectedRubric(null);
      setPostEditingBanner(null);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  /* useMount(async () => {
    const { setContent, setHeading } = editorRef?.current || {};
    const isFromEditMode = postEditMode && location.pathname === '/edit/0';
    const cached = LocalStorage.get(postCache);
    if (cached && isFromEditMode) return;

    const { content, post } = await storeActivePost() || {};
    const { authorLogin, meta } = post || {};

    if (!checkIsUser(authorLogin || '')) return;

    const { title, rubrics, tags: postTags, banner } = meta || {};

    // Note: string taken from avatar-default.svg file
    content && setContent && !content.includes('mask id="mask0" mask-type="alpha"') && setContent(content);
    title && setHeading && setHeading(title);
    rubrics && setSelectedRubric(rubrics[0]);
    postTags && setTags(`${postTags}`);
    banner && setPostEditingBanner(await urlToFile(getPic(banner.hash)));

    setIsExisting(!!post);
  }); */

  useUnMount(() => {
    setPostEditingBanner(null);
    postEditMode ? setPostEditMode(false) : setActivePost('');
  });

  useEffect(() => {
    if (!editorRef.current?.getContentObj) return;

    const cacheIntervalId = setInterval(async () => {
      const contentObj = await editorRef.current?.getContentObj();

      if (contentObj) {
        setTitleEmpty(!contentObj.meta?.title.length);
        setIsHavePreview(!!contentObj.meta?.preview.length);
      }

      if (isExisting) return;

      const metaObj = {
        ...contentObj?.meta,
        tags: uniqueArrayFromString(tags),
        ...(selectedRubric && {
          rubrics: [{
            code: selectedRubric?.code,
            name: selectedRubric?.name,
          }],
        }),
      };

      LocalStorage.set(postCache, {
        content: contentObj?.contentText,
        ...metaObj,
        ...(postEditingBanner && { banner: await fileToBase64(postEditingBanner) }),
      });
    }, 1000);

    return () => {
      clearInterval(cacheIntervalId);
    };
  }, [isExisting, postEditingBanner, selectedRubric, tags]);

  const onClickAddCoverPhoto = () => modalControl(uploadPostBanner);

  const onClickAddPost = async () => {
    const contentObj = await editorRef.current?.getContentObj();

    const metaObj = {
      ...contentObj?.meta,
      tags: uniqueArrayFromString(tags),
      ...(selectedRubric && {
        rubrics: [{
          code: selectedRubric?.code,
          name: selectedRubric?.name,
        }],
      }),
    };

    const meta = new Blob([JSON.stringify(metaObj)], { type: 'text/plain;charset=utf-8' });

    const body = {
      content: contentObj?.content,
      meta,
      ...(isExisting && { id: postId }),
      ...(postEditingBanner && { banner: postEditingBanner }),
    };

    const signModal = { ...signTransactionModal, pipeline: addPostPipeline, tag: 'posting', autoSign: true };
    modalControl(signModal, {
      isExisting,
      body,
    });
  };

  const onClickClearBtn = () => {
    if (postEditMode) {
      return setPostEditMode(false);
    }

    editorRef.current?.setContent('');
    editorRef.current?.setHeading('');
    setTags('');
    setSelectedRubric(null);
    setPostEditingBanner(null);
  };

  const setNoneRub = (list: []) => [{
    code: 'NONE',
    id: 1,
    name: 'Без рубрики',
    parent_id: 0,
  }, ...list.filter((x: any) => x.code !== 'NONE')];

  if (isBlocked) window.location.href = '/';

  return (
    <div className={`post-edit-container ${containerClassName}`}>
      <div className={`post-edit-main post-edit-container--${activeTheme}`}>
        <div className="post-edit-top-container">
          <div
            className={`select-photo-container select-photo-container--${activeTheme}`}
            onClick={onClickAddCoverPhoto}
          >
            {!postEditingBannerUrl ? 'Добавить обложку поста' : (
              <img src={postEditingBannerUrl} alt="post-banner" />
            )}
          </div>
        </div>
        <Editor
          ref={editorRef}
          showTypewriterBtn
          showHeadingInp
          showPreviewBtn
          showSwitcherButtons
          defaultEditorType={defaultEditorType}
          classNames={{
            topContainer: 'top-container',
            iconsContainer: 'icons-container',
          }}
        />
        <div className="post-tags">
          <CustomSelect
            apiLink="dict"
            params={{ dict: 'rubrics' }}
            responseKey="list"
            valueKey="code"
            placeholder="Выберите рубрику"
            isSearchable
            listFunc={setNoneRub}
            className="rubrics-select"
            selected={selectedRubric ? {
              value: selectedRubric?.code,
              label: selectedRubric?.name,
            } : null}
            onChange={(item: any) => setSelectedRubric(item.opt)}
            styles={{
              control: styles => ({
                ...styles,
                boxShadow: '0px 2px 3px -1px rgb(0 0 0 / 10%)',
                borderColor: 'rgba(0, 0, 0, 0.15)',
                cursor: 'pointer',
                ':hover': { borderColor: 'rgba(0, 0, 0, 0.15)' },
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.15)' : isSelected ? 'rgba(0, 0, 0, 0.15)' : 'unset',
                cursor: 'pointer',
                ':hover': { backgroundColor: activeTheme === 'dark' ? 'rgba(255, 255, 255, 0.09) !important' : '#ededed' },
              }),
              input: (() => ({
                color: activeTheme === 'dark' ? '#fff' : 'black',
              })),
              singleValue: (styles, { isRtl }) => ({
                ...styles,
                color: isRtl || activeTheme === 'dark' ? '#fff' : 'black',
              }),
            }}
          />
          <Input
            className="tag-inp"
            placeholder="Добавьте название сборника личного блога (можно несколько через запятую)"
            pValue={tags}
            onChange={setTags}
          />
        </div>
        <div className="warning-text">
          Обратите внимание, что «Заголовок» и «Выберите рубрику» - обязательные поля для создания публикации.
        </div>
        <div className="theme-warning-container">
          <p className="warning-text">Проверьте, как будет выглядеть пост в другой теме -</p>
          <img src={getThemeIcon() || ''} alt="theme-icon" onClick={() => changeTheme(themeData?.next || 'light')} />
        </div>
        <div className="add-post-agreement-container">
          <Checkbox
            className="agreement-checkbox"
            checked={isExisting || agreementChecked}
            disabled={isExisting}
            checkBoxHandler={checked => setAgreementChecked(checked)}
          />
          <div className="agreement-text">
            Подтверждаю согласие с условиями
            {' '}
            <a href="https://stihi.io/post/evdazo52xujg/f9f2803e-6d3d-48ec-9e04-5fafa61a7069">Пользовательского соглашения</a>
          </div>
        </div>
        <div className="post-edit-button">
          <Button
            title={(isExisting || postEditMode) ? 'Обновить' : 'Добавить'}
            className="add-post-btn"
            action={onClickAddPost}
            disabled={(isExisting ? titleEmpty : !agreementChecked || !selectedRubric || titleEmpty) || !isHavePreview}
          />
          <Button
            title={postEditMode ? 'Отменить' : 'Очистить'}
            className="clear-post-btn"
            action={onClickClearBtn}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(PostEditor);
