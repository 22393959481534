import { ReactElement } from 'react';

import { ReactComponent as PreloaderIcon } from 'assets/images/preloader.svg';
import './style.scss';

const PreloaderOld = () : ReactElement => (
  <div className="preloader">
    <PreloaderIcon />
  </div>
);

export default PreloaderOld;
