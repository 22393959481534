import { useCallback, useState } from 'react';

import MainStore from 'store';
import apiRequest from 'services/Api';
import { getNextPrevPosts } from 'settings/api';
import { storePostCommentsById } from 'helpers';

export default function useNavigaror() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>({});

  const { activePost, setNavigator } = MainStore;

  const { cursor, dataIndex } = activePost || {};

  const setNextPrev = useCallback(async (props?: { cursorStr: string; index: number }) => {
    const { cursorStr, index } = props || {};
    if (!cursor) {
      setIsLoading(false);
      return;
    }
    try {
      const { data: { next, prev } } = await apiRequest(getNextPrevPosts(cursorStr || cursor, index || dataIndex));
      await storePostCommentsById();
      setNavigator({ next: prev, prev: next });
      setIsLoading(false);
      setIsSuccess(true);
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setError(err);
    }
  }, [cursor, dataIndex, setNavigator]);

  const fetch = useCallback(
    ({ cursorStr, index }) => {
      setIsLoading(true);
      setNextPrev({ cursorStr, index });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [],
  );

  return {
    isLoading, isError, isSuccess, error, fetch,
  };
}
