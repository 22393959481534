import { observer } from 'mobx-react';
import { getPic } from 'helpers';
import * as s from 'settings/modal';
import MainStore, { ModalStore } from 'store';
import { ReactComponent as Camera } from 'assets/images/camera.svg';
import { ReactComponent as Dustbin } from 'assets/images/dustbin.svg';

interface IProfileMainBoxMobileProps {
    profileData: any;
    id: string;
    activeTheme: string;
}

const ProfileMainBoxMobile = ({ id, profileData, activeTheme }: IProfileMainBoxMobileProps) => {
  const { modalControl } = ModalStore;
  const { checkIsUser } = MainStore;
  return (
    <>
      <div className={`profile-header_avatar profile-header_avatar--mobile profile-header_avatar--${activeTheme}`}>
        <img src={getPic(profileData.avatar, 'avatar')} alt="avatar" />
        {checkIsUser(id) && (
          <>
            <div className="profile-header_avatar--upload profile-header_avatar--upload--mobile" onClick={() => modalControl(s.uploadAvatar)}>
              <Camera />
            </div>
            {profileData.avatar.length
              ? (
                <div className="profile-header_avatar--delete profile-header_avatar--delete--mobile" onClick={() => modalControl(s.deleteAvatar)}>
                  <Dustbin />
                </div>
              ) : null}
          </>
        )}
      </div>
      <div
        className="profile-header_banner profile-header_banner--mobile"
        style={{ backgroundImage: `url(${getPic(profileData.banner, 'banner')})`, backgroundSize: 'cover', width: '100%', height: '200px' }}
      >
        {checkIsUser(id) && (
          <>
            <div className="profile-header_banner--upload profile-header_banner--upload--mobile" onClick={() => modalControl(s.uploadBanner)}>
              <Camera />
            </div>
            {profileData.banner
              ? (
                <div className="profile-header_banner--delete" onClick={() => modalControl(s.deleteBanner)}>
                  <Dustbin />
                </div>
              ) : null}
          </>
        )}
      </div>
    </>
  );
};
export default observer(ProfileMainBoxMobile);
