import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import MainStore, { ModalStore, ThemeStore } from 'store';
import Amount from 'components/Amount';
import Button from 'UI/Button';
import FundBox from 'components/FundBox';
import { contributeModal } from 'settings/modal';
import {
  getUTCTimeToShow,
  timestamptToData,
  firtsUppercase,
  getMyShare,
} from 'helpers';

const PositiveFund = () => {
  const { activePost, postFundsObject, isAuth, fundConfig, userData } = MainStore;
  const { activeTheme } = ThemeStore;
  const { modalControl } = ModalStore;
  const [balance, setBalance] = useState<string>('');
  const [blink, setBlink] = useState<boolean>(false);
  const [myPatron, setMyPatron] = useState<any>({});
  const isRountTime = Number(postFundsObject.roundtime) / 1000 < new Date().getTime();

  // LAST ROUND MAKE postFundsObject islastround написать

  const rewardPercent = (sum: string) => {
    let win = Number(sum);
    const winState = [...fundConfig.winlist];
    for (let i = 0; i < winState.length; i++) {
      win *= (1 - (winState[i] / 100));
    }
    return (Number(sum) - win).toFixed(4);
  };

  useEffect(() => {
    if (balance !== postFundsObject.balance && isAuth) {
      getMyShare(activePost.id, activePost.authorLogin)
        .then(res => {
          setMyPatron(res);
        }).catch();
      setBlink(true);
      setBalance(postFundsObject.balance || '');
    }
    blink && setTimeout(() => setBlink(false), 1990);
  }, [postFundsObject, blink, balance, activePost, userData.cyber_name, isAuth]);

  return (
    <div className="vertical mt10">
      <FundBox>
        <div className="feedPost_fund ">
          <span className="flare" style={{ display: blink ? '' : 'none' }} />
          <span className={`feedPost_fund--title feedPost_fund--title--${activeTheme}`}>
            ФОНД ПОСТА
          </span>
          <div className={`feedPost_fund--amount ${blink ? 'scaleRide' : 'scaleRideOff'}`}>
            <Amount amount={postFundsObject.balance ? (+postFundsObject.balance.split(' ')[0]).toFixed(4) : '0.0000'} large resize />
          </div>
          <div className="feedPost_fund--patron feedPost_fund--note">
            <div className="shadowLine mb10" />
            Меценатов
            {' '}
            <strong style={{ color: '#fff' }}>{postFundsObject.npatrons || 0}</strong>
            <div className="shadowLine mt10" />
          </div>
          {isAuth && (
            <div className="feedPost_fund--share feedPost_fund--note">
              <span>Моя доля в фонде</span>
              <span>{`${Math.round(myPatron?.my_share || 0)} из ${myPatron?.total_share || 0}`}</span>
            </div>
          )}
          {
            postFundsObject.roundtime !== '18446744073709551615' ? (
              <>
                <div className="feedPost_fund--note">
                  {isRountTime ? 'Ожидайте тираж' : postFundsObject.islastround ? 'Финальный раунд' : 'Следующий тираж'}
                </div>
                <div className="feedPost_fund--pays">
                  <span>
                    <strong>
                      <Amount amount={postFundsObject.islastround ? postFundsObject.balance.split(' ')[0] : rewardPercent(postFundsObject.balance.split(' ')[0])} resize fontSize={blink ? 15 : 18} />
                      {!isRountTime ? <span className="dot">·</span> : null}
                      {isRountTime ? '' : firtsUppercase(getUTCTimeToShow(timestamptToData(postFundsObject.roundtime)))}
                    </strong>
                  </span>
                </div>
              </>
            ) : null
            }
          <div className="fundBox_buttonBox">
            {(isAuth && (postFundsObject.roundtime === '18446744073709551615' || !isRountTime))
              && (
              <Button
                action={() => modalControl(contributeModal, { uuid: activePost.id, author: activePost.authorLogin })}
                title="Спонсировать"
                styleName="orange"
                className="w100"
              />
              )}
          </div>
        </div>
      </FundBox>
      <div className={`feedPost_fundNote feedPost_fundNote--${activeTheme} pt40`}>
        <p><strong>СТАНЬТЕ МЕЦЕНАТОМ ЭТОГО ПОСТА</strong></p>
        <p>
          Поддержите автора и получите доступ к участию в регулярных тиражах.
        </p>
        <p>
          Вы можете поощрять пост неограничен- ное количество раз и неограниченно по времени, тем самым благодаря автора и помогая в продвижении его творчества
        </p>
      </div>
    </div>
  );
};
export default observer(PositiveFund);
