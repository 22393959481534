import { ReactChild, useRef, useEffect } from 'react';

import MainStore from 'store';

import './style.scss';

interface IContentWrapperProps {
    leftBlock: ReactChild;
    rightBlock?: ReactChild;
    reverse?: boolean;
    fixed?: boolean;
}

const ContentWrapper = ({ leftBlock, rightBlock, reverse, fixed = true }: IContentWrapperProps) => {
  const { screenWidth } = MainStore;
  const ref = useRef<any>(null);

  const scrollControl = () => {
    if (!ref.current) return;
    const cliHeight = ref.current?.clientHeight - window.innerHeight + 200;
    const scroll = Math.round(window.scrollY);
    const delta = cliHeight - scroll;
    if (delta > 0) {
      ref.current.style.setProperty('margin-top', `${scroll * -1}px`);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollControl);
    return () => {
      window.removeEventListener('scroll', scrollControl);
    };
  }, []);
  return (
    <div className="contentWraper">
      <div className={`contentWraper${reverse ? '_sidebar' : '_content'}`} style={{ maxWidth: screenWidth < 1200 ? screenWidth - 50 : '', width: screenWidth - 40 }}>
        {leftBlock}
      </div>
      {screenWidth >= 1200 ? (
        <div className={`contentWraper${reverse ? '_content' : '_sidebar'}`} style={{ height: ref.current?.scrollHeight }}>
          <div className={fixed ? 'contentWraper_fixed' : ''} ref={ref}>
            {rightBlock}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ContentWrapper;
