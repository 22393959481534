import { ReactElement } from 'react';

import { Link, useHistory } from 'react-router-dom';
import EditButton from 'UI/EditButton';
import MainStore, { ThemeStore } from 'store';
import { observer } from 'mobx-react';
import { getPic } from 'helpers';
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import './style.scss';

const UserPanel = observer(({ cyber_name, avatar }: any): ReactElement => {
  const history = useHistory();
  const { isBlocked, isAdmin } = MainStore;
  const { getThemeIcon, changeTheme, themeData } = ThemeStore;

  return (
    <div className="usePanel-box">
      {!isBlocked && <EditButton action={() => history.push('/edit/0')} />}
      <div className="usePanel-box_img">
        <Link to={`/${cyber_name}`} onClick={() => MainStore.blogFilter = {}}>
          <img src={getPic(avatar)} alt="avatar" />
        </Link>
      </div>
      {
        isAdmin
          ? <Link to="/adminhub/posts">A</Link>
          : null
      }
      <div className="usePanel-box_img--theme" onClick={() => changeTheme(themeData?.next || 'light')}>
        <img src={getThemeIcon()} alt="avatar" />
      </div>
      <div className="usePanel-box_img--theme">
        <LogoutIcon onClick={() => MainStore.logout()} />
      </div>

    </div>
  );
});
export default UserPanel;
