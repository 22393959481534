const getPostsLink = (link: string) => `/posts/${link}`;

const listPublic = [
  {
    name: 'Лидеры',
    link: getPostsLink('popular'),
    tag: 'popular',
    private: false,
  },
  {
    name: 'Актуальное',
    link: getPostsLink('actual'),
    tag: 'actual',
    private: false,
  },
  {
    name: 'Комментарии',
    link: '/comments',
    tag: 'comments',
    private: false,
  },
  {
    name: 'Новое',
    link: getPostsLink('new'),
    tag: 'new',
    private: false,
  },
  {
    name: 'Лента',
    link: getPostsLink('feed'),
    tag: 'feed',
    private: true,
  },
  {
    name: 'NFT коллекция',
    link: '/nft',
    tag: 'nft',
    private: false,
  },
];

export default listPublic;
