import { observer } from 'mobx-react';
// import { EditOutlined } from '@ant-design/icons';
import { getCommentsByParrent, getPic, getUTCTimeToShow } from 'helpers';
import Amount from 'components/Amount';
import { IAddSetCommentEnabled } from 'types';
import UserCard from 'UI/UserCard';
import { ReactComponent as CommentIcon } from 'assets/images/comment.svg';
import MainStore, { CommentsStore, ThemeStore } from 'store';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import { ReactComponent as CircleIcon } from 'assets/images/circle.svg';
import Preloader from 'UI/Preloader';
// import { Tooltip } from 'antd';

interface IPostBottomBarProps extends IAddSetCommentEnabled{
  time: string;
  paddingBottom: string | number;
  addCommentText: string;
  showUserAndAmount?: boolean;
  authorAvatar: string;
  author?: string;
  authorLogin?: string;
  level?: number;
  onClickEdit?: () => void;
  blockedComment?: boolean;
  isMore?: boolean;
  setCommentLess?: Function;
  isPost?: boolean;
  align?: string;
  blockComment?: any;
  parrent?: string;
}

const PostBottomBar = ({
  addCommentEnabled,
  setAddCommentEnabled,
  time,
  paddingBottom,
  addCommentText,
  author,
  authorLogin,
  showUserAndAmount,
  level = 0,
  onClickEdit,
  authorAvatar,
  blockedComment,
  setCommentLess,
  isPost,
  align = 'left',
  blockComment = false,
  parrent,
  isMore,
}: IPostBottomBarProps) => {
  const { userData, activePost, isAuth, isAdmin } = MainStore;
  const { activeTheme } = ThemeStore;
  const { isOpenMore, dropOneMore, loadingMore } = CommentsStore;

  const getMore = () => {
    getCommentsByParrent(parrent || '');
  };

  return (
    <div
      className={`blogLine_box_preview_information blogLine_box_preview_information--${activeTheme}`}
      style={{ paddingBottom }}
    >
      {showUserAndAmount && (
        <div>
          <UserCard
            title={author || ''}
            username={authorLogin || ''}
            img={getPic(authorAvatar)}
            small
          />
        </div>
      )}
      {showUserAndAmount ? level === 0 && <CircleIcon className="dot" /> : <span className="postBottomBarInfo_time">{getUTCTimeToShow(time)}</span>}
      {showUserAndAmount ? (
        <span className="postBottomBarInfo_time">
          {getUTCTimeToShow(time)}
        </span>
      ) : level === 0 && <div className="postBottomBarInfo_dot"><CircleIcon className="dot dot_fill-blue" /></div>}
      {showUserAndAmount && (
        <>
          <span className="comments-count" style={{ marginRight: '10px' }}>
            <CommentIcon />
            {activePost.commentsCount}
          </span>
          {activePost.donated ? <Amount justifyContent="flex-start" amount={activePost.donated?.toFixed(0)} /> : ''}
        </>
      )}
      <div className={`postBottomBarInfo_buttons postBottomBarInfo_buttons--${align}`}>
        {
          (blockComment && isAdmin) ? (
            <div className="prwBlock" onClick={blockComment}>
              <Lock />
            </div>
          ) : null
        }
        {authorLogin === userData.cyber_name && (
          <span className="edit-comment" onClick={() => onClickEdit && onClickEdit()}>
            Редактировать
            {/* <Tooltip title="Редактировать">
              <EditOutlined />
            </Tooltip> */}
          </span>
        )}
        {(!isPost && level !== 0) && (
          <span
            className="add-comment"
            onClick={() => setCommentLess && setCommentLess()}
          >
            К беседе
          </span>
        )}
        {(isMore && !isPost) && (
          <span
            className="add-comment"
            onClick={() => (!isOpenMore(parrent) ? getMore() : dropOneMore(parrent))}
          >
            {loadingMore.includes(parrent || 'xoxox') ? <Preloader small /> : <>{isOpenMore(parrent) ? 'Скрыть ответы' : 'Показать ответы'}</>}
          </span>
        )}
        {(!blockedComment && isAuth) && (
          <span
            className="add-comment"
            onClick={() => setAddCommentEnabled(!addCommentEnabled)}
          >
            {addCommentText}
          </span>
        )}
      </div>
    </div>
  );
};

export default observer(PostBottomBar);
