import { useState } from 'react';

import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMount, useUnMount } from 'hooks';
import apiRequest from 'services/Api';
import { CommentListCFG } from 'settings/api';
import NoPost from 'pages/components/NoPosts';
import { CommentsStore, ThemeStore } from 'store';
import 'pages/Post/style.scss';
import Preloader from 'UI/Preloader';
import { storeNewComment } from 'helpers';
import { IComment } from 'types';

import CommentBox from './Comments/CommentBox';

const NewCommentsList = () => {
  const { activeTheme } = ThemeStore;
  const { setCommentList, commentList, dropCommentsList } = CommentsStore;
  const [hasMore, setHasMore] = useState(true);

  useMount(async () => {
    setCommentList((await apiRequest(CommentListCFG))?.data?.list || []);
  });

  const onNextScroll = async () => {
    const newComments = await storeNewComment();
    setHasMore(!!newComments.list?.length);
  };

  useUnMount(dropCommentsList);

  return (
    <>
      {commentList?.list.length ? (
        <InfiniteScroll
          style={{ minHeight: 'calc(100vh - 270px)' }}
          dataLength={commentList.list.length}
          hasMore={hasMore}
          next={onNextScroll}
          loader={<div className={`noPosts noPosts--${activeTheme}`}><Preloader /></div>}
        >
          <div className="post-comments-list">
            {commentList.list.map((el: IComment, index: number) => <CommentBox comment={el} i={index} isLast={index} key={el.id} />)}
          </div>
        </InfiniteScroll>
      ) : <NoPost />}
    </>
  );
};

export default observer(NewCommentsList);
