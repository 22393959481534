import { getFunds } from 'helpers';
import MainStore from 'store';
import { Pipeline } from 'store/PipelineStore';

const { setPostFunds } = MainStore;
const updateFundPost: Pipeline = {
  title: 'updateFundPost',
  tasks: [
    {
      title: 'Запрос фонд поста',
      task: async (meta: any) => {
        const { guid, author } = meta;
        const answer = await getFunds(guid, author);
        return answer;
      },
    },
    {
      title: 'Обновление фонда поста',
      task: async (_: any, results) => {
        const data = results['Запрос фонд поста'].rows;
        setPostFunds(data.length > 0 ? data[0] : {});
      },
    },
  ],
};

export default updateFundPost;
