import { useEffect } from 'react';

import { ModalStore } from 'store';
import { observer } from 'mobx-react';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';

const SignTransaction = observer(() => {
  const { modalValues } = ModalStore;

  useEffect(() => {
    checkFields(modalValues, reqFields.signTransaction);
  }, [modalValues]);

  return (
    <div className="w100">
      <div className="modal_body_line">
        <span className="label" />
        <InputPassword
          placeholder="Приватный ключ"
        />
      </div>
    </div>
  );
});
export default SignTransaction;
