import { useState, useMemo } from 'react';

import { Link, useHistory } from 'react-router-dom';
import * as settings from 'settings';
import MainStore, * as Store from 'store';
import Tabs from 'UI/MenuTabs';
import UserCard from 'UI/UserCard';
import Button from 'UI/Button';
import { getPic } from 'helpers';
import { observer } from 'mobx-react';
import BurgerMenu from 'UI/BurgerMenu';
import { usePostId, useMount } from 'hooks';
import { ReactComponent as EditBtnIcon } from 'assets/images/editIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { ReactComponent as WalletIcon } from 'assets/images/wallet.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';

const MobileMenu = () => {
  // add this to the getPostIdFromLink function
  const history = useHistory();
  const postId = usePostId();

  const [open, setOpen] = useState(false);
  const { getThemeIcon, changeTheme, themeData, activeTheme } = Store.ThemeStore;
  const { isAuth, userData, isBlocked } = Store.default;
  const { modalControl } = Store.ModalStore;

  const userMenu = useMemo(() => ([
    { icon: <EditBtnIcon />, title: 'Новый пост', link: `/edit/${userData.cyber_name}/${postId}`, disable: !isBlocked },
    { icon: <WalletIcon />, title: 'Кошелёк', link: `/profile/${userData.cyber_name}/wallet/` },
    { icon: <SettingsIcon />, title: 'Настройки', link: `/profile/${userData.cyber_name}/settings/edit-profile` },
  ]), [userData, isBlocked, postId]);

  useMount(() => history.listen(() => (setOpen(false))));

  return (
    <>
      <div className={`mobileMenu_wrap ${activeTheme}`}>
        <div><SearchIcon /></div>
        <Link to="/" className="mobileMenu_wrap_logo">
          <img src={themeData?.logoMobile} alt="stihi.io" />
        </Link>
        <BurgerMenu onClick={setOpen} open={open} />
      </div>
      <div className={`mobileMenu_menu ${!open ? 'mobileMenu_menu_closed' : ''} ${activeTheme}`}>
        <div className="mobileMenu_menu_menu">
          <Tabs list={settings.mainMenu.default} column />
        </div>
        {isAuth ? (
          <div className="mobileMenu_menu_links">
            {userMenu.map(el => !el.disable && (
              <Link to={el.link} key={el.link}>
                <div>
                  {el.icon}
                </div>
                <div className={activeTheme}>
                  {el.title}
                </div>
              </Link>
            ))}
          </div>
        ) : null}
        <div className="mobileMenu_menu_links">
          <div className="mobileMenu_menu_links_box" onClick={() => changeTheme(themeData?.next || 'light')}>
            <div>
              <img src={getThemeIcon()} alt="theme" />
            </div>
            <div className={activeTheme}>
              {themeData?.next_name}
            </div>
          </div>
        </div>
        <div className="mobileMenu_menu_footer">
          {isAuth ? (
            <UserCard
              title={userData.alias}
              username={userData.cyber_name}
              description={userData.golos_name}
              img={getPic(userData.avatar)}
            />
          ) : null }
          {!isAuth ? (
            <div className="mobileMenu_menu_footer_line">
              <Button title="Регистрация" action={() => modalControl(settings.modalSettings.signUp)} />
            </div>
          ) : null}
          <div className="mobileMenu_menu_footer_line">
            <Button title={isAuth ? 'Выйти' : 'Войти'} secondary action={() => { isAuth ? MainStore.logout() : modalControl(settings.modalSettings.signIn, { login: '' }); }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(MobileMenu);
