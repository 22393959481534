import ReactJson from 'react-json-view';
import { ModalStore, ThemeStore } from 'store';

const jsonStyle = {
  padding: '20px',
  fontSize: '16px',
};

export const JsonVIew = () => {
  const { getModalValues } = ModalStore;
  const { activeTheme } = ThemeStore;
  const isDark = activeTheme === 'dark';

  return (
    <div style={{ borderRadius: '5px', overflow: 'hidden' }}>
      <ReactJson
        src={getModalValues.object}
        style={{
          ...jsonStyle,
          ...(isDark && { background: '#333' }),
        }}
        indentWidth={2}
        displayDataTypes={false}
        displayObjectSize={false}
        iconStyle="circle"
        theme={isDark ? 'tomorrow' : undefined}
      />
    </div>
  );
};
export default JsonVIew;
