import { FunctionComponent, SVGProps } from 'react';

import { ReactComponent as FBIcon } from 'assets/images/fb.svg';
import { ReactComponent as VKIcon } from 'assets/images/vk.svg';
import { ReactComponent as YoutubeIcon } from 'assets/images/yt.svg';
import { ReactComponent as TelegramIcon } from 'assets/images/tg.svg';

interface IInternalLinks {
    id: number;
    link: string;
    title: string;
    withoutDote?: boolean;
}

export const internalLinks: IInternalLinks[] = [{
  id: 1,
  title: 'Добро пожаловать',
  link: 'https://stihi.io/post/evdazo52xujg/c1352b74-94d1-4667-84b7-94fbbd096b7e',
}, {
  id: 2,
  title: 'Пользовательское соглашение',
  link: 'https://stihi.io/post/evdazo52xujg/f9f2803e-6d3d-48ec-9e04-5fafa61a7069',
}, {
  id: 3,
  title: 'Новости',
  link: 'https://stihi.io/@stihi-io/',
  withoutDote: true,
}];

interface ISocialIconsData extends Omit<IInternalLinks, 'title'> {
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const socialIconsData: ISocialIconsData[] = [{
  id: 1,
  link: 'https://www.facebook.com/www.stihi.io',
  Icon: FBIcon,
}, {
  id: 2,
  link: 'https://vk.com/stihi_io',
  Icon: VKIcon,
}, {
  id: 3,
  link: 'https://www.youtube.com/channel/UCW9rsJEdby5tm1wM0zXrMHQ/featured',
  Icon: YoutubeIcon,
}, {
  id: 4,
  link: 'https://t.me/stihi_io_support',
  Icon: TelegramIcon,
}];
