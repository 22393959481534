import { useState, useEffect, useRef } from 'react';

import useDebounce from 'hooks/useDebounce';
import Input from 'UI/InputText';
import { getPic } from 'helpers';
import { ReactComponent as Search } from 'assets/images/search-user.svg';
import './style.scss';
import apiRequest from 'services/Api';
import { ThemeStore } from 'store';
import UserCard from 'UI/UserCard';
import Preloader from 'UI/Preloader';
import { useHistory } from 'react-router-dom';

const SearchUser = () => {
  const { activeTheme } = ThemeStore;
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1500);
  const [open, setIsOpen] = useState<boolean>(false);
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const rootEl = useRef<any>(null);

  const getUsers = (value: string) => {
    setLoading(true);
    apiRequest({
      link: 'profile/search',
      method: 'get',
      params: {
        search: value.toLocaleLowerCase(),
      },
    }).then(res => {
      setLoading(false);
      setList(res?.data.list);
    })
      .catch(() => setLoading(false));
  };

  const toogle = () => {
    if (open) {
      setList([]);
      setSearchTerm('');
    } setIsOpen(!open);
  };

  useEffect(() => {
    open && toogle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 2) {
      getUsers(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <div className={`searchBox searchBox${open ? '--open' : '--close'}`} ref={rootEl}>
        <Input
          onChange={(val: string) => setSearchTerm(val.trim())}
          pValue={searchTerm}
          style={{ height: '35px', overflow: 'hidden' }}
        />
        <div className="searchBoxDropDown shadow" style={{ padding: list.length > 0 || loading ? '20px' : '0px' }}>
          { loading ? <Preloader /> : null}
          {(list.length ? list : []).map((el: any) => (
            <div className={`subList_unit subList_unit--${activeTheme} searchBoxDropDown_unit`} key={el.CyberName} onClick={() => { history.replace(`/${el.CyberName}`); toogle(); }}>
              <UserCard
                title={el.Alias?.String || el.GolosName || el.CyberName}
                username={el.CyberName}
                // eslint-disable-next-line max-len
                description={(
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {
                            el.BlockedTime
                              ? `Заблокирован: ${el.BlockComment.String}` : (
                                <>
                                  <span>
                                    {`@ ${el.GolosName}`}
                                  </span>
                                  <span style={{ width: '30px', display: 'flex' }} />
                                  <span>{`ID: ${el.CyberName}`}</span>

                                </>
                              )
                        }
                  </div>
                  )}
                img={getPic(el.Avatar?.String)}
                onClick={toogle}
              />
              <div className="divider" />
              {/* {el.cyber !== userData.cyber_name ? (
                <Button
                  action={() => {}}
                  className={isUserSubscriptions || el.subscribed ? '' : 'btn-subscribe'}
                  title={isUserSubscriptions || el.subscribed ? 'Вы подписаны' : 'Подписаться'}
                />
              ) : null} */}
            </div>
          ))}
        </div>
      </div>
      <span onClick={toogle} className="searchBoxButton">{open ? loading ? <Preloader /> : 'X' : <Search height="20px" width="20px" fill="red" />}</span>
    </>
  );
};

export default SearchUser;
