import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  getPic,
  getUTCTimeToShow,
} from 'helpers';
import MainStore, { ThemeStore } from 'store';
import Amount from 'components/Amount';
import FundBox from 'components/FundBox';
import { ReactComponent as Comment } from 'assets/images/comment.svg';
import Preloader from 'UI/Preloader';
import { BADPIC } from 'settings/main';
import { ReactComponent as NFTIcon } from 'assets/images/NFT5.svg';

// TODO: all comments in this component should added when backend will be ready
// Note: need discuss 'small' property
const NFTLine = ({
  id,
  small = false,
  content_guid,
  line = false,
  showNotes = true,
  article,
  donated,
  author,
  commentsCount,
  amount,
  salePrice,
  isInternal,
  is_nsfw,
}: any) => {
  const {
    isMobileContent,
    userData,
    setActivePost,
    showedNsfwList,
  } = MainStore;
  const { activeTheme } = ThemeStore;
  const [balance] = useState<number>(salePrice || 0);
  const [blink, setBlink] = useState<boolean>(false);

  const onClickRubric = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    // const { location: { pathname } } = window;
    /* if (!pathname.includes('posts/')) {
      await updatePostsByFilter({ id: article.authorLogin, rubric: article.meta.rubrics ? article.meta.rubrics[0].code : '' });
    } else {
      await updatePostsByRubrics(article.meta.rubrics ? article.meta.rubrics[0].code : '');
    } */
  };

  const fixLengthPreview = (data: string) => {
    if (!data) return;
    // if ((data.slice(data.length - 3) === '...' || data.length < 85) && balance === 0) return data;
    // const length = data.length - 1;
    return `${data.slice(0, balance === 0 ? 85 : 65)}${data.length > 55 ? '...' : ''}`;
  };

  // addChange price

  /* useEffect(() => {
    const currBal = lastEventsList.find(el => el.name === 'cntrbtevent' && el.content_guid.String === id);
    if (!currBal) return;
    setBlink(true);
    setBalance(Number(currBal.event.balance.split(' ')[0]));
  }, [lastEventsList, id]); */

  const checkNsfw = (text: string | undefined) => {
    if (!text) return;
    const lockedTxt = 'Осторожно, контент содержит нецензурную лексику';
    if (userData.nsfw === 's' || !is_nsfw || showedNsfwList.includes(id) || article.authorLogin === userData.cyber_name) return text;
    return lockedTxt;
  };

  useEffect(() => {
    blink && setTimeout(() => setBlink(false), 1990);
  }, [blink]);

  const setPrev = () => {
    const arr = window.location.pathname.split('/');
    MainStore.listType = arr.includes('posts') ? arr[2] : 'blog';
  };

  const getPreviwPic = (bnr: string, avatar: string) => getPic((bnr === BADPIC || bnr === '') ? avatar : bnr);
  setPrev();

  return (
    <Link
      to={`/post/${author}/${content_guid}`}
      onClick={() => setActivePost(content_guid)}
      className={`blogLine_box ${isMobileContent ? 'blogLineMobile_box' : ''} ${(small || line) ? 'blogLine_box--small' : ''}`}
    >
      {(!small && !line) && (
        <div className="blogLine_banner">
          <div className={`blogLine_img ${isMobileContent ? 'blogLineMobile_img' : ''}`}>
            <img src={getPreviwPic(article.meta.banner?.hash || '', article.authorAvatar || '')} alt="img_post" />
          </div>
        </div>
      )}
      <div className="blogLine_box_preview">
        <object style={{ display: 'flex' }}>
          <Link to={`/${author}`}>
            <span
              className={`blogLine_box_preview--author blogLine_box_preview--author--${activeTheme}`}
            >
              {article.authorAlias || article.authorLogin}
              {isInternal && <Preloader />}
            </span>
          </Link>
        </object>
        <span className={`blogLine_box_preview--title blogLine_box_preview--title--${activeTheme}`}>{checkNsfw(article.meta.title)}</span>
        <span className={`blogLine_box_preview--prev blogLine_box_preview--prev--${activeTheme}`}>{checkNsfw(fixLengthPreview(article.meta.preview))}</span>
        <div className={`blogLine_box_preview_information blogLine_box_preview_information--${activeTheme} ${line ? 'blogLine_box_preview_information--line' : ''} `}>
          {showNotes && (
            <>
              <span className="blogLine_box_preview_rubric" onClick={onClickRubric}>{article.meta.rubrics ? article.meta.rubrics[0].name : ''}</span>
              <span className="dot">·</span>
              <span>{getUTCTimeToShow(article.meta.time)}</span>
            </>
          )}

          <span className="comment-count">
            <Comment />
            {commentsCount}
          </span>
          {donated ? <Amount justifyContent="flex-start" amount={donated?.toFixed(0) || '0'} /> : ''}
          {amount ? <NFTIcon style={{ width: '18.5px', height: '18.5px', filter: 'invert(0%)', paddingLeft: '15px' }} /> : ''}
        </div>
      </div>
      <div className={`blogLine_box_preview--fund ${line ? 'blogLine_box_preview--fund--line' : ''} ${small ? 'w100' : ''}`}>
        {amount > 0
          ? (
            <FundBox small line={line} nft>
              <div className={`blogLine_fund ${small ? 'fr' : ''} ${line ? 'blogLine_fund--line' : ''}`}>
                <span className="flare" style={{ display: blink ? '' : 'none' }} />
                {!line && (
                <span className="feedPost_fund--title feedPost_fund--title--small">
                  СТОИМОСТЬ NFT
                </span>
                )}
                <div className={`feedPost_fund--amount feedPost_fund--amount--small ${blink ? 'scaleRide' : 'scaleRideOff'}`}>
                  <Amount amount={amount.toFixed(3)} fontSize={line ? 22 : 26} resize />
                </div>
              </div>
            </FundBox>
          ) : <></>}
      </div>
    </Link>
  );
};
export default observer(NFTLine);
