import { ReactElement } from 'react';

import {
  Switch, Route, useLocation, Redirect,
} from 'react-router-dom';
import MainStore, { ThemeStore } from 'store';
import Permissions from 'pages/Profile/Settings/Permissions';
import Tabs from 'UI/MenuTabs';
import EditProfile from 'pages/Profile/Settings/EditProfile';
import { settingsMenu } from 'pages/Profile/Settings/data';
import { MOBILE_POINT_MENU } from 'settings/main';
import MenuProfile from 'UI/MenuProfile';
import { observer } from 'mobx-react';

import './style.scss';
import NFTSettings from './NFTSettings';

interface ISettingsProps {
  id: string;
  bPass: string;
}

const Settings = ({ id, bPass }: ISettingsProps): ReactElement => {
  const { activeTheme } = ThemeStore;
  const { screenWidth } = MainStore;
  const basePath = `${bPass}/settings/`;

  const myPage = id === MainStore.userData.cyber_name;

  const redirect = () => <Redirect push to="edit-profile" />;

  return (
    <div className={`settingBox settingBox_${activeTheme}`}>
      {basePath === useLocation().pathname && redirect()}
      {myPage && (
        <>
          <div className={`settingBox_menu settingBox_menu_${activeTheme}`}>
            {screenWidth >= MOBILE_POINT_MENU
              ? <Tabs list={settingsMenu} prefix={`${basePath}`} />
              : <MenuProfile list={settingsMenu} prefix={basePath} />}
          </div>
          <div className="settingBox_content">
            <Switch>
              <Route path={`${basePath}edit-profile/`}><EditProfile id={id} /></Route>
              <Route path={`${basePath}permissions/`}><Permissions id={id} /></Route>
              <Route path={`${basePath}nft-settings/`}><NFTSettings id={id} /></Route>
            </Switch>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Settings);
