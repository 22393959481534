import { v4 as uuid } from 'uuid';
import MainStore, { ThemeStore } from 'store';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';

const PostTags = ({ author }: any) => {
  const { activePost } = MainStore;
  const { activeTheme } = ThemeStore;
  const history = useHistory();

  const { meta } = activePost || {};
  const { tags, rubrics } = meta || {};

  const linkTOBLog = (name:string, type: string) => {
    MainStore.setBlogFilter({ name, type }).then(() => history.push(`/${author}/`));
  };

  return (
    <div className={`feedPost_content_tags feedPost_content_tags--${activeTheme}`}>
      {rubrics?.map((item: any) => (
        <div
          key={`${item.code}-${uuid()}`}
          className="feedPost_content_tags_unit"
          onClick={() => item.code && linkTOBLog(item.code, 'rubrics')}
        >
          {item.name}
        </div>
      ))}
      {tags?.map((item: string) => ((item && item !== 'stihi-io') ? (
        <div
          key={`${item}-${uuid()}`}
          className="feedPost_content_tags_unit"
          onClick={() => linkTOBLog(item, 'tags')}
        >
          {item}
        </div>
      ) : null))}
    </div>
  );
};

export default observer(PostTags);
