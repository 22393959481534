import { ReactElement, useState } from 'react';

import './style.scss';

interface ISButtonProps {
    title: string;
    svg?: object;
    vector?: string;
    onClick: Function;
    disabled?: boolean;
    color?: string;
}

const SliderButton = ({
  title, onClick, svg, disabled, color = 'primary',
}: ISButtonProps): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      className={`sliderButton sliderButton_${color}`}
      onMouseEnter={() => setOpen(!open)}
      onMouseLeave={() => setOpen(!open)}
      onClick={e => !disabled && onClick(e)}
    >
      <div className={`sliderButton_text sliderButton_text--${open ? 'open' : ''} sliderButton_text--${color}`}>{title}</div>
      <div className={`sliderButton_icon sliderButton_icon--${color}`}>{svg}</div>
    </div>
  );
};
export default SliderButton;
