import { decryptPassword } from 'helpers';
import LocalStorage from 'services/LocalStorage';
import { INPUT_SYMBOLS_CRYPTO, OUTPUT_SYMBOLS_CRYPTO } from 'settings/main';
import { ModalStore } from 'store';

const ecc = require('eosjs-ecc');
const uuid = require('uuid');

export const createKey = async (name: string) => {
  const privateKey = await ecc.randomKey();

  return {
    [name]: {
      private: privateKey,
      public: ecc.privateToPublic(privateKey, 'GLS'),
    },
  };
};

export const createPublicKey = (privateKey: string) => ({
  private: privateKey,
  public: ecc.privateToPublic(privateKey, 'GLS'),
});

export const keyFromPassw = async (l: string, p: string, t: string) => {
  const privateKey = await decryptPassword(l, t, p);
  return createPublicKey(privateKey);
};

export const keyGenerator = async (): Promise<object> => {
  const keys = ['key_owner', 'key_active', 'key_posting'];

  return (await Promise.all(keys.map((el: string): Promise<any> => createKey(el))))
    .reduce((prev, next): void => Object.assign(prev, next));
};

export const createSign = (key: string, value: any): string => btoa(ecc.signHash(ecc.sha256(value), key));

export const privateToPublic = (key: string): any => ecc.privateToPublic(key, 'GLS');

export const cipher = (str: string) => {
  const index = (x: string) => INPUT_SYMBOLS_CRYPTO.indexOf(x);
  const translate = (x: any) => (index(x) > -1 ? OUTPUT_SYMBOLS_CRYPTO[index(x)] : x);
  return str.split('').map(translate).join('');
};

export const decipher = (str: string) => {
  const index = (x: string) => OUTPUT_SYMBOLS_CRYPTO.indexOf(x);
  const translate = (x: any) => (index(x) > -1 ? INPUT_SYMBOLS_CRYPTO[index(x)] : x);
  return str.split('').map(translate).join('');
};

export const saveKeyInBrowser = (key: string) => {
  const cryptedKey = cipher(key);
  LocalStorage.set('pk', cryptedKey);
};

export const setKey = () => {
  const { changeHandler, modalSettings } = ModalStore;
  const key = LocalStorage.get('pk');
  if (key) {
    modalSettings.saveKey = true;
    changeHandler(decipher(key), 'key');
  }
};

export const uint128 = (guid: string) => {
  const bytes = uuid.parse(guid);
  let bi = 0n;

  for (let i = 0; i < 16; i++) {
    bi = (bi << 8n) | BigInt(bytes[i]);
  }
  return bi;
};

export const uint128ToUUID = (value: any) => {
  const rb:any = [];
  let bi = value;
  for (let i = 0; i < 16; i++) {
    rb.unshift(bi & 255n);
    bi >>= 8n;
  }
  return uuid.stringify(rb);
};
