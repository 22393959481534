import { useEffect, useState } from 'react';

import {
  getFrozen,
  getStakedAccounts,
  getUserAccountBalances,
  getUserBlockchainAccount,
} from 'helpers';
import { observer } from 'mobx-react';
import { ModalStore } from 'store';
import Input from 'UI/InputText';
/* import SearchForm from 'UI/SearchForm'; */
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';
import SearchForm from 'UI/SearchForm';
import CustomSelect from 'UI/CustomSelect';
import ModalNote from 'UI/ModalNote';
import ModalLine from 'UI/Modal/ModalLine';
import { IProvidedOptions } from 'types';

import { headerNote, footerNote } from './data';

const correctiveStyles = {
  display: 'flex',
  width: '70%',
};
const balanceStyle = {
  color: '#337DB2',
  fontWeight: 700,
  cursor: 'pointer',
};

interface IProvidedList {
  amount: number;
  recipient_name: string;
}

const Stake = observer(() => {
  const { modalValues, changeHandler, modalSettings, setModalValues, getModalValues } = ModalStore;
  const [stakedAccounts, setStakedAccounts] = useState<IProvidedOptions[]>([]);

  const sr = (data: any) => changeHandler(data || null, 'recipient');

  useEffect(() => {
    if (modalValues.balance) return;
    modalSettings.tag === 'stake' && getUserAccountBalances(modalValues.sender, modalValues.token || 'SAVVA').then(res => changeHandler(res[modalValues.token || 'SAVVA'] || 0, 'balance'));
    (modalSettings.tag === 'withdraw' || modalSettings.tag === 'delegateuse') && getUserBlockchainAccount(modalValues.sender).then(
      res => changeHandler((+res.stake_info.staked.split(' ')[0] - +res.stake_info.provided.split(' ')[0]).toFixed(4) || 0, 'balance'),
    );
  }, [changeHandler, modalValues.sender, modalValues.balance, modalValues.token, modalSettings.tag]);

  useEffect(() => {
    checkFields(modalValues, reqFields.stake);
  }, [modalValues]);

  const crateOptions = (data:IProvidedList[]) => {
    const opt = [];
    for (let i = 0; i < data.length; i++) {
      opt.push({ value: [data[i].amount / 10000, data[i].recipient_name], label: `${data[i].recipient_name} (${(data[i].amount / 10000).toFixed(4)} CYBER)` });
    }
    return opt;
  };

  useEffect(() => {
    if (modalSettings.tag === 'recalluse') {
      getStakedAccounts(modalValues.sender).then(res => setStakedAccounts(crateOptions(res || [])));
    }
    if (modalSettings.tag === 'claim') {
      getFrozen(modalValues.sender).then(res => setStakedAccounts(crateOptions(res || [])));
    }
  }, [modalSettings.tag, modalValues.sender]);

  return (
    <div>
      <ModalNote text={headerNote.find(x => x.tag === modalSettings.tag)?.text || null} />
      {modalSettings.tag === 'delegateuse'
        && (
          <>
            <ModalLine>
              <SearchForm searchResult={(val: object) => sr(val)} />
            </ModalLine>
            <ModalLine noteLeft="ID">
              <span className="note" style={{ marginRight: 'auto' }}>
                {modalValues.recipient || 'Неизвестный пользователь'}
              </span>
            </ModalLine>
          </>
        )}
      {(modalSettings.tag === 'recalluse' || modalSettings.tag === 'claim')
        && (
          <ModalLine>
            <div className="w100">
              <CustomSelect
                optionsList={stakedAccounts}
                onChange={(o:any) => setModalValues({ ...getModalValues, balance: ((o?.value[0] || 0).toFixed(4)), recipient: o?.value[1] })}
              />
            </div>
          </ModalLine>
        )}
      <ModalLine noteLeft="Количество">
        <div style={correctiveStyles}>
          <Input
            type="number"
            onChange={(val: any) => changeHandler(modalValues.val && modalValues.val < 0 ? val.slice(1) : val > +modalValues.balance ? +modalValues.balance : val, 'amount')}
            pValue={modalValues.amount > +modalValues.balance ? +modalValues.balance : +modalValues.amount}
            noteRight={modalValues.token || 'SAVVA'}
          />
        </div>
      </ModalLine>

      <ModalLine noteLeft="Баланс">
        <div style={correctiveStyles} onClick={() => changeHandler(modalValues.balance, 'amount')}>
          <span style={balanceStyle}>
            {`${(+modalValues.amount && modalValues.balance) ? (+modalValues.balance - +modalValues.amount).toFixed(4) : (modalValues.balance || 0.0000)} ${modalValues.token || 'SAVVA'}`}
          </span>
        </div>
      </ModalLine>
      <ModalNote text={footerNote.find(x => x.tag === modalSettings.tag)?.text || null} />
      <div className="modal_body_line">
        <span className="label" />
        <InputPassword
          placeholder="Приватный ключ"
        />
      </div>
    </div>
  );
});

export default Stake;
