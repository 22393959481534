import {
  ReactElement, useEffect, useRef, useState,
} from 'react';

import { observer } from 'mobx-react';
import MainStore, { ModalStore, ThemeStore } from 'store';
import UserCard from 'UI/UserCard';
import { Link } from 'react-router-dom';
import { useMount, useUnMount } from 'hooks';
import {
  getUTCTimeToShow,
  changePostContent,
  storeActivePost,
  getPic,
} from 'helpers';
import NextPrevPosts from 'pages/Post/NextPrevPosts';
import PostTags from 'pages/Post/PostTags';
import PostBottomBar from 'pages/Post/PostBottomBar';
import PostContent from 'pages/Post/PostContent';
import AddSimpleComment from 'pages/Post/AddSimpleComment';
import PostCommentsList from 'pages/Post/PostCommentsList';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
// eslint-disable-next-line import/order
import PostEditor from 'pages/PostEditor';
import SliderButton from 'UI/SliderButton';
import Preloader from 'UI/Preloader';
import { announceModal, banArticle, unBanArticle } from 'settings/modal';
import LocalStorage from 'services/LocalStorage';
import { EditorTypes } from 'components/Editor/data';
import useNavigaror from 'hooks/useNavigaror';

import './style.scss';

const SimplePost = (): ReactElement => {
  const {
    activePost,
    isMobileContent,
    postEditMode,
    setPostEditMode,
    userData,
    isBlocked,
    isAdmin,
    dropNavigator,
    dropActivePost,
  } = MainStore;

  const { modalControl } = ModalStore;
  const { activeTheme } = ThemeStore;

  const [content, setContent] = useState<string>();
  const [addCommentEnabled, setAddCommentEnabled] = useState<boolean>(false);
  const [editorType, setEditorType] = useState<EditorTypes>(EditorTypes.visual);

  const postContentRef = useRef<HTMLDivElement | null>(null);

  const { fetch, isLoading } = useNavigaror();

  const {
    meta, id, authorLogin, authorAlias, authorAvatar, isInternal, blocked, is_blocked_author, cursor, dataIndex,
  } = activePost || {};
  const { time } = meta || {};

  const updateContent = (newContent: string) => {
    const cnt = newContent.replace(new RegExp('\\r?\\n', 'g'), '<br />');
    cnt && setContent(cnt);
    changePostContent(postContentRef?.current);
  };

  const setBack = (e: any) => {
    const pl = { ...JSON.parse(LocalStorage.get('list')) };
    pl[window.name] = {
      link: e.currentTarget.location.pathname,
      guid: id,
      list: MainStore.postsProxy,
    };
    LocalStorage.set('list', JSON.stringify(pl));
    MainStore.setFocudes(id);
  };

  useEffect(() => {
    (async () => (await storeActivePost())?.content)();
    fetch({ cursorStr: cursor, index: dataIndex });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePost]);

  useEffect(() => {
    if (postEditMode || !activePost) return;
    (async () => setContent((await storeActivePost())?.content))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postEditMode]);

  const upd = async () => {
    await storeActivePost({ isNeedToChange: true });
  };

  useMount(async () => {
    window.addEventListener('popstate', e => setBack(e));
    const { content: newContent } = await storeActivePost({ isNeedToChange: true }) || {};
    updateContent(newContent || '');
  });

  useUnMount(() => {
    window.removeEventListener('popstate', e => setBack(e));
    dropActivePost();
  });

  const setBlocked = async (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    const modalS = {
      ...(!blocked.blocked_actual ? banArticle : unBanArticle),
    };
    modalControl({ ...modalS, backFunc: upd }, { guid: id, author_login: authorLogin, content_type: 'a' });
  };

  const onClickEdit = () => {
    const { className } = (postContentRef.current?.firstChild as HTMLElement).firstChild as HTMLElement;

    if (className.includes && className.includes(EditorTypes.markdown)) {
      setEditorType(EditorTypes.markdown);
    }

    setPostEditMode(true);
  };

  useUnMount(() => dropNavigator());

  if (!activePost || !content) {
    return <div />;
  }

  if (isLoading) {
    return (
      <div className="feedPost">
        <div
          style={{ alignItems: 'center' }}
          className={`feedPost_content shadow ${isMobileContent ? 'feedPost_content_mobile' : ''} feedPost_content_${activeTheme}`}
        >
          <Preloader />
        </div>
      </div>
    );
  }

  return (
    <div className="feedPost">
      <NextPrevPosts updateContent={updateContent} fetch={fetch} margin="-25px 0 10px" />
      {blocked.blocked_actual && (
      <div className={`postBlockedComment postBlockedComment--${activeTheme} shadow`}>
        <div className="postBlockedComment_pic"><Lock /></div>
        <div className="postBlockedComment_text">
          <span>Статья заблокированна</span>
          <span>{blocked.blocked_comment}</span>
        </div>
      </div>
      )}
      {is_blocked_author && (isAdmin || userData.cyber_name === authorLogin) && (
        <div className={`postBlockedComment postBlockedComment--${activeTheme} shadow`}>
          <div className="postBlockedComment_pic"><Lock /></div>
          <div className="postBlockedComment_text">
            <span>Автор заблокирован</span>
            <span>
              Подробная информация
              {' '}
              <Link to={`/${authorLogin}`}>
                в профиле
              </Link>
            </span>
          </div>
        </div>
      )}
      <div className={`feedPost_content shadow ${isMobileContent ? 'feedPost_content_mobile' : ''} feedPost_content_${activeTheme}`}>
        <span className="simpleBlock">
          {
            isAdmin ? (
              <SliderButton
                title={!blocked?.blocked_actual ? 'Заблокировать' : 'Разблокировать'}
                svg={<Lock />}
                color="secondary"
                onClick={(e:any) => setBlocked(e)}
              />
            ) : null
          }
        </span>
        <div className="feedPost_content_author">
          <UserCard
            img={getPic(authorAvatar || '')}
            title={authorAlias || authorLogin || ''}
            username={authorLogin || ''}
            description={getUTCTimeToShow(time)}
          />
          {isInternal && <Preloader />}
        </div>
        {postEditMode ? (
          <PostEditor containerClassName="post-editor" defaultEditorType={editorType} />
        ) : (
          <PostContent
            content={content}
            contentRef={postContentRef}
          />
        )}
        <div className={`tags-anons-container tags-anons-container--${activeTheme}`}>
          <PostTags author={authorLogin} />
          {(!blocked.blocked_actual && userData.cyber_name.length) ? (
            <div className="anons-btn" onClick={() => ModalStore.modalControl(announceModal, { guid: activePost.id, title: activePost.meta.title })}>Анонсировать</div>
          ) : null }
        </div>
        <PostBottomBar
          authorAvatar={authorAvatar || ''}
          author={authorAlias || authorLogin || ''}
          authorLogin={authorLogin || ''}
          time={time}
          paddingBottom={10}
          addCommentText="Добавить комментарий"
          addCommentEnabled={addCommentEnabled}
          setAddCommentEnabled={setAddCommentEnabled}
          showUserAndAmount
          blockedComment={isBlocked || blocked.blocked_actual || !userData.cyber_name.length || is_blocked_author}
          onClickEdit={onClickEdit}
          align="right"
        />
        <AddSimpleComment
          level={0}
          id={id}
          parentId={id}
          articleId={id}
          margin="10px 0 0"
          isPost
          addCommentEnabled={addCommentEnabled}
          setAddCommentEnabled={setAddCommentEnabled}
        />
      </div>
      <NextPrevPosts updateContent={updateContent} fetch={fetch} margin="25px 0 10px" />
      <PostCommentsList />
    </div>
  );
};

export default observer(SimplePost);
