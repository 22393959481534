import { useEffect, useState } from 'react';

import { getUserAccountBalances } from 'helpers';
import { useMount } from 'hooks';
import { observer } from 'mobx-react';
import MainStore, { ModalStore } from 'store';
import apiRequest from 'services/Api';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';
import ModalNote from 'UI/ModalNote';
import ModalLine from 'UI/Modal/ModalLine';

import AnnounceListLIne from './AnnounceListLine';

const correctiveStyles = {
  display: 'flex',
  width: '70%',
};

const balanceStyle = {
  color: '#337DB2',
  fontWeight: 700,
  cursor: 'pointer',
};

export interface IPageList {
  id: number;
  code: string;
  name: string;
  unit: string;
  price: number;
}

const Announce = observer(() => {
  const [pageList, setPageList] = useState<IPageList[]>([]);
  const { modalValues, changeHandler } = ModalStore;
  const { userData } = MainStore;
  // const { activeTheme } = ThemeStore;

  useEffect(() => {
    if (modalValues.balance) return;

    getUserAccountBalances(userData.cyber_name, 'SAVVA').then(res => changeHandler(res[modalValues.token || 'SAVVA'] || 0, 'balance'));
  }, [changeHandler, userData.cyber_name, modalValues.balance, modalValues.token]);

  useEffect(() => {
    checkFields(modalValues, reqFields.announce);
  }, [modalValues]);

  useMount(() => {
    apiRequest({
      link: 'get_announces_pages',
      method: 'get',
    })
      .then(res => setPageList(res.data.list))
      .catch();
  });

  return (
    <div>
      <ModalNote
        text="После размещения анонса, ссылка на Ваш пост добавится в самый верх блока и будет находиться
        в нем до тех пор, пока  другие анонсы не потеснят Вас.  Время нахождения  в блоке зависит от активности других пользователей."
      />
      <ModalLine noteLeft="Баланс">
        <div style={correctiveStyles}>
          <div style={balanceStyle}>{`${modalValues.amount ? (+modalValues.balance - +modalValues.amount).toFixed(4) : (modalValues.balance || 0.0000)} ${modalValues.token || 'SAVVA'}`}</div>
        </div>
      </ModalLine>

      <ModalLine>
        <div className="w100">
          {
            (pageList || []).map(el => (
              <AnnounceListLIne key={el.id} {...el} />
            ))
          }
        </div>
      </ModalLine>
      <ModalNote
        text="Не рекомендуем размещать в анонсах произведения, содержащие ненормативную лексику, NSFW-материалы,
        тексты оскорбительного содержания, объявления, не имеющие отношения к тематике Stihi.io"
      />
      <ModalNote
        text="Такие анонсы могут быть заблокированы на Портале без предупреждения и без  возврата затраченных SAVVA."
      />
      <div className="modal_body_line">
        <InputPassword
          placeholder="Приватный ключ"
        />
      </div>
    </div>
  );
});

export default Announce;
