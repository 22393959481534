import { useState, useEffect, useRef } from 'react';

import './style.scss';
import arrow from 'assets/images/arrow.svg';
import { ThemeStore } from 'store';

interface ISelectProps {
    selectedValue: string | number;
    options: any;
    onSelect: Function;
    valueKey: string;
}

const DropDownSelect = ({ selectedValue, options, onSelect, valueKey }: ISelectProps) => {
  const { activeTheme } = ThemeStore;
  const [open, setOpen] = useState(false);
  const refButton = useRef<any>(null);

  useEffect(() => {
    if (open) {
      document.body.addEventListener(
        'click',
        ({ target }) => (refButton.current && !refButton.current.contains(target)) && setOpen(false),
        { once: true },
      );
    }
  }, [open]);

  return (
    <div className="smallSelect" onClick={() => setOpen(!open)} ref={refButton}>
      <div className="smallSelect_selected">
        <span>{options.find((x:any) => x.id === selectedValue)?.[valueKey] || 'Выбрать'}</span>
        <div>
          <img src={arrow} alt="arrow" />
        </div>
      </div>
      <div className={`smallSelect_items ${open ? ' smallSelect_items--open' : ' smallSelect_items--close'} smallSelect_items_${activeTheme}`}>
        {options.filter((x:any) => x.value !== selectedValue).map((el: any) => (
          <div className="smallSelect_items_unit" key={options.id} onClick={() => onSelect(el.id)}>{el[valueKey]}</div>
        ))}
      </div>
    </div>
  );
};
export default DropDownSelect;
