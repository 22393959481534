import {
  getDataBody, resultMessage, ShowToast,
} from 'helpers';
import { sendTransaction } from 'services/Api';
import { contractAdress } from 'settings/main';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';

interface ContractActionData {
  acc: string;
  amount?: string;
}

const contractActionPipeline: Pipeline = {
  title: 'contractActionPipeline',
  tasks: [
    {
      title: 'Выполняем перевод токенов',
      task: async (meta: any) => {
        const { modalSettings: { tag } } = ModalStore;
        let data: ContractActionData = {
          acc: meta.sender,
        };
        if (meta.action !== 'cancelwd' || meta.action !== 'cancelwd') data = { ...data, amount: `${(+meta.amount).toFixed(4)} SAVVA` };

        const sendingData = await getDataBody(meta.sender, contractAdress, data, meta.action);

        const result = await sendTransaction(meta.key, sendingData);
        ShowToast(resultMessage(tag || 'default', meta.amount, meta.token), 'success');
        return result;
      },
    },
    {
      title: 'Обновляем балансы',
      task: async () => {
        const { userData: { cyber_name }, setBlockchainAccount, setUserBalances } = MainStore;
        setBlockchainAccount(cyber_name);
        setUserBalances(cyber_name);
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default contractActionPipeline;
