interface IStakeNote {
    tag: string;
    text: string;
}

export const headerNote: IStakeNote[] = [
  { tag: 'stake', text: 'Чем больше у Вас ресурсов, тем больше транзакций Вы сможете выполнить' },
  { tag: 'withdraw', text: 'Чем меньше у Вас ресурсов, тем меньше транзакций Вы сможете выполнить' },
  { tag: 'delegateuse', text: 'Вы можете увеличть stake другого аккаунта делегировав использование своих ресурсов' },
  { tag: 'recalluse', text: 'Выберите аккаунт для отмены делегирования ресурсов' },
];

export const footerNote: IStakeNote[] = [
  { tag: 'stake', text: 'Вы можете в любой момент вернуть (unstake) токены на баланс перемещаемых токенов  CYBER' },
  { tag: 'withdraw', text: 'Указанная сумма будет моментально вычтена из ресурсов и добавлена на баланс перемещаемых токенов CYBER' },
  { tag: 'delegateuse', text: 'Вы делегируете использование Ваших мощностей на указанную сумму' },
  { tag: 'recalluse', text: 'Ресурсы на указанную сумму будут снова доступны только Вам' },

];
