import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import apiRequest from 'services/Api';
import { IBlogPreviewProps } from 'types';
import BlogLine from 'pages/components/BlogLine';
import Preloader from 'UI/Preloader';
import NoPosts from 'pages/components/NoPosts';

import './style.scss';

interface IAnnouncementList {
  // TODO MAKE INTERFACE
  data: [];
  loaded: boolean;
}
// TODO: change to dynamic data in this component
const Announcement = () => {
  const [list, setList] = useState<IAnnouncementList>({ data: [], loaded: false });
  const { location: { pathname } } = useHistory();

  // const isComments = pathname.includes(FilterPosts.comments);
  useEffect(() => {
    const pathSplited = pathname.split('/');
    apiRequest({
      link: 'get_announces',
      method: 'get',
      params: {
        count: 5,
        code: (pathname === '/') ? 'popular' : pathSplited[pathSplited.length - 1],
      },
    })
      .then(res => {
        const data: any = (res?.data?.list || []).map((el: any) => ({
          ...el,
          authorLogin: el.user,
          nextRoundTime: el.nextRoundTime.Time || '',
          meta: JSON.parse(el.meta),
          id: el.guid,
        }));
        setList({
          data,
          loaded: true,
        });
      })
      .catch();
  }, [pathname]);

  return (
    <div className="announcementBox">
      {
        !list.loaded
          ? <div className="announcementBox_preloaderBox"><Preloader /></div>
          : list.data.length ? list.data.map((el: IBlogPreviewProps) => <BlogLine key={el.id} {...el} line showNotes={false} />) : <NoPosts text="Еще ничего не анонсировали" />
      }
    </div>
  );
};

export default Announcement;
