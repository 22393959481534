import { Fragment } from 'react';

import { ThemeStore } from 'store';
import { observer } from 'mobx-react';

import { internalLinks, socialIconsData } from './data';

import './style.scss';

interface ISocialIconsProps {
  showText?: boolean;
  showInternalLinks?: boolean;
}

const SocialIcons = ({ showText = true, showInternalLinks = true }: ISocialIconsProps) => {
  const { activeTheme } = ThemeStore;

  return (
    <div className={`social-icons-container social-icons-container--${activeTheme}`}>
      {showInternalLinks && (
        <div className="internal-links">
          {internalLinks.map(item => (
            item.link ? (
              <Fragment key={item.id}>
                <a key={item.id} href={item.link}>{item.title}</a>
                {!item.withoutDote && <p>•</p>}
              </Fragment>
            ) : item.title
          ))}
        </div>
      )}
      {showText && <p className="social-icons-title">Мы в соцсетях</p>}
      <div className="icons-container">
        {socialIconsData.map(item => (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <a key={item.id} href={item.link}><item.Icon /></a>
        ))}
      </div>
    </div>
  );
};

export default observer(SocialIcons);
