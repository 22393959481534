import { ReactElement, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Amount from 'components/Amount';
import { ThemeStore } from 'store';
import { observer } from 'mobx-react';
import apiRequest from 'services/Api';
import { getUTCTimeToShow } from 'helpers';
import { getPatronCFG } from 'settings/api';

import './style.scss';
/* interface IPatronList{
  Donaited: 39.33
  DonaitedCurrent: 37.3635
  RewardedAuthor: 1.9665
  RewardedWinner: 0
  author: "pudvypgvesnk"
  guid: "338f64d9-ec94-4bb9-84b2-db5d92758f9b"
  next_round_time: ""
  title: "И снова тестируем спонсирование"
  user_donated: 34.33
  user_donated_current: 34.33
} */

const Patron = ({ id }: any): ReactElement => {
  const { activeTheme } = ThemeStore;
  const [list, setList] = useState([]);

  useEffect(() => {
    apiRequest(getPatronCFG({ user: id })).then(res => {
      setList(res.data.result);
    }).catch();
  }, [id]);

  return (
    <div className="walletPatron">
      {/* <div className="walletPatron_titleInfo">
      <span>
        <h1>Данные о ваших вложениях в Фонды постов на текущий момент.</h1>
      </span>
      <span>
        Показывать:
        {' '}
        <strong>по умолчанию</strong>
      </span>
    </div> */}

      <div className={`walletPatron_table ${activeTheme}`}>
        <div className="walletPatron_table--header">
          <span>Пост</span>
          <span>
            Размер
            <br />
            Фонд Поста
          </span>
          <span>
            Доля
            <br />
            в Фонде Поста
          </span>
          <span>
            Дата
            <br />
            След. Выплат
          </span>
        </div>
        {list.map((el: any) => (
          <div className="walletPatron_table_line" key={el.date}>
            <div className="walletPatron_table_line_title">
              <Link to={`/post/${el.author}/${el.guid}`} className={`walletPatron_linkColors walletPatron_linkColors_${activeTheme}`}>
                <span>{el.title}</span>
              </Link>
              <span>{`Публикация ${getUTCTimeToShow(el.time)}`}</span>
            </div>
            <div className="walletPatron_table_line_amount">
              <Amount amount={el.donated_current} justifyContent="flex-end" />
            </div>
            <div className="walletPatron_table_line_amount">
              {`${Math.round(el.user_donated_current)} / ${Math.round(el.donated_round)}`}
            </div>
            <div className="walletPatron_table_line_date">
              {el.next_round_time.length ? getUTCTimeToShow(el.next_round_time) : ''}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(Patron);
