import { ReactChild } from 'react';

import { observer } from 'mobx-react';
import MainStore from 'store';
import { MOBILE_POINT_MENU } from 'settings/main';

interface IModalLineProps {
  children: ReactChild[] | ReactChild;
  noteLeft?: string;
  correctiveStyle?: string;
}

const ModalLine = ({ children, noteLeft, correctiveStyle }: IModalLineProps) => {
  const { screenWidth } = MainStore;
  const isMobile = screenWidth <= MOBILE_POINT_MENU;
  return (
    <div className={`modal_body_line ${isMobile ? 'modal_body_line--mobile' : ''} ${correctiveStyle}`}>
      {noteLeft && <span className={`modal_body_line_segment ${isMobile ? 'modal_body_line_segment--mobile' : ''}`}>{noteLeft}</span>}
      {children}
    </div>
  );
};
export default observer(ModalLine);
