import {
  ReactElement,
  useRef,
  useEffect,
  MutableRefObject,
  useState,
} from 'react';

import MainStore, { ThemeStore } from 'store';
import InfoBlock from 'UI/InfoBlock';
import AboutSelf from 'pages/Profile/AboutSelf';
import { IProfileData } from 'types';
import {
  checkIsBlog,
  firtsUppercase,
  updatePostsByFilter,
} from 'helpers';
import { useHistory } from 'react-router';
import SocialIcons from 'UI/SocialIcons';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import './style.scss';

interface IAboutSelfProps {
    profileData: IProfileData;
    id: string;
    refContent: MutableRefObject<HTMLDivElement | null>;
    refHeader: MutableRefObject<HTMLDivElement | null>;
}

const ProfileSidebar = ({ profileData, id, refContent, refHeader }: IAboutSelfProps): ReactElement => {
  const { activeTheme } = ThemeStore;
  const { screenWidth } = MainStore;
  const [tagsLength, setTagsLength] = useState<number>(10);
  const history = useHistory();
  const ref = useRef<any>(null);
  // const [listRubLength, setListRubLength] = useState<number>(10);

  const setFilterReq = async (name: string, type: string) => {
    if (checkIsBlog()) {
      await updatePostsByFilter({ id, [type]: name });
      MainStore.setBlogFilter({ name, type });
    } else {
      MainStore.setBlogFilter({ name, type }).then(() => history.push(`/${profileData?.cyber_name}/`));
    }
    refHeader.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollControl = () => {
    if (!ref.current) return;

    const scroll = Math.round(window.scrollY);
    const { top } = ref.current.getBoundingClientRect();
    const delta = ref.current?.clientHeight - window.innerHeight;

    if (scroll > 330) {
      if (delta < 0) {
        ref.current.style.setProperty('position', 'fixed');
      } else if (((Math.round(top) + 100) * -1) > delta) {
        ref.current.style.setProperty('position', 'fixed');
        ref.current.style.setProperty('top', `${Math.round(top)}px`);
      }
    } else {
      ref.current.style.removeProperty('position');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollControl);
    return () => {
      window.removeEventListener('scroll', scrollControl);
    };
  }, []);

  useEffect(() => {
    if (!refContent.current || !ref.current) return;
    refContent.current.style.setProperty('min-height', `${ref.current?.clientHeight || 0}px`);
  });

  const getMore = () => {
    setTagsLength(tagsLength + 10);

    // refContent.current.style.setProperty('min-height', `${ref.current?.clientHeight || 0}px`);
  };

  useEffect(() => {
    if (!refContent.current || !ref.current) return;

    ref.current.style.removeProperty('position');
    // refContent.current.style.setProperty('min-height', `${ref.current?.clientHeight || 0}px`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsLength]);

  return (
    <div className="profileSidebar" style={{ display: screenWidth < 1200 ? 'none' : '' }}>
      <div className="profileSidebar_scrollable" ref={ref}>
        {profileData?.blocked.blocked_actual
          && (
            <div className={`profileBlockedComment profileBlockedComment--${activeTheme}`}>
              <span>Причина блокировки</span>
              {profileData?.blocked.blocked_comment}
            </div>
          )}
        <InfoBlock title="О Себе">
          <AboutSelf {...profileData} />
        </InfoBlock>
        <InfoBlock title="Рубрики блога">
          <div className="profileContent__rubrics">
            <span onClick={() => setFilterReq('', '')} className={!Object.keys(toJS(MainStore.blogFilter || {}))?.length ? 'strong' : ''}>— Все —</span>
            {(profileData?.rubrics || []).map((el: any) => (
              <span key={`${el.rubric.name}_${el.count}`} onClick={() => setFilterReq(el.rubric.code, 'rubric')}>
                <span className={el.rubric.code === MainStore.blogFilter?.name ? 'strong' : ''}>{el.rubric.name}</span>
                <span className="profileContent__rubrics--count">{el.count}</span>
              </span>
            ))}
          </div>
        </InfoBlock>
        <InfoBlock title="Сборники автора">
          <div className="profileContent__rubrics">
            {(profileData?.tags || []).slice(0, tagsLength).map((el: any) => (
              <span key={`${el.tag}_${el.count}`} onClick={() => setFilterReq(el.tag, 'tag')}>
                <span className={el.tag === MainStore.blogFilter?.name ? 'strong' : ''}>{firtsUppercase(el.tag.trim())}</span>
                <span className="profileContent__rubrics--count">{el.count}</span>
              </span>
            ))}
            {profileData?.tags.length > tagsLength && <span className="note" onClick={() => getMore()}>Больше рубрик и тегов...</span>}
          </div>
        </InfoBlock>
        <SocialIcons />
      </div>
    </div>
  );
};

export default observer(ProfileSidebar);
