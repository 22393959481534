import { useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import Content from 'components/Content';
import EventsContainer from 'components/Events';
import { ToastContainer } from 'react-toastify';
import { useMount, useUnMount } from 'hooks';
import Menu from 'UI/MainMenu';
import Modal from 'UI/Modal';
import MainStore, * as store from 'store';
import CurtainWrapper from 'curtain';
import Crashed from 'components/Crashed';
import LocalStorage from 'services/LocalStorage';
import { ReactComponent as ArrowUpIcon } from 'assets/images/arrow_up.svg';

import './style.scss';

const App = (): JSX.Element => {
  const { open } = store.ModalStore;
  const {
    isAuth,
    screenWidth,
    setScreenWidth,
    userData,
    logout,
    setUser,
    isCrached,
  } = MainStore;
  const { activeTheme } = store.ThemeStore;
  const { disable } = store.CurtainStore;
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const wrapperRef = useRef<undefined | any>();

  const version = '1.0.40';
  const currVersion = LocalStorage.get('v');
  if (currVersion !== version) {
    LocalStorage.set('v', version);
    window.location.replace(window.location.href);
  }

  const mouseOnHandler = () => {
    setIsVisible(true);
  };

  const mouseLeaveHandler = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const resize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [setScreenWidth]);

  useEffect(() => {
    if (!wrapperRef?.current) return;
    wrapperRef.current.style.width = `${screenWidth - 10}px`;
  }, [screenWidth]);

  const storageChange = (e: any) => {
    const storageData = e;
    if (!storageData.storageArea.length) logout();
    if (!storageData || storageData.key !== 'u') return;
    if (storageData.key === 'u') {
      storageData.newValue?.cyber_name !== userData?.cyber_name && setUser(JSON.parse(storageData.newValue));
    }
  };

  useMount(() => {
    window.name = new Date().getTime().toString();
    window.addEventListener('storage', storageChange);
  });

  useUnMount(() => {
    window.removeEventListener('storage', storageChange);
  });

  if (isCrached) return <Crashed />;

  return disable ? (
    <div className={`wrapper ${activeTheme}`} ref={wrapperRef} onMouseLeave={mouseLeaveHandler} onMouseEnter={mouseOnHandler}>
      <div className={`wrapper_backImg wrapper_backImg--${activeTheme}`} />
      <ToastContainer style={{ width: '406px' }} />
      <EventsContainer isAuth={isAuth} isVisible={isVisible} />
      {open && <Modal />}
      <Menu />
      <Content />
      {/* TODO: this will be rendered conditionally */}
      <div className="footer" />
      <div className={`arrow_up_container arrow_up_container--${activeTheme}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <ArrowUpIcon />
      </div>
    </div>
  ) : <CurtainWrapper />;
};

export default observer(App);
