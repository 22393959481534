import { useEffect, useState, useLayoutEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import MainStore, { ThemeStore } from 'store';
import BlogLine from 'pages/components/BlogLine';
import {
  storeNewPosts,
  storeRubrics,
  checkIsBlog,
  updatePostsByFilter,
  getPostsPage,
  getBlockedArtsData,
} from 'helpers';
import Preloader from 'UI/Preloader';
import ActionMenu from 'UI/ActionMenu';
import { useMount, useUnMount } from 'hooks';
import LocalStorage from 'services/LocalStorage';
import { FilterPosts } from 'types';

import NoPost from './NoPosts';

interface IBlogListProps {
  id?: string;
  isBlockedList?: boolean;
}

const timeFilterData: { id: number; name: string; type: string }[] = [
  { id: 1, name: 'За неделю', type: '7d' },
  { id: 2, name: 'За месяц', type: '1m' },
  { id: 3, name: 'За год', type: '1y' },
  { id: 4, name: 'За всё время', type: '' },
];

const BlogList = ({ id, isBlockedList = false }: IBlogListProps) => {
  const history = useHistory();

  const {
    posts,
    rubrics,
    activeRubric,
    dropPostList,
    blogFilter,
    focusedGuid,
    dropFocused,
    isAdmin,
  } = MainStore;
  const { activeTheme } = ThemeStore;

  const [hasMore, setHasMore] = useState(true);
  const [fgid, setFgid] = useState('xxx');
  const [activeTimeFilter, setActiveTimeFilter] = useState(timeFilterData[0]);

  const isPopularPage = history.location?.pathname.split('/')[2] === FilterPosts.popular || history.location?.pathname === '/';

  useMount(async () => {
    isAdmin && getBlockedArtsData();

    const oldList = await { ...JSON.parse(LocalStorage.get('list')) };
    const currentData = oldList[window.name];
    if (currentData && currentData.link === history.location.pathname && currentData.link.includes('posts/')) {
      MainStore.setOP({
        list: currentData.list.list,
        cursor: currentData.list.cursor,
      });
      delete oldList[window.name];
      LocalStorage.set('list', JSON.stringify(oldList));
      return;
    }
    delete oldList[window.name];
    await LocalStorage.set('list', JSON.stringify(oldList));

    !rubrics.length && await storeRubrics();

    switch (blogFilter?.type) {
      case 'rubric':
      case 'tag':
        await updatePostsByFilter({
          id,
          ...(blogFilter.type === 'tag' && { tag: blogFilter.name }),
          ...(blogFilter.type === 'rubric' && { rubric: blogFilter.name }),
          time: activeTimeFilter.type,
        });
        break;
      default:
        const page = getPostsPage();
        const storedFilters = LocalStorage.get(page);
        const timeFilterStored = timeFilterData.find(item => item.type === storedFilters?.time);

        timeFilterStored && setActiveTimeFilter(timeFilterStored);

        await updatePostsByFilter({
          id,
          time: storedFilters?.time || activeTimeFilter.type,
          rubric: storedFilters?.rubric,
        });
        break;
    }
  });

  useLayoutEffect(() => {
    if (posts.list?.length > 0 && focusedGuid?.length > 0) return;
    setTimeout(() => {
      setFgid(focusedGuid);
      dropFocused();
    }, 500);
  }, [posts, focusedGuid, dropFocused]);

  const onNextScroll = async () => {
    const newPosts = await storeNewPosts();
    setHasMore(!!newPosts.list?.length);
  };

  useUnMount(() => {
    const { location: { pathname } } = history;
    MainStore.blogFilter = null;
    if (pathname.includes('post/')) return;
    dropPostList(pathname.includes('posts/'));
  });

  useEffect(() => {
    if (history.location.pathname.includes('post/')) return;
    dropPostList();
  }, [history.location.pathname, dropPostList]);

  return (
    <>
      {!checkIsBlog() && (
        <div className="rubrics-filter-container">
          {isPopularPage && (
            <ActionMenu
              items={timeFilterData}
              onClickItem={async filter => {
                setActiveTimeFilter(filter);
                await updatePostsByFilter({ id, time: filter.type, rubric: activeRubric?.code });
                setHasMore(true);
              }}
              selectedItem={activeTimeFilter}
              containerClassName="filter-container"
              showSelected
            />
          )}
          <div className={`rubrics-line rubrics-line--${activeTheme}`} />
          <ActionMenu
            defaultSelected="Все рубрики"
            items={rubrics}
            onClickItem={async rubric => {
              await updatePostsByFilter({
                id,
                rubric: rubric.code,
                time: activeTimeFilter.type,
              });
              setHasMore(true);
            }}
            onReset={async () => {
              await updatePostsByFilter({ time: activeTimeFilter.type });
              setHasMore(true);
            }}
            showSelected
            selectedItem={activeRubric}
          />
        </div>
      )}
      {posts.list.length ? (
        <InfiniteScroll
          style={{ minHeight: 'calc(100vh - 270px)', marginTop: checkIsBlog() ? '-11px' : '' }}
          dataLength={posts.list.length}
          next={onNextScroll}
          hasMore={hasMore}
          loader={<div className={`noPosts noPosts--${activeTheme}`}><Preloader /></div>}
        >
          {posts.list.map((el: any) => <BlogLine {...el} key={el.id} showBlock focusedG={fgid} blockedPage={isBlockedList} />)}
        </InfiniteScroll>
      ) : <NoPost text="Публикаций пока нет" />}
    </>
  );
};

export default observer(BlogList);
