import { observer } from 'mobx-react';
import MainStore, { ModalStore } from 'store';
import Button from 'UI/Button';
import FundBox from 'components/FundBox';
import { contributeModal } from 'settings/modal';

// BigInt(postFundsObject?.roundtime).toString(16)

const EmptyFund = () => {
  const { activePost, isAuth } = MainStore;
  const { modalControl } = ModalStore;

  return (
    <FundBox secondary>
      <div className="vertical mt10">
        <div className="feedPost_fundNote">
          <p><strong>ГЛАВНАЯ ЗАДАЧА ПЛАТФОРМЫ STIHI.IO - ЭТО ПОДДЕРЖКА АВТОРОВ</strong></p>
          <p>
            Переведите токены SAVVA в Фонд этого поста. Это поддер- жит
            автора и поднимет пост в лентах.
          </p>
          <p>
            Станьте первым меценатом поста и получите доступ к участию в регулярных тиражах.
          </p>
        </div>
        <div className="fundBox_buttonBox">
          {isAuth
            && (
              <Button
                action={() => modalControl(contributeModal, { uuid: activePost.id, author: activePost.authorLogin })}
                title="Спонсировать"
                styleName="orange"
                className="w100"
              />
            )}
        </div>
      </div>
    </FundBox>
  );
};
export default observer(EmptyFund);
