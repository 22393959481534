import { observable, decorate, action } from 'mobx';
import IndexedDB from 'services/IndexedDB';
import { ModalStore } from 'store';

class Curtain {
    disable: boolean = window.location.origin === 'https://stihi.io';

    passw: string = 'oMnTk%VW4ERo';

    comeOn = (passw?: any): void => {
      if (passw.key === this.passw) {
        this.disable = !this.disable;
        const toBaseUD = { id: 'curtain', get: true };
        IndexedDB.addData(toBaseUD);
        ModalStore.modalControl();
      }
    }

    setDisableCurtain = (newDisable: boolean) => {
      this.disable = newDisable;
    }
}

decorate(Curtain, {
  disable: observable,
  comeOn: action,
  setDisableCurtain: action,
});

const CurtainStore = new Curtain();

export default CurtainStore;
