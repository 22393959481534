import {
  useCallback, useEffect, useState,
} from 'react';

import { UPDATE_TIME } from 'settings/api';

import useNodeQuery from './useNodeRequest';

interface IContext {
    method: string;
    body: any;
    node?: string;
  }

export default function useNodeTimerRequest() {
  const [init, setInit] = useState<boolean>(false);
  const [returnedData, setReturnedData] = useState<any>(null);
  const [counter, setCounter] = useState<number>(UPDATE_TIME);
  const [ctx, setCtx] = useState<IContext>({} as IContext);
  const { fetch, data, isSuccess: isSuccessFetch } = useNodeQuery();

  useEffect(() => {
    if (counter === 0) {
      setCounter(UPDATE_TIME);
      fetch(ctx);
      return;
    }
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter, fetch, ctx]);

  useEffect(() => {
    if (isSuccessFetch) {
      setReturnedData(data);
    }
  }, [data, isSuccessFetch]);

  const initTimer = useCallback(
    (
      context: IContext,
    ) => {
      setInit(true);
      setCtx(context);
    }, [],
  );

  return {
    returnedData, init, initTimer,
  };
}
