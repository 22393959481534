interface IWalletsMenu {
  name: string;
  link: string;
}

// TODO: should be changed to dynamic
const walletsMenu: IWalletsMenu[] = [
  { name: 'Балансы', link: 'balances' },
  { name: 'CyberWay', link: 'cyber-balance' },
  { name: 'Блокнот Мецената', link: 'patron' },
  { name: 'Награды', link: 'awards' },
  { name: 'История транзакций', link: 'history' },
];

export default walletsMenu;
