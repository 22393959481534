import { IComment, IComments } from 'types';
import MainStore, { CommentsStore, ThemeStore } from 'store';
import apiRequest from 'services/Api';
import {
  getCommentsByParrentList,
  getCommentsByPost,
  getNextComment,
  initialGetCommentsList,
} from 'settings/api';
import { Link } from 'react-router-dom';
import CommentCard from 'pages/components/Comments/CommentCard';

export function getCommentsList(comments: IComments, isShowTitle: boolean = true) {
  const { activeTheme } = ThemeStore;
  const newComments: JSX.Element[] = [];

  const tempArr: JSX.Element[] = [];

  const getCommentChildren = (comment: IComment) => {
    comment.children?.forEach(com => {
      tempArr.push(<CommentCard
        key={com.id}
        comment={com}
        level={com.level}
        isPost
      />);

      if (com.children?.length) {
        return getCommentChildren(com);
      }
    });

    return [...tempArr];
  };

  const listIds: string[] = [];

  comments?.list.forEach(comment => {
    const commentChildren = getCommentChildren(comment);
    tempArr.length = 0;
    if (listIds.includes(comment.id)) return;
    listIds.push(comment.id);
    newComments.push(
      <div
        className={`comments-block comments-block--${activeTheme}`}
        key={comment.id}
      >
        {isShowTitle && (
          <div className={`comments-card-title comments-card-title--${activeTheme}`}>
            <span>
              Комментарий к
              <Link to={`/post/${comment.articleAuthorLogin.String}/${comment.articleGUID}`}>{`"${comment.articleTitle}"`}</Link>
              <Link to={`/${comment.articleAuthorLogin.String}`}>{`(${comment.articleAuthorAlias.String || comment.articleAuthorLogin.String})`}</Link>
            </span>
          </div>
        )}
        <CommentCard comment={comment} level={0} isPost />
        {commentChildren}
      </div>,
    );
  });

  return newComments;
}

export function updateComments(newComments: any) {
  const { addComments } = CommentsStore;

  addComments({
    cursor: newComments.cursor,
    list: newComments.list,
  });

  return newComments;
}

export async function storeNewComment() {
  const { commentList } = CommentsStore;

  return updateComments((await apiRequest(getNextComment(commentList.cursor))).data?.list || []);
}

export async function storePostCommentsById() {
  const { activePost } = MainStore;
  const { setCommentList } = CommentsStore;
  const config = activePost ? getCommentsByPost(activePost.id || '') : initialGetCommentsList;

  setCommentList((await apiRequest(config)).data?.list || []);
}

export async function getSubComments(guid: string) {
  const { setGetMore } = CommentsStore;
  const config = getCommentsByPost(guid || '');

  setGetMore(guid, (await apiRequest(config)).data?.list || []);
}

export function createCommentsStructure(comments: IComment[]) {
  const newComments: IComment[] = [];
  if (!comments?.length) return [];

  comments.forEach(comment => {
    const existingCom = comments.find(item => item.id === comment.parentGUID);
    if (existingCom) {
      existingCom.children?.length
        ? existingCom.children = [comment, ...existingCom.children]
        : existingCom.children = [comment];
    } else {
      newComments.push(comment);
    }
  });

  return newComments;
}

export const getCommentsByParrent = async (guid: string) => {
  const { setGetMore, loadingHandler } = CommentsStore;
  loadingHandler(guid);
  setGetMore(guid, (await apiRequest(getCommentsByParrentList(guid))).data?.list || []);
};

export const getCommentsByCursor = async (cursor: string) => apiRequest(getCommentsByParrentList(cursor));
