import React, { useState, useRef, useEffect } from 'react';

import { ThemeStore } from 'store';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import { IRubric } from 'types';
import './style.scss';

interface IActionMenuItems extends IRubric{
  name: string;
  link?: string;
  function?: () => void;
}

interface IActionMenuProps<T> {
    items: T[];
    selectedItem?: T;
    hide?: boolean;
    onClickItem?: (item: T) => void;
    onReset?: () => void;
    showSelected?: boolean;
    defaultSelected?: string;
    selectedClassName?: string;
    containerClassName?: string;
}

const ActionMenu = <T extends IActionMenuItems>({
  items, hide, onClickItem, showSelected, defaultSelected, selectedClassName, containerClassName, onReset, selectedItem,
}: IActionMenuProps<T>) => {
  const { activeTheme } = ThemeStore;

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(selectedItem || (defaultSelected ? { name: defaultSelected } : items[0]));

  const refButton = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelected(selectedItem || { name: defaultSelected } as IActionMenuItems);
  }, [selectedItem, defaultSelected]);

  useEffect(() => {
    if (!open) return;

    document.body.addEventListener(
      'click',
      ({ target }) => (refButton.current && !refButton.current.contains(target as Node)) && setOpen(false),
      { once: true },
    );
  }, [open]);

  const onSelectItem = (item: T) => {
    setSelected(item);
    return (item.function && item.function()) || (onClickItem && onClickItem(item));
  };

  return (
    <div className={`actionMenu ${containerClassName || ''}`}>
      <div
        className={`actionMenu__button${open ? ' actionMenu__button--rotate' : ' '} ${selectedClassName || ''} actionMenu__button_${activeTheme}`}
        onClick={() => !hide && setOpen(!open)}
        ref={refButton}
      >
        {showSelected && selected?.name}
        {!hide ? <Arrow /> : <div className="walletBalances_line_title_amount_pseudoMenu" />}
      </div>
      <div className={`actionMenu__modal ${open ? ' actionMenu__modal--open' : ' actionMenu__modal--close'}`}>
        <div className={`actionMenu__menu actionMenu__menu_${activeTheme}`}>
          {showSelected && defaultSelected && selected.name !== defaultSelected && (
            <div
              onClick={() => {
                setSelected({ name: defaultSelected } as T);
                onReset && onReset();
              }}
            >
              {defaultSelected}
            </div>
          )}
          {items.length && items.map((el: T) => (
            el.link ? <a href={el.link} key={el.link} target="_blank" rel="noreferrer">{el.name}</a>
              : (
                <div
                  key={`${el.name}${Math.random()}`}
                  onClick={() => onSelectItem(el)}
                >
                  {el.name}
                </div>
              )
          ))}
        </div>
      </div>
    </div>
  );
};
export default ActionMenu;
