import { isBoolean } from 'lodash';
import { ModalStore } from 'store';

// TODO: make enum for all cases
const validator = (name: string, value: any): boolean => {
  let pattern; let
    result: boolean = false;
  switch (name) {
    case 'email':
      // eslint-disable-next-line
      pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      result = pattern.test(String(value).toLowerCase());
      break;
    case 'key':
      result = value.length > 20 && ModalStore.trueKey;
      break;
    case 'golos_login':
    case 'author_login':
    case 'captcha':
    case 'login':
    case 'sender':
    case 'recipient':
    case 'cyber_name':
      pattern = /[a-z0-9]+$/i;
      result = pattern.test(String(value)) && value;
      break;
    case 'first_sale':
    case 'isExisting':
    case 'content':
      result = isBoolean(value);
      break;
    case 'restore':
    case 'saveKeys':
    case 'terms':
      result = !!value;
      break;
    case 'keyCreated':
    case 'keyIsDownLoad':
    case 'enableChechbox':
    case 'guid':
    case 'uuid':
    case 'hash':
    case 'code':
    case 'type':
    case 'title':
    case 'action':
    case 'author':
    case 'keyType':
    case 'price':
    case 'comment':
    case 'content_type':
    case 'min_amount':
    case 'max_amount':
    case 'acc':
      result = value;
      break;
    case 'keys':
    case 'body':
      result = typeof value === 'object';
      break;
    case 'captcha_id':
    case 'memo':
    case 'user_alias':
    case 'token':
    case 'updateCommentsList':
      result = true;
      break;
    case 'amount':
    case 'balance':
      result = +value > 0 || +(value.split(' ')[0]) > 0;
      break;
    default:

      break;
  }

  return result;
};

const checkFields = (obj: any, reqF?: any) => {
  let fraudPoint: number = 0;
  if (reqF) {
    const res = reqF.map((item: any) => obj[item]);
    if (res.includes(undefined)) return false;
  }

  if (!Object.keys(obj).length) return false;

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && !validator(key, obj[key])) fraudPoint += 1;
  }
  ModalStore.approveValues(fraudPoint === 0);
};

export default checkFields;
