import { observer } from 'mobx-react';
import { ThemeStore } from 'store';

interface IModalNoteProps {
    text: string | null;
    classname?: string;
    style?: any;
    underline?: boolean;
}

const ModalNote = ({ text, classname, style, underline }: IModalNoteProps) => {
  const { activeTheme } = ThemeStore;
  return (
    <div className="modal_body_line" style={style}>
      <span className={`note note_${activeTheme} ${classname || ''} ${underline ? `note_underline note_underline--${activeTheme}` : null}`}>{text || null}</span>
    </div>
  );
};
export default observer(ModalNote);
