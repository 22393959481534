import { ReactComponent as NFTIcon } from 'assets/images/NFT5.svg';
import { ReactComponent as NFTIconWhite } from 'assets/images/nftlines.svg';
import { observer } from 'mobx-react';
import { ThemeStore } from 'store';
import Preloader from 'UI/Preloader';

interface INopostProps{
  text?: string;
  loaded: boolean;
}

const NoNFT = ({ text = 'Пока ничего не куплено.', loaded } : INopostProps) => {
  const { activeTheme } = ThemeStore;
  const isDark = activeTheme === 'dark';
  return (
    <div className={`noPosts noPosts--${activeTheme}`}>
      {
        loaded ? (
          <>
            {
              isDark
                ? <NFTIconWhite style={{ filter: 'invert(0%)' }} />
                : <NFTIcon />
            }
            <span>
              {text}
            </span>
          </>
        ) : <Preloader />
      }
    </div>
  );
};
export default observer(NoNFT);
