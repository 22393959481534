import { useEffect } from 'react';

import checkFields from 'utils/checkFields';
import { declOfNum, getUserAccountBalances, getUserContract } from 'helpers';
import { observer } from 'mobx-react';
import MainStore, { ModalStore } from 'store';
import Input from 'UI/InputText';
import reqFields from 'settings/requiredFields';
import { PATRON_BALANCE_COFFICIENT, SAFE_BALANCE_COFFICIENT } from 'settings/main';
import InputPassword from 'UI/InputPassword';
import ModalNote from 'UI/ModalNote';
import ModalLine from 'UI/Modal/ModalLine';

const correctiveStyles = {
  display: 'flex',
  width: '70%',
};
const balanceStyle = {
  color: '#337DB2',
  fontSize: '20px',
  fontWeight: 700,
  cursor: 'pointer',
};

interface IInnerSendProps {
  receive?: boolean;
  store?: boolean;
}

const InnerSend = observer(({ receive, store }: IInnerSendProps) => {
  const { changeHandler, modalValues, modalSettings } = ModalStore;
  const { fundConfigProxy } = MainStore;

  // TODO: SET ACTUAL BALANCES, make cases enum
  useEffect(() => {
    if (!modalValues.balance) {
      switch (modalSettings.type) {
        case 'send-to-store':
        case 'receive-from-patron':
          getUserContract(modalValues.sender).then((res: any) => changeHandler((res.pBalance / PATRON_BALANCE_COFFICIENT).toFixed(4), 'balance'));
          break;
        case 'receive-from-store':
          getUserContract(modalValues.sender).then((res: any) => changeHandler((res.sBalance / SAFE_BALANCE_COFFICIENT).toFixed(4), 'balance'));
          break;
        default:
          getUserAccountBalances(modalValues.sender, 'SAVVA').then(res => changeHandler(res.SAVVA || 0, 'balance'));
          break;
      }
    }
    return () => {
    };
  }, [modalValues.sender, modalValues.balance, modalSettings.type, changeHandler]);

  useEffect(() => {
    checkFields(modalValues, reqFields.innerSend);
  }, [modalValues]);

  return (
    <div>
      <ModalNote
        text={store ? null : receive
          ? 'Уменьшая  Фонд Мецената, Вы уменьшаете свои возможности при вознаграждении авторов и получении наград.'
          : 'Переведите токены SAVVA в Фонд Мецената, чтобы увеличить свои возможности при вознаграждении авторов и  получении наград.'}
      />

      <ModalLine noteLeft="Количество">
        <div style={correctiveStyles}>
          <Input
            type="number"
            onChange={(val: any) => changeHandler(val < 0 ? val.slice(1) : val > +modalValues.balance ? +modalValues.balance : val, 'amount')}
            pValue={modalValues.amount}
            noteRight="SAVVA"
          />
        </div>
      </ModalLine>

      <ModalLine noteLeft="Баланс">
        <div style={correctiveStyles} onClick={() => changeHandler(modalValues.balance, 'amount')}>
          <span style={balanceStyle}>
            {`${modalValues.amount ? (+modalValues.balance - +modalValues.amount).toFixed(4) : modalValues.balance || '0.0000'} SAVVA`}
          </span>
        </div>
      </ModalLine>
      <ModalNote
        text={(store && !receive) ? `Возврат токенов из сейфа в Фонд Мецената занимает ${fundConfigProxy.unsafedays} ${declOfNum(fundConfigProxy.unsafedays, ['день', 'дня', 'дней'])}.`
          : (store && receive) ? `Указанная сумма будет  моментально вычтена из Сейфа и добавится на баланс Фонда Меценатов 
            через ${fundConfigProxy.unsafedays} ${declOfNum(fundConfigProxy.unsafedays, ['день', 'дня', 'дней'])}.`
            : receive
              ? `Указанная сумма  будет моментально  вычтена из  Фонда Мецената и добавится на баланс перемещаемых  токенов  SAVVA  
                через ${fundConfigProxy.withdrawdays} ${declOfNum(fundConfigProxy.withdrawdays, ['день', 'дня', 'дней'])}.`
              : `Вам потребуются ${fundConfigProxy.withdrawdays} ${declOfNum(fundConfigProxy.withdrawdays, ['день', 'дня', 'дней'])}, 
                чтобы вернуть  их обратно в ликвидные токены SAVVA.`}
      />

      <div className="modal_body_line">
        <span className="label" />
        <InputPassword
          placeholder="Приватный ключ"
        />
      </div>
    </div>
  );
});
export default InnerSend;
