// eslint-disable-next-line max-classes-per-file
import {
  observable, decorate, action, computed, toJS,
} from 'mobx';
import {
  getUserAccountBalances,
  getUserBlockchainAccount,
  getUserContract,
  userFromStorage,
  themeToStorage,
  themeFromStorage,
  parseJwt,
} from 'helpers';
import * as Settings from 'settings';
import {
  BASE_NODE_LINK,
  initialUserData,
  MOBILE_POINT_CONTENT,
  MOBILE_POINT_MENU,
  PATRON_BALANCE_COFFICIENT,
  SAFE_BALANCE_COFFICIENT,
} from 'settings/main';
import {
  BalancesType, IComments, IPost, IPosts, IRubric, IUserData,
} from 'types';
import { findLast } from 'lodash';
import { POSTS_PAGE_COUNT } from 'settings/api';
import LocalStorage from 'services/LocalStorage';

import firstInit from './firstInit';
import ModalStore from './Modal';

export interface IPermissions {
  id: number;
  account: string;
  code: string;
  message_type: string;
  required_permission: string;
}

export interface IMetaData {
  title: string;
  description?: string;
  keywords?: string;
}

export interface ISubsribe {
  subscribers_count: number;
  subscriptions_count: number;
}

export interface INftState {
  accountname:string;
  allow: boolean;
  price: number;
}

export interface IPostConfiguration {
  ashare: string;
  id: string;
  inactivedays: number;
  maxwin: string;
  minamount: string;
  minpot: string;
  owner: string;
  pmin: number;
  randscname: string;
  unsafedays: number;
  waithours: number;
  winlist: number[];
  withdrawdays: number;
}

export interface INftStore {
  author: string;
  contentguid: number;
  hash: string;
  key: number;
  lastprice: string;
  owner: string;
  saleprice: string;
  storage: string;
  time: string;
}

export interface NavigatorTypes {
  next: IPost;
  prev: IPost;
}

class Store {
  constructor() {
    firstInit();
  }

  screenWidth: number = window.innerWidth;

  get isMobileContent(): boolean {
    return this.screenWidth <= MOBILE_POINT_CONTENT;
  }

  get isMobile(): boolean {
    return this.screenWidth <= MOBILE_POINT_MENU;
  }

  activeNode: string = BASE_NODE_LINK;

  listType: string = '';

  metaDataStore: IMetaData = {} as IMetaData;

  isAuth: boolean = userFromStorage();

  isCrached: boolean | object = false;

  userData: IUserData = userFromStorage() || initialUserData;

  jwtToken: string = '';

  userBalance: BalancesType = { SAVVA: '0.0000', CYBER: '0.0000' };

  userBalanceLoading: boolean = true;

  pBalance: number = 0.0000;

  patronWithdrawDate: string = '';

  sBalance: number = 0.0000;

  swamount: number = 0;

  wamount: number = 0;

  safeWithdrawDate: string = '';

  blockchainAccount: object = {};

  blockchainAccountLoading: boolean = true;

  contractBalancesLoading: boolean = true;

  postEditingBanner: File | null = null;

  postsProxy: IPosts = { cursor: '', list: [], loaded: false };

  commentsProxy: IComments = { cursor: '', list: [] };

  rubricsProxy: IRubric[] = [];

  stakeInformationProxy: any = {};

  userPermissionLinksProxy: IPermissions[] = [];

  postEditMode: boolean = false;

  postFundsProxy: object = {};

  subscribe: ISubsribe = { subscribers_count: 0, subscriptions_count: 0 };

  focusedGuid: string = '';

  fundConfigProxy: IPostConfiguration = {
    ashare: '',
    id: '',
    inactivedays: 0,
    maxwin: '',
    minamount: '',
    minpot: '',
    owner: '',
    pmin: 0,
    randscname: '',
    unsafedays: 0,
    waithours: 0,
    winlist: [0],
    withdrawdays: 0,
  }

  laroundEvents: any[] = [];

  lastRandom: any[] = [];

  setRandomize = (list: any = []) => {
    const ev = list.length ? list : [...toJS(this.laroundEvents)];
    const result: any[] = [];
    if (!ev.length) return [];

    const getRandomInt = (max: number) => Math.floor(Math.random() * Math.floor(max));

    while (result.length !== 2) {
      const index = getRandomInt(ev.length);
      if (result.length === ev.length) break;
      if (!result.find((el: any) => el.event.contentguid === ev[index].event.contentguid)) {
        result.push(ev[index]);
      } else {
        const newIndex = getRandomInt(ev.length);
        result.push(ev[newIndex]);
      }

      // result = result.filter((v, i, arr) => arr.indexOf(v) === i);
    }
    this.lastRandom = result;
  }

  addLaroundEvents = (list: any) => {
    if (!list?.length) return;
    const ev = [...toJS(this.laroundEvents)];
    const nlrE = [];

    for (let i = 0; i < list.length; i++) {
      if (!ev.find((el:any) => el.event.contentguid === list[i].event.contentguid)) {
        nlrE.push(list[i]);
      }
    }
    // filterEvents by date roundtime - 5 min - now()
    const newList = [...ev, ...nlrE];
    this.laroundEvents = [...newList];
    this.setRandomize(newList);
  }

  get randomEvents() {
    return toJS(this.lastRandom);
  }

  lastEvents: any[] = [];

  blogFilter: any = {};

  nftStore: INftStore = {} as INftStore;

  nftState: INftState = {} as INftState;

  blockedPostsData: any[] = [];

  setMetaDataStore = (data: IMetaData) => this.metaDataStore = data;

  setBlockedPostsData = (data: any[]) => this.blockedPostsData = data;

  getBlockedHistoryById = (id: string) => {
    const res = toJS(this.blockedPostsData)?.filter(item => item.guid === id) || [];
    return res;
  }

  setNftState = (data: INftState) => this.nftState = data;

  setNftStore = (data: INftStore) => this.nftStore = data;

  setBlogFilter = async (filter: any) => this.blogFilter = filter;

  setFocudes = (guid: string) => this.focusedGuid = guid;

  dropFocused = () => this.focusedGuid = '';

  showedNsfw: string[] = LocalStorage.get('spnsfw') || [];

  get showedNsfwList() {
    return toJS(this.showedNsfw);
  }

  get lastEventsList() {
    return toJS(this.lastEvents);
  }

  get isBlocked() {
    return parseJwt(LocalStorage.get('at'))?.r === 'b' || false;
  }

  get isAdmin() {
    return parseJwt(LocalStorage.get('at'))?.r === 'a' || false;
  }

  get fundConfig() {
    return this.observableArray(this.fundConfigProxy);
  }

  get postFundsObject() {
    return this.observableArray(this.postFundsProxy);
  }

  get userPermissionLinks() {
    return this.observableArray(this.userPermissionLinksProxy);
  }

  get stakeInformation() {
    return this.observableArray(this.stakeInformationProxy);
  }

  get safeBalance() {
    return this.sBalance / SAFE_BALANCE_COFFICIENT;
  }

  get patronBalance() {
    return this.pBalance / PATRON_BALANCE_COFFICIENT;
  }

  get getBlokchainAccountData() {
    return this.observableArray(this.blockchainAccount);
  }

  get posts(): IPosts {
    return this.observableArray(this.postsProxy);
  }

  get comments(): IComments {
    return this.observableArray(this.commentsProxy);
  }

  get activePost(): IPost {
    const activePost = this.postsProxy.list.find(item => item.active);
    return activePost ? this.observableArray(activePost) : null;
  }

  get activeRubric(): IRubric {
    const activeRubric = this.rubricsProxy.find(item => item.active);
    return activeRubric ? this.observableArray(activeRubric) : null;
  }

  get postEditingBannerUrl() {
    return this.postEditingBanner ? URL.createObjectURL(this.postEditingBanner) : '';
  }

  get rubrics() {
    return this.observableArray(this.rubricsProxy);
  }

  navigator: NavigatorTypes = {} as NavigatorTypes

  get navObject() {
    return toJS(this.navigator);
  }

  setNavigator = (data: NavigatorTypes) => this.navigator = data;

  dropNavigator = () => this.navigator = {} as NavigatorTypes

  setIsCrashed = (data: object) => {
    if (this.isCrached) return;
    this.isCrached = data;
  }

  setShowedNsfw = (guid: string) => {
    const d = [...this.showedNsfw, ...[guid]];
    this.showedNsfw = d;
    LocalStorage.set('spnsfw', d);
  }

  deleteFromShowedNsfw = (guid: string) => {
    const d = this.showedNsfw.filter((el: string) => el !== guid);
    this.showedNsfw = d;
    LocalStorage.set('spnsfw', d);
  }

  setLastEvents = (data: any[]) => this.lastEvents = [...data];

  setFundConfig = (data: IPostConfiguration) => {
    this.fundConfigProxy = { ...data };
  }

  setUserPermissionLinks = (arr: IPermissions[]) => {
    this.userPermissionLinksProxy = arr;
  }

  checkIsUser = (name: string) => this.userData.cyber_name === name;

  setScreenWidth = (size: number) => {
    this.screenWidth = size;
  }

  observableArray(array: any): any {
    return JSON.parse(JSON.stringify(array));
  }

  setSubscribed = (data: any) => {
    this.subscribe = data;
  }

  setUserBalances = (id: string) => {
    getUserAccountBalances(id).then(res => {
      this.userBalance = { ...res };
      this.userBalanceLoading = false;
    }).catch();
    this.setContractBalances(id);
  }

  setBlockchainAccount = (id: string) => {
    getUserBlockchainAccount(id).then((res:any) => {
      this.blockchainAccount = { ...res };
      this.blockchainAccountLoading = false;
    });
  }

  dropBlockchainAccountData = () => {
    this.blockchainAccountLoading = true;
    this.blockchainAccount = {};
  }

  dropUserBalances = () => {
    this.userBalance = { SAVVA: '0.0000', CYBER: '0.0000' };
    this.pBalance = 0.0000;
    this.patronWithdrawDate = '';
    this.sBalance = 0.0000;
    this.swamount = 0;
    this.wamount = 0;
    this.safeWithdrawDate = '';
    this.blockchainAccount = {};
    this.userBalanceLoading = true;
    this.blockchainAccountLoading = true;
    this.contractBalancesLoading = true;
  }

  setContractBalances = (id: string) => {
    getUserContract(id).then((res:any) => {
      if (res) {
        this.pBalance = res.pBalance;
        this.sBalance = res.sBalance;
        this.wamount = res.wamount;
        this.swamount = res.swamount;
        this.patronWithdrawDate = res.patronWithdrawDate;
        this.safeWithdrawDate = res.safeWithdrawDate;
        this.contractBalancesLoading = false;
      } else {
        this.pBalance = 0;
        this.sBalance = 0;
        this.wamount = 0;
        this.swamount = 0;
        this.patronWithdrawDate = '0.0000';
        this.safeWithdrawDate = '0.0000';
        this.contractBalancesLoading = false;
      }
    });
  }

  setUser = (obj: IUserData) => {
    this.isAuth = true;
    this.userData = { ...obj };
  }

  updateUser = (obj: any) => {
    this.userData = obj;
  }

  setPostEditingBanner = (file: File | null) => {
    this.postEditingBanner = file;
  }

  setOP = ({ list, cursor }: any) => {
    this.postsProxy = {
      cursor,
      list,
      loaded: true,
    };
  }

  setPosts = ({ list, cursor }: IPosts, isFilter?: boolean) => {
    const internalPosts = this.postsProxy.list.filter(item => item.isInternal);
    const newPostsListCustom = list?.map((item, index) => ({ ...item, cursor, dataIndex: POSTS_PAGE_COUNT - index })) || [];
    const mergedPostsList = [...this.posts.list, ...newPostsListCustom];
    const uniquePosts = [...new Set(mergedPostsList.map(item => item.id))];
    const newPostsList = uniquePosts.map(item => findLast(mergedPostsList, elem => item === elem.id) as IPost);

    this.postsProxy = {
      cursor,
      list: isFilter ? [...internalPosts, ...newPostsListCustom] : newPostsList,
      loaded: true,
    };
    // LocalStorage.set('posts', this.posts);
  }

  // addPost = (post: IPost) => {
  //   const existingPost = this.postsProxy.list.find(p => p.id === post.id);
  //
  //   this.postsProxy = {
  //     ...this.posts,
  //     list: existingPost
  //       ? this.postsProxy.list.map(item => (item.id === existingPost.id ? { ...existingPost, ...item, isInternal: false } : item))
  //       : [{ ...post }, ...this.postsProxy.list],
  //   };
  //
  //   LocalStorage.set('posts', this.posts);
  // }

  // clearInternalPosts = () => {
  //   const internalPosts = this.postsProxy.list.filter(post => post.isInternal);
  //
  //   if (internalPosts.length <= 5) return;
  //
  //   this.postsProxy = {
  //     ...this.posts,
  //     list: [...internalPosts.slice(internalPosts.length - 6, internalPosts.length - 1), ...this.posts.list.filter(post => !post.isInternal)],
  //   };
  //
  //   LocalStorage.set('posts', this.posts);
  // }

  dropPostList = (dropFilter?: boolean) => {
    this.postsProxy = { cursor: '', list: [], loaded: false };
    if (dropFilter) this.blogFilter = {};
    // LocalStorage.set('posts', this.posts);
  }

  dropActivePost = () => {
    this.postsProxy = {
      ...this.postsProxy,
      list: this.postsProxy.list.map(item => ({
        ...item,
        active: false,
      })),
    };

    // LocalStorage.set('posts', this.posts);
    return this.activePost;
  }

  setActivePost = (id: string) => {
    this.postsProxy = {
      ...this.postsProxy,
      list: this.postsProxy.list.map(item => ({
        ...item,
        active: item.id === id,
      })),
    };

    // LocalStorage.set('posts', this.posts);
    return this.activePost;
  }

  addNewPostToList = (data: IPost) => {
    const d = { ...this.postsProxy };
    const existing = d.list.find(item => item.id === data.id);

    existing ? d.list = d.list.map(item => (item.id === data.id ? { ...item, ...data, meta: data.meta } : item)) : d.list.unshift(data);

    this.postsProxy = d;
  }

  updateBlockedUser = (is_blocked_author: boolean) => {
    const data = toJS(this.posts);
    const newList = data.list.map((el: IPost) => ({ ...el, is_blocked_author }));
    data.list = newList;
    this.setPosts(data);
  }

  setBlockedStore = (guid: string, blockdata: any) => {
    const data = toJS(this.posts);
    data.list[data.list.findIndex(x => x.id === guid)].blocked = blockdata;
    data.list[data.list.findIndex(x => x.id === guid)].is_blocked = true;
    this.setPosts(data);
  }

  setUnBlockedStore = (guid: string, unblockdata: any) => {
    const data = toJS(this.posts);
    data.list[data.list.findIndex(x => x.id === guid)].unbloked = unblockdata;
    data.list[data.list.findIndex(x => x.id === guid)].is_blocked = false;
    this.setPosts(data);
  }

  setRubrics = (newRubrics: IRubric[]) => {
    this.rubricsProxy = newRubrics;
  }

  setActiveRubric = (code: string) => {
    this.rubricsProxy = this.rubricsProxy.map(item => ({
      ...item,
      active: item.code === code,
    }));
  }

  setPostEditMode = (mode: boolean) => {
    this.postEditMode = mode;
  }

  setPostFunds = (data: any) => {
    this.postFundsProxy = data;
  }

  logout = async () => {
    this.isAuth = false;
    this.userData = initialUserData;
    this.jwtToken = '';
    this.userBalance = { SAVVA: '0.0000', CYBER: '0.0000' };
    this.pBalance = 0.0000;
    this.patronWithdrawDate = '';
    this.sBalance = 0.0000;
    this.swamount = 0;
    this.wamount = 0;
    this.safeWithdrawDate = '';
    this.blockchainAccount = {};
    ModalStore.modalValues = {};
    ModalStore.modalSettings = {};
    const v = LocalStorage.get('v');
    const theme = LocalStorage.get('theme');
    const pk = LocalStorage.get('pk');
    LocalStorage.clear();
    LocalStorage.set('theme', theme);
    LocalStorage.set('v', v);
    LocalStorage.set('pk', pk);
  }
}

decorate(Store, {
  subscribe: observable,
  screenWidth: observable,
  isMobileContent: computed,
  isMobile: computed,
  setScreenWidth: action,
  checkIsUser: action,
  isAuth: observable,
  jwtToken: observable,
  observableArray: action,
  setUser: action,
  userData: observable,
  updateUser: action,
  userBalance: observable,
  setUserBalances: action,
  pBalance: observable,
  sBalance: observable,
  setContractBalances: action,
  safeBalance: computed,
  patronBalance: computed,
  patronWithdrawDate: observable,
  swamount: observable,
  wamount: observable,
  safeWithdrawDate: observable,
  blockchainAccount: observable,
  getBlokchainAccountData: computed,
  logout: action,
  postEditingBanner: observable,
  postEditingBannerUrl: computed,
  setPostEditingBanner: action,
  postsProxy: observable,
  setPosts: action,
  posts: computed,
  activePost: computed,
  setActivePost: action,
  dropPostList: action,
  stakeInformation: computed,
  stakeInformationProxy: observable,
  dropBlockchainAccountData: action,
  dropUserBalances: action,
  userBalanceLoading: observable,
  blockchainAccountLoading: observable,
  contractBalancesLoading: observable,
  rubricsProxy: observable,
  setRubrics: action,
  rubrics: computed,
  activeRubric: computed,
  setActiveRubric: action,
  userPermissionLinks: computed,
  setUserPermissionLinks: action,
  userPermissionLinksProxy: observable,
  setPostEditMode: action,
  postEditMode: observable,
  postFundsProxy: observable,
  postFundsObject: computed,
  setPostFunds: action,
  setLastEvents: action,
  lastEvents: observable,
  lastEventsList: computed,
  isCrached: observable,
  setIsCrashed: action,
  showedNsfw: observable,
  showedNsfwList: computed,
  setShowedNsfw: action,
  deleteFromShowedNsfw: action,
  isAdmin: computed,
  listType: observable,
  blogFilter: observable,
  focusedGuid: observable,
  activeNode: observable,
  nftStore: observable,
  nftState: observable,
  blockedPostsData: observable,
  metaDataStore: observable,
  navigator: observable,
  navObject: computed,
  laroundEvents: observable,
  randomEvents: computed,
  lastRandom: observable,
});

class Theme {
  defaultTheme: string = 'light';

  activeTheme: string = themeFromStorage();

  get themeData() {
    return Settings.mainSettings.mainSettings.themesList.find(x => x.name === this.activeTheme);
  }

  getThemeIcon = (): string | undefined => Settings.mainSettings.mainSettings.themesList.find(x => x.name === this.activeTheme)?.icon

  changeTheme = (next: string): void => {
    const nextTheme = Settings.mainSettings.mainSettings.themesList.find(x => x.name === next);
    if (!nextTheme) {
      themeToStorage(this.defaultTheme);
      return;
    }
    themeToStorage(nextTheme.name);
    this.activeTheme = nextTheme.name;
  }
}
decorate(Theme, {
  activeTheme: observable,
  getThemeIcon: action,
  changeTheme: action,
  themeData: computed,
});

const MainStore = new Store();
export default MainStore;

export const ThemeStore = new Theme();
export { default as ModalStore } from './Modal';
export { default as CurtainStore } from 'curtain/Curtain';
export { default as CommentsStore } from './CommentsStore';
