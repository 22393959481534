import { ReactElement } from 'react';

import { ThemeStore } from 'store';
import './style.scss';

interface IButtonProps {
    title?: string;
    svg?: object;
    secondary?: boolean;
    action?: Function;
    disabled?: boolean;
    styleName?: string;
    className?: string;
    soft?: boolean;
    children?: ReactElement;
}

const Button = ({
  title, secondary, action, svg, disabled, styleName, className, soft, children,
}: IButtonProps): ReactElement => (
  <button
    disabled={disabled || false}
    className={
      `button ${styleName ? `button_${styleName}` : ' '} ${soft ? 'button_soft' : ''} ${(secondary || disabled) ? `button_grey button_grey--${ThemeStore.activeTheme}` : ''} ${className || ''}`
    }
    onClick={() => action && action()}
  >
    {children || svg || title}
  </button>
);

export default Button;
