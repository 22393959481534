import { ReactElement, useState } from 'react';

import Button from 'UI/Button';
import Input from 'UI/InputText';
import UserCard from 'UI/UserCard';
import { useMount } from 'hooks';
import MainStore, { ThemeStore } from 'store';
import { observer } from 'mobx-react';
import { subscriptionListCFG } from 'settings/api';
import apiRequest from 'services/Api';
import { getPic, handleSubscribe } from 'helpers';
import { useLocation } from 'react-router';

import './style.scss';

interface ISubscriptionInfo {
  cyber: string;
  golos: string;
  alias: string;
  subscribed: boolean;
  subs_count: number;
  avatar: string;
}

interface ISubscribersListProps {
  id: string;
  updateProfile: () => void;
}

const SubscribersList = ({ id, updateProfile }: ISubscribersListProps): ReactElement => {
  const { userData } = MainStore;
  const { activeTheme } = ThemeStore;

  const [subscriptionList, setSubscriptionList] = useState<ISubscriptionInfo[]>([]);
  const [filterVal, setFilterVal] = useState('');
  const isSubscriptions = useLocation().pathname.includes('subscriptions');
  const isUserSubscriptions = isSubscriptions && userData.cyber_name === id;

  const setSubscriptions = async () => {
    const list = await apiRequest(subscriptionListCFG(isSubscriptions ? 'subscription' : 'subscribers', id));
    setSubscriptionList(list?.data.list || []);
    updateProfile();
  };

  useMount(setSubscriptions);

  const onClickSubscribe = async (user: ISubscriptionInfo) => {
    await handleSubscribe({
      subscribed: user.subscribed,
      cyberName: user.cyber,
      alias: user.alias,
      cb: setSubscriptions,
    });
  };

  const filteredSubscribers = () => {
    if (!subscriptionList.length) return [];

    if (!filterVal.length) return subscriptionList;

    const lowerFilterVal = filterVal.toLowerCase();

    return subscriptionList.filter(el => el.alias.toLowerCase().includes(lowerFilterVal)
      || el.cyber.toLowerCase().includes(lowerFilterVal)
      || el.golos.toLowerCase().includes(lowerFilterVal));
  };

  return (
    <div className={`settingBox settingBox_${activeTheme} p20`}>
      <div className="p20">
        <Input placeholder="Фильтр..." onChange={setFilterVal} pValue={filterVal} className="filter-input" />
      </div>
      <div className="subList">
        {filteredSubscribers().map(el => (
          <div className={`subList_unit subList_unit--${activeTheme}`} key={el.cyber}>
            <UserCard
              title={el.alias || el.cyber}
              username={el.cyber}
              description={`${el.subs_count} подписчиков`}
              img={getPic(el.avatar)}
            />
            {el.cyber !== userData.cyber_name ? (
              <Button
                action={() => onClickSubscribe(el)}
                className={isUserSubscriptions || el.subscribed ? '' : 'btn-subscribe'}
                title={isUserSubscriptions || el.subscribed ? 'Вы подписаны' : 'Подписаться'}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default observer(SubscribersList);
