/* eslint-disable max-len */
import apiRequest, { RequestTypes } from 'services/Api';
import MainStore from 'store';
import IndexedDB from 'services/IndexedDB';
import ModalStore from 'store/Modal';
import banner from 'assets/images/banner.png';
import defaultAvatar from 'assets/images/avatar-default.svg';
import { getUTCTimeToShow, parseJwt, ShowToast } from 'helpers';
import {
  defaultCFG,
  subscribeCFG,
  updateJwtCFG,
} from 'settings/api';
import { IUserData } from 'types';
import { BADPIC, BASE_LINK } from 'settings/main';
import { unSubscribe } from 'settings/modal';
import LocalStorage from 'services/LocalStorage';

const base58 = require('base58-encode');

export const userToStorage = (data: IUserData) => LocalStorage.set('u', data);

export const themeToStorage = (data: string) => LocalStorage.set('theme', data);

export const themeFromStorage = () => {
  const uTheme = LocalStorage.get('theme');
  if (!uTheme) {
    themeToStorage('light');
    return 'light';
  }
  return uTheme;
};

export const declOfNum = (number: number, words: string[]) => words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];

export const userFromStorage = () => {
  const uData = LocalStorage.get('u');
  if (!uData) return false;
  return uData;
};

const checkExpire = (exp: number) : boolean => ((exp - 10) < Math.floor(Date.now() / 1000));

export const updToken = async ({ config, body, type } : RequestTypes) => {
  const refresh = LocalStorage.get('rt');
  const refreshExp = checkExpire(LocalStorage.get('rtExp'));

  if (MainStore.isAuth && !refreshExp && !!refresh) {
    try {
      const requestConfig = {
        ...updateJwtCFG,
        params: {
          r: refresh,
        },
      };
      const response = await apiRequest(requestConfig);
      const key:number = Number(Object.keys(response.data.jwts)[0]);
      LocalStorage.set('at', response.data.jwts[key]);
      LocalStorage.set('rt', response.data.refreshes[key]);
      LocalStorage.set('rtExp', response.data.refreshesExpAt[key]);
      return await apiRequest(config, body, type, response.data.jwts[key]);
    } catch (e) {
      MainStore.logout();
    }
  }
};

export const getPic = (link: string, type?: string) => {
  let newLink = link;
  if (link && link.length > 0) {
    [newLink] = link.replace('ipfs://', '').split('|');
    if (link === BADPIC) return defaultAvatar;
    return `${BASE_LINK}ipfs/${newLink}`;
  }
  return type === 'banner' ? banner : defaultAvatar;
};

export const checkLogin = (golos: string, cyber: string, real?: string) => ((real && real.length > 0) ? real : golos.length > 0 ? `@${golos}` : cyber);

export const getProfileName = (profile: any) => {
  const { alias, golos_name, cyber_name } = profile;
  return alias.length ? alias : golos_name.length ? golos_name : cyber_name;
};
export const updateProfilePic = () => {
  const { modalValues, getModalSettings, modalControl, setLoading } = ModalStore;
  const { userData, updateUser, observableArray } = MainStore;
  const { tag } = getModalSettings;
  setLoading();
  apiRequest(getModalSettings.config || defaultCFG, { ...modalValues }, 'media').then((res): void => {
    if (res && res.status === 200) {
      const data = observableArray(userData);
      data[tag] = res.data.url;
      updateUser(data);
      modalControl();
    } else {
      ShowToast('Не корректные данные', 'error');
    }
  });
};

export const deleteUserPic = () => {
  const { userData, observableArray, updateUser } = MainStore;
  const { setLoading, getModalSettings, modalControl } = ModalStore;
  const { tag } = getModalSettings;

  const config = {
    ...getModalSettings.config,
    params: {
      cyber: userData.cyber_name,
    },
  };
  setLoading();
  apiRequest(config).then(() => {
    const data = observableArray(userData);
    data[tag] = '';
    updateUser(data);
    modalControl();
  }).catch();
};

export const timeAgo = (date: string) => getUTCTimeToShow(date);

export const logoutDB = async () => {
  await IndexedDB.removeAllData();
};

export const createTransactionInfoBody = (data: any, account?: string) => {
  const { userData: { cyber_name } } = MainStore;
  const isSender = data.arguments.grantor_name === account;
  const isTransferSender = data.arguments.from === account;

  let body = {
    vector: '',
    actor: '',
    amount: 0,
    memo: '',
    token: '',
    time: timeAgo(data.time),
    action: data.action,
  };
  switch (data.action) {
    case 'stihi.patron::contribute':
      body = {
        ...body,
        actor: 'stihi.patron',
        vector: 'Кому:',
        amount: +(data.arguments.amount.split(' ')[0]),
        token: data.arguments.amount.split(' ')[1],
        // eslint-disable-next-line max-len
        memo: `Спонсирование поста:<br /><a href="/post/${data.arguments.author}/${data.content_guid}" class="titleStyle" style='text-decoration:none; margin-top:2px; margin-left: 5px'>"${data.article?.title}"</a>`,
      };
      break;
    case 'stihi.patron::buynft':
      const link = `<a href="/post/${data.arguments.author}/${data.content_guid}" class="titleStyle" style='text-decoration:none; margin-top:2px; margin-left: 5px'>"${data.article?.title || ''}"</a>`;
      body = {
        ...body,
        actor: data.arguments.patron,
        vector: `${data.arguments.patron === account ? 'От: ' : 'Кому: '}`,
        amount: +(data.arguments.amount.split(' ')[0]),
        token: data.arguments.amount.split(' ')[1],
        memo: data.arguments.author === account ? `Покупка NFT Вашего поста ${link}` : `Покупка NFT поста "${link}"`,
      };
      break;
    case 'stihi.patron::setnftprice':
      body = {
        ...body,
        actor: data.arguments.acc,
        vector: 'От: ',
        token: data.arguments.amount.split(' ')[1],
        memo: `Изменение минимальной стоимости NFT Ваших произвидений ${data.arguments.amount}`,
      };
      break;
    case 'stihi.patron::setnftsale':
      body = {
        ...body,
        actor: data.arguments.patron,
        vector: 'От: ',
        amount: +(data.arguments.amount.split(' ')[0]),
        token: data.arguments.amount.split(' ')[1],
        memo: `Изменение стоимости NFT поста на ${data.arguments.amount}`,
      };
      break;
    case 'cyber::updateauth':
      body = {
        ...body,
        actor: data.arguments.account,
        vector: 'От: ',
        memo: 'Обновление ключей',
      };
      break;
    case 'cyber::linkauth':
    case 'cyber::unlinkauth':
      body = {
        ...body,
        actor: data.arguments.account,
        vector: 'От: ',
        memo: 'Обновление прав для ключа',
      };
      break;
    case 'stihi.patron::reg':
      body = {
        ...body,
        actor: data.arguments.author,
        vector: 'От: ',
        memo: data.article ? `Публикация поста <a href="/post/${data.arguments.author}/${data.content_guid}" class="titleStyle" style='text-decoration:none; margin-top:2px; margin-left: 5px'>"${data.article?.title}"</a>` : 'Публикация комментария',

      };
      break;
    case 'cyber.stake::delegateuse':
    case 'cyber.stake::recalluse':
      body = {
        ...body,
        actor: (data.action === 'cyber.stake::recalluse' ? 'cyber.stake' : isSender ? data.arguments.recipient_name : data.arguments.grantor_name),
        vector: (data.action === 'cyber.stake::recalluse' ? 'От: ' : isSender ? 'Кому: ' : 'От: '),
        amount: +(data.arguments.quantity.split(' ')[0]),
        token: data.arguments.quantity.split(' ')[1],
        memo: `${data.action !== 'cyber.stake::recalluse' ? 'Делегирование' : 'Отмена делегирования'} от ${data.arguments.grantor_name} для ${data.arguments.recipient_name}`,
      };
      break;
    case 'cw.jb::relaytx':
      body = {
        ...body,
        actor: data.arguments.to,
        vector: 'Кому: ',
        token: 'SAVVA',
        memo: `Валидатор ${data.arguments.witness} подтвердил обмен ID: ${data.arguments.id}`,
      };
      break;
    case 'cw.jb::claim':
      body = {
        ...body,
        actor: data.arguments.user,
        vector: 'Кому: ',
        memo: `Подтверждение заявки на обмен EOS на SAVVA по заявке ID: ${data.arguments.id}`,
      };
      break;
    case 'cw.jb::withdraw':
      body = {
        ...body,
        actor: cyber_name,
        vector: 'Кому: ',
        memo: 'Отмена заявки на обмен EOS на SAVVA ',
      };
      break;
    case 'cyber.token::transfer':
    case 'cw.token::transfer':
      const mm = data.arguments.memo.includes('{') ? JSON.parse(data.arguments.memo) : data.arguments.memo;
      const isAnnounce = !!mm.guid && !!mm.code && !!mm.title;
      const isBridge = data.arguments.to === 'cw.jb';
      const memo = isAnnounce
        ? `Анонсирование поста <span class="titleStyle" style='text-decoration:none; margin-top:2px; margin-left: 5px'>"${mm.title}"</span>`
        : isBridge
          ? `Обмен ${data.arguments.quantity} на EOS, в сервисе jBridje.app`
          : data.arguments.to === 'stihi.patron'
            ? 'В фонд мецената'
            : data.arguments.to === 'cyber.stake'
              ? 'Увеличение ресурсов'
              : data.arguments.memo;
      body = {
        ...body,
        actor: isAnnounce ? data.arguments.to : isBridge ? data.arguments.to : data.arguments.to === 'stihi.patron' ? data.arguments.to : isTransferSender ? data.arguments.to : data.arguments.from,
        vector: isAnnounce ? 'Кому: ' : (data.arguments.to === 'stihi.patron' ? 'Кому: ' : isTransferSender ? 'Кому: ' : 'От: '),
        amount: +(data.arguments.quantity.split(' ')[0]),
        token: data.arguments.quantity.split(' ')[1],
        memo,
      };
      break;
    case 'stihi.patron::withdraw':
      body = {
        ...body,
        actor: data.receiver,
        vector: 'От:',
        amount: +(data.arguments.amount.split(' ')[0]),
        token: data.arguments.amount.split(' ')[1],
        memo: 'Вывод из фонда Мецената',
      };
      break;
    case 'cyber.stake::withdraw':
      body = {
        ...body,
        actor: 'cyber.stake',
        vector: 'От ',
        amount: +(data.arguments.quantity.split(' ')[0]),
        token: data.arguments.quantity.split(' ')[1],
        memo: 'Уменьшение ресурсов',
      };
      break;
    case 'stihi.patron::cancelwd':
    case 'stihi.patron::cancelus':
      body = {
        ...body,
        actor: data.arguments.acc,
        vector: 'От: ',
        memo: `Отмена вывода из ${data.action !== 'stihi.patron::cancelus' ? 'фонда Мецената' : 'сейфа'}`,
      };
      break;
    case 'stihi.patron::safe':
    case 'stihi.patron::unsafe':
      body = {
        ...body,
        actor: data.arguments.acc,
        vector: `${data.action !== 'stihi.patron::unsafe' ? 'Кому: ' : 'От: '}`,
        amount: +(data.arguments.amount.split(' ')[0]),
        token: data.arguments.amount.split(' ')[1],
        memo: `${data.action !== 'stihi.patron::unsafe' ? 'Отправлено в сейф' : 'Из сейфа'}`,
      };
      break;
    default:
      break;
  }
  return body;
};

export const createTransactionsList = (data: any, id?: string) => data.map((item:any) => createTransactionInfoBody(item, id));

export const getUserName = (): string => {
  const { userData: { alias, golos_name, cyber_name } } = MainStore;

  return alias || golos_name || cyber_name;
};

interface IHandleSubscribe {
  subscribed: boolean;
  cyberName: string;
  alias: string;
  cb: () => void;
}

export const handleSubscribe = async ({ subscribed, cyberName, alias, cb }: IHandleSubscribe) => {
  const { modalControl } = ModalStore;

  if (subscribed) {
    return modalControl({ ...unSubscribe, backFunc: cb }, {
      note: `Отказаться от подписки на блог "${alias}" ?`,
      author: cyberName,
    });
  }

  await apiRequest(subscribeCFG, { author: cyberName });
  await cb();
};

export interface IUserNames {
  alias: string;
  golos: string;
  cyber: string;
}
export const usersName = (names: IUserNames) => {
  if (names.alias.length) return names.alias;
  if (names.golos.length) return names.golos;
  if (names.cyber.length) return names.cyber;
};

export const decryptPassword = async (login: string, keyType: string, password: string) => {
  const summ = login + keyType + password;
  const msgBuffer = new TextEncoder().encode(summ);

  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  const hashSha256 = Array.from(new Uint8Array(hashBuffer));
  const pk = new Uint8Array([128].concat(hashSha256));

  const chsBuffer = await crypto.subtle.digest('SHA-256', pk);
  const chs = Array.from(new Uint8Array(chsBuffer));

  const chsBuffer256 = await crypto.subtle.digest('SHA-256', new Uint8Array(chs));
  const chs256 = Array.from(new Uint8Array(chsBuffer256));
  const b58 = [...pk, ...chs256.slice(0, 4)];

  return base58(b58);
};

export const blockUser = async () => {
  const { getModalSettings, modalValues, modalControl } = ModalStore;
  try {
    await apiRequest(getModalSettings.config, {
      cyber_name: modalValues.cyber_name,
      comment: modalValues.comment,
    });
    modalControl();
  } catch (e) {
    ShowToast(`Возникла ошибка ${e}`, 'error');
  }
};

export const getUserKeyList = () => {
  const { modalControl, modalSettings, modalValues } = ModalStore;
  const { userData } = MainStore;
  modalControl({
    ...modalSettings,
    type: 'sign-in2',
    title: 'Сохраните приватные ключи',
    button: 'Продолжить',
    tag: 'savedKeys',
    buttonhidden: true,
    payload: {
      g: userData.golos_name,
      p: modalValues.key,
    },
  });
};

export const blockArt = async () => {
  const { getModalSettings, modalValues, modalControl } = ModalStore;
  const { setBlockedStore, setUnBlockedStore } = MainStore;
  try {
    const result = await apiRequest(getModalSettings.config, {
      guid: modalValues.guid,
      author_login: modalValues.author_login,
      comment: modalValues.comment,
      content_type: modalValues.content_type,
    });
    if (getModalSettings.tag === 'unban') {
      setUnBlockedStore(modalValues.guid, {
        unblocked_actual: true,
        unblocked_at: parseJwt(LocalStorage.get('at'))?.l,
        unblocked_comment: modalValues.comment,
        unblocked_time: result.data.blocked_data[0].time,
      });
    } else {
      setBlockedStore(modalValues.guid, {
        blocked_actual: true,
        blocked_at: parseJwt(LocalStorage.get('at'))?.l,
        blocked_comment: modalValues.comment,
        blocked_time: result.data.blocked_data[0].time,
      });
    }
    modalControl();
  } catch (e) {
    ShowToast(`Возникла ошибка ${e}`, 'error');
  }
};
