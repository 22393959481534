import { useEffect } from 'react';

import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import Feed from 'pages/Feed';
import PostPage from 'pages/Post';
import PostEditor from 'pages/PostEditor';
import Profile from 'pages/Profile';
import Confirmation from 'pages/Confirmation';
import listPublic from 'settings/main-menu';
import AdminHub from 'components/AdminHub';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { observer } from 'mobx-react';
import MainStore from 'store';

import './style.scss';

const Content = (): JSX.Element => {
  const { metaDataStore } = MainStore;
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // setTitle(history.location.pathname);
    setTimeout(() => {
      const aElements = Array.from(document.body.getElementsByTagName('a'));

      aElements.forEach(a => {
        if (a.href.includes(window.location.origin)) {
          a.removeAttribute('target');
          return;
        }

        if (a.href.includes('https://cw.finance')) return;

        a.addEventListener('click', e => {
          e.preventDefault();
          history.push({ pathname: '/confirmation', state: a.href });
        });
      });
    }, 500);
  }, [location.pathname, history]);

  return (
    <div className="content">
      <HelmetProvider>
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Stihi.io :: ${metaDataStore.title}`}</title>
            <meta name="description" content={metaDataStore.description} />
            <meta name="keywords" content="Stihi, Стихи" />
            <link rel="canonical" href={window.location.href} />
          </Helmet>
          <Switch>
            <Route path="/post" component={PostPage} />
            <Route path="/post/:author" component={Feed} />
            <Route path="/post/:author/:id" component={PostPage} />
            <Route path="/edit/:id" component={PostEditor} key={2} />
            {listPublic.map((item => <Route key={item.link} path={item.link} component={Feed} />))}
            <Route path="/adminhub" component={AdminHub} />
            <Route path="/confirmation" component={Confirmation} />
            {/* <Route path="/:uid(\@{1}.*)" component={Profile} /> */}
            <Route path="/:uid" component={Profile} />
            <Route path="/" component={Feed} />
            <Route path="*" component={() => <Redirect to="/" />} key={1} />
          </Switch>
        </>
      </HelmetProvider>

    </div>
  );
};

export default observer(Content);
