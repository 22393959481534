import { SetStateAction } from 'react';

export interface RouteComponentProps {
    match: match;
}

export interface match {
    isExact: boolean;
    url: string;
    params: {
        uid: string;
        page: string;
    };
    path: string;
}

export interface ProfileRouteComponentProps {
    match: matchProps;
}

export interface matchProps {
    isExact: boolean;
    url: string;
    params: {
        userid: string;
    };
    path: string;
}

export interface InputsTypes {
    onChange?: Function;
    value?: string;
    placeholder?: string;
    className?: string;
}

export enum TransactionTypes {
    transfer = 'transfer',
    addLiq = 'addLiq',
    retLiq = 'retLiq',
    swap = 'swap',
}

export interface IGetTransaction {
    account: string;
    actor: string;
    recipient: string;
    amount: string;
    memo: string;
    curr: string;
}

export interface IUserKey {
    key: string;
    login?: string;
}

export interface ITransfetTokenForm {
    sender: string;
    amount: string;
    balance: string;
    note?: string;
    recipient: string;
    key: string;
}

export interface IToast {
    hideAfter: number;
    position: 'top-left'| 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
}

export interface BalancesType {
    SAVVA: string;
    CYBER: string;
}

export interface IRoute {
    path: string;
    auth: boolean;
    component: JSX.Element;
}

export enum BorderPosTypes {
    left = 'left',
    right = 'right',
    top = 'top',
    bottom = 'bottom'
}

export interface IBackendFile {
    id: string;
    mime: string;
    hash: string;
}

export interface IRubric {
    code?: string;
    name: string;
    id: number;
    parent_id?: number;
    active?: boolean;
}

export interface IMetaPost {
    time: string;
    title: string;
    preview: string;
    content: IBackendFile;
    banner: null | IBackendFile;
    rubrics: null | IRubric[];
    tags: null | string[];
    files: IBackendFile[];
}

export interface IPost {
    donatedCurrent: number;
    dataIndex: number;
    cursor: string;
    id: string;
    metaFile: IBackendFile;
    meta: IMetaPost;
    content: null | string;
    authorLogin: string;
    active?: boolean;
    authorAvatar?: string;
    authorAlias?: string;
    authorGolos?: string;
    donated?: number;
    isInternal?: boolean;
    commentsCount: number;
    is_nsfw: boolean;
    blocked: any;
    is_blocked: boolean;
    is_blocked_author: boolean;
    unbloked: any;
    next_cursor?: string;
    prev_cursor?: string;
}

export interface IPosts {
    cursor: string;
    list: IPost[];
    loaded?: boolean;
}

export interface IComment {
    id: string;
    metaFile: IBackendFile;
    authorLogin?: string;
    authorAvatar?: string;
    authorAlias?: string;
    authorGolos?: string;
    meta: {
        time: string;
        parent_guid: string;
        article_guid: string;
        preview: string;
        content: IBackendFile;
    };
    parentGUID: string;
    articleGUID: string;
    level: number;
    content: null | string;
    children: IComment[];
    isInternal?: boolean;
    articleTitle: string;
    articleAuthorLogin: any;
    articleAuthorGolos: any;
    articleAuthorAlias: any;
    comments: IComment[];
    child_count: number | null;
}

export interface IComments {
    cursor: string;
    list: IComment[];
    loaded?: boolean;
}

export interface INFTInPost {
    amount: number;
    parton: string;
    salePrice: number;
}

export interface IBlogPreviewProps extends IPost {
    small?: boolean;
    line?: boolean;
    donated?: number;
    donatedCurrent: number;
    nextRoundTime?: string;
    showNotes?: boolean;
    is_nsfw: boolean;
    blocked: any;
    unblocked: any;
    showBlock?: any;
    focusedG?: string;
    nft?: INFTInPost;
    is_blocked: boolean;
    is_blocked_author: boolean;
    blockedPage: boolean;
}

interface IUserKeys {
    type: string;
    key: string;
}

export interface ISubscriptionInfo {
    cyber: string;
    golos: string;
    alias: string;
    subscribed: boolean;
    subs_count: number;
    avatar: string;
}

export interface IUserData {
    cyber_name: string;
    golos_name: string;
    names: string[];
    keys: IUserKeys;
    site: string;
    alias: string;
    avatar: string;
    banner: string;
    location: string;
    about: string;
    sponsor_values: number[];
    rubrics?: string[];
    tags: string[];
    email?: string;
    gender?: string;
    subscribed?: boolean | null;
    subscribers_count?: number;
    subscriptions_count?: number;
    nsfw: string;
    blocked: any;
}

export interface IProvidedOptions {
    value: any;
    label: string;
}

export enum FilterPosts {
    actual = 'actual',
    newPosts = 'new',
    popular = 'popular',
    blog = 'blog',
    comments = 'comments',
    rubrics = 'rubrics',
    feed = 'feed',
    nft = 'nft',
    admin = 'adminhub',
}

export interface IContentObj {
    meta: {
        title: string;
        author: string;
        time: string;
        preview: string;
    };
    contentText: string;
    content: Blob;
}

export interface IEditorRef {
    setContent: SetStateAction<any>;
    setTypeWriterMode: (mode: boolean) => void;
    getContentObj: () => Promise<IContentObj>;
    heading: string;
    content: string;
    setHeading: (value: string) => void;
}

export interface IAddSetCommentEnabled {
    addCommentEnabled: boolean;
    setAddCommentEnabled: React.SetStateAction<any>;
}

interface INames {
    domain: string;
    name: string;
}

interface IKeys {
    type: 'owner' | 'active' | 'posting';
    key: string;
}

export interface IProfileData {
    rubrics: [];
    cyber_name: string;
    golos_name: string;
    names: INames[];
    keys: IKeys[];
    email: string;
    site: string;
    alias: string;
    avatar: string;
    banner: string;
    location: string;
    gender: string;
    about: string;
    sponsor_values: number[];
    subscribed: boolean;
    subscribers_count: number;
    subscriptions_count: number;
    tags: IProfileTags[];
    blocked: any;
    unblocked: any;
    nsfw: string;
}

interface IProfileTags{
    tag: string;
    count: number;
}
