import { useEffect } from 'react';

import { observer } from 'mobx-react';
import ContentWrapper from 'components/Content/ContentWrapper';
import SimplePost from 'pages/Post/SimplePost';
import MainStore from 'store';
import { fundCorretion } from 'helpers';
import NftBox from 'pages/components/NFT';
import FundIPost from 'components/FundBox/FundIPost';

const PostPage = () => {
  const { activePost, setPostFunds, setMetaDataStore } = MainStore;

  useEffect(() => {
    if (!activePost) return;
    setMetaDataStore({
      title: activePost.meta.title,
      description: activePost.meta.preview,
    });
  }, [activePost, setMetaDataStore, setPostFunds]);

  return (
    <ContentWrapper
      leftBlock={<SimplePost />}
      rightBlock={(
        <>
          <div className="recalcButton" onClick={() => fundCorretion(activePost?.id)} />
          <FundIPost />
          <NftBox />
        </>
      )}
    />
  );
};

export default observer(PostPage);
