import { getNFTS, getNFTState } from 'helpers';
import MainStore from 'store';
import { Pipeline } from 'store/PipelineStore';

const { setNftStore, setNftState } = MainStore;
const getNFTData: Pipeline = {
  title: 'getNFTData',
  tasks: [
    {
      title: 'Запрос стоимости NFT поста',
      task: async (meta: any) => {
        const { guid, author } = meta;
        const answer = await getNFTS(guid, author);
        return answer;
      },
    },
    {
      title: 'Обновление стоимости NFT фонда поста',
      task: async (_: any, results) => {
        const data = results['Запрос стоимости NFT поста'].rows;
        setNftStore(data.length > 0 ? data[0] : {});
      },
    },
    {
      title: 'Запрос мимнимальной стоимости NFT поста',
      task: async (meta: any) => {
        const { author } = meta;
        const answer = await getNFTState(author);
        return answer;
      },
    },
    {
      title: 'Обновление минимальной стоимости NFT фонда поста',
      task: async (_: any, results) => {
        const data = results['Запрос мимнимальной стоимости NFT поста'].rows;
        setNftState(data.length > 0 ? data[0] : {});
      },
    },
  ],
};

export default getNFTData;
