import { getDataBody, SendActionData } from 'helpers';
import apiRequest, { sendTransaction } from 'services/Api';
import { addCommentCFG, prepareContentCFG, putCommentCFG } from 'settings/api';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';

const addCommentPipeline: Pipeline = {
  title: 'addPostPipeline',
  tasks: [
    {
      title: 'Сохраняем комментарий на серверах stihi.io',
      task: async (meta: any) => {
        const { data: { result } } = await apiRequest(meta.content ? putCommentCFG : addCommentCFG, meta.body, 'media');
        return result;
      },
    },
    {
      title: 'Сохраняем комментарий в блокчейне',
      task: async (meta: any, results: any) => {
        const { userData } = MainStore;
        const commentData = results['Сохраняем комментарий на серверах stihi.io'];
        const { id: guid, metaFile: { hash } } = commentData;
        const data: SendActionData = {
          author: userData.cyber_name,
          website: 'stihi.io',
          guid,
          hash,
        };
        const sendingData = await getDataBody(userData.cyber_name, 'stihi.patron', data, 'reg');
        const res = await sendTransaction(meta.key, sendingData);
        return res;
      },
    },
    {
      title: 'Публикация комментария',
      skip: true, // TODO DELETE AFTER TEST
      task: async (meta: any, results: any) => {
        const commentData = results['Сохраняем комментарий на серверах stihi.io'];
        const { id: guid } = commentData;
        const res = await apiRequest(prepareContentCFG, { id: guid });
        await meta.updateCommentsList(commentData);
        return res;
      },
      errorShow: (_: any, e: any) => {
        if (e.message.includes('action declares irrelevant authority')) {
          return 'Не достаточно прав для ключа, проверьте в настройках разрешений';
        }
        return 'У Вас недостаточно ресурсов';
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default addCommentPipeline;
