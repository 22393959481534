import { useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
  getPic,
  getUTCTimeToShow,
} from 'helpers';
import MainStore, { ThemeStore } from 'store';
import Amount from 'components/Amount';
import FundBox from 'components/FundBox';
import { BADPIC } from 'settings/main';
import moment from 'moment';

export const getPreviwPic = (bnr: string, avatar: string) => getPic((bnr === BADPIC || bnr === '') ? avatar : bnr);

const BlogPreviewLaround = ({
  content_guid,
  content_metadata,
  small = true,
  line = true,
  showNotes = true,
  author_names,
  donated_current,
  event,
  authorAvatar,
}: any) => {
  const {
    isMobileContent,
    lastEventsList,
    setActivePost,
    setPostFunds,
  } = MainStore;
  const { activeTheme } = ThemeStore;
  const { alias: authorAlias, cyber: authorLogin } = author_names;
  const { roundtime: nextRoundTime } = event;
  const { String: id } = content_guid;
  const meta = content_metadata;
  const [balance, setBalance] = useState<number>(donated_current || 0);
  const [blink, setBlink] = useState<boolean>(false);

  const fixLengthPreview = (data: string) => {
    if (!data) return;
    return `${data.slice(0, balance === 0 ? 85 : 65)}${data.length > 55 ? '...' : ''}`;
  };

  const checkNextRound = (roundData: any, isSidebar: boolean) => {
    if (!roundData) return;

    const nextRound = roundData !== '0001-01-01T00:00:00Z' ? roundData : '';

    return nextRound.length ? (
      <div className={`feedPost_fund--note feedPost_fund--note--small ${line ? 'feedPost_fund--note--line' : ''}`}>
        {
          new Date(roundData) < new Date(new Date().getTime() - 330 * 60 * 1000) ? ('Ожидайте тираж')
            : (
              <>
                {isSidebar ? 'Финальный тираж ' : ''}
                <span style={{ fontWeight: 700, color: '#FFF' }}>{getUTCTimeToShow(moment.utc(Number(nextRound / 1000)))}</span>
              </>
            )
            }
      </div>
    ) : null;
  };

  useEffect(() => {
    const currBal = lastEventsList.find(el => el.name === 'cntrbtevent' && el.content_guid.String === id);
    if (!currBal) return;
    setBlink(true);
    setBalance(Number(currBal.event.balance.split(' ')[0]));
  }, [lastEventsList, id]);

  useEffect(() => {
    blink && setTimeout(() => setBlink(false), 1990);
  }, [blink]);

  return (
    <div style={{ width: '100%' }}>
      <Link
        to={`/post/${authorLogin}/${id}`}
        onClick={() => {
          setActivePost(id);
          setPostFunds({});
        }}
        className={`blogLine_box ${isMobileContent ? 'blogLineMobile_box' : ''} ${(small || line) ? 'blogLine_box--small' : ''}`}
      >
        {(!small && !line) && (
        <div className="blogLine_banner">

          <div className={`blogLine_img ${isMobileContent ? 'blogLineMobile_img' : ''}`}>
            <img src={getPreviwPic(meta.banner?.hash || '', authorAvatar || '')} alt="img_post" />
          </div>

        </div>
        )}
        <div className="blogLine_box_preview">
          <object style={{ display: 'flex' }}>
            <Link to={`/${authorLogin}`}>
              <span
                className={`blogLine_box_preview--author blogLine_box_preview--author--${activeTheme}`}
              >
                {authorAlias || authorLogin}
              </span>
            </Link>
          </object>
          <span className={`blogLine_box_preview--title blogLine_box_preview--title--${activeTheme}`}>{meta.title}</span>
          <span className={`blogLine_box_preview--prev blogLine_box_preview--prev--${activeTheme}`}>{fixLengthPreview(meta.preview)}</span>
        </div>
        <div className={`blogLine_box_preview--fund ${line ? 'blogLine_box_preview--fund--line' : ''} ${small ? 'w100' : ''}`}>
          {balance > 0
            ? (
              <FundBox small line={line}>
                <div className={`blogLine_fund ${small ? 'fr' : ''} ${line ? 'blogLine_fund--line' : ''}`}>
                  <span className="flare" style={{ display: blink ? '' : 'none' }} />
                  {!line && (
                  <span className="feedPost_fund--title feedPost_fund--title--small">
                    ФОНД ПОСТА
                  </span>
                  )}
                  <div className={`feedPost_fund--amount feedPost_fund--amount--small ${blink ? 'scaleRide' : 'scaleRideOff'}`}>
                    <Amount amount={balance.toFixed(3)} fontSize={line ? 22 : 26} resize />
                  </div>
                  {checkNextRound(nextRoundTime, showNotes)}
                </div>
              </FundBox>
            ) : <></>}
        </div>
      </Link>
    </div>
  );
};
export default observer(BlogPreviewLaround);
