import {
  getDataBody, resultMessage, SendActionData, ShowToast,
} from 'helpers';
import { sendTransaction } from 'services/Api';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';

const stakeActionPipeline: Pipeline = {
  title: 'stakeActionPipeline',
  tasks: [
    {
      title: 'Выполняем перевод токенов',
      task: async (meta: any) => {
        const { modalSettings } = ModalStore;
        const contract = 'cyber.stake';
        const isUnStake = modalSettings.tag === 'withdraw';
        const isDelegeteResouces = modalSettings.tag === 'delegateuse';
        const isUnDelegeteResources = modalSettings.tag === 'recalluse';
        // ...((claim) && { grantor_name: store.sender, recipient_name: store.recipient, token_code: 'CYBER' }),
        let data: SendActionData = {
          quantity: `${(+meta.amount).toFixed(4)} ${meta.token}`,
        };
        data = {
          ...data,
          ...(isUnStake && { account: meta.sender }),
          // eslint-disable-next-line
          ...((isDelegeteResouces || isUnDelegeteResources) && { grantor_name: meta.sender, recipient_name: meta.recipient }),
        };

        const sendingData = await getDataBody(meta.sender, contract, data, modalSettings.tag || '');
        const result = await sendTransaction(meta.key, sendingData);
        ShowToast(resultMessage(modalSettings.tag || 'default', meta.amount, meta.token), 'success');
        return result;
      },
    },
    {
      title: 'Обновляем балансы',
      task: async () => {
        const { userData: { cyber_name }, setBlockchainAccount, setUserBalances } = MainStore;
        setBlockchainAccount(cyber_name);
        setUserBalances(cyber_name);
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default stakeActionPipeline;
