interface IKeyList {
  title: string;
  type: string;
  note: string;
  button?: boolean;
}

const keyList: IKeyList[] = [{
  title: 'КЛЮЧ ВЛАДЕЛЬЦА',
  type: 'key_owner',
  button: true,
  note: 'Ключ владельца это главный ключ ко всему аккаунта, он необходим для изменения других ключей. Приватный ключ должен храниться в оффлайне насколько это возможно.',
}, {
  title: 'АКТИВНЫЙ КЛЮЧ',
  type: 'key_active',
  note: 'Активный ключ используется для переводов и размещения заказов на внутреннем рынке.',
  button: true,
}, {
  title: 'ПОСТИНГ КЛЮЧ',
  type: 'key_posting',
  note: 'Постинг ключ используется для постинга и спонисрования. Он должен отличаться от активного ключа и ключа владельца',

},
];

export default keyList;
