import { ReactElement, useEffect, useState } from 'react';

import { ThemeStore } from 'store';
import { useLocation, Redirect } from 'react-router-dom';
import apiRequest from 'services/Api';
import Tabs from 'UI/MenuTabs';
import awardsMenu from 'pages/Profile/Wallet/components/Awards/data';
import { getEventsCFG } from 'settings/api';
import { IUserNames, sortByDateDESC } from 'helpers';
import { ReactComponent as Broke } from 'assets/images/pencil.svg';
import Preloader from 'UI/Preloader';

import AwardsLine from './components/AwardsLine';
import RewardLine from './components/RewardLine';

import './style.scss';

interface IAwardsProps {
    id: string;
    bPass: string;
}

export interface IAwardsLine {
  author: IAwardIncluded;
  user_names: IUserNames;
  content_guid: IAwardIncluded;
  content_title: IAwardIncluded;
  event: IDonateEvent;
  index: number;
  name: string;
  time: string;
  user: IAwardIncluded;
}
export interface IAwardIncluded {
  String: string;
  Valid: boolean;
}

export interface IDonateEvent {
  author: string;
  award: string;
  contentguid: bigint;
  patron: string;
  storage: string;
}

const Awards = ({ id, bPass }: IAwardsProps): ReactElement => {
  const { activeTheme } = ThemeStore;
  const basePath = `${bPass}/wallet/awards`;
  const location = useLocation();
  const [eventsList, setEventsList] = useState<IAwardsLine[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(10);
  const [type, setType] = useState<string>('');

  useEffect(() => {
    if (!type.length) return;
    const params = {
      type,
      [type === 'donatevent' ? 'author' : 'user']: id,
    };
    apiRequest({ ...getEventsCFG, params })
      .then(res => setEventsList(sortByDateDESC(res.data.result, 'time')))
      .catch()
      .finally(() => setLoading(false));
  }, [id, type]);

  useEffect(() => {
    if (location.pathname === basePath) return;
    setLoading(true);
    setType(location.pathname.includes('donats') ? 'donatevent' : 'rewardevent');
  }, [location.pathname, basePath]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY > document.body.clientHeight - 100) {
        if (eventsList.length > limit) setLimit(limit + 10);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [eventsList, limit]);

  const checkName = (el: IAwardsLine) => {
    if (!el) return '';
    return type === 'donatevent' ? el.event.author : el.user.String;
  };

  const eventListFiltred = (list: IAwardsLine[], t: string, i: string) => list.filter((el: IAwardsLine) => el.name === t && checkName(el) === i);

  const redirect = () => <Redirect push to={`${basePath}/donats`} />;

  return (
    <div className="walletAwards">
      {basePath === useLocation().pathname && redirect()}
      {/* <div className="walletAwards_titleInfo"> */}
      {/*  <span> */}
      {/*    <h1>Данные о ваших наградах, полученных от пользователей и от вложений в Фонды постов.</h1> */}
      {/*  </span> */}
      {/*  <span> */}
      {/*    Показывать: */}
      {/*    {' '} */}
      {/*    <strong>по времени</strong> */}
      {/*  </span> */}
      {/* </div> */}
      <div className="walletAwards_menu">
        <Tabs list={awardsMenu} prefix={`${basePath}`} paddingLeftNone />
      </div>
      {
        loading ? <div className="profilePreloader"><Preloader /></div>
          : eventListFiltred(eventsList, type, id).length ? eventListFiltred(eventsList, type, id).slice(0, limit).map((el:IAwardsLine) => (
            el.name === 'donatevent'
              ? <AwardsLine {...el} />
              : el.name === 'rewardevent'
                ? <RewardLine {...el} />
                : <></>
          ))
            : (
              <div className={`noPosts noPosts--${activeTheme}`}>
                <Broke />
                <span>
                  Пока ничего не получено.
                </span>
              </div>
            )
      }
    </div>
  );
};

export default Awards;
