import { ReactElement, useEffect } from 'react';

import { ModalStore, ThemeStore } from 'store';
import { observer } from 'mobx-react';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import ModalLine from 'UI/Modal/ModalLine';
import CustomSelect from 'UI/CustomSelect';
import ModalNote from 'UI/ModalNote';
import InputTextarea from 'UI/InputTextarea';

import { banReason } from './data';

const BlockUser = observer((): ReactElement => {
  const { modalValues, changeHandler, getModalSettings } = ModalStore;
  const { activeTheme } = ThemeStore;
  const isUnBan = getModalSettings.tag === 'unban';
  const modulate = isUnBan ? 'раз' : '';

  useEffect(() => {
    checkFields(modalValues, reqFields.ban);
  }, [modalValues]);

  return (
    <div className="w100">
      {!isUnBan && (
        <CustomSelect
          optionsList={banReason}
          isSearchable={false}
          placeholder="Выберите вариант или введите вручную"
          className="settingBox_box_field_select"
          onChange={(o:any) => changeHandler(o.value, 'comment')}
          styles={{
            control: styles => ({
              ...styles,
              boxShadow: '0px 2px 3px -1px rgb(0 0 0 / 10%)',
              borderColor: 'rgba(0, 0, 0, 0.15)',
              cursor: 'pointer',
              ':hover': { borderColor: 'rgba(0, 0, 0, 0.15)' },
            }),
            option: (styles, { isFocused, isSelected }) => ({
              ...styles,
              backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.15)' : isSelected ? 'rgba(0, 0, 0, 0.15)' : 'unset',
              cursor: 'pointer',
              ':hover': { backgroundColor: activeTheme === 'dark' ? 'rgba(255, 255, 255, 0.09) !important' : '#ededed' },
            }),
            singleValue: styles => ({
              ...styles,
              color: activeTheme === 'dark' ? '#fff' : '#222',
            }),
          }}
        />
      )}
      <ModalNote text={`Причина ${modulate}блокировки`} />
      <ModalLine>
        <InputTextarea
          value={modalValues.comment}
          onChange={(d: string) => changeHandler(d, 'comment')}
          className="banField"
          placeholder={`Укажите причину ${modulate}блокировки пользователя`}
        />
      </ModalLine>
      <ModalNote text={`${modalValues.cyber_name} будет ${isUnBan ? 'раз' : 'за'}блокирован. Пользователь ${isUnBan ? '' : 'не'} сможет публиковать посты и комментарии`} />
    </div>
  );
});
export default BlockUser;
