import { ReactElement, useEffect, useState } from 'react';

import { getPermissions, getUserPermKeys } from 'helpers';
import Preloader from 'UI/Preloader';
import { useUnMount } from 'hooks';
import { keyList, permList } from 'pages/Profile/Settings/data';
import MainStore, { IPermissions, ModalStore, ThemeStore } from 'store';
import Button from 'UI/Button';
import {
  getKFP,
  setOwnPermissionLInk,
  setPostingKey,
  updPostingKey,
} from 'settings/modal';
import { ReactComponent as Done } from 'assets/images/done.svg';
import { observer } from 'mobx-react';
import LocalStorage from 'services/LocalStorage';

interface IPermissionsProps {
    id: string;
}

const Permissions = ({ id }: IPermissionsProps): ReactElement => {
  const [keys, setKeys] = useState<any | boolean>(false);
  const { activeTheme } = ThemeStore;
  const { modalControl } = ModalStore;
  const { checkIsUser, setUserPermissionLinks, userPermissionLinks, blockchainAccount, userData } = MainStore;

  useEffect(() => {
    getUserPermKeys(id).then(res => setKeys({ ...res }));
    getPermissions(id).then((res: IPermissions[]) => setUserPermissionLinks(res));
  }, [id, setUserPermissionLinks, checkIsUser, blockchainAccount]);

  const checkPermInList = (elm: any) => {
    const rtype = elm.type === '*' ? '' : elm.type;
    return userPermissionLinks.find((el: IPermissions) => el.code === elm.code && el.message_type === rtype);
  };

  const setPermissiomStatus = (el:any) => {
    const { code, type, title } = el;
    const body = {
      code,
      type,
      title,
      action: checkPermInList(el) ? 'unlinkauth' : 'linkauth',
    };
    modalControl(setOwnPermissionLInk, body);
  };
  // console.log(userPermissionLinks, permList);

  useUnMount(() => setKeys({}));

  return (
    <>
      {/*  <div className={`permissionSettingsBox permissionSettingsBox--${activeTheme}`}>
        <h1>Важно сохранить свой ключ-пароль. Stihi.io не хранит ваши пароли. Убедитесь, что надежно сохранили ваши ключи.</h1>
      </div> */}

      {keyList.map(el => (
        <div key={el.title} className="permissionSettingsBox">
          <div className={`permissionSettingsBox_line permissionSettingsBox_line--${activeTheme}`}>
            <span className="permissionSettingsBox_name">
              <h1>{el.title}</h1>
              {el.reRelease
                ? (
                  <span onClick={() => modalControl(updPostingKey)}>
                    Перевыпустить ключ
                  </span>
                ) : null}
            </span>
            {/* {el.button && (
              <div style={{ width: 'auto' }}>
                <Button title="Авторизоваться" secondary />
              </div>
            )} */}
          </div>
          <div className={`permissionSettingsBox_line permissionSettingsBox_line--key permissionSettingsBox_line--${activeTheme} fs`}>
            {/* <img src={QR} alt="qr" /> */}
            {' '}
            {keys ? keys[el.type] : <Preloader />}
          </div>
          <div className={`permissionSettingsBox_line permissionSettingsBox_line--${activeTheme}`}>
            <span className={`note note_${activeTheme}`}>{el.note}</span>
          </div>
          {keys ? !keys[el.type] ? <Button title="Создать" action={() => modalControl(setPostingKey)} className="width200" /> : null : null}
        </div>
      ))}
      {keys && keys.posting ? (
        <div className="permissionSettingsBox">
          <div className={`permissionSettingsBox_line permissionSettingsBox_line--${activeTheme}`}>
            <span className="uc"><h1>Права для ПОСТИНГ ключа</h1></span>
          </div>
          <div className={`permissionSettingsBox_line permissionSettingsBox_line--${activeTheme}`}>
            <span className={`note note_${activeTheme}`}>Права привязанные к Вашим ключам, для изменения состояния права нажмите на него</span>
          </div>
          <div className="permissionSettingsBox_permList">
            {permList.map(el => (
              <div className="permissionSettingsBox_permLine" key={el.id} onClick={() => setPermissiomStatus(el)}>
                <span>
                  {el.title}
                </span>
                <span>
                  {el.code}
                  ::
                  {el.type}
                </span>
                <span className={`createPermissionsBox_permList_line ${checkPermInList(el) ? 'createPermissionsBox_permList_line--done' : ''}`}>
                  <Done />
                </span>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      {
        LocalStorage.get('afp') && (
          <div className="permissionSettingsBox">
            <div className={`permissionSettingsBox_line permissionSettingsBox_line--${activeTheme}`}>
              <span className={`note note_${activeTheme}`}>
                В случае если Вы используете пароль, Вам необходимо получить из него список ключей и использовать их вместо пароля. Пароль - это небезопансно!
              </span>
            </div>
            <div className={`permissionSettingsBox_line permissionSettingsBox_line--${activeTheme}`}>
              <Button title="Получить ключи" action={() => modalControl(getKFP, { login: userData.cyber_name })} className="width200" />
            </div>
          </div>
        )
      }
    </>
  );
};

export default observer(Permissions);
