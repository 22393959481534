import { StrictMode } from 'react';

import { render } from 'react-dom';
import App from 'components/App';
import moment from 'moment';
import 'assets/styles/index.scss';
import { BrowserRouter } from 'react-router-dom';

import(('moment/locale/ru.js')).then(() => {
  moment.locale('ru');
});

render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('app'),
);
