import { useState } from 'react';

import { observer } from 'mobx-react';
import { ModalStore } from 'store';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from 'UI/Button';
import { signIn } from 'settings/modal';

import PdfDocument from './KeyTemplate';

const FinishSignUp = observer(() => {
  const { modalValues, modalControlForce, modalSettings } = ModalStore;
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const redirectToAuth = async () => {
    modalControlForce({ ...signIn, redirect: `/${modalValues.cyberKey}` }, { /* login: modalValues.golos_login, key: modalValues.keys.key_posting.private */ });
  };

  return (
    <div className="SignUpInfo">
      <div className="SignUpInfo_helloText">
        <p>Вы зарегистрировали аккаунт на Stihi.io в системе CyberWay.</p>
        <p>Сохраните  Ваш  логин  и ключи в надежном месте. </p>
        <p>Они понадобятся вам для входа в Ваш аккаунт.. Если вы потеряете эти ключи, Вы не сможете получить доступ к Вашей учетной записи, и никто не сможет помочь Вам восстановить ключи.</p>
      </div>
      <div className="SignUpInfo_line">
        <p>
          ЛОГИН:
          {modalValues.golos_login}
        </p>
        <p>
          ID пользователя:
          {modalValues.cyberKey}
        </p>
      </div>
      <div className="SignUpInfo_line">
        <span>ПОСТИНГ КЛЮЧ</span>
        <p>
          {
            modalSettings.payload.pk ? (
              <>
                <strong>приватный: </strong>
                {modalValues.keys.key_active.private || ''}
              </>
            ) : 'Не удалось зарегистрировать постинг ключ, Вы можете сделать это самостоятельно в настройках профиля'
          }
        </p>
        <p>
          {
            modalSettings.payload.pk ? (
              <>
                <strong>публичный: </strong>
                {modalValues.keys.key_posting.public || ''}
              </>
            ) : 'После создания ключа - назначьте ему необходимые права'
          }
        </p>
      </div>
      <div className="SignUpInfo_line">
        <span>АКТИВНЫЙ КЛЮЧ</span>
        <p>
          <strong>приватный: </strong>
          {modalValues.keys.key_active.private || ''}
        </p>
        <p>
          <strong>публичный: </strong>
          {modalValues.keys.key_active.public || ''}
        </p>
      </div>
      <div className="SignUpInfo_line">
        <span>КЛЮЧ ВЛАДЕЛЬЦА</span>
        <p>
          <strong>приватный: </strong>
          {modalValues.keys.key_owner.private || ''}
        </p>
        <p>
          <strong>публичный: </strong>
          {modalValues.keys.key_owner.public || ''}
        </p>
      </div>
      <div className="modal_body_buttons ">
        {downloaded ? (
          <Button title="Перейти к авторизации" action={() => redirectToAuth()} styleName="m0" />
        ) : (
          <div
            className="SignUpInfo_button"
            onClick={() => {
              setDownloaded(true);
              ModalStore.approveValues(true);
            }}
          >
            <PDFDownloadLink
              document={<PdfDocument keys={modalValues.keys} golos={modalValues.golos_login || 0} cyber={modalValues.cyberKey} isPosting={modalSettings.payload.pk} />}
              fileName="stihi_io_keys.pdf"
              className="button m0"
            >
              {({ loading }) => (loading ? 'Генерация ключей' : 'Сохранить ключи в PDF')}
            </PDFDownloadLink>
          </div>
        )}
      </div>
    </div>
  );
});

export default FinishSignUp;
