import CurtainStore from 'curtain/Curtain';
import { getPostConfig } from 'helpers/node';
import { autorun } from 'mobx';
import IndexedDB from 'services/IndexedDB';
import MainStore, { IPostConfiguration } from 'store';

import ModalStore from './Modal';

interface IPostConfigs {
  rows: IPostConfiguration[];
}

// const checkPostsComments = (items: IPosts | IComments) => !!items && Object.keys(items).includes('list') && Array.isArray(items.list)
//     && items.list.length > 1 && Object.keys(items.list).includes('meta');

const firstInit = () => {
  let firstRun = true;
  autorun((): void => {
    if (firstRun) {
      getPostConfig()
        .then((res:IPostConfigs) => res && MainStore.setFundConfig(res.rows[0]))
        .catch();

      IndexedDB.getAllData().then(
        res => {
          // const posts = LocalStorage.get('posts');
          // if (checkPostsComments(posts)) {
          //   MainStore.setPosts(posts);
          //   MainStore.clearInternalPosts();
          // }
          // const comments = LocalStorage.get('comments');0
          // if (checkPostsComments(comments)) {
          //   MainStore.setComments(posts);
          //   MainStore.clearInternalComments();
          // }

          if (res) {
            const curtain: any = res.find((x: any) => x.id === 'curtain');

            if (curtain) {
              CurtainStore.setDisableCurtain(true);
              ModalStore.modalControl();
            }
          }
        },
      );
    }
  });

  firstRun = false;
};

export default firstInit;
