import { ReactElement } from 'react';

import { observer } from 'mobx-react';
import { ThemeStore } from 'store';
import { InputsTypes } from 'types';
import './style.scss';

const InputTextArea = ({ onChange, value, placeholder, className }: InputsTypes): ReactElement => {
  const { activeTheme } = ThemeStore;
  return (
    <textarea
      className={`customTextarea ${className} customTextarea_${activeTheme}`}
      value={value || ''}
      placeholder={placeholder}
      onChange={(e): void => onChange && onChange(e.target.value)}
    />
  );
};

export default observer(InputTextArea);
