import { ReactElement } from 'react';

import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import { ThemeStore } from 'store';

import './style.scss';

interface ITabsProps {
    list: any;
    onClick?: Function;
    paddingLeftNone?: boolean;
    column?: boolean;
    prefix?: string;
    subscribed?: ISubscribe;
    backlight?: boolean;
    className?: string;
}

interface ISubscribe {
  subscribers_count: number;
  subscriptions_count: number;
}

const Tabs = observer(({
  list,
  prefix,
  column,
  onClick,
  paddingLeftNone,
  backlight = true,
  className,
}: ITabsProps): ReactElement => {
  const { pathname } = useLocation();
  const { activeTheme } = ThemeStore;

  const selected = (link:string, selectedPrefix?: string) => {
    const arr = pathname.split('/');
    const splitedLink = link.split('/').join('');
    if (pathname === '/' && link === '/posts/popular') return true;
    return ((selectedPrefix !== undefined ? selectedPrefix : '') + link === pathname
      || `${arr[arr.length - 2]}/` === link
      || `${arr[arr.length - 3]}/` === link
      || arr.includes(splitedLink) && splitedLink.length);
  };

  return (
    <ul className={`tabs ${column ? 'fc' : null} tabs_${activeTheme} ${paddingLeftNone ? 'pl0' : ''} ${className && className}`}>
      {(list || []).map((el: any): JSX.Element => (
        <li key={`${el.name}`} style={{ fontWeight: !backlight ? 600 : 400 }}>
          <Link
            to={prefix ? prefix + el.link : el.link}
            onClick={onClick ? () => onClick(el) : () => {}}
            className={selected(el.link, prefix) ? `tabs_selected ${backlight ? 'tabs_selected--backlight' : ''}` : ''}
          >
            {el.name}
          </Link>
          {el.count >= 0 && (
            <span className="tabs_count">
              {el.count}
            </span>
          )}
        </li>
      ))}
    </ul>
  );
});
export default Tabs;
