import {
  useState, useCallback, useRef, useEffect,
} from 'react';

import ReactCrop from 'react-image-crop';
import { ModalStore } from 'store';
import './style.scss';
import 'react-image-crop/dist/ReactCrop.css';
import InputText from 'UI/InputText';
import { ShowToast } from 'helpers';

interface IUploadImgProps {
    aspect?: any;
}

const maxImgSizeAvatarPost = 256;
const maxImgSizeProfileBanner = 1170;

const UploadImg = ({ aspect }: IUploadImgProps) => {
  const { modalSettings } = ModalStore;
  const [upImg, setUpImg] = useState<string>('');
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState<any>({ unit: '%', width: 300, aspect: aspect ? aspect[0] / aspect[1] : 1 });
  const [completedCrop, setCompletedCrop] = useState<any>(null);
  const [finishImg, setFinishImg] = useState<any>(null);
  const [imgLink, setImgLink] = useState<string>('');

  const onSelectFile = (e: any) => {
    // e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getResizedCanvas = useCallback((canvas: HTMLCanvasElement) => {
    const smallCanvas = document.createElement('canvas');
    const ctx = smallCanvas.getContext('2d');

    const iAvatarOrPost = ['avatar', 'postBanner'].includes(modalSettings.tag as string);
    const resizeSize = iAvatarOrPost ? maxImgSizeAvatarPost : maxImgSizeProfileBanner;

    const ratio = Math.min(resizeSize / canvas.width, resizeSize / canvas.height);
    const newWidth = Math.floor(ratio * canvas.width);
    const newHeight = Math.floor(ratio * canvas.height);

    smallCanvas.width = newWidth;
    smallCanvas.height = newHeight;

    ctx?.drawImage(canvas, 0, 0, newWidth, newHeight);

    return smallCanvas;
  }, [modalSettings.tag]);

  const generateDownload = useCallback((canvas: any, cropValue: any) => {
    if (!cropValue || !canvas) {
      return;
    }

    const resizedCanvas = getResizedCanvas(canvas);

    const newFileName = `${Date.now()}_${Math.random()}`;

    resizedCanvas.toBlob((blob: any) => {
      const file = new File([blob], newFileName, {
        type: 'image/jpeg',
        lastModified: Date.now(),
      });
      setFinishImg(file);
    }, 'image/jpeg', 1);
  }, [getResizedCanvas]);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const canvas: any = previewCanvasRef.current;

    const { min, floor } = Math;

    const ratio = min(image.naturalWidth / image.width, image.naturalHeight / image.height);

    const ctx = canvas.getContext('2d');

    canvas.width = floor(completedCrop.width * ratio);
    canvas.height = floor(completedCrop.height * ratio);

    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(image, -floor(completedCrop.x * ratio), -floor(completedCrop.y * ratio));
    generateDownload(previewCanvasRef.current, completedCrop);
  }, [completedCrop, generateDownload]);

  useEffect(() => {
    ModalStore.setModalValues({ file: finishImg });
    ModalStore.approveValues(true);
  }, [finishImg]);

  useEffect(() => {
    if (imgLink.length) {
      fetch(imgLink)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'SavedImg', { type: 'image/png' });
          const pseudo = {
            target: {
              files: [
                file,
              ],
            },
          };
          onSelectFile(pseudo);
        })
        // eslint-disable-next-line
        .catch(e => {
          ShowToast('Данное изображение не доступно по ссылке или скрыто настройками приватности', 'error');
          setImgLink('');
        });
    }
  }, [imgLink]);

  return (
    <div className="uploadImg">
      <div className="uploadImg_input">
        <input
          id="file"
          name="file"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          className="uploadImg_input-file"
        />
        <label className="button" htmlFor="file">Выберите изображение</label>
      </div>
      <div className="uploadImg_fromUrl">
        <span className="uploadImg_fromUrl_note">
          или вставьте ссылку на изображение
        </span>
        <InputText onChange={setImgLink} pValue={imgLink} />
      </div>
      <canvas
        ref={previewCanvasRef}
      />
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={ModalStore.modalSettings.isCrop ?? crop}
        onChange={c => setCrop(c)}
        onComplete={c => setCompletedCrop(c)}
      />
      <div />
    </div>
  );
};
export default UploadImg;
