import apiRequest, { sendTransaction } from 'services/Api';
import { getDataBody, setPermissionLink } from 'helpers';
import { signUpCFG } from 'settings/api';
import { Pipeline } from 'store/PipelineStore';
import { keyGenerator } from 'utils/KeyUtils';
import { permList } from 'pages/Profile/Settings/data';
import { ModalStore } from 'store';
import { toJS } from 'mobx';
// import ym from 'react-yandex-metrika';

const signupPipeline: Pipeline = {
  title: 'sginupPipeline',
  dropValues: true,
  tasks: [
    {
      title: 'Генерация ключей',
      task: async () => {
        const keys: any = await keyGenerator();
        return keys;
      },
      errorShow: (_: any, error: any) => error,
    },
    {
      title: 'Регистрация пользователя на сайте',
      task: async (meta: any, results: any) => {
        const keys = toJS(results['Генерация ключей']);
        const userData = {
          golos_login: meta.golos_login,
          user_alias: meta.user_alias,
          captcha: meta.captcha,
          captcha_id: meta.captcha_id,
          key_owner: keys.key_owner.public,
          key_active: keys.key_active.public,
          key_posting: keys.key_posting.public,
        };
        const registredData = await apiRequest(signUpCFG, userData);
        if (registredData.data.error) {
          let err = '';
          switch (registredData?.data?.error) {
            case 'login already exists':
              err = 'Пользователь с таким логином существует';
              break;
            case 'name already exists':
              err = 'Пользователь с таким именем существует';
              break;
            case 'wrong captcha':
              err = 'Вы не правильно ввели капчу';
              break;
            default:
              err = registredData?.data?.error;
              break;
          }
          if (registredData?.data?.error.includes('Username already exists: Cannot create username')) {
            err = 'Такое имя пользователя уже зарегистрировано в gls scope';
          }
          throw new Error(err);
        }
        return registredData.data;
      },
      errorShow: (_: any, error: any) => error.message,
    },
    {
      title: 'Регистрируем постинг ключ',
      task: async (_: any, results: any) => {
        const keys = toJS(results['Генерация ключей']);
        const registredUser = results['Регистрация пользователя на сайте'];
        const { profile: { cyber_name } } = registredUser;
        const authorization_object = {
          threshold: 1,
          accounts: [],
          keys: [{
            key: keys.key_posting.public,
            weight: 1,
          }],
          waits: [],
        };

        const data = {
          account: cyber_name,
          permission: 'posting',
          parent: 'active',
          auth: authorization_object,
        };
        const sendingData = await getDataBody(cyber_name, 'cyber', data, 'updateauth');
        let tryCount = 3;
        let result: any = false;
        while (!result && tryCount > 0 && result !== undefined) {
          try {
            result = await sendTransaction(keys.key_active.private, sendingData);
          } catch (error) {
            result = false;
            tryCount--;
          }
        }
        return result;
      },
    },
    {
      title: 'Назначаем права постинг ключу',
      task: async (_: any, results: any) => {
        const isPosting = results['Регистрируем постинг ключ'];
        if (!isPosting) return false;
        const keys = toJS(results['Генерация ключей']);
        const registredUser = results['Регистрация пользователя на сайте'];
        const { profile: { cyber_name } } = registredUser;
        const { setLoadingTitle } = ModalStore;
        if (!permList.length) return;
        for (let i = 0; i < permList.length; i++) {
          try {
            setLoadingTitle(`Назначаем права постинг ключу: ${permList[i].title}`);
            await setPermissionLink(cyber_name, permList[i].code, permList[i].type, keys.key_active.private);
          } catch {
            continue;
          }
        }
        return true;
      },
      errorShow: (_: any, error: any) => error,
    },
    {
      title: 'Завершение регистрации',
      task: async (_: any, results) => {
        const keys = toJS(results['Генерация ключей']);
        const registredUser = results['Регистрация пользователя на сайте'];
        const { modalControl } = ModalStore;
        // ym('89344929', 'reachGoal', 'regin');
        modalControl({
          payload: {
            pk: !!results['Регистрируем постинг ключ'],
          },
          type: 'FinishSignUp',
          title: 'Поздравляем!',
          button: 'Войти',
          buttonhidden: true,
        }, {
          golos_login: registredUser.profile.golos_name,
          cyberKey: registredUser.profile.cyber_name,
          keys,
        });
      },
    },
  ],
};

export default signupPipeline;
