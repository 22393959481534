import { observer } from 'mobx-react';
import { buyNFT } from 'settings/modal';
import MainStore, { ModalStore } from 'store';
import * as settings from 'settings';
import Button from 'UI/Button';
import ModalNote from 'UI/ModalNote';
import { PATRON_BALANCE_COFFICIENT } from 'settings/main';
import { ReactComponent as Savva } from 'assets/images/currIcon.svg';

const EmptyNFT = () => {
  const { modalControl } = ModalStore;
  const { activePost, isAuth, nftState, fundConfig } = MainStore;

  const price = (nftState?.price / PATRON_BALANCE_COFFICIENT).toFixed(4) || 0.0000;

  return (
    <>
      <div className="nftBox_emptyTitle">
        <ModalNote
          text="Добавьте в свою коллекцию NFT-токен этого произведения, став его эксклюзивным владельцем"
        />
      </div>
      <div className="nftBox_button">
        <Button
          className="w100"
          action={() => {
            modalControl(
              isAuth ? buyNFT : settings.modalSettings.signIn,
              isAuth ? {
                uuid: activePost?.id,
                author: activePost?.authorLogin,
                min_amount: nftState?.price ? `${price} SAVVA` : fundConfig.minamount,
                first_sale: true,
                amount: nftState?.price ? price : fundConfig.minamount.split(' ')[0],
              } : { login: '' },
            );
          }}
        >
          <div className="nftBox_buyButton">
            <span>Купить NFT</span>
            {nftState?.price > 0
              && (
              <div className="nftBox_amount">
                <Savva className="nftBox_currency" style={{ filter: 'invert(0%)' }} />
                <div>{price}</div>
              </div>
              )}
          </div>
        </Button>
      </div>
      {/* <div className="nftBox_whatIs">
        <ModalNote
          text="Что такое NFT-токен?"
          underline
        />
      </div> */}
    </>
  );
};
export default observer(EmptyNFT);
