import { ReactElement, useEffect, useRef } from 'react';

import curr from 'assets/images/currIcon.svg';
import cyberIcon from 'assets/images/cyber_icon.svg';

interface IAmountProps {
    amount: string;
    img?: string;
    cyber?: boolean;
    justifyContent?: string;
    large?: boolean;
    resize?: boolean;
    fontSize?: number;
    classname?: any;
}

const Amount = ({
  amount,
  img,
  justifyContent,
  large,
  cyber,
  resize,
  fontSize,
  classname,
}: IAmountProps): ReactElement => {
  const amountRef = useRef<undefined | any>();

  useEffect(() => {
    if (!amountRef || !resize) return;
    const size = 208 / amount.length * 1.3;
    amountRef.current.style.fontSize = `${fontSize || (size > 40 ? 40 : size < 15 ? 15 : size).toFixed(0)}px`;
  }, [amountRef, amount, resize, fontSize]);

  return (
    <div
      className={`amount ${large ? 'amount--large' : ''} ${classname}`}
      style={{ justifyContent: justifyContent || '', fontSize: (fontSize && !resize) ? fontSize : '' }}
      ref={amountRef}
    >
      <img className={cyber ? 'cyber-icon' : ''} src={img || cyber ? cyberIcon : curr} alt="curr" />
      {amount}
    </div>
  );
};

export default Amount;
