import { useMemo, useState } from 'react';

import apiRequest from 'services/Api';
import { observer } from 'mobx-react';
import MarkdownEditor from '@uiw/react-markdown-editor';
import MainStore, { ThemeStore } from 'store';
import Input from 'UI/InputText';
import Button from 'UI/Button';
import { editProfileCFG } from 'settings/api';
import { fields, showType } from 'pages/Profile/Settings/data';
import { MOBILE_POINT_MENU } from 'settings/main';
import CustomSelect from 'UI/CustomSelect';
import { ShowToast, userToStorage } from 'helpers';
import ModalNote from 'UI/ModalNote';

interface IEditProfileProps {
    id?: string;
}

const EditProfile = observer(({ id }: IEditProfileProps): JSX.Element => {
  const { userData, updateUser, screenWidth, fundConfigProxy } = MainStore;
  const { activeTheme } = ThemeStore;

  const initialValues = useMemo(() => ({
    about: userData.about,
    alias: userData.alias,
    gender: userData.gender,
    location: userData.location,
    site: userData.site,
    sponsor_values: userData.sponsor_values,
    nsfw: userData.nsfw,
  }), [userData]);

  const [changed, setChanged] = useState(false);
  const [values, setValues] = useState<any>(initialValues);
  const [showPH, setShowPH] = useState<boolean>(true);

  const changeHandler = (value: any, key: string) => {
    if (values[key] === value) return;
    !changed && setChanged(true);
    setValues({ ...values, [key]: value });
  };

  const changeHandlerArray = (value: number, index: number) => {
    !changed && setChanged(true);
    const newValues:any = values;
    newValues.sponsor_values[index] = value;
    setValues({ ...newValues });
  };

  const handleSubmit = async (obj: any) => {
    const res = await apiRequest(editProfileCFG, obj);
    userToStorage(res.data.profile);
    !!res.data?.profile && updateUser(res.data.profile);
    ShowToast('Данные успешно обновлены', 'success');
    setChanged(false);
  };
  return (
    <div className="settingBox_box">
      <div className="settingBox_box_userId">
        <div className={`settingBox_box_userId_line settingBox_box_userId_line--${activeTheme}`}>
          <h1>Ваш ID пользователя:</h1>
          <h1>{id}</h1>
        </div>
        <span className={`note note_${activeTheme}`}>
          ID пользователя может потребоваться для совершения операций по обмену/покупке/продаже цифровых токенов на биржах.
        </span>
      </div>
      {fields.map(el => (
        <div className="settingBox_box_field" key={el.id}>
          <span className={`label label--${activeTheme}`}>{el.label}</span>
          <Input onChange={(value: any) => changeHandler(value, el.stateName)} pValue={values[el.stateName]} placeholder={el.placeholder} />
        </div>
      ))}
      <div className="settingBox_box_field">
        <span className={`label label--${activeTheme}`}>Отображение приватных постов</span>
        <div className="w100">
          <CustomSelect
            optionsList={showType}
            isSearchable={false}
            selected={showType.filter(x => x.value === values.nsfw)}
            placeholder="Не выбрано"
            className="settingBox_box_field_select"
            onChange={(o:any) => changeHandler(o.value, 'nsfw')}
            styles={{
              control: styles => ({
                ...styles,
                boxShadow: '0px 2px 3px -1px rgb(0 0 0 / 10%)',
                borderColor: 'rgba(0, 0, 0, 0.15)',
                cursor: 'pointer',
                ':hover': { borderColor: 'rgba(0, 0, 0, 0.15)' },
              }),
              option: (styles, { isFocused, isSelected }) => ({
                ...styles,
                backgroundColor: isFocused ? 'rgba(0, 0, 0, 0.15)' : isSelected ? 'rgba(0, 0, 0, 0.15)' : 'unset',
                cursor: 'pointer',
                ':hover': { backgroundColor: activeTheme === 'dark' ? 'rgba(255, 255, 255, 0.09) !important' : '#ededed' },
              }),
              singleValue: styles => ({
                ...styles,
                color: activeTheme === 'dark' ? '#fff' : '#222',
              }),
            }}
          />
        </div>
      </div>
      <div className="settingBox_box_field w100">
        <span className={`label label--${activeTheme}`}>О себе</span>
        <div className="settingBox_aboutSelf_box" onBlur={() => setShowPH(true)} onFocus={() => setShowPH(false)}>
          <span className={`settingBox_aboutSelf_placeholder settingBox_aboutSelf_placeholder--${values.about.length || !showPH ? 'hide' : 'show'}`}>
            Напишите коротко о себе
          </span>
          <MarkdownEditor
            toolbars={[]}
            toolbarsMode={[]}
            className={`settingBox_aboutSelf settingBox_aboutSelf--${activeTheme} ${activeTheme === 'dark' ? 'whiteText' : ''}`}
            options={{ lineNumbers: false, lineWrapping: true, defaultStyle: 'font-size: 15px; font-family: Roboto, sans-serif' }}
            preview={false}
            value={values.about}
            // Note: not defined types of this arguments in lib
            onChange={(editor: any, data: any, value: string) => changeHandler(value, 'about')}
          />
        </div>
      </div>
      <div className="settingBox_box_field w100">
        <span className={`label label--${activeTheme}`}>Выберите сумму для кнопок спонсирования</span>
        <div className={`settingBox_box_rowInputs settingBox_box_rowInputs--${screenWidth > MOBILE_POINT_MENU ? 'desktop' : 'mobile'}`}>
          {values.sponsor_values.map((el:number, index: number) => (
            <Input type="number" onChange={(value: number) => changeHandlerArray(Number(value), index)} pValue={el} placeholder="0" />
          ))}
        </div>
        <ModalNote text={`Обратите внимание, что минимальная сумма спонсирования составляет - ${fundConfigProxy.minamount}`} />
      </div>
      <div className="settingBox_box_field" style={{ width: screenWidth > MOBILE_POINT_MENU ? '118px' : '100%' }}>
        <Button
          title="Обновить"
          secondary={!changed}
          action={() => changed && handleSubmit(values)}
        />
      </div>

    </div>
  );
});

export default EditProfile;
