import moment from 'moment';
import { History } from 'history';
import apiRequest from 'services/Api';
import { getCaptchaCFG, checkCaptchaCFG } from 'settings/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LINK_PATTERN } from 'settings/main';

import { FilterPosts } from '../types';

const eosjsAccountName = require('eosjs-account-name');

interface IJWT{
  exp: number;
  iss: string;
  jti: string;
  l: string;
  n: string;
  r: string;
  sub: string;
}

interface IToastOptions{
  position: 'top-center'| 'top-right' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left' | undefined;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: any;
}

export const ShowToast = (content: string | JSX.Element, type: 'error' | 'info' | 'success' | 'warn', hideAfter: number = 4, opt: any = {}) => {
  const types = {
    error: toast.error,
    info: toast.info,
    success: toast.success,
    warn: toast.warn,
  };

  const options: IToastOptions = {
    autoClose: hideAfter * 1000,
    position: 'top-center',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  types[type](content, { ...options, ...opt });
};

export const getCaptcha = async () => {
  const capID = await apiRequest(getCaptchaCFG);

  return capID.data.captcha_id;
};

export const checkCaptcha = async (id: string, value: string) => {
  const response = await apiRequest(checkCaptchaCFG, { captcha: value, captcha_id: id });

  return response.data.status === 'ok';
};

export const cleanDate = (date:string) => {
  const d = new Date(date);
  let curr_date = (d.getDate()).toString();
  let curr_month = (d.getMonth() + 1).toString();
  const curr_year = d.getFullYear();
  if (+curr_date < 10) curr_date = `0${curr_date}`;
  if (+curr_month < 10) curr_month = `0${curr_month}`;

  return (`${curr_date}.${curr_month}.${curr_year}`);
};

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const startIndex = b64Data.indexOf('base64,') + 7;
  const b64 = b64Data.substr(startIndex);
  const byteCharacters = atob(b64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

// INVALID UUID RTODO FIX!!!!!
export const getPostIdFromLink = (history?: History): { id: string; author: string } => {
  const { pathname } = (history || window).location;

  const isPostOrEditPage = pathname.includes('post/') || pathname.includes('edit/');
  const splittedPath = pathname.split('/');

  return {
    id: isPostOrEditPage ? splittedPath[3] : '',
    author: isPostOrEditPage ? splittedPath[2] : '',
  };
};

export const checkLocation = (value: string) => window.location.pathname.split('/').includes(value);
export const checkIsBlog = (history?: History) => {
  const splittedPath = (history || window).location.pathname.split('/').filter((el: string) => el !== '');
  const {
    actual, popular, feed, newPosts, nft,
  } = FilterPosts;

  return (splittedPath.length === 1 && splittedPath[0] !== nft)
    || (splittedPath.length === 2 && ![actual, popular, feed, newPosts].includes(<FilterPosts>splittedPath[1]));
};

const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const TIME_FORMAT_STR = 'DD MMMM';

export const getUTCTime = (): string => moment().utc().format(TIME_FORMAT);
export const getUTCTimeToShow = (tUTC: any): string => moment.utc(tUTC, TIME_FORMAT).fromNow();
export const getUTCTimeToShowStr = (tUTC: string): string => moment.utc(tUTC, TIME_FORMAT).format(TIME_FORMAT_STR);

export const timestamptToData = (time: string): string | any => {
  if (time === '18446744073709551615') return;
  const date = new Date(Number(time) / 1000);
  return date.toISOString();
};

export const urlToFile = async (src: string) => {
  const response = await fetch(src);
  const blob = await response.blob();
  return new File([blob], 'image.jpg', { type: blob.type });
};

export const fileToBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const dataURLtoFile = (dataUrl: string, filename: string = ''): File => {
  const arr = dataUrl.split(',');
  const mimeType = arr[0].match(/:(.*?);/);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mimeType ? mimeType[1] : 'image/png' });
};

export const getArrLastElem = (array: any[]) => (array?.length ? array[array.length - 1] : {});

export const uint64 = (str: string) => BigInt(eosjsAccountName.nameToUint64(str));

export const uint64ToString = (uint: any) => eosjsAccountName.uint64ToName(uint);

export const parseJwt = (token: string) : null | IJWT => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const firtsUppercase = (value: string) => value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();

export const sortASC = (array:[], key:string) => array.sort((a, b) => a[key] - b[key]);

export const sortDESC = (array:[], key:string) => array.sort((a, b) => b[key] - a[key]);

export const sortByDateASC = (array:[], key:string) => array.sort((a: any, b:any) => new Date(a[key]).getTime() - new Date(b[key]).getTime());

export const sortByDateDESC = (array:[], key:string) => array.sort(((a: any, b:any) => new Date(b[key]).getTime() - new Date(a[key]).getTime()));

export const uniqueArrayFromString = (str: string) => [...new Set(str.split(',').map((el: string) => el.trim().toLocaleLowerCase()))].filter((el:string) => el.length !== 0 && !LINK_PATTERN.test(el));

export const resizeImages = (images: NodeListOf<HTMLImageElement>, resizableWidth: number) => new Promise(resolve => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!images.length) {
    resolve(images);
  }

  images.forEach((item, index) => {
    if (!ctx) return;

    const { width, height } = item;

    if (width > resizableWidth || height > resizableWidth) {
      const ratio = Math.min(resizableWidth / width, resizableWidth / height);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const newWidth = ratio * width;
      const newHeight = ratio * height;

      canvas.width = newWidth;
      canvas.height = newHeight;

      ctx.drawImage(item, 0, 0, newWidth, newHeight);

      let newSrc = item.src;

      try {
        newSrc = canvas.toDataURL();
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.log(err?.message);
      }

      item.setAttribute('src', newSrc);

      if (images.length - 1 === index) {
        canvas.remove();
        resolve(images);
      }
    } else {
      resolve(images);
    }
  });
});

// eslint-disable-next-line
const urlRegex = /((http(s)?(\:\/\/))?(www\.)?([\a-zA-Z0-9-_\.\/])*(\.[a-zA-Z]{2,3}\/?))([\a-zA-Z0-9-_\/?=@&#])*(?!(.*a>)|(\'|\"))/g;
const youtubeUrlRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
const vimeoUrlRegex = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;

export function getYoutubeVideoId(url: string) {
  const match = url.match(youtubeUrlRegex);

  return (match && match[2].length === 11) ? match[2] : null;
}

export function getVimeoVideoId(url: string) {
  const match = url.match(vimeoUrlRegex);

  return match && match[2] ? match[2] : null;
}

export function urlify(text: string) {
  return text.replace(urlRegex, url => {
    if (url.includes('youtube')) {
      const youtubeVideoId = getYoutubeVideoId(url);
      // eslint-disable-next-line max-len
      return youtubeVideoId ? `<div class="se-component se-video-container __se__float-none" contenteditable="false" style="width: 100%;"><figure style="width: 100%; height: 56.25%; padding-bottom: 56.25%; margin: 0;"><iframe allowfullscreen="" src="https://www.youtube.com/embed/${youtubeVideoId}" data-percentage="100%,56.25%" data-size="100%,56.25%" data-align="none" style="width: 100%; height: 100%;" data-origin="100%,56.25%"></iframe></figure></div>` : url;
    }

    if (url.includes('vimeo')) {
      const vimeoVideoId = getVimeoVideoId(url);
      // eslint-disable-next-line max-len
      return vimeoVideoId ? `<div class="se-component se-video-container __se__float-" contenteditable="false" style="width: 100%;"><figure style="width: 100%; height: 56.25%; padding-bottom: 56.25%; margin: 0;"><iframe src="https://player.vimeo.com/video/${vimeoVideoId}" allowfullscreen="" data-percentage="100%,56.25%" data-size="100%,56.25%" data-align="" data-index="0" data-origin="100%,56.25%" style="width: 100%; height: 100%;"></iframe></figure></div>` : url;
    }

    if (url.includes('w.soundcloud')) {
      return url;
    }

    return `<a class="on" href="${url}" target="_self">${url}</a>`;
  });
}

export const setDocumentCopiedText = (str: string) => {
  const textarea = document.createElement('textarea');
  document.body.appendChild(textarea);
  textarea.value = str;
  textarea.select();
  document.execCommand('copy', false);
  textarea.remove();
};
