import { useEffect } from 'react';

import BlogList from 'pages/components/BlogList';
import Announcement from 'UI/Announcement';
import InfoBlock from 'UI/InfoBlock';
// import UserCard from 'UI/UserCard';
import ContentWrapper from 'components/Content/ContentWrapper';
// import templateData from 'pages/Feed/data';
import { useHistory } from 'react-router-dom';
import { FilterPosts } from 'types';
// import CommentsList from 'pages/components/CommentsList';
import NewListComments from 'pages/components/NewListComments';
import SocialIcons from 'UI/SocialIcons';
import NFTList from 'pages/components/NFTList';
import MainStore, { IMetaData, ThemeStore } from 'store';
import { observer } from 'mobx-react';
import LaroundList from 'UI/LaroundList';
import apiRequest from 'services/Api';
import { getLaroundsCFG } from 'settings/api';
import { useMount } from 'hooks';
import moment from 'moment';
import { ReactComponent as Fire } from 'assets/images/fire2.svg';

const Feed = () => {
  const { setMetaDataStore, addLaroundEvents, randomEvents } = MainStore;
  const { activeTheme } = ThemeStore;
  const history = useHistory();

  const roundParams = {
    // count: 4,
    age: moment().unix() - (moment().unix() - 24 * 60 * 60),
  };

  useMount(() => {
    apiRequest({ ...getLaroundsCFG, params: roundParams })
      .then(res => {
        if (randomEvents.length) return;
        addLaroundEvents(res?.data.result);
      })
      .catch();
  });

  const rightBlockContent = (
    <>
      {
        randomEvents?.length > 0 ? (
          <InfoBlock title="Финальные тиражи" extra={<Fire style={{ fill: activeTheme === 'dark' ? '#FF7100' : '#fff', height: '20px' }} />}>
            <LaroundList />
          </InfoBlock>
        ) : null
      }

      <InfoBlock title="Анонсы">
        <Announcement />
      </InfoBlock>
      <SocialIcons />
    </>
  );
  useEffect(() => {
    const pData: IMetaData = {
      title: '',
      description: '',
    };
    switch (history.location.pathname) {
      case '/posts/actual':
        pData.title = 'Актуальное';
        pData.description = 'Посты с активными фондами мецената';
        break;
      case '/posts/popular':
        pData.title = 'Популярные';
        pData.description = 'Популярные посты';
        break;
      case '/posts/new':
        pData.title = 'Новые произведения';
        pData.description = 'Самые новые произведения наших авторов';
        break;
      case '/nft':
        pData.title = 'Лента NFT';
        pData.description = 'Список постов NFT токены которых были куплены';
        break;
      case '/comments':
        pData.title = 'Комментарии';
        pData.description = 'Комментарии наших авторов';
        break;
      default:
        break;
    }
    setMetaDataStore(pData);
    apiRequest({ ...getLaroundsCFG, params: roundParams })
      .then(res => {
        if (randomEvents.length) return;
        addLaroundEvents(res?.data.result);
      })
      .catch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, setMetaDataStore]);

  const isComments = history.location.pathname.includes(FilterPosts.comments);
  const isNFT = history.location.pathname.includes('/nft');

  return (
    <ContentWrapper
      leftBlock={isComments ? <NewListComments /> : isNFT ? <NFTList /> : <BlogList />}
      rightBlock={rightBlockContent}
    />
  );
};

export default observer(Feed);
