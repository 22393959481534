import { observer } from 'mobx-react';
import Amount from 'components/Amount';
import { timeAgo, usersName } from 'helpers';
import { Link } from 'react-router-dom';
import { ThemeStore } from 'store';

import { IAwardsLine } from '..';

const AwardsLine = ({
  time,
  name,
  user_names,
  content_guid,
  content_title,
  event,
}: IAwardsLine) => {
  const { activeTheme } = ThemeStore;

  return (
    <div className={`walletAwards_line walletAwards_line--${activeTheme}`} key={`${time}-${name}`}>
      <div className="walletAwards_line_info">
        <div className="walletAwards_line_titles">
          <span>От </span>
          <span className="walletAwards_line_link">
            <Link to={`/${user_names.cyber}/blog`} className="normalText">
              {usersName(user_names)}
            </Link>
          </span>
          <span> за пост </span>
          <span className="walletAwards_line_link">
            <Link to={`/post/${user_names.cyber}/${content_guid.String}`} className="titleStyle">
              {`"${content_title.String}"`}
            </Link>
          </span>
        </div>
        <span>{timeAgo(time)}</span>
      </div>
      <Amount amount={event.award.split(' ')[0]} fontSize={20} classname="flex1" />
    </div>
  );
};
export default observer(AwardsLine);
