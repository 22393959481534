import { useState, useEffect, useRef } from 'react';

import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ThemeStore } from 'store';
import BurgerMenu from 'UI/BurgerMenu';
import './style.scss';

interface IProfileMenuProps {
    list: any;
    prefix: string;
    onChange?: (d: string) => void;
}

const ProfileMenu = ({ list, prefix }: IProfileMenuProps) => {
  const { activeTheme } = ThemeStore;
  const refButton = useRef<any>(null);
  const [activeName, setActiveName] = useState<string>(list[0].name);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      document.body.addEventListener(
        'click',
        ({ target }) => (refButton.current && !refButton.current.contains(target)) && setOpen(false),
        { once: true },
      );
    }
  }, [open]);

  return (
    <div className={`profileMenu profileMenu--${activeTheme}`} ref={refButton}>
      <div className="profileMenu_headBox" onClick={() => setOpen(!open)}>
        <div>
          {activeName}
        </div>
        <BurgerMenu onClick={setOpen} open={open} />
      </div>
      <div className={`profileMenu_body shadow profileMenu_body--${activeTheme} profileMenu_body--${open ? 'open' : 'closed'}`}>
        {list.map((el:any): JSX.Element => (
          <NavLink
            key={el.link}
            to={prefix ? prefix + el.link : el.link}
            onClick={() => { setOpen(false); setActiveName(el.name); }}
            activeClassName="profileMenu_activeLink"
          >
            {el.name}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
export default observer(ProfileMenu);
