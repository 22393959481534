interface IField {
  id: number;
  label: string;
  stateName: string;
  placeholder: string;
}

export const fields: IField[] = [{
  id: 1,
  label: 'Отображаемое имя',
  stateName: 'alias',
  placeholder: 'Введите имя',
}, {
  id: 2,
  label: 'Веб-сайт',
  stateName: 'site',
  placeholder: 'Не выбрано',
}, {
  id: 3,
  label: 'Местоположение',
  stateName: 'location',
  placeholder: 'Не выбрано',
}];

interface ISettingsMenu {
  name: string;
  link: string;
}

export const settingsMenu: ISettingsMenu[] = [{
  name: 'Редактирование профиля',
  link: 'edit-profile',
}, {
  name: 'Разрешения',
  link: 'permissions',
}, {
  name: 'NFT',
  link: 'nft-settings',
}];

interface IKeyList {
  title: string;
  type: string;
  note: string;
  button?: boolean;
  reRelease?: boolean;
}

export const keyList: IKeyList[] = [{
  title: 'АКТИВНЫЙ КЛЮЧ',
  type: 'active',
  note: 'Активный ключ используется для переводов и размещения заказов на внутреннем рынке.',
}, {
  title: 'КЛЮЧ ВЛАДЕЛЬЦА',
  type: 'owner',
  note: 'Ключ владельца это главный ключ ко всему аккаунту, он необходим для изменения других ключей. Приватный ключ должен храниться в оффлайне насколько это возможно.',
},
{
  title: 'ПОСТИНГ КЛЮЧ',
  type: 'posting',
  note: 'Постинг ключ используется для постинга и спонсирования. Он должен отличаться от активного ключа и ключа владельца..',
  reRelease: true,
}];

interface IPermList {
  id: number;
  title: string;
  type: string;
  code: string;
}

export const permList: IPermList[] = [
  { id: 1, title: 'Публикация постов на stihi.io', code: 'stihi.patron', type: 'reg' },
  { id: 2, title: 'Спонсирование постов на stihi.io', code: 'stihi.patron', type: 'contribute' },
  { id: 3, title: 'Публиковать постов на golos.io', code: 'gls.publish', type: '*' },
  { id: 4, title: 'Лайкинг постов на golos.io', code: 'gls.social', type: '*' },
  { id: 5, title: 'Покупка NFT постинг ключем', code: 'stihi.patron', type: 'buynft' },
];

export const showType = [
  { value: 'h', label: 'Всегда скрывать' },
  { value: 's', label: 'Всегда отображать' },
  { value: 'w', label: 'Всегда предупреждать' },
];
