import { ReactElement } from 'react';

import { ReactComponent as CheckIcon } from 'assets/images/check-icon.svg';
import './style.scss';

interface ICheckBox {
    checkBoxHandler?: (checked: boolean) => void;
    label?: string;
    disabled?: boolean;
    className?: string;
    checked?: boolean;
}

const Checkbox = ({ checkBoxHandler, label, disabled, className, checked }: ICheckBox): ReactElement => {
  const createMarkup = (str:any) => ({ __html: str });

  return (
    <div className={`customCheckBox ${disabled ? 'customCheckBox--disabled' : ''} ${className}`}>
      <label>
        <input
          type="checkbox"
          disabled={disabled}
          onChange={e => checkBoxHandler && checkBoxHandler(e.target.checked)}
          checked={checked}
        />
        <span><CheckIcon /></span>
        <div className="customCheckBox_label" dangerouslySetInnerHTML={createMarkup(label)} />
      </label>
    </div>
  );
};

export default Checkbox;
