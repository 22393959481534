export interface IBanReason {
    label: string;
    value: string;
}

export const banReason: IBanReason[] = [
  {
    label: 'Спам',
    value: 'Спам',
  },
  {
    label: 'Публикация не соответствует тематике  Stihi.io',
    value: 'Публикация не соответствует тематике  Stihi.io',
  },
  {
    label: 'Нарушение п. 2.1. Пользовательского  Соглашения (плагиат)',
    value: 'п. 2.1. Пользователь Портала Stihi.io  должен строго соблюдать авторские права, быть автором или законным правообладателем публикуемых им на Портале произведений, либо иметь разрешение от законных правообладателей на их публикацию', // eslint-disable-line max-len
  },
  {
    label: 'Нарушение п. 3.1. Пользовательского  Соглашения.',
    value: 'п. 3.1  … Запрещается использовать в качестве логина нецензурные или ругательные слова',
  },
  {
    label: 'Нарушение п. 3.2. Пользовательского  Соглашения.',
    value: 'п. 3.2 … Запрещается использовать в качестве псевдонима нецензурные или ругательные слова.',
  },
  {
    label: 'Нарушение п. 4.4. Пользовательского  Соглашения.',
    value: 'п. 4.4. Категорически запрещается использование нецензурных и ругательных слов в заголовках произведений.',
  },
];
