import { createCommentsStructure } from 'helpers';
import {
  observable, decorate, action, computed, toJS,
} from 'mobx';
import { IComment, IComments } from 'types';

type GetMoreType = {
  [key: string]: IComment[];
}

class Store {
  commentsListProxy: IComments = {
    cursor: '',
    list: [],
    loaded: false,
  };

  dropMoreId: string = '';

  updateMore: number = 0;

  updateMoreHandle = () => this.updateMore = Math.random() * Math.random();

  loadingMore: string[] = [];

  loadingHandler = (guid: string, drop: boolean = false) => {
    let newArray = toJS(this.loadingMore);
    if (!drop) {
      newArray.push(guid);
    } else {
      newArray = newArray.filter((el: string) => el !== guid);
    }
    this.loadingMore = newArray;
  }

  getMoreCommentsProxy: GetMoreType = {} as GetMoreType

  get getMoreComments() {
    return toJS(this.getMoreCommentsProxy);
  }

  commentEdit: string = '';

  commentEditHandler = (guid: string) => this.commentEdit = guid;

  setGetMore = (key: string, list: IComments) => {
    this.getMoreCommentsProxy = {
      ...this.getMoreCommentsProxy,
      [key]: list.list,
    };
    this.updateMoreHandle();
    this.loadingHandler(key, true);
  }

  isOpenMore = (guid: string | undefined) => (guid ? !!this.getMoreCommentsProxy[guid] : false);

  dropOneMore = (guid: string | undefined) => {
    if (!guid) return;
    const list = toJS(this.getMoreCommentsProxy);
    delete list[guid];
    this.dropMoreId = guid;
    this.getMoreCommentsProxy = { ...list };
  }

  dropMoreIdHandle = () => this.dropMoreId = '';

  dropGetMore = () => {
    this.getMoreCommentsProxy = {};
  }

  get commentList() {
    return toJS(this.commentsListProxy);
  }

  setCommentList = ({ list, cursor }: IComments) => {
    const internalComments = this.commentsListProxy.list.filter(item => item.isInternal);
    const listNewStructure = createCommentsStructure(list);

    this.commentsListProxy = {
      cursor,
      list: [...listNewStructure, ...internalComments],
    };
  }

  addComments = ({ list, cursor }: IComments) => {
    const listNewStructure = createCommentsStructure(list);
    const internalComments = this.commentList.list.filter(item => item.isInternal);
    const oldList = toJS(this.commentsListProxy);
    this.commentsListProxy = {
      cursor,
      list: [...oldList.list, ...listNewStructure, ...internalComments],
      loaded: true,
    };
  }

  addNewCommentToList = (data: IComment, level: number) => {
    if (level < 2) {
      const d = { ...this.commentList };
      const index = d.list.findIndex((x: IComment) => x.id === data.parentGUID);
      d.list[index].comments.push(data);
      this.commentsListProxy = d;
    } else {
      const l = { ...this.getMoreCommentsProxy };
      if (l[data.parentGUID]) {
        l[data.parentGUID].push(data);
      } else {
        l[data.parentGUID] = [data];
      }
      this.getMoreCommentsProxy = l;
    }
  }

  dropCommentsList = () => {
    this.commentsListProxy = { cursor: '', list: [], loaded: false };
    // LocalStorage.set('comments', this.comments);
  }
}

decorate(Store, {
  commentsListProxy: observable,
  setCommentList: action,
  commentList: computed,
  dropCommentsList: action,
  getMoreComments: computed,
  getMoreCommentsProxy: observable,
  dropMoreId: observable,
  commentEdit: observable,
  updateMore: observable,
  loadingMore: observable,
  loadingHandler: action,
});

const CommentsStore = new Store();

export default CommentsStore;
