import { useEffect, useMemo } from 'react';

import './style.scss';
import { ReactComponent as CurrIcon } from 'assets/images/currIcon.svg';
// import Preloader from 'components/Preloader';
import MainStore, { ModalStore, ThemeStore } from 'store';
import * as s from 'settings/modal';
import { observer } from 'mobx-react';
import Button from 'UI/Button';
import BalanceLine from 'pages/Profile/Wallet/components/BalanceLine';
import {
  cleanDate,
  getUTCTimeToShow,
  timestamptToData,
  declOfNum,
} from 'helpers';
import { contractAdress } from 'settings/main';
import { useUnMount } from 'hooks';
import contractActionPipeline from 'Pipelines/contractActionPipeline';

interface IBalancesProps {
  id: string;
}

// TODO MAKE SOFT RERENDER
const Balances = observer(({ id }: IBalancesProps): JSX.Element => {
  const {
    wamount,
    userData,
    swamount,
    checkIsUser,
    safeBalance,
    userBalance,
    patronBalance,
    safeWithdrawDate,
    dropUserBalances,
    patronWithdrawDate,
    userBalanceLoading,
    contractBalancesLoading,
    fundConfigProxy,
  } = MainStore;
  const { modalControl } = ModalStore;
  const { activeTheme } = ThemeStore;

  useEffect(() => {
    MainStore.setUserBalances(id);
  }, [id]);

  const initValues = useMemo(() => ({
    sender: userData.cyber_name,
    recipient: contractAdress,
    token: 'SAVVA',
  }), [userData]);

  const savvaBalance = useMemo(() => ([
    { name: 'Передать', action: '', function: () => modalControl(s.sendTokens, { sender: userData.cyber_name, token: 'SAVVA' }) },
    { name: 'Перевести в  Фонд Мецената', action: '', function: () => modalControl(s.sentToPatron, initValues) },
    { name: 'Обменять', action: '', link: `https://cw.finance/?account=${userData.cyber_name}&pair=cw.pcybsav` },
  ]), [modalControl, initValues, userData.cyber_name]);

  const patronBalanceAM = useMemo(() => ([
    (!(wamount > 0) && { name: 'Уменьшить Фонд Мецената', action: '', function: () => modalControl(s.receiveFromPatron, { ...initValues, action: 'withdraw' }) }),
    (patronBalance > 0 && { name: 'Перевести в сейф', action: '', function: () => modalControl(s.sendToStore, { ...initValues, action: 'safe' }) }),
  ]), [modalControl, initValues, patronBalance, wamount]);

  const safeBalanceAM = useMemo(() => ([
    { name: 'Вывести из сейфа', action: '', function: () => modalControl(s.receiveFromStore, { ...initValues, action: 'unsafe' }) },
  ]), [modalControl, initValues]);

  const balancesList = [
    {
      name: 'SAVVA',
      note: 'Перемещаемые цифровые токены, которые могут быть переданы куда угодно в любой момент. Например, конвертированы в Фонд Мецената или использованы для анонсирования публикаций.',
      icon: <CurrIcon />,
      amount: userBalance.SAVVA || '0.0000',
      menuItems: savvaBalance,
      menuHide: !(+userBalance.SAVVA > 0),
      isLoading: userBalanceLoading,
    },
    {
      name: 'Фонд Мецената',
      icon: <CurrIcon />,
      // eslint-disable-next-line max-len
      note: 'Баланс, с которого вы вознаграждаете авторов, и на который получаете вознаграждения сами.  Чем больше SAVVA в вашем Фонде, тем больше у вас возможностей для вознаграждения авторов и получения наград.',
      amount: patronBalance.toFixed(4),
      menuItems: patronBalanceAM,
      menuHide: !(patronBalance > 0),
      isLoading: contractBalancesLoading,
    },
    {
      name: 'Сейф',
      note: `Вывод токенов из сейфа занимает ${fundConfigProxy.unsafedays} ${declOfNum(fundConfigProxy.unsafedays, ['день', 'дня', 'дней'])}.`,
      icon: <CurrIcon />,
      amount: safeBalance.toFixed(4),
      menuItems: safeBalanceAM,
      menuHide: !(safeBalance > 0) || swamount > 0,
      isLoading: contractBalancesLoading,
    },
  ];

  useUnMount(() => dropUserBalances());

  return (
    <>
      <div className={`walletBalances walletBalances_${activeTheme}`}>
        {balancesList.map(el => (
          <BalanceLine {...el} id={id} activeTheme={activeTheme} key={el.name} isLoading={el.isLoading} />
        ))}
        {wamount > 0 && (
          <div className={`walletBalances_line walletBalances_line_${activeTheme}`}>
            <div className={`walletBalances_line_title walletBalances_line_title_${activeTheme}`}>
              <span>
                <strong>{(wamount / 10000).toFixed(4)}</strong>
                {' '}
                SAVVA находятся в процессе вывода из фонда Мецената и будут добавлены на основной баланс
                {' '}
                {cleanDate(timestamptToData(patronWithdrawDate))}
                , через
                {' '}
                {getUTCTimeToShow(timestamptToData(patronWithdrawDate))}
              </span>
              <div className={`walletBalances_line_title_amount walletBalances_line_title_amount_${activeTheme}`}>
                {checkIsUser(id)
                && (
                  <Button
                    title="Отменить"
                    secondary
                    action={() => modalControl({ ...s.signTransactionModal, pipeline: contractActionPipeline }, { sender: userData.cyber_name, action: 'cancelwd' })}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {swamount > 0 && (
          <div className={`walletBalances_line walletBalances_line_${activeTheme}`}>
            <div className={`walletBalances_line_title walletBalances_line_title_${activeTheme}`}>
              <span>
                <strong>{(swamount / 10000).toFixed(4)}</strong>
                {' '}
                SAVVA находятся в процессе вывода из сейфа и будут добавлены на баланс Фонда Мецената
                {' '}
                {cleanDate(timestamptToData(safeWithdrawDate))}
                ,
                {' '}
                {getUTCTimeToShow(timestamptToData(safeWithdrawDate))}
              </span>
              <div className={`walletBalances_line_title_amount walletBalances_line_title_amount_${activeTheme}`}>
                {checkIsUser(id)
                && (
                  <Button
                    title="Отменить"
                    secondary
                    action={() => modalControl({ ...s.signTransactionModal, pipeline: contractActionPipeline }, { sender: userData.cyber_name, action: 'cancelus' })}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={`walletBalances_note walletBalances_note_${activeTheme}`}>
        <span>
          {/* eslint-disable-next-line max-len */}
          Команда Stihi.io не несет ответственности за операции пользователей по обмену/покупке/продаже цифровых токенов. Все операции по обмену/покупке/продаже цифровых токенов пользователи совершают самостоятельно.
          {/* Токены CYBER торгуются на CoinGecko, CoinMarketCap. */}
        </span>
      </div>
    </>
  );
});

export default Balances;
