import { SunEditorOptions } from 'suneditor/src/options';

export const editorConfig: SunEditorOptions = {
  mode: 'balloon',
  rtl: false,
  width: '100%',
  height: '100%',
  minHeight: '330px',
  defaultStyle: 'font-size: 16px; line-height: 1.56; font-family: Roboto, sans-serif',
  fontSize: [10, 13, 19, 35],
  formats: [
    { tag: 'p', name: 'Текст', command: 'replace', class: '' },
    { tag: 'h1', name: 'Заголовок 1', command: 'replace', class: '' },
    { tag: 'h2', name: 'Заголовок 2', command: 'replace', class: '' },
    { tag: 'h3', name: 'Заголовок 3', command: 'replace', class: '' },
  ],
  videoResizing: false,
  videoHeightShow: false,
  videoFileInput: false,
  videoRatioShow: false,
  audioUrlInput: false,
  tabDisable: false,
  placeholder: 'Текст',
  mediaAutoSelect: false,
  lineHeights: [{
    text: '1',
    value: 1,
  }, {
    text: '2',
    value: 2,
  }],
  buttonList: [[
    '-right', ':i-More Misc-default.more_vertical',
    'formatBlock',
    'fontSize',
    'list',
    'table',
    'fontColor',
    'hiliteColor',
    'horizontalRule',
    'removeFormat',
    'outdent',
    'indent',
    'undo',
    'redo',
  ], [
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'link',
    'blockquote',
    'image',
    'video',
  ]],
};

export enum EditorTypes {
  markdown = 'markdown',
  visual = 'visual',
}

interface IEditorTypesData {
  id: number;
  name: string;
  type: EditorTypes;
}

export const editorTypesData: IEditorTypesData[] = [{
  id: 1,
  name: 'Визуальный редактор',
  type: EditorTypes.visual,
}, {
  id: 2,
  name: 'Markdown редактор',
  type: EditorTypes.markdown,
}];
