import { useEffect } from 'react';

import { getUserAccountBalances } from 'helpers';
import { observer } from 'mobx-react';
import MainStore, { ModalStore, ThemeStore } from 'store';
import Input from 'UI/InputText';
import SearchForm from 'UI/SearchForm';
import checkFields from 'utils/checkFields';
import reqFields from 'settings/requiredFields';
import InputPassword from 'UI/InputPassword';
import ModalNote from 'UI/ModalNote';
import ModalLine from 'UI/Modal/ModalLine';

const correctiveStyles = {
  display: 'flex',
  width: '70%',
};
const balanceStyle = {
  color: '#337DB2',
  fontWeight: 700,
  cursor: 'pointer',
};

const SendToUSer = observer(() => {
  const { modalValues, changeHandler } = ModalStore;
  const { activeTheme } = ThemeStore;
  const { isBlocked, userData: { cyber_name } } = MainStore;
  const sr = (data: any) => changeHandler(data !== cyber_name ? data : false, 'recipient');

  useEffect(() => {
    if (modalValues.balance) return;

    getUserAccountBalances(cyber_name, modalValues.token || 'SAVVA').then(res => changeHandler(res[modalValues.token || 'SAVVA'] || 0, 'balance'));
  }, [changeHandler, cyber_name, modalValues.balance, modalValues.token]);

  useEffect(() => {
    checkFields(modalValues, reqFields.sendAtoA);
  }, [modalValues]);

  return (
    <div>
      <ModalLine>
        <SearchForm searchResult={(val: any) => sr(val)} />
      </ModalLine>
      <ModalLine noteLeft="ID">
        <div className={`note note_${activeTheme}`} style={{ paddingLeft: '10px', marginRight: 'auto' }}>
          {modalValues.recipient || 'Неизвестный пользователь'}
        </div>
      </ModalLine>
      <ModalLine noteLeft="Количество">
        <div style={correctiveStyles}>
          <Input
            type="number"
            onChange={(val: any) => changeHandler(modalValues.val && modalValues.val < 0 ? val.slice(1) : val > +modalValues.balance ? +modalValues.balance : val, 'amount')}
            pValue={modalValues.amount}
            noteRight={modalValues.token || 'SAVVA'}
          />
        </div>
      </ModalLine>

      <ModalLine noteLeft="Баланс">
        <div style={correctiveStyles} onClick={() => changeHandler(modalValues.balance, 'amount')}>
          <div style={balanceStyle}>{`${modalValues.amount ? (+modalValues.balance - +modalValues.amount).toFixed(4) : (modalValues.balance || 0.0000)} ${modalValues.token || 'SAVVA'}`}</div>
        </div>
      </ModalLine>

      <ModalLine noteLeft=" ">
        <div style={correctiveStyles}>
          <ModalNote text="Эта заметка является публичной" />
        </div>
      </ModalLine>

      <ModalLine noteLeft="Заметка">
        <div style={correctiveStyles}>
          <Input
            onChange={(val: any) => changeHandler(val, 'memo')}
            pValue={modalValues.memo}
            disabled={isBlocked}
            autoComplete={false}
            placeholder="Вы можете оставить комментарий"
          />
        </div>
      </ModalLine>

      <div className="modal_body_line">
        <InputPassword
          placeholder="Приватный ключ"
        />
      </div>
    </div>
  );
});

export default SendToUSer;
