import {
  getDataBody, resultMessage, SendActionData, ShowToast,
} from 'helpers';
import { sendTransaction } from 'services/Api';
import { SAVVA_CONTRACT } from 'settings/main';
import MainStore, { ModalStore } from 'store';
import { Pipeline } from 'store/PipelineStore';

const tokenTransferPipline: Pipeline = {
  title: 'tokenTransferPipline',
  tasks: [
    {
      title: 'Выполняем перевод токенов',
      task: async (meta: any) => {
        const { modalSettings: { tag } } = ModalStore;
        const currList = [
          { id: 1, name: 'CYBER', token: 'cyber.token' },
          { id: 2, name: 'SAVVA', token: SAVVA_CONTRACT },
        ];

        const contract = currList.find(x => x.name === meta.token)?.token || 'cyber.token';
        const data: SendActionData = {
          quantity: `${(+meta.amount).toFixed(4)} ${meta.token}`,
          to: meta.recipient,
          memo: meta.memo,
          from: meta.sender,
        };

        const sendingData = await getDataBody(meta.sender, contract, data, 'transfer');
        const result = await sendTransaction(meta.key, sendingData);
        ShowToast(resultMessage(tag || 'default', meta.amount, meta.token), 'success');

        return result;
      },
    },
    {
      title: 'Обновляем балансы',
      task: async () => {
        const { userData: { cyber_name }, setBlockchainAccount, setUserBalances } = MainStore;
        setBlockchainAccount(cyber_name);
        setUserBalances(cyber_name);
      },
    },
    {
      title: 'Закрыть модальное окно',
      task: async () => {
        const { modalControl } = ModalStore;
        modalControl();
      },
    },
  ],
};

export default tokenTransferPipline;
