import { useEffect, useCallback, useState } from 'react';

import MainStore, { ThemeStore } from 'store';
import { getFunds, getPostConfig } from 'helpers/node';
import { observer } from 'mobx-react';
import apiRequest from 'services/Api';
import {
  ShowToast,
  checkLocation,
  usersName,
  getBlockedArtsData,
  getPic,
} from 'helpers';
import {
  getEventsCFG,
  getActionsCFG,
  UPDATE_TIME,
  getLaroundsCFG,
} from 'settings/api';
import { IAwardsLine } from 'pages/Profile/Wallet/components/Awards';
import { ITransaction } from 'pages/Profile/Wallet/components/Transactions';
import { Link } from 'react-router-dom';

interface IEventsContainer {
  isAuth: boolean;
  isVisible: boolean;
}

const EventsContainer = ({ isAuth, isVisible }: IEventsContainer) => {
  const {
    userData,
    setUserBalances,
    activePost,
    postFundsObject,
    setPostFunds,
    setLastEvents,
    isAdmin,
    addLaroundEvents,
    setRandomize,
  } = MainStore;
  const { activeTheme } = ThemeStore;
  const [counter, setCounter] = useState<number>(UPDATE_TIME);
  const [updateTime, setupdateTime] = useState<number>(0);
  const toastTime = 7;

  const laraoundMethod = useCallback((data: any) => {
    const { content_title, content_guid, author, author_names } = data;
    // if (content_guid.String !== '8dcecf1f-ee61-4365-a84d-ed34a1a85198') return;
    ShowToast(
      <div style={{ display: 'flex', width: '340px' }}>
        <div
          className="blogLine_img"
          style={{ marginRight: '20px' }}
        >
          <img src={getPic(data.author_avatar)} alt="img_post" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <span style={{ fontSize: '12px' }}> Назначен финальный тираж 🔥</span>
          <span style={{ fontSize: '14px' }}>{author_names.alias}</span>
          <span className={`blogLine_box_preview--title blogLine_box_preview--title--${activeTheme}`} style={{ paddingBottom: 0, color: '#333' }}>
            {`"${content_title.String}"`}
          </span>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Link
              to={`/post/${author.String}/${content_guid.String}`}
              className={`walletPatron_linkColors walletPatron_linkColors_${activeTheme}`}
              style={{ fontSize: '14px', color: '#337DB2' }}
            >
              Участвовать
            </Link>
          </div>

        </div>
      </div>,
      'success', 10, { icon: false, position: 'bottom-right', progressClassName: 'fancy-progress-bar' },
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventsAction = useCallback((data:any) => {
    const isUserAction = data.event.user === userData.cyber_name;
    switch (data.name) {
      case 'donatevent':
        if (data.event.author === userData.cyber_name) {
          ShowToast(`Вы получили награду ${data.event.award} от ${usersName(data.user_names)} за пост "${data.content_title.String}".`, 'success', toastTime);
          checkLocation('balances') && setUserBalances(userData.cyber_name);
        }
        break;
      case 'cntrbtevent':
        data.event.author === userData.cyber_name && ShowToast(`Фонд Вашего поста "${data.content_title.String}" составляет ${data.event.balance}.`, 'success', toastTime);
        checkLocation(data.content_guid.String) && getFunds(activePost.id, activePost.authorLogin).then(res => setPostFunds(res.rows[0] || { new: true }));
        break;
      case 'rewardevent':
        isUserAction && ShowToast(`Вы получили награду ${data.event.award} по результатам тиража Фонда поста "${data.content_title.String}".`, 'success', toastTime);
        break;
      case 'prwdevent':
        isUserAction && ShowToast('Вывод из Фонда Меценатов успешно осуществлён.', 'success', toastTime);
        break;
      case 'prunsevent':
        isUserAction && ShowToast('Вывод из сейфа успешно осуществлён.', 'success', toastTime);
        break;
      case 'roundevent':
        checkLocation(data.content_guid.String) && getFunds(activePost.id, activePost.authorLogin).then(res => setPostFunds(res.rows[0] || { new: true }));
        break;
      case 'configevent':
        getPostConfig()
          .then((res:any) => MainStore.setFundConfig(res.rows[0]))
          .catch();
        break;
      default:
        break;
    }
  }, [
    userData.cyber_name,
    setUserBalances,
    activePost,
    setPostFunds,
  ]);

  const transactionAction = useCallback((data:any) => {
    switch (data.action) {
      case 'cw.token::transfer':
      case 'cyber.token::transfer':
        if (data.arguments.to === userData.cyber_name) {
          ShowToast(
            `Вы получили перевод ${data.arguments.quantity} от ${data.arguments.from}${data.arguments.memo.length ? ` C примечанием "${data.arguments.memo}"` : ''}.`,
            'success',
            toastTime,
          );
        }
        break;
      case 'stihi.patron::buynft':
        if (data.arguments.author === userData.cyber_name) {
          ShowToast(
            `NFT токен Вашего произведения ${data.article.title} был приобретен ${data.arguments.patron} зa ${data.arguments.amount}.`,
            'success',
            toastTime,
          );
        }
        break;
      default:
        break;
    }
  }, [userData.cyber_name]);

  useEffect(() => {
    if (counter === 0 && isVisible) {
      const time = updateTime > 0 ? Math.round((new Date().getTime() - updateTime) / 1000) > 86400 ? 86400 : Math.round((new Date().getTime() - updateTime) / 1000) : 0;
      setCounter(UPDATE_TIME);
      const paramsEvent = {
        user: userData.cyber_name,
        age: time > 0 ? time : UPDATE_TIME - 1,
      };
      isAuth && apiRequest({ ...getEventsCFG, params: paramsEvent })
        .then(res => {
          setLastEvents(res?.data.result || []);
          res?.data.result.map((el:IAwardsLine) => eventsAction(el));
        })
        .catch();

      const paramsAction = {
        login: userData.cyber_name,
        age: time > 0 ? time : UPDATE_TIME,
      };
      isAuth && apiRequest({ ...getActionsCFG, params: paramsAction })
        .then(res => res?.data.result.map((el:ITransaction) => transactionAction(el)))
        .catch();

      const roundParams = {
        // count: 3,
        age: time > 0 ? time : UPDATE_TIME,
      };
      apiRequest({ ...getLaroundsCFG, params: roundParams })
        .then(res => {
          res?.data.result.map((el: any) => laraoundMethod(el));
          if (!res?.data.result.length) {
            setRandomize();
          } else {
            addLaroundEvents(res?.data.result);
          }
        })
        .catch();
      /* if (activePost !== null) {
        getFunds(activePost.id, activePost.authorLogin)
          .then(res => {
            setPostFunds(res.rows[0] || { new: true });
          });
      } */
      if (isAdmin) getBlockedArtsData();

      setupdateTime(0);
      return;
    }
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  // eslint-disable-next-line max-len
  }, [counter, eventsAction, userData.cyber_name, isAdmin, transactionAction, activePost, postFundsObject, setPostFunds, isAuth, setLastEvents, isVisible, setupdateTime, updateTime, laraoundMethod, addLaroundEvents, setRandomize]);

  useEffect(() => {
    (!isVisible && updateTime === 0) && setupdateTime(new Date().getTime());
  }, [isVisible, updateTime, setupdateTime]);

  return (
    <></>
  );
};
export default observer(EventsContainer);
