/* eslint-disable max-classes-per-file */
import { ShowToast } from 'helpers';
import {
  observable, action, computed, toJS,
} from 'mobx';

import ModalStore from './Modal';

export type Pipeline = {
    title: string;
    tasks: Task[];
    dropValues?: boolean;
}
export type TaskResults = {
    [key: string]: any;
}

export type Task = {
    title: string;
    task: (variables: any, results: any) => Promise<void>;
    errorShow?: any;
    skip?: boolean;
}

export type PipelineHandler = (pipeline: PipelineStore) => void

export class PipelineStore {
    pipeline: Pipeline = {} as Pipeline;

    meta: any = {}

    @observable
    results: TaskResults = {} as TaskResults;

    @computed
    get name() {
      return this.pipeline.title;
    }

    @computed
    get tasks() {
      return this.pipeline.tasks.map(t => t.title);
    }

    @observable currentTask = 0

    @observable errorMessage = ''

    @observable isError = false

    @observable isProgress = false

    @observable isSuccess = false

    @computed
    get isInit() {
      return !this.isError && !this.isProgress && !this.isSuccess;
    }

    @computed
    get status() {
      if (this.isSuccess) return 'success';

      if (this.isError) return 'error';

      if (this.isProgress) return 'progress';

      return 'init';
    }

    dropPipline = () => {
      this.results = {};
      this.isError = false;
      this.isProgress = false;
      this.isSuccess = false;
      this.currentTask = 0;
    }

    runPipeline = async (meta: any) => {
      const { setLoading, disableLoading, modalSettings, modalControl } = ModalStore;
      let i = this.currentTask;
      this.isProgress = true;
      this.meta = meta;
      for (let l = this.tasks.length; i < l; ++i) {
        try {
          setLoading(this.pipeline.tasks[i].title);
          const result = await this.pipeline.tasks[i].task(this.meta, this.results);
          this.results = {
            ...this.results,
            [this.pipeline.tasks[i].title]: result,
          };
          this.currentTask = i;
        } catch (error: any) {
          if (this.pipeline.tasks[i].skip) continue;
          if (this.pipeline.dropValues) {
            modalControl();
            modalControl(toJS(modalSettings));
          }
          if (this.pipeline.tasks[i].errorShow) {
            ShowToast(`Ошибка "${this.pipeline.tasks[i].title}": ${this.pipeline.tasks[i].errorShow(this.meta, error)}`, 'error');
          }
          this.isError = true;
          this.isProgress = false;
          this.errorMessage = 'Ошибка';
          disableLoading();
          break;
        }

        if (i === this.pipeline.tasks.length - 1) {
          this.isSuccess = true;
          this.isProgress = false;
          this.currentTask = 0;
          disableLoading();
        }
      }
    }

    constructor(pipeline: Pipeline, private whenRun: PipelineHandler) {
      this.pipeline = pipeline;
    }
}

class PipelinesStore {
    pipelines = observable.map<string, PipelineStore>()

    createPipeline = (pipeline: Pipeline) => new PipelineStore(pipeline, this.runTasks)

    @action
    runTasks = (pipelineStore: PipelineStore) => {
      this.pipelines.set(pipelineStore.name, pipelineStore);

      return {
        pipeline: pipelineStore.pipeline,
        meta: pipelineStore.meta,
        currentTask: pipelineStore.currentTask,
      };
    }
}

export const PipeStore = new PipelinesStore();
