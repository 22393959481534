import { observer } from 'mobx-react';
import MainStore, { ModalStore, ThemeStore } from 'store';
import nc from 'assets/images/no-connection.png';
import './style.scss';
import { errorView } from 'settings/modal';
import Modal from 'UI/Modal';

const Crashed = () => {
  const { activeTheme, themeData } = ThemeStore;
  const { modalControl, open } = ModalStore;
  const { isCrached } = MainStore;
  return (
    <div className={`crashed crashed--${activeTheme}`}>
      {open && <Modal />}
      <img src={themeData?.logo || ''} alt="lg" />
      <p>Отсутствует стабильное подключение к сети интренет.</p>
      <br />
      <p><strong>Проверьте подключение и попробуйте еще раз.</strong></p>
      <p className="crashed_reload" onClick={() => window.location.reload()}>Обновить страницу</p>

      <img src={nc} alt="lg" className={`crashed_img crashed_img--${activeTheme}`} />
      <span className="note" onClick={() => modalControl(errorView, { object: isCrached })}>error log</span>
    </div>
  );
};
export default observer(Crashed);
