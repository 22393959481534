import { useState, useEffect } from 'react';

import useDebounce from 'hooks/useDebounce';
import Input from 'UI/InputText';
import DropDownSelect from 'UI/Select';
import { getResolveName } from 'helpers';

interface ISearchForm {
    searchResult: Function;
    full?: boolean;
}

const selectData = [
  { id: 'golos', name: 'Golos Login' },
  { id: 'cyber', name: 'Cyber ID' },
];

const SearchForm = ({ searchResult, full }: ISearchForm) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [nameType, setNameType] = useState<string>('golos');
  const debouncedSearchTerm = useDebounce(searchTerm, 1500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 2 && nameType === 'golos') {
      getResolveName(searchTerm, nameType)
        .then(res => { full ? searchResult(`${res}|${searchTerm}`) : searchResult(res); })
        .catch(searchResult(false));
    } else {
      searchResult(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, nameType]);

  return (
    <div className="w100">
      <Input
        onChange={(val: string) => setSearchTerm(val.trim())}
        pValue={searchTerm}
        noteLeft={<DropDownSelect selectedValue={nameType} valueKey="name" options={selectData} onSelect={setNameType} />}
      />
    </div>
  );
};

export default SearchForm;
