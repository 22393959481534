import moment from 'moment';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';

type Props = {
    data: BlockedData[];
    openSpoiler: boolean;
    theme: string;
    closeFunc: any;
}

type TimeValid = {
    Time: string;
    Valid: string;
}

type StringValid = {
    String: string;
    Valid: string;
}
type BlockedData = {
    BlockComment: StringValid;
    BlockedAt: StringValid;
    BlockedTime: TimeValid;
    UnBlockedComment: StringValid;
    UnblockedAt: StringValid;
    UnblockedTime: TimeValid;
    author: string;
    content_type: string;
    guid: string;
}

const BlockedHistory = ({ data, openSpoiler = false, theme, closeFunc }: Props) => (
  <div className={`blockedHistory ${openSpoiler ? 'blockedHistory--open' : ''}`}>
    {
        data.map((el: BlockedData) => (
          <div className={`blockedHistory_unit shadow blockedHistory_unit--${theme}`} key={el.BlockedTime.Time}>
            <p>
              <span>Заблокирован:</span>
              <span>{el.BlockedAt.String}</span>
              <span>{moment(el.BlockedTime.Time).format('Do MMMM y, h:mm:ss')}</span>
            </p>
            <p>
              <span>Комментарий:</span>
              <span>{el.BlockComment.String}</span>
            </p>
            <p>
              <span>Разблокирован:</span>
              <span>{el.UnblockedAt?.String}</span>
              <span>{el.UnblockedTime ? moment(el.UnblockedTime.Time).format('Do MMMM y, HH:mm:ss') : '---'}</span>
            </p>
            <p>
              <span>Комментарий:</span>
              <span>{el.UnBlockedComment?.String || '---'}</span>
            </p>
          </div>
        ))
    }
    <div onClick={closeFunc} className="blockedHistory_close">
      {' '}
      <Arrow />
      Свернуть
    </div>

  </div>
);

export default BlockedHistory;
