import { ReactElement, useEffect, useState } from 'react';

import {
  useLocation, Redirect,
} from 'react-router-dom';
import { ReactComponent as Broke } from 'assets/images/pencil.svg';
import MainStore, { ThemeStore } from 'store';
import Tabs from 'UI/MenuTabs';
import { MOBILE_POINT_MENU } from 'settings/main';
import MenuProfile from 'UI/MenuProfile';
import apiRequest from 'services/Api';
import { observer } from 'mobx-react';
import Preloader from 'UI/Preloader';
import CommentBox from 'pages/components/Comments/CommentBox';
import InfiniteScroll from 'react-infinite-scroll-component';

import './style.scss';

interface ISettingsProps {
  id: string;
  bPass: string;
}

interface ISettingsMenu {
  name: string;
  link: string;
}

export const CommentsMenu: ISettingsMenu[] = [{
  name: 'Оставленные комментарии',
  link: 'sended',
}, {
  name: 'Полученные комментарии',
  link: 'received',
}];

const COMMENT_COUNT = 10;

const CommentsUser = ({ id, bPass }: ISettingsProps): ReactElement => {
  const { activeTheme } = ThemeStore;
  const { screenWidth } = MainStore;
  const location = useLocation().pathname;
  const pg = location.split('/')[location.split('/').length - 1];

  const [list, setList] = useState<any>({ cursor: '', list: [] });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState<string>('');
  const basePath = `${bPass}/comments/`;

  const redirect = () => <Redirect push to="sended" />;

  const getComment = (cursor: string = '') => {
    if (!page.length) return;
    const isSended = page === 'sended';
    const params = cursor.length ? {
      cursor: list.cursor,
      count: COMMENT_COUNT,
    } : {
      count: COMMENT_COUNT,
      filter: `b:1|${isSended ? 'u:' : 'au:'}${id}`,
      order: 't',
    };

    apiRequest({
      link: `comment/user/${page}`,
      method: 'get',
      params,
    }).then(res => {
      setList({
        list: [...list.list, ...res.data.list.list],
        cursor: res.data.list.cursor,
      });
      setHasMore(res.data.list?.list.length === 0 ? false : res.data.list?.list.length === COMMENT_COUNT);
    })
      .catch();
  };

  useEffect(() => {
    pg.length > 0 && setPage(pg);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pg]);

  useEffect(() => {
    getComment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const onNextScroll = () => {
    getComment(list?.cursor);
  };

  return (
    <>
      <div className={`settingBox settingBox_${activeTheme}`} style={{ marginBottom: '20px' }}>
        {basePath === location && redirect()}
        <>
          <div className={`settingBox_menu settingBox_menu_${activeTheme}`}>
            {screenWidth >= MOBILE_POINT_MENU
              ? (
                <Tabs
                  list={CommentsMenu}
                  prefix={`${basePath}`}
                  onClick={(d: any) => {
                    setList({ cursor: '', list: [] });
                    setPage(d.link);
                  }}
                />
              )
              : (
                <MenuProfile
                  list={CommentsMenu}
                  prefix={basePath}
                  onChange={(d: any) => {
                    setList({ cursor: '', list: [] });
                    setPage(d.link);
                  }}
                />
              )}
          </div>
        </>
      </div>
      <>
        {list?.list.length ? (
          <InfiniteScroll
            style={{ minHeight: 'calc(100vh - 270px)' }}
            dataLength={list.list.length}
            hasMore={hasMore}
            next={onNextScroll}
            endMessage={(
              <div className={`noPosts noPosts--${activeTheme}`}>
                <Broke />
                <span>
                  Больше комментарив нет
                </span>
              </div>
            )}
            loader={<div className={`noPosts noPosts--${activeTheme}`}><Preloader /></div>}
          >
            <div className="post-comments-list">
              {list.list.map((el: any) => <CommentBox comment={el} key={`${el.id}${Math.random()}`} onlyRead />)}
            </div>
          </InfiniteScroll>
        ) : (
          <div className={`noPosts noPosts--${activeTheme}`}>
            <Broke />
            <span>
              Комментарив нет
            </span>
          </div>
        )}
      </>
    </>
  );
};

export default observer(CommentsUser);
