import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  NextPrev,
  nextPrevButtons,
  nextPrevPageKeywords,
} from 'pages/Post/data';
import {
  getContentFromPost, storeNewPosts,
} from 'helpers';
import MainStore, { CommentsStore, ThemeStore } from 'store';
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg';
import { IPost } from 'types';

interface INextPrevPostsProps {
  updateContent: (newContent: string) => void;
  margin?: string | number;
  fetch: any;
}

const NextPrevPosts = ({ updateContent, margin }: INextPrevPostsProps) => {
  const history = useHistory();
  const {
    activePost, listType, navObject, setActivePost, posts, setPostFunds,
  } = MainStore;
  const { dropCommentsList } = CommentsStore;

  const { activeTheme } = ThemeStore;
  const { cursor } = activePost || {};

  const postPosition = posts.list.findIndex((el: IPost) => el.id === activePost.id);
  const nextIndex = postPosition + 1;
  const prevIndex = postPosition - 1;
  const postListLength = posts.list.length;

  const isNeedToLoading = (postListLength - postPosition) < 3;
  const isLast = postPosition - 1 === posts.list.length;
  const isFirst = postPosition === 0;

  const clickNext = async (type: NextPrev) => {
    if (isNeedToLoading) storeNewPosts();

    const { id, authorLogin } = posts.list[type === 'prev' ? nextIndex : prevIndex];
    const { meta: { content: { hash } } } = setActivePost(id);
    const newContent = await getContentFromPost(hash);

    window.scrollTo({ top: 0 });
    updateContent(newContent);
    history.push({ pathname: `/post/${authorLogin}/${id}`, state: history.location.state });
    setPostFunds({});
    dropCommentsList();
  };

  const nextPrevTitle = nextPrevPageKeywords[listType];

  return (cursor && Object.keys(navObject).length ? (
    <div className={`feedPost_nav feedPost_nav--${activeTheme}`} style={{ margin: margin || '' }}>
      {nextPrevButtons.map(({ id: nextPrevButtonsId, className, type, title: btnTitle, iconDeg }) => (
        <div
          key={`next-prev-${nextPrevButtonsId}`}
          className={`${className} ${((type === 'next' && isFirst) || (type === 'prev' && isLast)) ? 'hide-btn' : ''}`}
          onClick={async () => clickNext(type)}
        >
          <p>{`${btnTitle} ${nextPrevTitle}`}</p>
          <ArrowIcon style={{ transform: `rotate(${iconDeg}deg) scale(1.2)` }} />
        </div>
      ))}
    </div>
  ) : null);
};

export default observer(NextPrevPosts);
