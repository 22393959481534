import { ReactElement, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import Amount from 'components/Amount';
import { ModalStore, ThemeStore } from 'store';
import apiRequest from 'services/Api';
import { createTransactionsList } from 'helpers';
import { jsonShow } from 'settings/modal';
import Preloader from 'UI/Preloader';
import { ReactComponent as Broke } from 'assets/images/pencil.svg';

import './style.scss';

interface ITransactionsProps {
  id: string;
}

export interface ITransaction{
  authorization: string;
  index: number;
  receiver: string;
  action: string;
  arguments: {};
}

const Transactions = (({ id }: ITransactionsProps): ReactElement => {
  const [transactionList, setTransactionsList] = useState<any>([]);
  const [cleanTransactionList, setCleanTransactionList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState(10);
  const { activeTheme } = ThemeStore;
  useEffect(() => {
    const conf = {
      link: 'actions/history',
      method: 'get',
      params: {
        login: id || '',
        count: 100,
      },
    };
    apiRequest(conf).then(res => {
      setTransactionsList(res.data.result);
    })
      .catch()
      .finally(() => setLoading(false));
  }, [id]);

  useEffect(() => {
    if (transactionList?.length) setCleanTransactionList(createTransactionsList(transactionList.filter((x:any) => x.type !== 'updateauth'), id));
  }, [id, transactionList]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY > document.body.clientHeight - 100) {
        if (cleanTransactionList.length > limit) setLimit(limit + 10);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cleanTransactionList, limit]);

  const returnBody = (data: any) => ({ __html: data || '' });

  return (
    <div className="walletTransactions">
      { loading ? <div className="profilePreloader"><Preloader /></div>
        : cleanTransactionList.length ? cleanTransactionList.slice(0, limit).map((el: any, index:any) => (
          <div
            className={`walletTransactions_line walletTransactions_line_${activeTheme}`}
            key={`${el.time + index}`}
          >
            <div className="walletTransactions_line_wayTime">
              <span className="walletTransactions_line_wayTime--actor">
                <Link to={`/${el.actor}`}>
                  {`${el.vector} ${el.actor}`}
                </Link>
              </span>
              <span className={`walletTransactions_line_wayTime--action walletTransactions_line_wayTime--action_${activeTheme}`}>
                {el.action}
                <div onClick={() => ModalStore.modalControl(jsonShow, { object: transactionList[index] })}>TRX</div>
              </span>
              <span className={`walletTransactions_line_wayTime--time walletTransactions_line_wayTime--time_${activeTheme}`}>
                {el.time}
              </span>
            </div>
            <div className={`walletTransactions_line--comment walletTransactions_line--${activeTheme}`} dangerouslySetInnerHTML={returnBody(el.memo)} />
            {el.amount
              ? (
                <Amount cyber={el.token === 'CYBER'} amount={el.amount.toFixed(4)} classname="ac" />
              )
              : null}
          </div>
        ))
          : (
            <div className={`noPosts noPosts--${activeTheme}`}>
              <Broke />
              <span>
                Пока ничего не получено.
              </span>
            </div>
          )}

    </div>
  );
});

export default observer(Transactions);
