import { useHistory } from 'react-router-dom';
import './style.scss';
import MainStore, { ThemeStore } from 'store';
import { useMount } from 'hooks';

const Confirmation = () => {
  const history = useHistory();
  const { setMetaDataStore } = MainStore;
  const { activeTheme } = ThemeStore;

  const linkToGo = history.location.state as string;

  const goBack = () => history.goBack();

  useMount(() => setMetaDataStore({
    title: 'Страница перехода на внешний ресурс',
    description: 'Страница перехода на внешний ресурс',
  }));

  if (!linkToGo) {
    goBack();
  }

  return (
    <div className="confirmation-content">
      <div className={`confirmation-box confirmation-box--${activeTheme}`}>
        <h2>
          Вы покидаете
          <a href={window.location.origin} target="_blank" rel="noreferrer"> Stihi.io</a>
        </h2>
        Вы нажали внешнюю ссылку
        {' '}
        <b>{linkToGo}</b>
        , размещенную одним из авторов.
        <br />
        <b>Stihi.io</b>
        {' '}
        не имеет никакого отношения к сайту, расположенному по ссылке выше, и не может гарантировать Вам безопасность его использования.
        <br />
        Некоторые сайты могут содержать вирусы, использовать мошеннические схемы. Если у Вас нет серьезных оснований для доверия внешнему ресурсу, рекомендуем Вам отказаться от перехода по ссылке.
        <br />
        Помните, что активный ключ Вашего аккаунта на
        {' '}
        <b>Stihi.io</b>
        {' '}
        не может быть восстановлен, а доступ к нему позволит мошенникам завладеть всеми Вашими средствами.
        <br />
        <br />
        <ul>
          <li>
            <div className="confirm-links" onClick={() => window.open(linkToGo, '_blank')}>
              Перейти по ссылке
              <b>
                {' '}
                {linkToGo}
              </b>
            </div>
          </li>
          <li>
            <div className="confirm-links" onClick={goBack}>
              Отказаться от перехода и
              <b> вернуться назад</b>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Confirmation;
