import { useState } from 'react';

import useDebounce from 'hooks/useDebounce';
import { decryptPassword, getKeyType, ShowToast } from 'helpers';
import MainStore, { ModalStore } from 'store';
import Input from 'UI/InputText';
import { useMount } from 'hooks';
import { decipher } from 'utils/KeyUtils';
import { ReactComponent as Eye } from 'assets/images/eye.svg';
import { ReactComponent as HideEye } from 'assets/images/eye-hide.svg';
import './style.scss';
import LocalStorage from 'services/LocalStorage';
import ModalNote from 'UI/ModalNote';
import Checkbox from 'UI/InputChechbox';
import { observer } from 'mobx-react';
import useEffectAsync from 'hooks/useEffectAsync';

interface IInputPassword {
    placeholder: string;
    checkLogin?: string;
    golos?: string;
    type?: 'p' | 'k';
    forceKey?: boolean;
}

const InputPassword = ({ placeholder, checkLogin = '', golos = '', type = 'k', forceKey = false }: IInputPassword) => {
  const { userData, isAuth } = MainStore;
  const { modalValues, modalSettings, objectChangeHandler, setLoading } = ModalStore;
  const [searchTerm, setSearchTerm] = useState<string>(' ');
  const [isShowPass, setShowPass] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const showPassStatus = (
    <div
      className="showPassword"
      onClick={() => setShowPass(!isShowPass)}
    >
      {!isShowPass ? <HideEye /> : <Eye />}
    </div>
  );

  useMount(() => {
    if (modalSettings.tag !== 'posting') return;
    const key = LocalStorage.get('pk');
    if (key) {
      modalSettings.saveKey = true;
      setSearchTerm(decipher(key));
    }
  });

  const saveIfPosting = () => {
    modalSettings.saveKey = !modalSettings.saveKey;
  };

  useEffectAsync(async () => {
    if (!isAuth && !checkLogin.length) return;
    if (debouncedSearchTerm.length < 16) return;
    if (type === 'p' && searchTerm[0] !== 'P') {
      ShowToast('вы ввели не валидный пароль', 'error');
      return;
    }
    if (forceKey) {
      objectChangeHandler({ key: searchTerm });
      modalSettings.payload = {
        p: searchTerm,
        g: userData.golos_name,
      };
      ModalStore.trueKey = true;
      return;
    }
    setLoading('Проверка ключа', false);
    let key: string = searchTerm;
    let keyType: string = '';
    if (searchTerm[0] === 'P') {
      key = await decryptPassword(!isAuth ? golos : userData.golos_name, 'active', searchTerm);
      LocalStorage.set('afp', true);
      modalSettings.passwd = true;
      if (!isAuth) {
        modalSettings.payload = {
          p: searchTerm,
          g: golos,
        };
      }
    } else {
      LocalStorage.remove('afp');
    }
    keyType = forceKey ? 'password' : await getKeyType(key, !isAuth ? checkLogin : userData.cyber_name);
    setLoading();
    if (keyType) {
      objectChangeHandler({ keyType, key });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, checkLogin]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Input
        placeholder={placeholder}
        pValue={searchTerm.trim()}
        type={isShowPass ? 'text' : 'password'}
        noteRight={showPassStatus}
        onChange={(val:string) => setSearchTerm(val.trim())}
      />
      <ModalNote
        text={
          modalSettings.note
            ? modalSettings.note
            : modalSettings.tag === 'posting'
              ? 'Эта операция требует ввода Вашего приватного ключа'
              : 'Эта операция требует ввода Вашего активного ключа или ввода Вашего ключа владельца'
            }
      />
      {modalSettings.tag === 'posting'
        ? (
          <div className="modal_body_line">
            <Checkbox
              label="Это постинг ключ и я хочу его хранить в браузере."
              checkBoxHandler={saveIfPosting}
              checked={modalSettings.saveKey || false}
              disabled={modalValues.keyType !== 'posting'}
            />
          </div>
        )
        : (
          <div className="modal_body_line">
            <span className="modal_body_line_note">Stihi.io не хранит Ваши ключи</span>
          </div>
        )}
    </div>
  );
};

export default observer(InputPassword);
