interface IAwardsMenu {
  name: string;
  link: string;
}

const awardsMenu: IAwardsMenu[] = [{
  name: 'Полученные донаты',
  link: '/donats/',
}, {
  name: 'Из фондов постов',
  link: '/foundations/',
}];
export default awardsMenu;
