import { observer } from 'mobx-react';
import MainStore from 'store';
import ActionMenu from 'UI/ActionMenu';
import Preloader from 'UI/Preloader';

interface IBalanceLineProps {
  id: string;
  activeTheme: string;
  name: string;
  menuItems?: any;
  menuHide?: boolean;
  note: string;
  amount?: string | number;
  icon?: any;
  isLoading?: boolean;
  styles?: any;
  invert?: boolean;
}

const BalanceLine = ({
  id,
  activeTheme,
  name,
  amount,
  icon,
  menuItems,
  menuHide,
  note,
  isLoading,
  styles,
  invert,
} : IBalanceLineProps) => {
  const { checkIsUser, isMobile } = MainStore;
  return (
    <div className={`walletBalances_line walletBalances_line_${activeTheme}`} style={styles || {}}>
      <div className={`walletBalances_line_title walletBalances_line_title_${activeTheme}`}>
        <span><h1 className={isMobile ? 'mobileH1' : ''}>{name}</h1></span>
        <div className={`walletBalances_line_title_amount walletBalances_line_title_amount_${activeTheme}`}>
          {amount
            ? (
              isLoading
                ? <Preloader />
                : (
                  <div
                    className={(invert && activeTheme === 'dark') ? 'invertIcon' : ''}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {icon}
                    <span>
                      {`${amount || '0.0000'}`}
                    </span>
                  </div>
                )
            ) : null}
          {(checkIsUser(id) && menuItems)
            ? <ActionMenu items={menuItems} hide={menuHide || false} />
            : <div className="walletBalances_line_title_amount_pseudoMenu" />}
        </div>
      </div>
      <span>
        {note}
      </span>
    </div>
  );
};
export default observer(BalanceLine);
